import { useEffect } from "react";
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
// components
import { Box, Stack } from "@mui/material";
import { Tabs } from "@asayinc/component-library";
// Redux/Data
import { useQuestionsPageData } from "./hooks";
// constants
import { TabOptions, tabs } from "./constants";
// tabs
import { Header } from "./components";
import { Participants, Questions, Overview } from "./subPages";

const EventDetail = (): JSX.Element => {
  // data
  const { title, status } = useQuestionsPageData();
  // selected on page and current page result ids
  const navigate = useNavigate();
  const [eventSlug = "", view = TabOptions.questions.value] = Object.values(
    useParams()
  );

  useEffect(() => {
    document.title = `${title} | Say Issuer Portal`;
  }, [title]);

  const setActiveTab = (tab: string) => {
    navigate(`/qa/${eventSlug}/${tab.toLowerCase()}`);
  };

  return (
    <Stack
      p={10}
      width="100%"
      maxWidth={1264}
      m="0 auto"
      data-testid="event-detail-page"
    >
      <Stack mb={8}>
        <Header eventTitle={title} eventSlug={eventSlug} status={status} />
        <Tabs activeTab={view} clickHandler={setActiveTab} tabs={tabs} />
      </Stack>
      <Box>
        <Routes>
          <Route path="overview" element={<Overview />} />
          <Route path="questions" element={<Questions />} />
          <Route path="participants" element={<Participants />} />
          <Route path="" element={<Navigate to="overview" replace />} />
        </Routes>
      </Box>
    </Stack>
  );
};

export default EventDetail;
