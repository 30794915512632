import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
// material
import { Stack } from "@mui/material";
// say
import { DATE_FORMAT, TableV2 as Table } from "@asayinc/component-library";
// redux / data
import { useEventsPageData, useHasAnEvent } from "./hooks";
import { useSearchFns } from "../../hooks/useSearchFns";
// utils
import { paginationAction, track } from "../../analytics";
import { getColumnData, getRowData } from "./factories";
import { getNoResultsProps } from "./utils";
//constants
import { COLUMNS } from "./constants";
import { ROW_OPTIONS, SORT, SORT_SYMBOL, URL_PARAMS } from "../../constants";
// components
import { ListHeader } from "../../components/Common";
import { EventsListContainer, NoEvents } from "./components";
// types
import { TableEventData } from "../../types/Table";
import { useRequestMoreInfo, useTableSearchParams } from "../../hooks";
import { CollapsingAlert } from "../../components/Messages";
import { useGetFeaturesQuery } from "../../store/settings";
import { UiEventActions } from "../../store/consumerUser/types";

const Events = (): JSX.Element => {
  const didMount = useRef<boolean>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getSort, sortFn, ordering } = useTableSearchParams({
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.startTime}`,
  });
  const {
    searchParams,
    page,
    search,
    setPage,
    limit,
    setLimit,
    completedEvents,
    count,
    isLoading,
    isFetching,
  } = useEventsPageData({ ordering });
  const { data: features, isSuccess: isFeaturesSuccess } =
    useGetFeaturesQuery();
  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoQA,
  });

  const searchFns = useSearchFns(URL_PARAMS.search);

  const params = Object.fromEntries(searchParams);

  useEffect(() => {
    document.title = "Events | Say Issuer Portal";
  }, []);

  /**
   * Track search
   */
  useEffect(() => {
    if (search && didMount.current) {
      track({
        name: "Event Searched",
        searchApplied: Boolean(search),
        searchTerm: search,
      });
    }
  }, [search]);

  // component mounted
  useEffect(() => {
    didMount.current = true;
  }, []);

  /**
   * pull questions for the given page number
   */
  const handlePageChange = (newPage: number) => {
    paginationAction("Page", "Events", newPage, count, params);
    setPage(newPage.toString());
  };

  /**
   * pull questions with new rows per page, reset page to 0
   */
  const handleRowsChange = (newRows: number) => {
    paginationAction("Row", "Events", newRows, count, params);
    setPage("1");
    setLimit(newRows.toString());
  };

  /**
   * Navigate to QA Event
   */
  const goToEvent = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      trackEventClicked(id);
      navigate(`${pathname}/${id}/overview`);
    },
    [pathname, completedEvents]
  );

  /**
   * Track event clicked
   */
  const trackEventClicked = (id: string) => {
    const event = completedEvents.find((event) => event.slug === id);
    track({
      name: "Q&A Event Opened",
      slug: event?.slug,
      eventDate: dayjs(event?.eventStartDatetime).format(DATE_FORMAT),
      status: event?.eventState,
    });
  };

  /**
   * Column data for table
   */
  const columnData = useMemo(
    () => getColumnData({ goToEvent, getSort, sortFn }),
    [getSort, sortFn, goToEvent]
  );

  /**
   * Each rows specific data for table
   */
  const rowData = getRowData({ completedEvents });

  /**
   * content for when there are no results
   */
  const noResultsData = getNoResultsProps(params);

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search for a Q&A",
  };

  const {
    hasDraftEvent,
    hasPendingEvent,
    hasApprovedEvent,
    hasPublishedEvent,
    hasActiveEvent,
    hasCompletedEvent,
    hasAnEvent,
    loading,
  } = useHasAnEvent();

  const alertActions = [
    {
      label: "Request more info",
      onClick: requestMoreInfo,
    },
  ];

  return (
    <>
      <Stack px={10} maxWidth={1440} m={"0 auto"} height="100%">
        <ListHeader
          title="Q&As"
          buttonProps={{
            url: "/qa/create",
            text: "New Q&A",
          }}
          displayCta={hasAnEvent && features?.qAndA}
        />
        {isFeaturesSuccess && !features?.qAndA && (
          <CollapsingAlert
            showAlert={true}
            actions={alertActions}
            showNotificationIcon={false}
            defaultCloseIcon={false}
            message="Expand your Say feature set to engage shareholders in Q&A events."
            sx={{
              width: "100%",
              px: "32px !important",
              mb: 8,
            }}
          />
        )}
        {hasAnEvent || loading ? (
          <>
            {/* render draft events container for loading state */}
            {(hasDraftEvent || loading) && (
              <EventsListContainer
                title="Drafts"
                status="draft"
                showLoadingState={loading}
              />
            )}
            {/* display all event containers when all events have finished loading */}
            {!loading && (
              <>
                {hasPendingEvent && (
                  <EventsListContainer
                    title="Pending approval"
                    status="pending_approval"
                  />
                )}
                {hasApprovedEvent && (
                  <EventsListContainer title="Approved" status="approved" />
                )}
                {hasPublishedEvent && (
                  <EventsListContainer title="Published" status="published" />
                )}
                {hasActiveEvent && (
                  <EventsListContainer title="Active" status="active" />
                )}
              </>
            )}
            {/* render completed table for loading state */}
            {(hasCompletedEvent || loading) && (
              <Table
                rows={rowData}
                isLoading={isLoading || loading}
                isFetching={isFetching || loading}
                tableLayout="auto"
                columnData={columnData}
                title={`Completed (${count})`}
                columns={COLUMNS}
                count={count}
                searchBarProps={searchBarProps}
                tableMinWidth={600}
                testid="allevents-table"
                memoCells
                noResultsData={noResultsData}
                paginateProps={{
                  onChangePage: handlePageChange,
                  onChangeRows: handleRowsChange,
                  count: count,
                  page: parseInt(page),
                  rowsPerPage: parseInt(limit),
                  rowOptions: ROW_OPTIONS,
                }}
              />
            )}
          </>
        ) : (
          <NoEvents featureEnabled={features?.qAndA} />
        )}
      </Stack>
    </>
  );
};

export default Events;
