import {
  AutocompleteOptionType,
  HookAutocomplete,
  Text,
} from "@asayinc/component-library";
import { List } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TIMEZONE_OPTIONS } from "../../../../constants/timezones";
import { ITimeZoneOption } from "../../../../types/Timezone";

interface IProps {
  fieldName?: string;
  disabled?: boolean;
  validateOnChange?: boolean;
}

const TimeZoneAutocomplete = ({
  disabled,
  validateOnChange = true,
  fieldName = "timezone",
}: IProps) => {
  const { trigger } = useFormContext();

  // trigger validation on form as timezone may effect validation of other fields
  const onChangeCallback = () => {
    if (validateOnChange) {
      trigger();
    }
  };
  return (
    <HookAutocomplete
      name={fieldName}
      callback={onChangeCallback}
      options={TIMEZONE_OPTIONS}
      disabled={disabled}
      optionType={AutocompleteOptionType.CheckIcon}
      groupBy={(option) => (option as ITimeZoneOption).group}
      size="small"
      textFieldProps={{
        outerLabel: "Timezone",
      }}
      rules={{
        required: {
          value: true,
          message: "Timezone is required.",
        },
      }}
      renderGroup={(params) => {
        return (
          <List key={params.key}>
            <Text ml={4} variant="overline" emphasis="disabled">
              {params.group}
            </Text>
            <List>{params.children}</List>
          </List>
        );
      }}
    />
  );
};

export default TimeZoneAutocomplete;
