import { useIsMounted } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useState } from "react";
import {
  DragDropContext,
  DraggableLocation,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ICompanyLeader } from "../../../../../../types/Events";
import Leader from "../Leader";

interface IProps {
  selectable?: boolean;
  draggable?: boolean;
  leaders: ICompanyLeader[];
  editable?: boolean;
  deletable?: boolean;
  deleteLeader: (id: string) => void;
  saveOrAddLeader: (data: {
    leader: Partial<ICompanyLeader>;
    add?: boolean;
    id: string;
  }) => void;
  isLoading?: boolean;
}

const InteractiveLeadershipList = ({
  selectable,
  draggable,
  editable,
  deletable,
  leaders,
  saveOrAddLeader,
  deleteLeader,
  isLoading,
}: IProps) => {
  const { move } = useFieldArray({
    name: "leaders",
  });
  const { setValue } = useFormContext();
  const isMounted = useIsMounted();

  /**
   * This state is used as a buffer for the time it takes react-hook-form to update values and re-render
   * Without it there would be a flicker in the time drop finishes and state updates
   */
  const [temporaryLeaders, setTemporaryLeaders] = useState<
    ICompanyLeader[] | null
  >(null);

  // use temporary leaders when theyre set
  const leadersToUse = temporaryLeaders || leaders;

  /**
   * delay checking a leader
   * To avoid two setStates having a race condition, we need to wait for the first to finish
   * More info can be seen at the location this is invoked
   */
  const delayCheckLeader = (
    check: boolean,
    destination?: DraggableLocation
  ) => {
    setTimeout(() => {
      if (destination) {
        setValue(`leaders.${destination.index}.enabled`, check, {
          shouldTouch: true,
          shouldDirty: true,
        });
      }
    }, 100);
  };

  /**
   * when drag completes update react hook form by 'move'ing the item that was dragged
   * to its new location.
   * Also need to temporarily set the new order in local state to prevent a flicker
   * latest version of DND does not seem testable with react-testing-library
   */
  /* istanbul ignore next */
  const dragEnd = (result: DropResult) => {
    if (result.destination && result.source) {
      ////// temporary array to avoid flicker //////
      const newLeaders = Array.from(leaders);
      newLeaders.splice(result.source.index, 1);
      newLeaders.splice(
        result.destination.index,
        0,
        leaders[result.source.index]
      );
      setTemporaryLeaders(newLeaders);
      // reset temp leaders after next rerender
      setTimeout(() => {
        if (isMounted()) {
          setTemporaryLeaders(null);
        }
      }, 300);
      /////// end temporary array /////
      move(result.source.index, result.destination.index);

      // If a leader is dragged above the lowest selected leader, then check that leader
      if (
        leaders[result.destination.index].enabled &&
        !leaders[result.source.index].enabled
      ) {
        // wait for drag and drop to complete before checking
        delayCheckLeader(true, result.destination);
      } else if (
        result.destination.index > 0 &&
        !leaders[result.destination.index].enabled &&
        leaders[result.source.index].enabled
      ) {
        // wait for drag and drop to complete before un-checking
        delayCheckLeader(false, result.destination);
      }
    }
  };

  /**
   *
   * @param wasChecked - value of leader after selecting true = turned on false = turned off
   * @param leaderId - id of leader selected
   * @param idx - index of leader selected
   */
  const selectLeader = (wasChecked: boolean, leaderId: string, idx: number) => {
    let indexToMoveTo = -1;

    if (wasChecked) {
      // find index of first unchecked
      const firstUnchecked = leaders.findIndex(
        (itm) => !itm.enabled && leaderId !== itm.id
      );
      // if an unchecked item is above the newly checked leader, move it up
      if (firstUnchecked < idx) {
        indexToMoveTo = firstUnchecked;
      }
    } else {
      const someUnChecked = leaders.some(
        (itm) => !itm.enabled && itm.id !== leaderId
      );
      // if nothing is unchecked move to the bottom
      if (!someUnChecked) {
        indexToMoveTo = leaders.length - 1;
      } else {
        // find index of lowest checked item and move it below that item
        indexToMoveTo =
          leaders.findIndex((itm) => !itm.enabled && leaderId !== itm.id) - 1;
      }
    }
    // give react-hook-form a render cycle to process the selection then move it to its new location
    if (indexToMoveTo >= 0) {
      setTimeout(() => {
        if (isMounted()) {
          move(idx, indexToMoveTo);
        }
      }, 100);
    }
  };

  const leaderList = leadersToUse.map((leader, idx) => (
    <Leader
      key={leader.id}
      leader={leader}
      idx={idx}
      isLoading={isLoading}
      selectLeader={selectLeader}
      editable={editable}
      deletable={deletable}
      selectable={selectable}
      draggable={draggable}
      deleteLeader={deleteLeader}
      saveOrAddLeader={saveOrAddLeader}
    />
  ));

  const draggableContent = (
    <DragDropContext onDragEnd={dragEnd}>
      <Droppable droppableId="leaders">
        {(provided) => (
          <Stack {...provided.droppableProps} ref={provided.innerRef}>
            {leaderList}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );

  return draggable ? draggableContent : <Stack>{leaderList}</Stack>;
};

export default InteractiveLeadershipList;
