import { track } from ".";
import { Params } from "../types/Common";

const paginationAction = (
  action: "Row" | "Page",
  table: "Events" | "Questions",
  newValue: number,
  totalCount: number,
  params: Params
): void => {
  const currentRowLimit = Number(params.rows) || 10;
  const currentPage = Number(params.page) || 1;
  const totalPages = Math.ceil(totalCount / currentRowLimit);

  const getActionType = () => {
    if (action === "Row") {
      return `Row Limit changed to ${newValue}`;
    } else {
      if (newValue === currentPage - 1) {
        return "Back Page";
      } else if (newValue === currentPage + 1) {
        return "Forward Page";
      } else if (newValue === 1) {
        return "First Page";
      } else {
        return "Last Page";
      }
    }
  };

  track({
    name: "Pagination Action",
    action: getActionType(),
    table,
    currentPage,
    currentRowLimit,
    searchApplied: Boolean(params.query),
    sortingApplied: Boolean(params.ordering),
    statusFiltersApplied: Boolean(params.filter),
    tagFilterApplied: Boolean(params.tag_ids),
    totalItems: totalCount,
    totalPages,
    userTypeFilterApplied: Boolean(params.type),
  });
};

export { paginationAction };
