import { HookSelect, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { generateYears } from "../../../../helpers/generateYears";

interface IProps {
  isDisabled?: boolean;
}

const CADENCE_OPTIONS = [
  {
    label: "Annual",
    value: "annual",
  },
  {
    label: "Special",
    value: "special",
  },
];

const CADENCE_MAPPING = CADENCE_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);

const ShareholderMeetingFields = ({ isDisabled }: IProps) => {
  /**
   * generate select values for the previous 1 year to the next 5
   */
  const years = useMemo(() => generateYears(-1, 5), []);
  const yearsOptions = useMemo(
    () => years.map((itm) => ({ label: itm, value: itm })),
    []
  );
  const yearsMapping = useMemo(
    () =>
      yearsOptions.reduce(
        (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
        {}
      ),
    []
  );

  return (
    <>
      <Text variant="subtitle1">Shareholder meeting </Text>
      <Box width="100%">
        <HookSelect
          valueMapping={yearsMapping}
          clearable
          outerLabel="Year"
          disabled={isDisabled}
          placeholder="e.g., 2023"
          menuTitle="Year"
          displayEmpty
          size="medium"
          name="meta.fiscalYear"
          options={yearsOptions}
          rules={{
            required: "Year is required",
          }}
        />
      </Box>
      <Box width="100%">
        <HookSelect
          valueMapping={CADENCE_MAPPING}
          clearable
          outerLabel="Meeting Type"
          disabled={isDisabled}
          displayEmpty
          placeholder="e.g., Annual"
          menuTitle="Meeting Type"
          size="medium"
          name="meta.cadence"
          options={CADENCE_OPTIONS}
          rules={{
            required: "Meeting Type is required",
          }}
        />
      </Box>
    </>
  );
};

export default ShareholderMeetingFields;
