// determine which menu sections to display, only display auth with say if tesla
export const getMenuSections = (isTesla: boolean) => {
  const menuSections = [
    {
      title: "General",
      id: "1",
      menuItems: [
        {
          title: "Company details",
          id: "company-details",
        },
        {
          title: "Brand assets",
          id: "brand-assets",
        },
        {
          title: "Company leadership",
          id: "company-leadership",
        },
      ],
    },
    {
      title: "Features",
      id: "2",
      menuItems: [
        {
          title: "Q&A",
          id: "qa",
        },
        {
          title: "Messages",
          id: "messages",
        },
      ],
    },
  ];
  if (isTesla) {
    menuSections[1].menuItems.push({
      title: "Auth with say",
      id: "auth-with-say",
    });
  }
  return menuSections;
};
