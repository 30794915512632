import { useContext } from "react";
import { Box, Stack } from "@mui/material";
import { Text } from "@asayinc/component-library";
import ShareholderTags from "../ShareholderTags";
import { BasicInfo, MessagesSummary, QASummary } from "./components";
// data
import { Context } from "../../../context";

const ShareholderSummary = () => {
  const {
    hasQaActivity,
    hasMessagesActivity,
    isQaDataSuccess,
    isMessagesDataSuccess,
  } = useContext(Context);
  const isSuccess = isQaDataSuccess || isMessagesDataSuccess;
  return (
    <Stack spacing={8} data-testid="shareholder-summary">
      <BasicInfo />
      <ShareholderTags />
      {hasQaActivity || hasMessagesActivity || !isSuccess ? (
        <>
          <QASummary />
          <MessagesSummary />
        </>
      ) : (
        <Stack alignItems="center" data-testid="shareholder-no-activity-view">
          <Box width="400px">
            <img src="https://assets.say.rocks/images/broker-portal/empty_general.svg" />
          </Box>
          <Text variant="body3" emphasis="medium" align="center">
            There isn&apos;t much else about this shareholder right now. More
            insights will be shown once they participate a Q&A or receive a
            message.
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default ShareholderSummary;
