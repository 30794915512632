import { Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { EventCard } from "../../Molecules";
import { Event } from "../../../../../types/Events";
import { Carousel } from "../../../../../components/Common";
import { SwiperSlide } from "swiper/react";

interface IProps {
  events: Event[];
  title: string;
  count: number;
  isLoading: boolean;
  offset: number;
}

const EventCardList = ({ events, count, title, isLoading, offset }: IProps) => {
  const didMount = useRef<boolean>(false);
  const [allEvents, setEvents] = useState<Event[]>(events || []);

  useEffect(() => {
    // if mounting hasnt been set, than this first pass of data is already added in initial state
    if (didMount.current && events.length) {
      if (offset === 0) {
        setEvents(events);
      } else {
        const mergedEvents = [...allEvents, ...events].filter(
          (v, i, a) => a.findIndex((v2) => v2.slug === v.slug) === i
        );
        setEvents(mergedEvents);
      }
    }
  }, [events]);

  useEffect(() => {
    didMount.current = true;
  }, []);

  const loadingContent = Array(3)
    .fill(true)
    .map((_, i) => <EventCard isLoading={true} key={"loading" + i} />);

  return (
    <Stack mb={8} data-testid={`cards-${title}`} gap={6}>
      <Text variant="subtitle1" loadingProps={{ sx: { width: "80px" } }}>
        {isLoading ? undefined : `${title} (${count})`}
      </Text>
      {isLoading ? (
        <Stack direction="row" gap={6} pl="13px" ml="-13px" pr={10}>
          {loadingContent}
        </Stack>
      ) : (
        <Carousel>
          {allEvents.map((event) => (
            <SwiperSlide key={event.slug}>
              <EventCard event={event} />
            </SwiperSlide>
          ))}
        </Carousel>
      )}
    </Stack>
  );
};

export default EventCardList;
