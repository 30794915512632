export interface IMessageOverviewAPIResponse {
  campaignName: string;
  containsLinks: boolean;
  containsReplies: boolean;
  stats: IAdditionalMessageStats;
}

export interface IBaseMessageStats {
  count: number | null;
  openedCount: number | null;
  openedRate: number | null;
  deliveredCount: number | null;
  votedCount: number | null;
  votedShares: number | null;
  votedRate: number | null;
  deliveredRate: number | null;
  clickedCount: number | null;
  clickedRate: number | null;
  repliedCount: number | null;
  repliedRate: number | null;
  gainedPct: number | null;
}

export interface IAdditionalMessageStats extends IBaseMessageStats {
  openedShares: number;
  deliveredShares: number;
  clickedShares: number;
  repliedShares: number;
}

export enum MessageExportTypes {
  Clicks = "clicks",
  Responses = "responses",
  Overview = "overview",
}
