import { IShareholderListRequestQuery } from "../../../../../../../store/shareholderList/types";

/**
 * Get no results messaging
 */
export const getNoResultsProps = (
  searchParams: URLSearchParams
): { title: string; subtitle: string } => {
  const hasFilterApplied =
    searchParams.get("filter") ||
    searchParams.get("query") ||
    searchParams.get("type");
  return {
    title: hasFilterApplied
      ? "No shareholders in this filtered view"
      : "No shareholders yet",
    subtitle: hasFilterApplied
      ? "Sorry, there are no shareholders that match your applied filters."
      : "There have not been any shareholders for this event yet.",
  };
};

interface IParamObj {
  [key: string]: string;
}

/**
 * Converts URL params to query object for Shareholder List request
 */

export const createQueryObj = (
  paramObj: IParamObj
): IShareholderListRequestQuery => {
  const query = {};
  const { sharesOwnedMin, sharesOwnedMax, ticker, country, state } = paramObj;

  // set shares_owned range object
  if (sharesOwnedMin || sharesOwnedMax) {
    query["shares_owned"] = { range: {} };

    if (sharesOwnedMin) {
      query["shares_owned"].range.gte = sharesOwnedMin;
    }

    if (sharesOwnedMax) {
      query["shares_owned"].range.lte = sharesOwnedMax;
    }
  }

  if (ticker) {
    query["ticker"] = { values: ticker.split(",") };
  }

  if (state) query["state"] = { values: [state] };
  if (country) query["country"] = { values: [country] };

  return query;
};
