import {
  IFilterQueries,
  IRangeFilter,
  IShareholderComposeMessage,
  QuickReplyTypeOptions,
  IComposeMessageContent,
  IRadioFilter,
} from "../../store/messageCompose";
import {
  ComposeFlowFormFields,
  FormMessageContent,
} from "../../pages/Messages/subPages/CreateAndEdit/types";
import { NO_GREETING, VALUE_PAIRS } from "../../constants";
import { MessageStep } from "../../types/Messages";

/**
 * api uses two separate fields, convert messagesOpened {range} to messagesOpenedRate {range}
 */
const convertOutgoingValues = (query: IFilterQueries) => {
  if ((query.sharesStatus as IRadioFilter)?.radio) {
    query["hasSold"] = {
      values: [true],
    };
    delete query.sharesStatus;
  }
  for (const [from, to] of VALUE_PAIRS) {
    if ((query?.[from] as IRangeFilter)?.range) {
      query[to] = {
        ...query[from],
      };
      delete query[from];
    }
  }
  return query;
};

export const parseForm = (
  formData: Partial<ComposeFlowFormFields>,
  currentStepName: MessageStep,
  prevContent?: Partial<IComposeMessageContent> | null
): Partial<IShareholderComposeMessage> => {
  switch (currentStepName) {
    case MessageStep.ChooseRecipients:
      let query = { ...formData.filterMap?.query };
      query = convertOutgoingValues(query);
      return {
        filterMap: {
          query,
        },
      };
    case MessageStep.DraftMessage:
      const { content = {} } = formData;
      const {
        signatureId: leaderId,
        greetings,
        ...restOfContent
      } = content as FormMessageContent;
      const signatureObj = leaderId
        ? { leaderId: leaderId === "clear" ? "" : leaderId }
        : undefined;
      let quickReplies = undefined;
      if (formData?.quickReplies?.type === QuickReplyTypeOptions.Emoji) {
        quickReplies = formData?.quickReplies.emojiData;
      } else if (
        formData?.quickReplies?.type === QuickReplyTypeOptions.Text &&
        formData?.quickReplies?.textData?.options
      ) {
        quickReplies = {
          ...formData?.quickReplies.textData,
          options: formData?.quickReplies?.textData?.options.map(
            (reply) => reply.value
          ),
        };
      }

      return {
        content: {
          ...(prevContent || {}),
          ...restOfContent,
          importantLinks:
            // remove id from importantLinks arr
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            formData?.content?.importantLinks?.map(({ id, ...rest }) => rest) ||
            [],
          signature: signatureObj,
          quickReplies,
          // if greetings value is "No greeting", use empty string
          greetings: greetings === NO_GREETING ? "" : greetings,
        },
      };
    case MessageStep.DraftInAppMessage:
      return {
        content: {
          ...(prevContent || {}),
          bodyShort: formData?.content?.bodyShort,
        },
        pushNotificationTemplateId: formData?.pushNotificationTemplateId,
      };
    default:
      return { ...formData };
  }
};
