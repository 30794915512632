import { Dialog } from "@asayinc/component-library";
import {
  selectDisplayPublishDialog,
  setPublishDialog,
  useGetEventQuery,
} from "../../../../../store/event";
import { setSnackbar } from "../../../../../store/common";
import { SetPublishDateReview } from "./Molecules";
// route / data
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const PublishSuccessDialog = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const displayDialog = useAppSelector(selectDisplayPublishDialog);

  const { data: event } = useGetEventQuery(
    !displayDialog ? skipToken : displayDialog.eventSlug
  );

  if (!displayDialog || !event) {
    return null;
  }

  const { title } = event;

  const handleClose = () => {
    dispatch(setPublishDialog(null));
    dispatch(
      setSnackbar({
        message: `${title} has been created and is pending approval`,
        open: true,
        type: "standard",
        ctaText: "Got it",
        severity: "success",
      })
    );
  };

  return (
    <Dialog
      open={!!displayDialog}
      sx={{
        ".MuiDialogContent-root": { pt: 6 },
      }}
      content={
        <SetPublishDateReview
          eventSlug={displayDialog.eventSlug}
          isDraft={displayDialog.type === "draft"}
          onClose={handleClose}
        />
      }
    />
  );
};

export default PublishSuccessDialog;
