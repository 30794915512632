import {
  useBulkAddTagsMutation,
  useCreateQuestionTagMutation,
  useGetQuestionTagsQuery,
} from "../../../../../store/questionTags";
import AddTagsDialog from "../AddTagsDialog";

interface IProps {
  open: boolean;
  eventSlug: string;
  handleClose: (
    type: "download" | "bulkTag" | "singleTag",
    _?: undefined,
    _2?: unknown,
    didSave?: boolean,
    tagCount?: number
  ) => void;
  questionIds: string[];
  dialog: "singleTag" | "bulkTag" | "download" | "";
}

const QuestionsAddTagsDialog = ({
  open,
  handleClose,
  eventSlug,
  questionIds,
  dialog,
}: IProps) => {
  const { data: tagOptions = [] } = useGetQuestionTagsQuery(eventSlug);
  const [createTag, { data: newTag }] = useCreateQuestionTagMutation();
  const [addTags, { isLoading, isError }] = useBulkAddTagsMutation();

  // pass through so multiple api types can be used
  const createNewTag = ({ color, name }: { color: string; name: string }) => {
    createTag({
      color,
      name,
      eventSlug,
    });
  };

  const onClose = (didSave: boolean, totalTags: number) => {
    handleClose(
      dialog as "singleTag" | "bulkTag" | "download",
      undefined,
      undefined,
      didSave,
      totalTags
    );
  };

  return (
    <AddTagsDialog
      rowIds={questionIds}
      open={open}
      dialogTitle="Add a tag to selected questions"
      handleClose={onClose}
      isError={isError}
      isLoading={isLoading}
      newTag={newTag}
      options={tagOptions}
      createTag={createNewTag}
      addTags={addTags}
    />
  );
};

export default QuestionsAddTagsDialog;
