import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DATE_FORMAT, INPUT_TIME_FORMAT } from "@asayinc/component-library";

/**
 * Verify time is between 8 and 8 eastern
 * @param val : string in the format HH:mm returned from native time input'
 * added dayjs input support for testing purposes due to test env being a dif timezone
 */
export const betweenEightAndEight = (val: null | Dayjs, timezoneId: string) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  if (val) {
    // time user picked

    const dayjstime = dayjs
      .tz(
        val.format(`${DATE_FORMAT} ${INPUT_TIME_FORMAT}`),
        `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`,
        timezoneId
      )
      .tz("America/New_York");
    // use same dayjs object used on user selection to set 8 and 8 times so that it preserves what
    // day of the year the dayjs object is acting in for isAfter and isBefore calculatations
    const eightAM = dayjstime.hour(7).minute(59);
    const eightPM = dayjstime.hour(20).minute(1);

    const isValid =
      dayjstime.isAfter(eightAM, "minute") &&
      dayjstime.isBefore(eightPM, "minute");
    if (isValid) {
      return true;
    }
    return "Must be between 8am and 8pm EST";
  }
  return true;
};
