import { useMemo, useState } from "react";
import { COLORS, Dialog, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { MessageTemplateTile } from "../../../Molecules";
import { CardWrapper } from "../../../../Common";
import { EmailPreviewDialogContent } from "../../../Organisms";
import {
  IMessageTemplate,
  useGetMessageTemplatesQuery,
} from "../../../../../store/messageCompose";

// blank template option to use standard no-template create flow
const BLANK_TEMPLATE = {
  id: "6",
  imageAssets: {
    sm: "",
    md: "https://assets.say.rocks/images/ir-portal/message-templates/message-template-blank-md.png",
  },
  suggestions: {
    subject: [],
    campaignName: [],
    body: [],
  },
  name: "Blank message",
  htmlPreview: "",
  description: "Create from an empty message to start simple.",
  isBlank: true,
  subject: "",
};

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

// EmailPreview Dialog Content requires different dialog setup so some !!template checks are required

const NewMessageTemplateDialog = ({ open, closeDialog }: IProps) => {
  const { data: templates = [] } = useGetMessageTemplatesQuery();
  const [template, setTemplate] = useState<IMessageTemplate | null>(null);

  // open template preview view
  const viewTemplate = (template: IMessageTemplate) => {
    setTemplate(template);
  };

  // go back to main template option screen
  const backToTemplateOptions = () => {
    setTemplate(null);
  };

  // close dialog
  const onClose = () => {
    setTemplate(null);
    closeDialog();
  };

  // prepend blank template to templates array
  const allTemplates: IMessageTemplate[] = useMemo(
    () => [BLANK_TEMPLATE, ...templates],
    [templates]
  );

  return (
    <Dialog
      title={
        !template
          ? "Get started with a template to create your message"
          : undefined
      }
      fullBleed={!!template}
      titleTextVariant="h4"
      data-testid="new-message-template-dialog"
      handleClose={onClose}
      sx={{
        ".MuiDialog-container > .MuiPaper-root": {
          maxWidth: 1206,
          ...(!!template ? { bgcolor: COLORS.P_OUTLINE, width: "100%" } : {}),
        },
      }}
      content={
        !!template ? (
          <EmailPreviewDialogContent
            template={template}
            closeDialog={onClose}
            goBack={backToTemplateOptions}
          />
        ) : (
          <Stack>
            <Text variant="body2">
              Deliver company news, announcements and press releases directly to
              your shareholders
            </Text>
            <Stack flexWrap="wrap" gap={8} direction="row" mt={6}>
              {allTemplates.map((template) => (
                <CardWrapper
                  key={template.name}
                  cardName={`messageTemplateTile-${template.id}`}
                  sx={{
                    width: ["calc(50% - 12px)", "calc(33.333333% - 22px)"],
                    flexGrow: [1, 0],
                    p: 2,
                    pb: 8,
                    maxWidth: "356px",
                    minWidth: "262px",
                  }}
                >
                  <MessageTemplateTile
                    template={template}
                    asCard
                    viewTemplate={viewTemplate}
                  />
                </CardWrapper>
              ))}
            </Stack>
          </Stack>
        )
      }
      open={open}
    />
  );
};

export default NewMessageTemplateDialog;
