import { Stack } from "@mui/material";
import { COLORS, Text, ButtonV2 as Button } from "@asayinc/component-library";
import { useNavigate } from "react-router-dom";
import { useGetSettingsQuery } from "../../../../store/settings";
import { ISettings } from "../../../../store/settings/types";

interface IProps {
  text: string;
}

const IncompleteProfileCallout = ({ text }: IProps) => {
  const { data: settings = {}, isLoading } = useGetSettingsQuery();
  const { submitted } = settings as ISettings;
  const navigate = useNavigate();

  if (submitted || isLoading) {
    return null;
  }

  return (
    <Stack
      bgcolor={COLORS.ERROR_DARK}
      py={3}
      px={8}
      direction="row"
      borderRadius={2}
      alignItems="center"
      mb={7}
      justifyContent="space-between"
    >
      <Text variant="body2" category="onsurface">
        {text}
      </Text>
      <Button
        variant="textDark"
        data-testid="btn-goto-profile"
        onClick={() => navigate("/settings")}
      >
        Add now
      </Button>
    </Stack>
  );
};

export default IncompleteProfileCallout;
