import { Stack } from "@mui/material";
import { track } from "../../../../../analytics";
import { FormSectionDivider } from "../../../../../components/Common";
import { useGetLeadersQuery } from "../../../../../store/companyLeadership";
import { ICompanyLeader } from "../../../../../types/Events";
import { CREATION_STEP_MAPPING } from "../../../constants";
import { useCreateQAData } from "../../../hooks";
import {
  ReviewFAQ,
  ReviewSortOrder,
  ReviewParticipants,
} from "../../Molecules";

interface IProps {
  goToStep: (step: number) => void;
}

export const ReviewEventDetails = ({ goToStep }: IProps) => {
  const { companyName, event } = useCreateQAData();
  const { ordering, leaders = [], faqs } = event;
  const { data: allCompanyLeaders = [] } = useGetLeadersQuery();

  /**
   * combine the array of enabled ids with the array of all leader data
   */
  const enabledLeadership = leaders.reduce((acc: ICompanyLeader[], cur) => {
    const foundLeader = allCompanyLeaders.find(
      (leader) => leader.id === cur.leaderId
    );
    if (foundLeader) {
      acc.push({
        ...foundLeader,
        ...cur,
      });
    }
    return acc;
  }, []);

  const editStep = (section: string) => {
    track({
      name: "Q&A Edited from Review",
      client: companyName,
      qaEvent: event.slug,
      qaStatus: event.status,
      composeStep: CREATION_STEP_MAPPING[3],
      reviewEdit: section,
    });
    switch (section) {
      case "sortOrder":
      default:
        return goToStep(1);
      case "companyParticipants":
        return goToStep(2);
      case "faq":
        return goToStep(2);
    }
  };

  return (
    <Stack spacing={6}>
      <ReviewSortOrder ordering={ordering} editStep={editStep} />
      {enabledLeadership.length ? (
        <>
          <FormSectionDivider />
          <ReviewParticipants
            enabledLeadership={enabledLeadership}
            editStep={editStep}
          />
        </>
      ) : null}
      {faqs?.length ? (
        <>
          <FormSectionDivider />{" "}
          <ReviewFAQ faqs={faqs || []} editStep={editStep} />{" "}
        </>
      ) : null}
    </Stack>
  );
};

export default ReviewEventDetails;
