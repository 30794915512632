// components
import { Dialog, Text, IButtonAction } from "@asayinc/component-library";
// store / data
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
import {
  MessageDialog,
  selectMessageComposeData,
  setMessageDialog,
  useDeleteMessageMutation,
} from "../../../../../store/messageCompose";
import { useNavigate, useParams } from "react-router-dom";
import { MessageType } from "../../../../../store/messageLists";
import { useFormContext } from "react-hook-form";

const DeleteDialog = (): JSX.Element => {
  const { proxyEventId } = useParams() as {
    proxyEventId: string;
  };
  const dispatch = useAppDispatch();
  const { messageDialog, messageDialogId } = useAppSelector(
    selectMessageComposeData
  );

  const [deleteMessage, deleteMessageRes] = useDeleteMessageMutation({
    fixedCacheKey: "shared-delete-message",
  });

  const { reset } = useFormContext() || {};
  const navigate = useNavigate();
  const onDeleteClick = async () => {
    // if form context is available, reset dirty fields to
    // prevent navigation from being blocked
    reset && reset(undefined, { keepDirtyValues: false });
    await deleteMessage({
      type: proxyEventId ? MessageType.ProxySM : MessageType.SM,
      id: messageDialogId as string,
    });
    navigate(proxyEventId ? `/vote/${proxyEventId}/messages` : "/messages", {
      replace: true,
    });
  };

  const closeDialog = () => {
    dispatch(
      setMessageDialog({
        messageDialogId: null,
        messageDialog: null,
      })
    );
  };

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg: "Message successfully deleted.",
    errorMsg: "Failed to delete message please try again.",
    errorAction: closeDialog,
    successAction: closeDialog,
    isSuccess: deleteMessageRes.isSuccess,
    isError: deleteMessageRes.isError,
  });

  const buttonActions = [
    {
      label: "Delete",
      variant: "warning",
      onClick: onDeleteClick,
    },
  ] as IButtonAction[];

  return (
    <Dialog
      titleTextVariant="subtitle1"
      title="Delete this message?"
      data-testid="delete-dialog"
      handleClose={closeDialog}
      buttonActions={buttonActions}
      content={
        <Text variant="body1" width="279px">
          Are you sure you want to delete this message? This is permanent.
        </Text>
      }
      open={messageDialog === MessageDialog.Delete}
    />
  );
};

export default DeleteDialog;
