import { IFeatures, ISettings } from "../../../store/settings/types";
import { ICompanyLeader } from "../../../types/Events";

export const getDefaultValues = ({
  settings: {
    name,
    twitterHandle,
    logo,
    darkModeLogo,
    primaryColor,
    secondaryColor,
    qaEventPreviewImage,
    investorRelationsUrl,
  },
  features,
  allCompanyLeaders,
  disclaimer,
}: {
  settings: ISettings;
  allCompanyLeaders: ICompanyLeader[];
  disclaimer: string;
  features: IFeatures;
}) => {
  return {
    features,
    companyName: name,
    twitterHandle,
    logo,
    darkModeLogo,
    primaryColor,
    secondaryColor,
    qaEventPreviewImage,
    leaders: allCompanyLeaders,
    disclaimer,
    investorRelationsUrl: investorRelationsUrl || "",
    logoFilename: "",
    qaEventPreviewImageFilename: "",
  };
};
