import { useForm, FormProvider } from "react-hook-form";
import { Stack } from "@mui/material";
import {
  RangeFilter,
  TagFilter,
  DateFilter,
} from "../../../../../components/Common";
import { URL_PARAMS } from "../../../../../constants";
import { useGetShareholderTagsQuery } from "../../../../../store/shareholderTags";

interface IProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

const FilterList = ({ searchParams, setSearchParams }: IProps): JSX.Element => {
  const { data: tagOptions = [], isLoading } = useGetShareholderTagsQuery();
  const methods = useForm({ criteriaMode: "all" });

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      gap={4}
      mt={2}
      width="100%"
    >
      <FormProvider {...methods}>
        <TagFilter
          stateSearchParams={searchParams}
          stateSetSearchParams={setSearchParams}
          tagIdsParameter={URL_PARAMS.tags}
          noTagsParameter="num_shareholder_tags_max"
          noTagsValue="0"
          tagOptions={tagOptions}
          fieldName="shareholderTags"
          chipLabel="Shareholder tags"
          isLoading={isLoading}
          valuesLabel="Shareholders tagged with"
          noValueLabel="Shareholders not tagged"
        />
        <RangeFilter
          stateSearchParams={searchParams}
          stateSetSearchParams={setSearchParams}
          fieldName="sharesRange"
          minParam={URL_PARAMS.sharesOwnedMin}
          maxParam={URL_PARAMS.sharesOwnedMax}
          chipLabel="Shares owned"
        />
        <DateFilter
          stateSearchParams={searchParams}
          stateSetSearchParams={setSearchParams}
          beforeParam={URL_PARAMS.submittedBefore}
          afterParam={URL_PARAMS.submittedAfter}
          fieldNamePrefix="submitted"
          chipLabel="Submitted"
        />
      </FormProvider>
    </Stack>
  );
};

export default FilterList;
