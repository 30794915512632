import { nFormatter } from "@asayinc/component-library";
import {
  IAllShareholderLocationStat,
  IAllShareholderStats,
} from "../../../../../../../store/allShareholderStats";
import { OTHERS_TITLE } from "../LocationDialog/constants";

/**
 * add all values below 1% to a different field
 * if theres at least 1 value in the other field, append to the end under "other"
 */
export const parseLocationData = (
  locationData: IAllShareholderLocationStat[] | undefined,
  stats?: IAllShareholderStats
) => {
  if (locationData && stats) {
    const formattedResponse = locationData.reduce(
      (acc, cur) => {
        const val = (cur.docCount / stats.valueCount) * 100;
        if (val > 1) {
          acc.above.push({
            title: cur.key,
            value: `${nFormatter(val)}%`,
          });
        } else {
          acc.end += val;
        }
        return acc;
      },
      {
        above: [] as { title: string; value: string }[],
        end: 0,
      }
    );
    if (formattedResponse.end > 0) {
      formattedResponse.above.push({
        title: OTHERS_TITLE,
        value: `${nFormatter(formattedResponse.end)}%`,
      });
    }
    return formattedResponse.above;
  }
  return [];
};
