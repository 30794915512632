import { QaPublishOptions } from "../constants";

export enum EventStateOptions {
  UNPUBLISHED = "unpublished",
  SUBMISSION_OPEN = "submission_open",
  SUBMISSION_CLOSED = "submission_closed",
  ARCHIVED = "archived",
}

export enum EventTypeOptions {
  EARNINGS_CALL = "earnings_call",
  SHAREHOLDER_MEETING = "shareholder_meeting",
  INVESTOR_DAY = "investor_day",
  WEBINAR = "webinar",
  PRODUCT_LAUNCH = "product_launch",
}

export enum EventSortOptions {
  RECOMMENDED = "recommended",
  TOP_VOTED = "num_upvotes",
  MOST_SHARES = "num_shares",
  NEWEST = "created",
}

export enum EventStatusOptions {
  DRAFT = "draft",
  PENDING = "pending_approval",
  APPROVED = "approved",
  PUBLISHED = "published",
  ACTIVE = "active",
  COMPLETED = "completed",
}

export interface QuestionsCount {
  retail: number;
  institutional: number;
  analyst: number;
  answered: number;
}

export interface SecuritySummary {
  details: null | string | number;
  company: number | null;
  symbolName: string;
  symbol: string;
  id: number;
  latestPrice: number | null;
}

export interface ICompanyLeader {
  fullName: string;
  longTitle: string;
  shortTitle: string;
  photo: string;
  photoFilename?: string;
  companyId: string;
  id: string;
  enabled?: boolean;
}

export interface IActiveCompanyLeader {
  leaderId: string;
  order: number;
}

export interface IFaq {
  subject: string;
  bodyMarkdown: string;
  order: number;
}

export interface ISortOrderObj {
  order: number;
  value: EventSortOptions;
}

export interface Meta {
  fiscalQuarter?: string;
  investorDayName?: string;
  cadence?: string;
  shareholderType?: string; // old value that may exist on existing events - now cadence
  webinarName?: string;
  productLaunchName?: string;
  fiscalYear?: string;
}

export interface IAudience {
  name: string;
  url: string;
  file: File;
}

export interface Event {
  createdAt?: string | null;
  archiveDatetime?: string | null;
  closeDatetime: string | null;
  eventType: EventTypeOptions;
  eventStartDatetime: string | null;
  timezone: string;
  openDatetime: string | null;
  draftStep: number;
  eventState: EventStateOptions;
  id: number;
  logoUrl: string | null;
  faqs: IFaq[] | null;
  publishDatetime: string | null;
  publishType: QaPublishOptions | null;
  questionsCount: QuestionsCount;
  securities: SecuritySummary[];
  slug: string;
  title: string;
  shortTitle: string;
  totalShareholders: number;
  totalShares: number;
  leaders: IActiveCompanyLeader[];
  status: EventStatusOptions;
  ordering: ISortOrderObj[];
  lastEditAt?: string;
  submittedAt?: string;
  approvedAt?: string;
  submit?: boolean;
  meta: Meta;
  audience?: IAudience;
}

export interface IDraftEvent
  extends Omit<
    Event,
    | "questionsCount"
    | "eventType"
    | "eventState"
    | "securities"
    | "totalShareholders"
    | "totalShares"
  > {
  eventType?: EventTypeOptions;
}

export interface Events {
  count: number;
  draftCount: number;
  submittedCount: number;
  events: Event[];
}

export interface IEventTrends {
  companyAverageQuestionsAsked: number;
  companyAverageQuestionsAskedDiff: number;
  companyAverageUpvoteCount: number;
  companyAverageUpvoteCountDiff: number;
  companyAverageUpvotesPerQuestion: number;
  companyAverageUpvotesPerQuestionDiff: number;
  companyAverageMedianPositionSize: number;
  companyAverageMedianPositionSizeDiff: number;
  companyAverageQuestionsAnsweredCount: number;
  companyAverageQuestionsAnsweredCountDiff: number;
  companyAverageShareholdersAddressedPercent: number;
  companyAverageShareholdersAddressedPercentDiff: number;
  companyAverageTotalShareholders: number;
  companyAverageTotalShareholdersDiff: number;
}

export interface IEventStats {
  isEventOpen: false;
  questionsAsked: number;
  upvoteCount: number;
  questionsAnsweredCount: number;
  averageUpvotesPerQuestion: number;
  medianPositionSize: number;
  trends: IEventTrends;
  totalShareholders: number;
  shareholdersAddressedPercent: number;
}
