import {
  Dialog,
  IButtonAction,
  IconButton,
  Text,
} from "@asayinc/component-library";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

interface IProps {
  img: string;
  imgAlt: string;
  title: string;
  description: string;
  buttonActions?: IButtonAction[];
  open: boolean;
  closeDialog: () => void;
  testId: string;
}

const ExpandWithSayDialog = ({
  img,
  imgAlt,
  title,
  description,
  buttonActions,
  open,
  testId,
  closeDialog,
}: IProps) => {
  return (
    <Dialog
      fullBleed
      data-testid={`${testId}-intro-dialog`}
      ctaOrientation="horizontal"
      handleClose={closeDialog}
      buttonActions={buttonActions}
      sx={{
        ".MuiDialogActions-root": {
          justifyContent: "center",
        },
      }}
      content={
        <Box textAlign="center" mb={8} maxWidth={662}>
          <IconButton
            aria-label="close"
            sx={{
              right: (theme) => theme.spacing(3),
              top: (theme) => theme.spacing(3),
              position: "absolute",
            }}
            data-testid="dialog-close"
            onClick={closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <img src={img} alt={imgAlt} width="100%" height="auto" />
          <Text variant="h4" mb={6} mt={10}>
            {title}
          </Text>
          <Text variant="body1" maxWidth="546px" margin="0 auto">
            {description}
          </Text>
        </Box>
      }
      open={open}
    />
  );
};

export default ExpandWithSayDialog;
