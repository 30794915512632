import { useMemo, useState } from "react";
import {
  HookCheckbox,
  Text,
  COLORS,
  getInitials,
  IconButton,
} from "@asayinc/component-library";
import { Avatar, Box, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ICompanyLeader } from "../../../../../../types/Events";
import { Draggable, DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import LeaderDialog from "../LeaderDialog";

type ISaveOrAdd = (data: {
  leader: Partial<ICompanyLeader>;
  add?: boolean;
  id: string;
}) => void;
interface IProps {
  leader: ICompanyLeader;
  selectable?: boolean;
  draggable?: boolean;
  editable?: boolean;
  deletable?: boolean;
  idx: number;
  saveOrAddLeader?: ISaveOrAdd;
  deleteLeader?: (id: string) => void;
  selectLeader?: (newValue: boolean, leaderId: string, idx: number) => void;
  isLoading?: boolean;
}

const Leader = ({
  leader,
  selectable,
  draggable,
  editable,
  deletable,
  idx,
  saveOrAddLeader,
  deleteLeader,
  selectLeader,
  isLoading,
}: IProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  /**
   * leader displays different if its interactive
   */
  const getStyles = () => {
    if (selectable || draggable || editable || deletable) {
      return {
        borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
        pt: 3,
      };
    }
    return {};
  };
  const rowStyles = getStyles();

  /**
   * get initials from full name "John Quick Smith" becomes "JQS"
   */
  const initials = useMemo(
    () => getInitials(leader.fullName),
    [leader.fullName]
  );

  /**
   * call delete api and delete this leader
   */
  const onDeleteLeader = () => {
    if (deleteLeader) {
      deleteLeader(leader.id);
    }
  };

  /**
   * pass checked value, id of leader and its index to process the selection
   */
  const handleLeaderSelect = (e: React.SyntheticEvent<Element, Event>) => {
    if (selectLeader) {
      selectLeader((e.target as HTMLInputElement).checked, leader.id, idx);
    }
  };

  const displayLeader = (
    dragHandleProps?: DraggableProvidedDragHandleProps | null
  ) => (
    <>
      <Stack direction="row" alignItems="center">
        {draggable ? (
          <Box
            className="icons"
            mr={3}
            {...dragHandleProps}
            data-testid={`scrubber-${leader.id}`}
          >
            <MoreVertIcon style={{ color: COLORS.P_MED_EMPHASIS }} />
          </Box>
        ) : null}
        {selectable && (
          <Box
            sx={{
              ".MuiFormControlLabel-root": {
                mr: 3,
              },
            }}
          >
            <HookCheckbox
              name={`leaders.${idx}.enabled`}
              data-testid={`checkbox-${leader.id}`}
              label=""
              disabled={isLoading}
              callback={handleLeaderSelect}
            />
          </Box>
        )}
        <Avatar
          sx={{ width: 48, height: 48, mr: 4, textTransform: "uppercase" }}
          src={leader.photo}
        >
          {initials}
        </Avatar>
        <Box>
          <Text variant="subtitle1" data-testid="text-leader-fullname">
            {leader.fullName}
          </Text>
          <Text variant="body1">
            {leader.shortTitle}, {leader.longTitle}
          </Text>
        </Box>
      </Stack>
      <Stack direction="row" className="icons">
        {deletable && (
          <IconButton
            onClick={onDeleteLeader}
            data-testid={`button-delete-${leader.id}`}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {editable && (
          <IconButton
            onClick={openDialog}
            data-testid={`button-edit-${leader.id}`}
            disabled={isLoading}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
    </>
  );

  const renderLeader = (
    dragHandleProps?: DraggableProvidedDragHandleProps | null
  ) => (
    <Stack
      pb={3}
      {...rowStyles}
      direction="row"
      alignItems="center"
      bgcolor={COLORS.WHITE}
      data-testid={`leader-${leader.id}`}
      justifyContent="space-between"
      sx={{
        ".icons": {
          opacity: 0,
        },
        "&:hover .icons": {
          opacity: 1,
          transition: "opacity 0.5s linear",
        },
      }}
    >
      {displayLeader(dragHandleProps)}
    </Stack>
  );

  const renderDroppable = () => (
    <Draggable index={idx} draggableId={leader.id} isDragDisabled={isLoading}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          {renderLeader(provided.dragHandleProps)}
        </div>
      )}
    </Draggable>
  );

  return (
    <>
      {draggable ? renderDroppable() : renderLeader()}
      {editable && (
        <LeaderDialog
          open={isDialogOpen}
          closeDialog={closeDialog}
          leader={leader}
          isDisabled={isLoading}
          saveOrAddLeader={saveOrAddLeader as ISaveOrAdd}
          idx={idx}
        />
      )}
    </>
  );
};

export default Leader;
