import {
  nFormatter,
  pluralize,
  Text,
  Tooltip,
} from "@asayinc/component-library";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Stack } from "@mui/material";

interface IProps {
  headline: string;
  shareholderCount: number | null;
  sharesCount: number;
  statsPending?: boolean;
  isLoading?: boolean;
}

const MessageStat = ({
  isLoading = false,
  headline,
  shareholderCount,
  sharesCount,
  statsPending,
}: IProps) => {
  return (
    <Stack
      direction="row"
      minWidth="130px"
      flex={["1 0", "auto"]}
      data-testid={`message-stat-${headline}`}
      justifyContent="space-between"
    >
      <Stack>
        <Stack direction="row" alignItems="center" mb={3}>
          <Text
            variant="subtitle2"
            mr={2}
            loadingProps={{ sx: { width: "80px" } }}
          >
            {isLoading ? undefined : headline}
          </Text>
          {statsPending && !isLoading && (
            <Tooltip
              testId="still-sending-tooltip"
              title="Your message is still being sent out to all selected
shareholders. This may take up to a few hours."
            >
              <AccessTimeIcon fontSize="small" sx={{ display: "block" }} />
            </Tooltip>
          )}
        </Stack>
        <Text
          sx={{ mb: 2 }}
          variant="h5"
          loadingProps={{ sx: { width: "224px" } }}
        >
          {isLoading
            ? undefined
            : `${nFormatter(shareholderCount || 0)} shareholder${pluralize(
                "",
                "s",
                shareholderCount || 0
              )}`}
        </Text>
        <Text variant="body2" loadingProps={{ sx: { width: "160px" } }}>
          {isLoading
            ? undefined
            : `${nFormatter(sharesCount)} share${pluralize(
                "",
                "s",
                sharesCount
              )} represented`}
        </Text>
      </Stack>
    </Stack>
  );
};

export default MessageStat;
