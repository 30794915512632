import { useParams } from "react-router";
import {
  DEFAULT_MESSAGE_LIMIT,
  MessageType,
  NOT_SENT_TYPES,
  useGetMessagesQuery,
} from "../store/messageLists";

export const useHasNotSentMessage = (
  messageType: MessageType
): {
  hasNotSentMessage: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  // draft/pending/scheduled messages
  const {
    data: notSentData,
    isLoading: isNotSentLoading,
    isError: isNotSentError,
  } = useGetMessagesQuery({
    limit: DEFAULT_MESSAGE_LIMIT.toString(),
    offset: 0,
    messageType: messageType,
    messageStatus: NOT_SENT_TYPES,
    proxyEventId,
  });

  return {
    hasNotSentMessage: !!notSentData?.count,
    isLoading: isNotSentLoading,
    isError: isNotSentError,
  };
};
