import { useState, useEffect, useCallback } from "react";
// routing
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  Navigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
// material
import { Box, Stack } from "@mui/material";
// Api data
import {
  useGetQuestionQuery,
  usePatchNotesMutation, // rtk query
} from "../../store/question";
import { trackSaveNotes } from "./utils";
// components
import { QuestionDetailHeaderV2, RemoveOverlay } from "./components";
// pages
import { QuestionDetailContent, Upvotes } from "./subPages";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Tabs } from "@asayinc/component-library";
import { URL_PARAMS } from "../../constants";

enum TabValues {
  details = "details",
  upvotes = "upvotes",
}

const TAB_OPTIONS = [
  {
    label: "Details",
    value: TabValues.details,
  },
  {
    label: "Upvotes",
    value: TabValues.upvotes,
  },
];

interface IProps {
  asDrawer?: boolean;
}

const Question = ({ asDrawer }: IProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation() as { state: { goBackText?: string } };
  const goBackText = state?.goBackText;
  // use state when using drawer
  const [drawerActiveTab, setDrawerActiveTab] = useState("details");
  // router
  const {
    questionId: questionParamId,
    eventSlug: eventParamSlug,
    ["*"]: activeTabParam,
  } = useParams() as {
    questionId: string;
    eventSlug: string;
    "*": string;
  };

  const activeTab = asDrawer ? drawerActiveTab : activeTabParam;

  const drawerQuestionId = searchParams.get(URL_PARAMS.qid);
  const drawerEventSlug = searchParams.get(URL_PARAMS.eid);

  // use reducer when using drawer
  const questionId = asDrawer ? (drawerQuestionId as string) : questionParamId;
  const eventSlug = asDrawer ? (drawerEventSlug as string) : eventParamSlug;
  const navigate = useNavigate();
  // state
  const [displayRemove, setDisplayRemove] = useState(false);
  // rtk
  const { data: question, isError } = useGetQuestionQuery(
    eventSlug && questionId
      ? {
          eventSlug,
          questionId,
        }
      : skipToken
  );
  const [patchNotes, { isLoading: isNotesSaving }] = usePatchNotesMutation();
  useEffect(() => {
    if (!asDrawer) {
      document.title = `Question from ${
        question?.authorName || "Shareholder"
      } | Say Issuer Portal`;
    }
  }, [question?.authorName]);

  /**
   * trigger save notes api request
   */
  const saveNotes = (notes: string) => {
    patchNotes({
      questionId,
      eventSlug,
      notes: {
        textPlain: notes,
      },
    });
    trackSaveNotes({ eventSlug, questionId });
  };

  /**
   * Navigate back to previous route
   */
  const navigateToParent = useCallback(() => {
    navigate(`/qa/${eventSlug}/questions`, { replace: true });
  }, []);

  const openRemoveDialog = useCallback(() => setDisplayRemove(true), []);
  const closeDialog = useCallback(() => setDisplayRemove(false), []);

  // expand side drawer to full page by navigating to the page
  const expand = useCallback(() => {
    navigate(
      `/qa/${eventSlug}/questions/${drawerQuestionId}/${drawerActiveTab}`,
      {
        state: { goBackText },
      }
    );
  }, [drawerQuestionId, drawerActiveTab]);

  // close side drawer
  const close = useCallback(() => {
    searchParams.delete(URL_PARAMS.qid);
    searchParams.delete(URL_PARAMS.eid);
    setSearchParams(searchParams);
  }, []);

  // either switch tabs or switch pages based on if action occurs in drawer mode
  const setActiveTab = (tab: string) => {
    if (asDrawer) {
      setDrawerActiveTab(tab.toLowerCase());
    } else {
      navigate(
        `/qa/${eventSlug}/questions/${question?.id}${
          tab === TabValues.details ? "" : `/${tab.toLowerCase()}`
        }`
      );
    }
  };

  const routes = (
    <Routes>
      <Route
        path=""
        element={
          <QuestionDetailContent
            question={question}
            eventSlug={eventSlug}
            isLoading={isNotesSaving}
            questionId={questionId}
            saveNotes={saveNotes}
          />
        }
      />
      <Route
        path="details"
        element={
          <Navigate to={`/qa/${eventSlug}/questions/${questionId}/`} replace />
        }
      />
      <Route
        path="upvotes"
        element={<Upvotes eventSlug={eventSlug} questionId={questionId} />}
      />
    </Routes>
  );

  const tabs = (
    <>
      {activeTab === TabValues.upvotes ? (
        <Upvotes
          eventSlug={eventSlug}
          questionId={questionId}
          asDrawer={asDrawer}
        />
      ) : (
        <QuestionDetailContent
          question={question}
          eventSlug={eventSlug}
          questionId={questionId}
          isLoading={isNotesSaving}
          saveNotes={saveNotes}
          asDrawer={asDrawer}
        />
      )}
    </>
  );

  if (isError) {
    return <Navigate to={`/qa/${eventSlug}/questions`} replace />;
  }

  return (
    <>
      <Box id="question-page" data-testid="question-page">
        <Box px={6} pt={6}>
          <Stack maxWidth={1440} m={"0 auto"}>
            <QuestionDetailHeaderV2
              active={!!question?.isSelectedByIssuer}
              questionId={questionId}
              eventSlug={eventSlug}
              openRemoveDialog={openRemoveDialog}
              question={question}
              asDrawer={asDrawer}
              expand={expand}
              close={close}
            />
            <Tabs
              tabs={TAB_OPTIONS}
              activeTab={activeTab || TabValues.details}
              clickHandler={setActiveTab}
            />
          </Stack>
        </Box>
      </Box>
      {question && (
        <Box px={6} pt={8}>
          <Box maxWidth={1440} m={"0 auto"}>
            {asDrawer ? tabs : routes}
          </Box>
        </Box>
      )}
      <RemoveOverlay
        navigateToParent={navigateToParent}
        open={displayRemove}
        closeDialog={closeDialog}
        question={question}
      />
    </>
  );
};

export default Question;
