import {
  Dialog,
  IButtonAction,
  DATE_FORMAT,
  INPUT_TIME_FORMAT,
} from "@asayinc/component-library";
import { useEffect, useMemo } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// dayjs
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
// components
import Form from "../../../../Messages/Organisms/Dialogs/ScheduleShareholderMessage/Form";
// store
import { setSnackbar } from "../../../../../store/common";
import { useAppDispatch } from "../../../../../store/hooks";
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
// types
import { MessageStatus } from "../../../../../types/Messages";
import { IShareholderComposeMessage } from "../../../../../store/messageCompose";
import { ISentMessage } from "../../../../../store/messageLists";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
// constants
import {
  TIME_ZONE_VALUE_MAPPING,
  USER_TIMEZONE_OBJ,
} from "../../../../../constants/timezones";
import { SendOptions } from "../../../../Messages/Organisms/Dialogs/ScheduleShareholderMessage/constants";
// utils
import { parseUTCtoDateAndTime } from "../../../../Messages/Organisms/Dialogs/ScheduleShareholderMessage/utils";
import { useGetProxyEventDetailsQuery } from "../../../../../store/proxyEvent";

dayjs.extend(utc);
dayjs.extend(timezone);

// TODO: add voting message types
type Message = IShareholderComposeMessage | ISentMessage;

interface IProps {
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateMessage: MutationTrigger<any>;
  isUpdateLoading: boolean;
  isUpdateSuccess: boolean;
  isUpdateError: boolean;
  messageData?: Message;
  status: MessageStatus;
  isMessageSuccess: boolean;
  isMessageFetching: boolean;
  close: () => void;
  successAction: () => void;
  numShareholders?: number;
  messageDialogId: string | null;
  proxyEventId?: string | null;
}

const ScheduleDialog = ({
  open,
  updateMessage,
  isUpdateLoading,
  isUpdateSuccess,
  isUpdateError,
  messageData,
  status,
  isMessageSuccess,
  isMessageFetching,
  close,
  successAction,
  numShareholders = 0,
  messageDialogId,
  proxyEventId,
}: IProps) => {
  const dispatch = useAppDispatch();

  const { isLoading: isProxyLoading } = useGetProxyEventDetailsQuery(
    proxyEventId || skipToken
  );

  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      timezone: USER_TIMEZONE_OBJ
        ? {
            id: USER_TIMEZONE_OBJ.value,
            name: USER_TIMEZONE_OBJ.value,
            offset: USER_TIMEZONE_OBJ.offset,
          }
        : undefined,
      scheduledDate: null as Dayjs | null,
      scheduledTime: null as Dayjs | null,
      sendOption: SendOptions.sendNow,
    },
  });

  const {
    formState: { isValid, isDirty, errors },
  } = methods;

  // are there any errors
  const hasError = Object.keys(errors).length > 0;

  const actionsDisabled =
    isUpdateLoading || isMessageFetching || isProxyLoading;

  /**
   * When new message data is pulled, reset form
   */
  useEffect(() => {
    if (isMessageSuccess) {
      const parsedData = parseUTCtoDateAndTime(
        USER_TIMEZONE_OBJ,
        messageData as Message
      );
      methods.reset(parsedData);
    }
    return () => {
      methods.reset({
        scheduledDate: null,
        scheduledTime: null,
      });
    };
  }, [isMessageSuccess, messageData?.id]);

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg:
      "Message has been submitted for review. It will be sent at the scheduled time or once approved.",
    errorAction: close,
    successAction: successAction,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  });

  /**
   * convert message back to draft
   */
  const cancelSchedule = () => {
    updateMessage({
      id: messageDialogId as string,
      message: {
        scheduledSendAt: null,
        scheduledSendAtTimezone: null,
      },
    })
      .unwrap() // uses same api as schedule, may want to rethink this and maybe add a "type" to the query so we can easier handle messaging
      .then(() => {
        close();
        dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: `Message is no longer scheduled to send!`,
          })
        );
      })
      .catch(() => {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: `Failed to cancel schedule for ${messageData?.campaignName}, please try again`,
          })
        );
      });
  };

  /**
   * list of ctas
   */
  const buttonActions = useMemo(
    () =>
      [
        {
          type: "submit",
          form: "schedule-form",
          label:
            status === MessageStatus.Scheduled ? "Update" : "Submit for review",
          disabled: (hasError && !isValid) || actionsDisabled,
        },
        ...(status === MessageStatus.Draft
          ? [
              {
                onClick: close,
                label: "Cancel",
                variant: "secondary",
                disabled: actionsDisabled,
              },
            ]
          : []),
        ...(status === MessageStatus.Scheduled
          ? [
              {
                onClick: cancelSchedule,
                label: "Cancel schedule",
                variant: "secondary",
                disabled: actionsDisabled,
              },
            ]
          : []),
      ] as IButtonAction[],
    [messageDialogId, isValid, isDirty, actionsDisabled, status, hasError]
  );

  const onSubmit = (formValues: FieldValues) => {
    if (formValues.sendOption === SendOptions.sendNow) {
      sendOnceApproved(messageDialogId as string);
    } else {
      scheduleMessage(formValues);
    }
  };

  /**
   * submit dialog form and send email
   */
  const sendOnceApproved = (messageDialogId: string) => {
    const utcDate = dayjs().utc().format();
    updateMessage({
      id: messageDialogId,
      message: {
        submit: true,
        scheduledSendAt: utcDate,
        scheduledSendAtTimezone:
          USER_TIMEZONE_OBJ?.value || "Eastern Standard Time",
      },
    });
  };

  /**
   * Submit scheduled date
   */
  const scheduleMessage = (formValues: FieldValues) => {
    const tzObject = TIME_ZONE_VALUE_MAPPING[formValues.timezone.id];

    // format date to a known format
    const formattedDate = formValues.scheduledDate.format(DATE_FORMAT);
    const formattedTime = formValues.scheduledTime.format(INPUT_TIME_FORMAT);
    // set timezone of selected date/time to selected timezone then convert to utc
    const utcDate = dayjs
      .tz(
        `${formattedDate} ${formattedTime}`,
        `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`,
        tzObject.utc[0]
      )
      .utc()
      .format();

    updateMessage({
      id: messageDialogId as string,
      message: {
        submit: true,
        scheduledSendAt: utcDate,
        scheduledSendAtTimezone: formValues.timezone.id,
      },
    }); // uses same api as cancel, may want to rethink this and maybe add a "type" to the query so we can easier handle messaging
  };

  const title =
    status === MessageStatus.Scheduled
      ? "Change schedule"
      : "Submit for review";

  return (
    <Dialog
      titleTextVariant="subtitle1"
      title={title}
      data-testid="submit-for-review-dialog"
      handleClose={close}
      buttonActions={buttonActions}
      content={
        <Form
          methods={methods}
          onSubmit={onSubmit}
          disabled={actionsDisabled}
          proxyEventId={proxyEventId}
          numShareholders={numShareholders}
          status={status as MessageStatus}
        />
      }
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: 662,
        },
      }}
      open={open}
    />
  );
};

export default ScheduleDialog;
