import {
  FilterSelectionFlow,
  FilterType,
  IFilter,
} from "@asayinc/component-library";
import { useMemo } from "react";
import { useGetShareholderTagsQuery } from "../../../../../../store/shareholderTags";
import { FILTER_MESSAGING_MAP } from "../../../../../../constants";

interface IProps {
  isLoading?: boolean;
}

const ShareholderTagsFilter = ({ isLoading }: IProps) => {
  const { data = [], isLoading: isTagsLoading } = useGetShareholderTagsQuery();

  // for compose flow we dont need ID, id should be the name
  const tagOptions = useMemo(
    () => data.map((t) => ({ ...t, id: t.name })),
    [data]
  );
  const options = useMemo(
    () =>
      [
        {
          type: FilterType.Unselected,
          label: "Not applied",
        },
        {
          type: FilterType.Values,
          label: "Shareholders tagged with",
          options: {
            groupLabel: "Tags",
            options: tagOptions,
          },
        },
        {
          type: FilterType.None,
          label: "Is not tagged",
        },
      ] as IFilter[],
    [data]
  );
  return (
    <FilterSelectionFlow
      withinForm={{
        fieldName: "filterMap.query.tags",
      }}
      clearable
      chipLabel="Shareholder tags"
      filterTypeOptions={options}
      chipProps={{
        disabled: isLoading || isTagsLoading,
      }}
      // chip messaging
      {...FILTER_MESSAGING_MAP.tags}
    />
  );
};

export default ShareholderTagsFilter;
