import {
  COLORS,
  IStep,
  StepIndicators,
  Text,
} from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";

interface IProps {
  header?: string;
  stepIndicatorData: IStep[];
  currentStep: number;
}

const EditHeader = ({ stepIndicatorData, currentStep, header }: IProps) => {
  return (
    <Stack
      sx={{
        borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
        pb: 8.5,
        mb: 8,
      }}
      alignItems="center"
    >
      <Text variant="h4" align="center" width={header ? "auto" : 300}>
        {header}
      </Text>
      <Box width="100%" maxWidth={1100} mx="auto">
        <StepIndicators
          steps={stepIndicatorData}
          currentStep={currentStep}
          sx={{ mt: 6 }}
        />
      </Box>
    </Stack>
  );
};

export default EditHeader;
