import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilterSelectionFlow,
  FilterType,
  IFilter,
} from "@asayinc/component-library";
import { useMemo, forwardRef, Ref } from "react";
import {
  useGetCountryFilterOptionsQuery,
  useGetStateFilterOptionsQuery,
} from "../../../../store/staticJson";
import { FILTER_MESSAGING_MAP } from "../../../../constants";

interface IProps {
  updateUrl?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  onClose?: () => void;
}

const CountryStateFilter = (
  { updateUrl = false, isLoading, onClose, disabled }: IProps,
  ref: Ref<{ triggerPopover: () => void }> // used to simulate click event on chip from parent
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstRender, setFirstRender] = useState(true);
  const { data: countryData, isLoading: countryDataLoading } =
    useGetCountryFilterOptionsQuery();
  const { data: stateData, isLoading: statesLoading } =
    useGetStateFilterOptionsQuery();

  const options = useMemo(
    () =>
      [
        {
          type: FilterType.Location,
          label: "Location selection",
          options: {
            hideSublocationIfEmpty: true,
            stateOptions: {
              multiple: false,
              optionType: "checkicon",
              groupLabel: "Country",
              placeholder: "All countries",
              options: statesLoading || countryDataLoading ? [] : countryData,
              mapping: {
                "United States": stateData?.map((state) => state.name),
              },
            },
            cityOptions: {
              placeholder: "All states",
              groupLabel: "State",
              multiple: false,
              optionType: "checkicon",
            },
          },
        },
      ] as IFilter[],
    [stateData, countryData]
  );

  const { watch, setValue } = useFormContext();
  /**
   * set formValues on page load
   */
  useEffect(() => {
    if (updateUrl) {
      const country = searchParams.get("country") || undefined;
      const state = searchParams.get("state") || undefined;

      if (country) setValue("country", { values: [country] });
      if (state) setValue("state", { values: [state] });
    }
  }, []);

  /**
   * update url params if updateURL is true
   */
  const country = watch("country");
  const state = watch("state");
  useEffect(() => {
    // prevent running on first render to avoid duplicate history states
    if (!firstRender) {
      if (updateUrl) {
        if (state?.values) searchParams.set("state", state.values[0]);
        if (country?.values) searchParams.set("country", country.values[0]);

        setSearchParams(searchParams);
      }
    } else {
      setFirstRender(false);
    }
  }, [country, state]);

  const onClear = () => {
    if (updateUrl) {
      searchParams.delete("country");
      searchParams.delete("state");
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <FilterSelectionFlow
        withinForm={{
          fieldName: "country",
          fieldName2: "state",
        }}
        clearable
        chipProps={{
          disabled:
            isLoading || statesLoading || countryDataLoading || disabled,
        }}
        onClear={onClear}
        onClose={onClose}
        ref={ref}
        testid="chip-location"
        chipLabel="Location"
        filterTypeOptions={options}
        {...FILTER_MESSAGING_MAP.location}
      />
    </>
  );
};

export default forwardRef(CountryStateFilter);
