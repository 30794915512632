import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { FieldValues } from "react-hook-form";
import {
  FilterSelectionFlow,
  IFilter,
  FilterType,
} from "@asayinc/component-library";
import dayjs from "dayjs";

interface IProps {
  beforeParam: string;
  afterParam: string;
  fieldNamePrefix: string;
  chipLabel: string;
  stateSearchParams?: URLSearchParams;
  stateSetSearchParams?: (params: URLSearchParams) => void;
}

/**
 * Uses FilterSelectionFlow from component library to update
 * URL search params according to param props
 */

const formatDate = (date: Date) => date.toISOString().slice(0, 10);

// add "T00:00:00" to reset timezone so date is correct
const createDateObj = (str: string) => dayjs(str + "T00:00:00");

const DateFilter = ({
  beforeParam,
  afterParam,
  fieldNamePrefix,
  chipLabel,
  stateSearchParams,
  stateSetSearchParams,
}: IProps) => {
  // allows for updating url or not updating url and using state params instead
  const [urlSearchParams, urlSetSearchParams] = useSearchParams();
  const searchParams = stateSearchParams || urlSearchParams;
  const setSearchParams = stateSetSearchParams || urlSetSearchParams;
  const [firstRender, setFirstRender] = useState(true);
  const [values, setValues] = useState<FieldValues | undefined>(undefined);

  /**
   * set formValues on page load
   */
  useEffect(() => {
    const before = searchParams.get(beforeParam);
    const after = searchParams.get(afterParam);

    if (before && after && before === after) {
      setValues({
        "date-on": {
          [`${fieldNamePrefix}-on`]: createDateObj(before),
        },
      });
    } else if (before) {
      setValues({
        "date-before": {
          [`${fieldNamePrefix}-before`]: createDateObj(before),
        },
      });
    } else if (after) {
      setValues({
        "date-after": {
          [`${fieldNamePrefix}-after`]: createDateObj(after),
        },
      });
    }
  }, []);

  /**
   * use date filter values to update URL search params
   */
  useEffect(() => {
    // prevent running on first render to avoid duplicate history states
    if (!firstRender) {
      if (values) {
        const dateBefore = values["date-before"];
        const dateAfter = values["date-after"];
        const dateOn = values["date-on"];
        if (dateBefore) {
          searchParams.set(
            beforeParam,
            formatDate(dateBefore[`${fieldNamePrefix}-before`])
          );
        }
        if (dateAfter) {
          searchParams.set(
            afterParam,
            formatDate(dateAfter[`${fieldNamePrefix}-after`])
          );
        }
        if (dateOn) {
          const formattedDate = formatDate(dateOn[`${fieldNamePrefix}-on`]);
          searchParams.set(beforeParam, formattedDate);
          searchParams.set(afterParam, formattedDate);
        }
        setSearchParams(searchParams);
      }
    } else {
      setFirstRender(false);
    }
  }, [values]);

  const options = [
    {
      type: FilterType.Unselected,
      label: "Not applied",
    },
    {
      type: "date-before",
      label: "Before",
      options: {
        fieldName: `${fieldNamePrefix}-before`,
      },
    },
    {
      type: "date-after",
      label: "After",
      options: {
        fieldName: `${fieldNamePrefix}-after`,
      },
    },
    {
      type: "date-on",
      label: "On",
      options: {
        fieldName: `${fieldNamePrefix}-on`,
      },
    },
  ] as IFilter[];

  const onSubmit = (data: FieldValues, filterType?: string) => {
    if (data) {
      setValues({
        [filterType as string]: data,
      });
    }
  };

  const onClear = () => {
    searchParams.delete(beforeParam);
    searchParams.delete(afterParam);
    setSearchParams(searchParams);
    setValues(undefined);
  };

  return (
    <FilterSelectionFlow
      skipMessaging
      clearable
      onClear={onClear}
      onSubmit={onSubmit}
      chipLabel={chipLabel}
      filterTypeOptions={options}
      values={values}
    />
  );
};

export default DateFilter;
