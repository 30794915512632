export enum MessageStatus {
  Draft = "draft",
  Scheduled = "scheduled",
  PendingApproval = "pending_approval",
  Sending = "sending", // sending
  Attaching = "attaching", // sent
  Attached = "attached", // sent
  Fetched = "fetched", // sent
  Fetching = "fetching", // sent
  Sent = "sent", // sent
  RefreshingIndex = "refreshing_index", // sent
  ReadyForFetching = "ready_for_fetching", // sent
}

export enum MessageStep {
  NameCampaign = "Name campaign",
  ChooseRecipients = "Choose recipients",
  DraftMessage = "Draft message",
  DraftInAppMessage = "Draft In App Message",
  Review = "Review",
}
