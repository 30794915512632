import { Text } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { CompanyLeadershipEditor } from "../../../../../components/Common";

interface IProps {
  isLoading?: boolean;
}

const CompanyLeadership = ({ isLoading }: IProps) => {
  return (
    <Stack spacing={6}>
      <Box>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Company leadership
        </Text>
        <Text variant="body2">
          The profiles of your company leaders will appear throughout
          shareholder experiences.
        </Text>
      </Box>
      <Box>
        <CompanyLeadershipEditor editable isLoading={isLoading} />
      </Box>
    </Stack>
  );
};

export default CompanyLeadership;
