import { ButtonV2, COLORS, Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";

interface IProps {
  reminder?: boolean;
  onClick: () => void;
  shareholderCount: string;
  buttonLabel: string;
}

const TimelineInfluenceNode = ({
  reminder,
  onClick,
  shareholderCount,
  buttonLabel,
}: IProps) => (
  <Stack
    pl={2.5}
    pb={3}
    pr={3.5}
    bgcolor={COLORS.ICE_BLUE}
    borderRadius={2}
    direction="row"
    alignItems="center"
    justifyContent="space-between"
  >
    <Box pr={1} pt={6}>
      <Box pl={3.5}>
        <Text variant="subtitle2" mb={3}>
          {reminder ? "Send a reminder" : "Influence the vote"}
        </Text>
        <Stack direction="row">
          <Text variant="body2" mb={1}>
            {reminder
              ? "Let shareholders know that their vote counts."
              : `Reach out to your ${shareholderCount} shareholders on Say.`}
          </Text>
          <Stack>
            <svg
              width="58"
              height="32"
              viewBox="0 0 58 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6823 19.9529L54.9529 0L3 16.9412L17.6823 19.9529Z"
                fill="#1968FF"
              />
              <path
                d="M22.1998 32L54.9527 0L17.6821 19.9529L22.1998 32Z"
                fill="#0051C1"
              />
              <path
                d="M25.0237 22.5882L54.9531 0L22.2002 32L25.0237 22.5882Z"
                fill="#002A51"
              />
              <path
                d="M25.0234 22.5882L54.9528 0L43.2822 28.6117L25.0234 22.5882Z"
                fill="#1968FF"
              />
            </svg>
          </Stack>
        </Stack>
      </Box>
      <ButtonV2
        variant="text"
        onClick={onClick}
        data-testid="influence-send-message"
        sx={{
          py: 2,
          px: 3.5,
        }}
      >
        {buttonLabel}
      </ButtonV2>
    </Box>
  </Stack>
);

export default TimelineInfluenceNode;
