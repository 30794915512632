import { Message } from "../../../../../../components/Messages";
import {
  ConfirmEditDialog,
  ScheduleShareholderMessage,
  TestEmailDialog,
} from "../../../../../../components/Messages";

const ProxyMessage = () => {
  return (
    <>
      <Message />
      <ScheduleShareholderMessage />
      <TestEmailDialog />
      <ConfirmEditDialog />
    </>
  );
};

export default ProxyMessage;
