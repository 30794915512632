import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { track } from "../../../../analytics";
import { initialState, useGetEventQuery } from "../../../../store/event";
import { useGetSettingsQuery } from "../../../../store/settings";
import { ISettings } from "../../../../store/settings/types";
import { Summary, ParticipantsTable } from "./components";

const Participants = () => {
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };
  const { data: event = initialState, isSuccess } = useGetEventQuery(eventSlug);
  const { status, slug } = event;

  const { data: settings = {}, isSuccess: isCompanySuccess } =
    useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;

  useEffect(() => {
    if (isSuccess && isCompanySuccess) {
      track({
        name: "Q&A Event Selected",
        client: companyName,
        qaEvent: slug,
        eventStatus: status,
        menuTab: "Participants",
      });
    }
  }, [isSuccess, isCompanySuccess]);
  return (
    <Stack spacing={8}>
      <Summary />
      <ParticipantsTable eventTitle={event.title} />
    </Stack>
  );
};

export default Participants;
