import {
  IEventParticipantsData,
  IEventParticipantsAgg,
} from "../../types/Participants";
import { IURL_PARAMS } from "../../types/Common";
import { rootApi } from "../rootApi";

// Define a service using a base URL and expected endpoints
export const participantsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // Participants
    getParticipants: builder.query<
      IEventParticipantsData | IEventParticipantsAgg,
      {
        eventSlug: string;
        params?: IURL_PARAMS;
      }
    >({
      query: ({ eventSlug, params }) => {
        const queryString = new URLSearchParams(params).toString();
        return `/qa-events/${eventSlug}/participants/?${queryString}`;
      },
      providesTags: ["ShareholderTags"],
      transformResponse: (
        response: IEventParticipantsData | IEventParticipantsAgg,
        _,
        arg
      ) => {
        if (arg?.params?.agg) {
          return response as IEventParticipantsAgg;
        }
        return response as IEventParticipantsData;
      },
    }),
  }),
});

export const { useGetParticipantsQuery } = participantsApi;
