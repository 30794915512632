import { LoadingSkeleton, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { sanitize } from "dompurify";

interface IProps {
  isLoading: boolean;
  subject?: string;
  emailHtml: string;
}

const EmailPreview = ({ isLoading, subject, emailHtml }: IProps) => {
  return (
    <Stack>
      <Text
        textAlign="center"
        variant="subtitle2"
        mb={3}
        loadingProps={{ sx: { width: "80px" } }}
      >
        {isLoading ? undefined : "Subject"}
      </Text>
      <Text textAlign="center" variant="h5" mb={6}>
        {isLoading ? undefined : subject}
      </Text>
      {isLoading ? (
        <LoadingSkeleton sx={{ height: "578px" }} />
      ) : (
        <div
          data-testid="email-html"
          dangerouslySetInnerHTML={{ __html: sanitize(emailHtml) }}
          style={{ pointerEvents: "none" }}
        />
      )}
    </Stack>
  );
};

export default EmailPreview;
