import {
  FilterSelectionFlow,
  FilterType,
  IFilter,
} from "@asayinc/component-library";
import { useMemo } from "react";
import { useGetEventFilterOptionsQuery } from "../../../../../../store/event";
import { FILTER_MESSAGING_MAP } from "../../../../../../constants";

interface IProps {
  isLoading?: boolean;
}

const QAsParticipatedFilter = ({ isLoading }: IProps) => {
  const { data } = useGetEventFilterOptionsQuery();

  const options = useMemo(
    () =>
      [
        {
          type: FilterType.Unselected,
          label: "Not applied",
        },
        {
          type: FilterType.Values,
          label: "Participated in",
          options: {
            groupLabel: "Q&As",
            options: data,
          },
        },
        {
          type: FilterType.Range,
          label: "Q&A count",
        },
      ] as IFilter[],
    [data]
  );

  return (
    <FilterSelectionFlow
      withinForm={{
        fieldName: "filterMap.query.qasParticipated",
      }}
      clearable
      chipProps={{
        disabled: isLoading,
      }}
      chipLabel="Q&As participated"
      filterTypeOptions={options}
      {...FILTER_MESSAGING_MAP.qasParticipated}
    />
  );
};

export default QAsParticipatedFilter;
