// components
import { Stack, Box } from "@mui/material";
import { Text, HookTextField } from "@asayinc/component-library";
// types
import {
  UseFormRegister,
  FieldValues,
  Control,
  UseFormSetValue,
  UseFormSetError,
} from "react-hook-form";
import { SyntheticEvent } from "react";
import { IImportantLink } from "../../../../../../store/messageCompose";
import { FormImageUpload } from "../../../../../Common";

interface IProps {
  control: Control;
  register: UseFormRegister<FieldValues>;
  setLinkValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  link: IImportantLink;
  handleBlur: (arg: SyntheticEvent) => void;
  isDisabled?: boolean;
  idx: number;
}

const ImportantLinksForm = ({
  control,
  register,
  setLinkValue,
  setError,
  link,
  idx,
  handleBlur,
  isDisabled = false,
}: IProps) => {
  return (
    <Stack width="598px">
      <Box>
        <Text variant="body1">Add a link or media.</Text>
      </Box>
      <Stack direction="row">
        <Stack direction="row" width="100%" data-testid="important-link-form">
          <input type="hidden" {...register("id", { value: link?.id })} />
          <Box width="100%" flex={1}>
            <Box mt={4.5}>
              <HookTextField
                onBlur={handleBlur}
                sx={{ width: "100%" }}
                name="url"
                placeholder="url"
                outerLabel="Url"
                id={`url-${idx}`}
                inputSize="small"
                control={control}
                disabled={isDisabled}
                validationArr={["url"]}
                defaultValue={link.url}
                rules={{
                  required: {
                    value: true,
                    message: "Url is required.",
                  },
                }}
              />
            </Box>
            <Box mt={4.5}>
              <HookTextField
                sx={{ width: "100%" }}
                name="title"
                placeholder="Title"
                control={control}
                defaultValue={link.title}
                disabled={isDisabled}
                outerLabel="Title"
                id={`title-${idx}`}
                inputSize="small"
                rules={{
                  required: {
                    value: true,
                    message: "Title is required.",
                  },
                }}
              />
            </Box>

            <Box mt={4.5}>
              <HookTextField
                sx={{ width: "100%" }}
                fullWidth
                name="description"
                placeholder="Description"
                inputSize="small"
                control={control}
                disabled={isDisabled}
                id={`description-${idx}`}
                defaultValue={link.description}
                outerLabel="Description"
              />
            </Box>
            <Box width={140} mr={1}>
              <Text sx={{ mt: 6, mb: 4 }} variant="body2">
                Thumbnail
              </Text>
              <FormImageUpload
                name="image"
                fileNameField="imageFileName"
                type="asset"
                size="medium"
                width={700}
                control={control}
                required={false}
                disabled={isDisabled}
                defaultValue={link.image}
                height={350}
                description="Use a high quality PNG image: 700x350px"
                data-testid={`img-${idx}`}
                setValue={setLinkValue}
                setError={setError}
                register={register}
              />
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ImportantLinksForm;
