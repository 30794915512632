import {
  MessageType,
  DEFAULT_MESSAGE_LIMIT,
  NOT_SENT_TYPES,
  useGetMessagesQuery,
} from "../../../../../../../store/messageLists";
import { MessageCardList } from "../../../../../../../components/Messages";

const NotSent = () => {
  const { data, isLoading } = useGetMessagesQuery({
    limit: DEFAULT_MESSAGE_LIMIT.toString(),
    offset: 0,
    messageType: MessageType.SM,
    messageStatus: NOT_SENT_TYPES,
  });

  return (
    <MessageCardList
      messages={data?.results || []}
      title="In progress"
      count={data?.count || 0}
      isLoading={isLoading}
    />
  );
};

export default NotSent;
