import dayjs, { Dayjs } from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";

dayjs.extend(dayjsBusinessDays);

interface IDateValidation {
  eventStartDatetime: Dayjs | null;
  openDatetime: Dayjs | null;
  closeDatetime: Dayjs | null;
  isDraft?: boolean;
  disabled?: boolean;
}

/**
 * validate currently selected eventDate
 */
export const isEventDateValid = ({
  eventStartDatetime,
  openDatetime,
  closeDatetime,
  disabled,
}: IDateValidation) => {
  if (disabled) {
    return true;
  }
  // valid date
  if (!eventStartDatetime || !eventStartDatetime.isBusinessDay()) {
    return "Date must be Monday-Friday.";
  }
  // must be after today
  if (dayjs().businessDaysAdd(1).isAfter(eventStartDatetime, "day")) {
    return "Date must be after today.";
  }
  // cannot be more than 1 day after Close Date
  if (
    closeDatetime &&
    closeDatetime.businessDaysAdd(1).isBefore(eventStartDatetime, "day")
  ) {
    return "Cannot be more than 1 day after after Close Date.";
  }
  // cannot be more than 11 days after open date
  if (
    openDatetime &&
    openDatetime.businessDaysAdd(11).isBefore(eventStartDatetime, "day")
  ) {
    return "Cannot be more than 11 days after Start Date.";
  }
  return true;
};

/**
 * validate currently selected closeDate
 */
export const isCloseDateValid = ({
  eventStartDatetime,
  openDatetime,
  closeDatetime,
  disabled,
}: IDateValidation) => {
  if (disabled) {
    return true;
  }

  // valid date
  if (!closeDatetime || !closeDatetime.isBusinessDay()) {
    return "Date must be Monday-Friday.";
  }

  if (eventStartDatetime) {
    // max 1 day before eventDate
    if (closeDatetime.businessDaysAdd(1).isBefore(eventStartDatetime, "day")) {
      return "Date must be at most 1 day before the Event Date.";
    }
  }
  if (openDatetime) {
    // cannot be before openDate
    if (closeDatetime.businessDaysAdd(-1).isBefore(openDatetime, "day")) {
      return "Date cannot be before the Start Date.";
    }
    // cannot be more than 10 days after openDate
    if (closeDatetime.businessDaysSubtract(10).isAfter(openDatetime, "day")) {
      return "Date cannot be more than 10 days after Start Date.";
    }
  }
  if (dayjs().isAfter(closeDatetime, "day")) {
    return "Date must be after today.";
  }
  return true;
};

/**
 * validate currently selected openDate
 */
export const isOpenDateValid = ({
  eventStartDatetime,
  openDatetime,
  closeDatetime,
  isDraft,
  disabled,
}: IDateValidation) => {
  if (disabled) {
    return true;
  }
  // go off exact time if non-draft
  if (!isDraft && openDatetime && openDatetime.isBefore(dayjs())) {
    return "Date must be after today.";
  }
  // valid date
  if (!openDatetime || !openDatetime.isBusinessDay()) {
    return "Date must be Monday-Friday.";
  }
  if (closeDatetime) {
    // cannot be after closeDate
    if (openDatetime.businessDaysAdd(1).isAfter(closeDatetime, "day")) {
      return "Date cannot be after Close Date.";
    }
    // cannot be more than 10 days before closeDdate
    if (openDatetime.businessDaysAdd(10).isBefore(closeDatetime, "day")) {
      return "Date cannot be more than 10 days before Close Date.";
    }
  }
  // cannot be more than 11 days before event date
  if (
    eventStartDatetime &&
    openDatetime.businessDaysAdd(11).isBefore(eventStartDatetime, "day")
  ) {
    return "Date cannot be more than 11 days before Event Date.";
  }
  if (dayjs().isAfter(openDatetime, "day")) {
    return "Date must be after today.";
  }
  return true;
};
