export const MORE_FILTER_OPTIONS = [
  {
    label: "Messages received",
    value: "messagesReceived",
  },
  {
    label: "Messages opened",
    value: "messagesOpened",
  },
  {
    label: "Links clicked",
    value: "messagesLinksClicked",
  },
  {
    label: "Messages Replied",
    value: "messagesReplied",
  },
];

/**
 * value label mapping required for select to display data properly
 */
export const MORE_FILTER_LABEL_MAP = MORE_FILTER_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);

export const FORM_PATH = "filterMap.query";
