import {
  ForgotPassword as ForgotPasswordComponent,
  ForgotPasswordConfirmation,
  MessageContainer,
} from "@asayinc/component-library";
import { useSubmitEmailMutation } from "../../store/ssoUser";

interface FormFields {
  email: string;
}

const ForgotPassword = (): JSX.Element => {
  document.title = "Forgot Password | Say Issuer Portal";
  const [submitEmail, response] = useSubmitEmailMutation();

  /**
   * submit form data to forgot password api
   */
  const submitForm = (data: FormFields) => {
    submitEmail({ domain: window.location.hostname, ...data });
  };

  return (
    <MessageContainer>
      {response.isSuccess ? (
        <ForgotPasswordConfirmation />
      ) : (
        <ForgotPasswordComponent submitForm={submitForm} />
      )}
    </MessageContainer>
  );
};

export default ForgotPassword;
