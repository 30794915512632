import { Box } from "@mui/material";
import { AutocompleteChip, COLORS, Text } from "@asayinc/component-library";
import { Question } from "../../../../../../../types/Questions";

interface IProps {
  question: Question;
}

const chipStyles = { mr: 2, mb: 2 };

const UserQuestion = ({ question }: IProps): JSX.Element | null => {
  const { questionText, tags } = question;

  return (
    <Box
      pb={tags?.length ? 4 : 6}
      borderBottom={`1px solid ${COLORS.P_OUTLINE}`}
    >
      <Text variant="subtitle1" sx={{ mb: 3 }}>
        {questionText}
      </Text>
      {tags.map((tag) => (
        <AutocompleteChip key={tag.id} option={tag} sx={chipStyles} />
      ))}
    </Box>
  );
};

export default UserQuestion;
