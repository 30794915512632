import { Box } from "@mui/material";
import { COLORS } from "@asayinc/component-library";

const DotIndicator = () => (
  <Box
    component="span"
    data-testid="profile-incomplete-indication"
    sx={{
      ml: 2,
      display: "inline-block",
      width: "12px",
      borderRadius: "50%",
      height: "12px",
      bgcolor: COLORS.SATURATED_RED,
    }}
  />
);

export default DotIndicator;
