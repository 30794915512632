import { EventStore, IPublishDialog } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: EventStore = {
  displayPublishDialog: null,
  creationStep: 0,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPublishDialog: (state, action: PayloadAction<IPublishDialog | null>) => {
      state.displayPublishDialog = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.creationStep = action.payload;
    },
  },
});

export const { setStep, setPublishDialog } = eventSlice.actions;

export const eventReducer = eventSlice.reducer;
