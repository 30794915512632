import { DATE_FORMAT, TIME_FORMAT } from "@asayinc/component-library";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { TIME_ZONE_VALUE_MAPPING } from "../constants/timezones";
import { MONTH_DAY_FORMAT } from "../constants/date";

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  scheduledSendAt?: string | null;
  scheduledSendAtTimezone?: string | null;
}

/**
 * generate time stamp based on utc time and a timezone id
 */
export const generateDateString = ({
  scheduledSendAt,
  scheduledSendAtTimezone,
}: IProps) => {
  const tzObject = scheduledSendAtTimezone
    ? TIME_ZONE_VALUE_MAPPING?.[scheduledSendAtTimezone]
    : null;
  if (scheduledSendAt && tzObject) {
    return dayjs(scheduledSendAt)
      .tz(tzObject.utc[0])
      .format(`${DATE_FORMAT} [at] ${TIME_FORMAT}`);
  }
  return "";
};

/**
 * generate short time stamp based on utc time and a timezone id
 */
export const generateShortDateString = ({
  scheduledSendAt,
  scheduledSendAtTimezone,
}: IProps) => {
  const tzObject = scheduledSendAtTimezone
    ? TIME_ZONE_VALUE_MAPPING?.[scheduledSendAtTimezone]
    : null;
  if (scheduledSendAt && tzObject) {
    return dayjs(scheduledSendAt)
      .tz(tzObject.utc[0])
      .format(`${MONTH_DAY_FORMAT} [at] ${TIME_FORMAT}`);
  }
  return "";
};
