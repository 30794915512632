import { track } from "../../../analytics";
import { Params } from "../../../types/Common";

/**
 * Tracks when a row is starred
 */
export const trackStarRow = (
  id: string,
  isSelectedByIssuer: boolean | undefined,
  slug: string
): void => {
  track({
    name: "Star Question Action",
    context: "From Row",
    questionId: id,
    slug,
    action: isSelectedByIssuer ? "Removed" : "Added",
  });
};

/**
 * Track ordering change
 */
export const trackOrderingChange = (
  sortValue: string,
  params: Params,
  slug: string
): void => {
  track({
    name: "Sort Option Selected",
    searchApplied: Boolean(params.query),
    slug,
    sortSelected: sortValue,
    sortingApplied: Boolean(sortValue),
  });
};

/**
 * select all rows
 */
export const trackToggleAllRows = (
  type: "select" | "deselect",
  params: Params,
  slug: string
): void => {
  track({
    name: "Bulk Check Action",
    action: type,
    slug,
    searchApplied: Boolean(params.query),
    sortingApplied: Boolean(params.ordering),
  });
};

/**
 * Track opening dialog for exporting or tagging questions
 */
export const trackOpenDialog = (
  type: "download" | "bulkTag" | "singleTag",
  params: Params,
  slug: string
): void => {
  track({
    name: type === "download" ? "Bulk Export Clicked" : "Bulk Tag Clicked",
    slug,
    searchApplied: Boolean(params.query),
    context: "Questions List",
    sortingApplied: Boolean(params.ordering),
  });
};

/**
 * Get no results messaging
 */
export const getNoResultsProps = (
  params: Params
): { title: string; subtitle: string } => {
  const hasFilterApplied = params.filter || params.query || params.type;
  return {
    title: hasFilterApplied
      ? "No questions in this filtered view"
      : "No questions yet",
    subtitle: hasFilterApplied
      ? "Sorry, there are no questions that match your applied filters."
      : "There have not been any questions submitted by participants yet.",
  };
};
