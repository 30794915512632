import { Stack } from "@mui/material";
import { useGetSettingsQuery } from "../../../../../../../store/settings";
import { IPushNotificationTemplate } from "../../../../../../../store/messageLists";
import { HomeScreen, MessageScreen } from "../../Atoms";

interface IProps {
  isStacked?: boolean; // will use once we have assets, sizes/spacing are different in the stacked and side by side look
  messageText?: string;
  notification?: IPushNotificationTemplate;
}

const InAppPreview = ({ notification, messageText, isStacked }: IProps) => {
  const { data: settingsData } = useGetSettingsQuery();

  return (
    <Stack
      direction={isStacked ? "column-reverse" : "row"}
      alignItems="center"
      gap={6}
    >
      <HomeScreen
        companyName={settingsData?.name}
        notification={notification}
        isStacked={isStacked}
      />
      <MessageScreen
        messageText={messageText}
        companyName={settingsData?.name}
        isStacked={isStacked}
      />
    </Stack>
  );
};

export default InAppPreview;
