import { createContext } from "react";
import { IShareholderMeta } from "../../../../types/Shareholder";

export const Context = createContext(
  {} as {
    setActiveTab: (tab: string) => void;
    data: IShareholderMeta;
    isLoading: boolean;
    isFetching: boolean;
    asDrawer: boolean;
    hasQaActivity: boolean;
    globalUserId?: string;
    hasMessagesActivity: boolean;
    isQaDataSuccess: boolean;
    isMessagesDataSuccess: boolean;
  }
);
