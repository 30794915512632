import { TableV2 as Table } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useMessageParams } from "../../../../../../../hooks";
import { track } from "../../../../../../../analytics";
import { SORT, SORT_SYMBOL } from "../../../../../../../constants";
import { useStateSorting } from "../../../../../../../hooks/useStateSorting";
import { useGetMessageRepliesQuery } from "../../../../../../../store/messageLists";
import {
  MessageExportTypes,
  useExportMessageDataMutation,
} from "../../../../../../../store/messageStats";
import { useGetMessageQuery } from "../../../../../../../store/messageCompose";
import { getColumnData, getRowData } from "./factories";

const COLUMNS = ["reply", "totalReplyPercent", "count", "shares"];

interface IProps {
  companyName: string;
  campaignName: string;
  asDrawer?: boolean;
}

const ReplyResults = ({
  companyName,
  campaignName,
  asDrawer,
}: IProps): JSX.Element => {
  const { messageId } = useMessageParams(asDrawer);
  // tracking values for sort
  const trackingData = useMemo(
    () => ({
      name: "Reply Results Sorted",
      client: companyName,
      campaign: campaignName,
      campaignStatus: "sent",
    }),
    [companyName, campaignName]
  );
  // for sorting columns
  const { sortFn, sortValue, getSort } = useStateSorting({
    defaultSort: `${SORT_SYMBOL.desc}${SORT.count}`,
    trackingData,
  });

  const {
    data: replyData = [],
    isFetching,
    isLoading,
    isError,
  } = useGetMessageRepliesQuery({
    messageId,
    sortValue: sortValue,
  });

  const [getExportedData, { isLoading: isExporting }] =
    useExportMessageDataMutation();

  // export csv for given reporting section
  const exportData = useCallback(() => {
    track({
      name: "List Downloaded",
      client: companyName,
      campaign: campaignName,
      campaignStatus: "sent",
      reportComponent: MessageExportTypes.Responses,
    });
    getExportedData({ exportType: MessageExportTypes.Responses, messageId });
  }, [messageId]);

  // props for download icon in table
  const downloadProps = useMemo(
    () => ({
      active: !isExporting,
      action: exportData,
      tooltip: "Download reply results.",
    }),
    [isExporting]
  );

  // row and column data for the table
  const rowData = useMemo(() => getRowData(replyData), [replyData]);
  const columnData = useMemo(
    () => getColumnData({ getSort, sortFn }),
    [sortFn, getSort]
  );

  const { data } = useGetMessageQuery(messageId);
  const prompt = data?.content?.quickReplies?.prompt;
  const subTitle = prompt ? `"${prompt}"` : undefined;

  return (
    <Box>
      <Table
        isLoading={isLoading || isError}
        isFetching={isFetching || isError}
        loadingRows={4}
        rows={rowData}
        testid="reply-results-table"
        downloadProps={downloadProps}
        toolbarInside
        titleVariant="subtitle2"
        title="Reply results"
        subTitle={subTitle}
        memoCells
        columnData={columnData}
        tableMinWidth={700}
        inDrawer={asDrawer}
        tableLayout="auto"
        columns={COLUMNS}
        count={replyData?.length || 0}
        disableHover={true}
      />
    </Box>
  );
};

export default ReplyResults;
