import { SelectChangeEvent } from "@mui/material";
import { useCallback, useState } from "react";
import { URL_PARAMS, SORT, FLIP_SORT, SORT_SYMBOL } from "../constants";
// types
import { TableEventData } from "../types/Table";
// utils
import { validateAllParams } from "../utils/validateParam";
import { track } from "../analytics";
import { useParams } from "./useParams";

interface ITrackingData {
  name: string;
  [key: string]: string | number;
}

interface IProps {
  defaultOrdering?: string;
  defaultAgg?: string;
  sortCallback?: (sortValue: string, searchParams: URLSearchParams) => void;
  pageChangeCallback?: (page: number) => void;
  rowChangeCallback?: (row: number) => void;
  trackingData?: ITrackingData;
  updateUrl?: boolean;
}

export const useTableSearchParams = ({
  defaultOrdering,
  defaultAgg,
  sortCallback,
  pageChangeCallback,
  rowChangeCallback,
  trackingData,
  updateUrl = true,
}: IProps) => {
  const [agg, setAgg] = useState(defaultAgg);
  const [searchParams, setSearchParams] = useParams(updateUrl);
  const rawApiObject = validateAllParams(
    Object.fromEntries(new URLSearchParams(searchParams))
  );
  const {
    [URL_PARAMS.limit]: limit = "10",
    [URL_PARAMS.page]: page = "1",
    [URL_PARAMS.ordering]: ordering = defaultOrdering || "",
    [URL_PARAMS.search]: search = "",
    [URL_PARAMS.replied]: replied = "",
    [URL_PARAMS.query]: query = "",
    [URL_PARAMS.investorType]: investorType = "",
    [URL_PARAMS.type]: type = "",
    [URL_PARAMS.filter]: filter = "",
    [URL_PARAMS.tags]: tags = "",
    [URL_PARAMS.voted]: voted = "",
    // questions table filter params
    [URL_PARAMS.sharesUpvotedMin]: sharesUpvotedMin = "",
    [URL_PARAMS.sharesUpvotedMax]: sharesUpvotedMax = "",
    [URL_PARAMS.upvoteCountMin]: upvoteCountMin = "",
    [URL_PARAMS.upvoteCountMax]: upvoteCountMax = "",
    [URL_PARAMS.submittedBefore]: submittedBefore = "",
    [URL_PARAMS.submittedAfter]: submittedAfter = "",
    [URL_PARAMS.isAnswered]: isAnswered = "",
    [URL_PARAMS.shareholderTagIds]: shareholderTagIds = "",
    [URL_PARAMS.shareholdersNotTagged]: shareholdersNotTagged = "",
    [URL_PARAMS.specificCategoryIds]: specificCategoryIds = "",
    // participants table filter params
    [URL_PARAMS.sharesOwnedMin]: sharesOwnedMin = "",
    [URL_PARAMS.sharesOwnedMax]: sharesOwnedMax = "",
    [URL_PARAMS.questionsAskedMin]: questionsAskedMin = "",
    [URL_PARAMS.questionsAskedMax]: questionsAskedMax = "",
    [URL_PARAMS.questionsAnsweredMin]: questionsAnsweredMin = "",
    [URL_PARAMS.questionsAnsweredMax]: questionsAnsweredMax = "",
    [URL_PARAMS.questionsUpvotedMin]: questionsUpvotedMin = "",
    [URL_PARAMS.questionsUpvotedMax]: questionsUpvotedMax = "",
    [URL_PARAMS.lastParticipatedBefore]: lastParticipatedBefore = "",
    [URL_PARAMS.lastParticipatedAfter]: lastParticipatedAfter = "",
    // upvotes table filter params
    [URL_PARAMS.numShareholderTagsMax]: numShareholderTagsMax = "",
    // Shareholder Network Filter Params
    [URL_PARAMS.ticker]: ticker = "",
    [URL_PARAMS.country]: country = "",
    [URL_PARAMS.state]: state = "",
  } = rawApiObject;
  const paramObj = {
    limit,
    page,
    ordering,
    search: search || query, // unfortunately apis are inconsistent
    investorType,
    replied,
    type,
    tags,
    filter,
    voted,
    // questions table filter params
    sharesUpvotedMin,
    sharesUpvotedMax,
    upvoteCountMin,
    upvoteCountMax,
    submittedBefore,
    submittedAfter,
    isAnswered,
    shareholderTagIds,
    shareholdersNotTagged,
    specificCategoryIds,
    // participants table filter params
    sharesOwnedMin,
    sharesOwnedMax,
    questionsAskedMin,
    questionsAskedMax,
    questionsAnsweredMin,
    questionsAnsweredMax,
    questionsUpvotedMin,
    questionsUpvotedMax,
    lastParticipatedBefore,
    lastParticipatedAfter,
    // upvotes table filter params
    numShareholderTagsMax,
    // Shareholder Network Filter Params
    ticker,
    country,
    state,
  };

  /**
   * Handle change in aggregate type
   */
  const handleAggChange = (e: SelectChangeEvent<unknown>): void => {
    setAgg(e.target.value as string);
  };

  /**
   * Flip current sort and sort on column
   * Default sort to "asc" so new column clicks get flipped to desc
   * @param data TableEventData
   */
  const sortFn = useCallback(
    (data: unknown) => {
      const { id, sort = "asc" } = data as TableEventData;
      const sortType = SORT?.[id as keyof typeof SORT];
      const newSort = FLIP_SORT[sort as keyof typeof FLIP_SORT];
      const sortValue = `${
        SORT_SYMBOL[newSort as keyof typeof SORT_SYMBOL]
      }${sortType}`;
      searchParams.set(URL_PARAMS.page, "1");
      searchParams.set(URL_PARAMS.ordering, sortValue);
      setSearchParams(searchParams);

      // if tracking data is provided track the sort fn
      if (trackingData) {
        track({
          sortColumn: sortType,
          sort: newSort,
          ...trackingData,
        });
      }

      if (sortCallback) {
        sortCallback(sortValue, searchParams);
      }
    },
    [searchParams, trackingData]
  );
  /**
   * calculate current sort based on queryParam
   */
  const getSort = useCallback(
    (value: string) => {
      const isFieldSorting = ordering.indexOf(value) !== -1;
      if (isFieldSorting) {
        return ordering.indexOf("-") !== -1 ? "desc" : "asc";
      }
      return undefined;
    },
    [ordering]
  );
  /**
   * pull questions for the given page number
   */
  const handlePageChange = (newPage: number) => {
    searchParams.set(URL_PARAMS.page, newPage.toString());
    setSearchParams(searchParams);
    if (pageChangeCallback) {
      pageChangeCallback(newPage);
    }
  };

  /**
   * pull questions with new rows per page, reset page to 0
   */
  const handleRowsChange = (newRows: number) => {
    searchParams.set(URL_PARAMS.page, "1");
    searchParams.set(URL_PARAMS.limit, newRows.toString());
    setSearchParams(searchParams);
    if (rowChangeCallback) {
      rowChangeCallback(newRows);
    }
  };

  return {
    searchParams,
    setSearchParams,
    limit,
    replied,
    page,
    paramObj,
    rawApiObject,
    ordering,
    search: search || query, // unfortunately apis are inconsistent
    filter,
    investorType,
    type,
    tags,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
    handleAggChange,
    agg,
  };
};
