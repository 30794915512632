import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { Text } from "@asayinc/component-library";
import { useAppDispatch } from "../../../../store/hooks";
import {
  IMessageTemplate,
  setTemplateId,
} from "../../../../store/messageCompose";
import { CardCtas, OverlayCtas } from "./components";
import { useGetFeaturesQuery } from "../../../../store/settings";

interface IProps {
  asCard?: boolean;
  template: IMessageTemplate;
  width?: string | string[];
  viewTemplate: (template: IMessageTemplate) => void;
}

const MessageTemplateTile = ({
  template,
  viewTemplate,
  asCard,
  width,
}: IProps) => {
  const { data: features } = useGetFeaturesQuery();
  const isFeatureEnabled = !!features?.messages;

  const {
    imageAssets: { sm: imagePreviewSm, md: imagePreviewMd },
    description,
    id,
    isBlank,
    name: title,
  } = template;
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  // go to create flow with template id instantiated
  const createFromTemplate = () => {
    dispatch(setTemplateId(id));
    goToCreate();
  };

  // go to create flow
  const goToCreate = () => {
    navigate("/messages/create");
  };

  // open view template preview dialog
  const handleView = () => {
    viewTemplate(template);
  };

  return (
    <Stack
      width={width}
      data-testid={`message-template-tile-${id}`}
      sx={{
        "&:hover .messageTemplate__overlay": {
          opacity: 1,
          pointerEvents: "all",
        },
      }}
    >
      <Stack position="relative" mb={6}>
        <img
          src={asCard ? imagePreviewMd : imagePreviewSm}
          alt={title}
          width="100%"
          height="auto"
        />
        {!asCard && (
          <OverlayCtas
            handleView={handleView}
            createFromTemplate={createFromTemplate}
            isFeatureEnabled={isFeatureEnabled}
          />
        )}
      </Stack>
      <Stack px={asCard ? 6 : 0}>
        <Text
          variant="subtitle1"
          mb={2}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            lineClamp: asCard ? 1 : 2,
            WebkitLineClamp: asCard ? 1 : 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {title}
        </Text>
        <Text
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            lineClamp: 2,
            WebkitLineClamp: 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {description}
        </Text>
        {asCard && (
          <CardCtas
            isBlank={isBlank}
            goToCreate={goToCreate}
            handleView={handleView}
            createFromTemplate={createFromTemplate}
            isFeatureEnabled={isFeatureEnabled}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default MessageTemplateTile;
