import { Stack, Box } from "@mui/material";
import { ButtonV2, COLORS } from "@asayinc/component-library";
import { useFormContext } from "react-hook-form";

interface IProps {
  isLoading?: boolean;
  reset: () => void;
}

const SettingsFooter = ({ isLoading, reset }: IProps): JSX.Element | null => {
  const {
    formState: { isDirty, errors, isValid },
  } = useFormContext();

  const hasError = Object.keys(errors).length > 0;

  return (
    <Stack
      position="sticky"
      bottom={0}
      right={0}
      p={10}
      pb={0}
      pt={0}
      width="100%"
      maxWidth={1440}
      m="0 auto"
      bgcolor="#F0F0F0"
      zIndex={10}
    >
      <Box px={1.5}>
        <Stack
          direction="row"
          sx={{
            borderTop: `1px solid ${COLORS.P_OUTLINE}`,
            pt: 6,
            pb: 10,
          }}
        >
          <Stack ml="auto" direction="row" gap={3}>
            <ButtonV2
              variant="secondary"
              disabled={!isDirty || isLoading}
              data-testid="btn-cancel"
              onClick={reset}
            >
              Cancel
            </ButtonV2>
            <ButtonV2
              data-testid="btn-save"
              disabled={!isDirty || isLoading || (hasError && !isValid)}
              type="submit"
            >
              Save
            </ButtonV2>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SettingsFooter;
