import {
  useBulkAddShareholderTagsMutation,
  useCreateShareHolderTagMutation,
  useGetShareholderTagsQuery,
} from "../../../../../store/shareholderTags";
import { Tag } from "../../../../../types/Common";
import AddTagsDialog from "../AddTagsDialog";
import { DialogTypes } from "./types";

interface IProps {
  handleClose: (dialog: DialogTypes, didSave?: boolean) => void;
  participantIds: string[];
  dialog: DialogTypes | "";
}

const ShareholderAddTagsDialog = ({
  handleClose,
  participantIds,
  dialog,
}: IProps) => {
  const open = dialog === "singleTag" || dialog === "bulkTag";
  const { data: options = [] } = useGetShareholderTagsQuery() as {
    data: Tag[];
  };
  const [createTag, { data: newTag }] = useCreateShareHolderTagMutation();
  const [addTags, { isLoading, isError }] = useBulkAddShareholderTagsMutation();

  // pass through so multiple api types can be used
  const createNewTag = (tagData: { color: string; name: string }) => {
    createTag(tagData);
  };

  const onClose = (didSave: boolean) => {
    handleClose(dialog as DialogTypes, didSave);
  };

  return (
    <AddTagsDialog
      rowIds={participantIds}
      open={open}
      dialogTitle="Add a tag to selected participants"
      handleClose={onClose}
      isError={isError}
      isLoading={isLoading}
      newTag={newTag}
      options={options}
      createTag={createNewTag}
      addTags={addTags}
    />
  );
};

export default ShareholderAddTagsDialog;
