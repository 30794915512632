export const AGG_OPTIONS = [
  {
    value: "avg",
    label: "Average",
  },
  {
    value: "median",
    label: "Median",
  },
  {
    value: "sum",
    label: "Sum",
  },
];

export const AGG_MAPPING = AGG_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);

export enum TabOptions {
  questions = "Questions",
  participants = "Participants",
}
