import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// components
import { Box, Stack } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  COLORS,
  Text,
  IconButton,
  pluralize,
} from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../../../../components/Common";
// redux / data
import { Context } from "../../../../../../context";
import { useGetShareholderMessageActivityQuery } from "../../../../../../../../../../store/shareholder";
// constants
import { TabOptions } from "../../../../../../constants";
import { IShareholderMessagesData } from "../../../../../../../../../../types/Shareholder";

const MessagesSummary = () => {
  const { data, setActiveTab } = useContext(Context);
  const goToMessages = () => {
    setActiveTab(TabOptions.messages.value);
  };

  const globalShareholderId = data?.globalShareholderId;

  const { data: messagesData, isLoading } =
    useGetShareholderMessageActivityQuery(
      globalShareholderId
        ? {
            shareholderId: globalShareholderId,
            offset: "0",
            limit: "1",
          }
        : skipToken
    );

  const messageCount = messagesData?.count || 0;
  const hasMessagesActivity = messageCount > 0;
  if (!hasMessagesActivity) {
    return null;
  }

  const mostRecentMessage = (messagesData as IShareholderMessagesData)
    .results[0];
  const { campaignName } = mostRecentMessage;

  return (
    <CardWrapper cardName="messages-summary">
      <Stack spacing={6}>
        <Stack spacing={3}>
          <Stack direction="row">
            <Text variant="subtitle2" loadingProps={{ sx: { width: "100px" } }}>
              {isLoading ? undefined : "Total received"}
            </Text>
            <IconButton
              onClick={goToMessages}
              data-testid="qa-summary-button"
              sx={{ ml: "auto" }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>
          <Text variant="h5" loadingProps={{ sx: { width: "200px" } }}>
            {isLoading
              ? undefined
              : `${messageCount} ${pluralize(
                  "message",
                  "messages",
                  messageCount
                )}`}
          </Text>
        </Stack>
        <Stack spacing={3}>
          <Text variant="body2">Most recently received</Text>
          <Box
            sx={{
              borderStyle: "solid",
              borderColor: COLORS.P_OUTLINE,
              borderWidth: "1px 0px",
              py: 5,
              px: 3,
            }}
          >
            <Text variant="subtitle2">{campaignName}</Text>
          </Box>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default MessagesSummary;
