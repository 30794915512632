import { Stack, Box } from "@mui/material";
import { IPushNotificationTemplate } from "../../../../../../store/messageLists";
import { COLORS, RadioButton, Text } from "@asayinc/component-library";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

interface IProps extends IPushNotificationTemplate {
  selected?: boolean;
  idx: number;
  fieldName: string;
}

const NotificationOption = ({
  id,
  title,
  text,
  selected,
  idx,
  fieldName,
}: IProps) => {
  const { setValue } = useFormContext();

  const handleChange = useCallback(() => {
    setValue(fieldName, id, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        onClick={handleChange}
        data-testid={`radio-${id}`}
      >
        <RadioButton
          checked={selected}
          value={id}
          id={id.toString()}
          name="radio-buttons"
        />
        <Text variant="body2" sx={{ cursor: "pointer" }}>
          Notification {idx + 1}
        </Text>
      </Stack>
      <Box
        mt={2}
        border={`1px solid ${COLORS.P_OUTLINE}`}
        borderRadius="13px"
        bgcolor="rgba(245,245,245,0.6)"
        px={3}
        py={2.5}
        data-testid={`radio-for-${id}`}
        onClick={handleChange}
        sx={{ cursor: "pointer" }}
      >
        <Text emphasis="medium_low" mb={2}>
          MOBILE APP
        </Text>
        <Text mb={0.5} variant="subtitle2">
          {title}
        </Text>
        <Text variant="body2">{text}</Text>
      </Box>
    </Box>
  );
};

export default NotificationOption;
