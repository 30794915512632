import { Text, TooltipInfoIcon } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormSectionDivider } from "../../../../../components/Common";
import { CloseDateAndTime, OpenDateAndTime } from "../../Molecules";
import { SortOrderForm } from "../../Organisms";

interface IProps {
  isLoading?: boolean;
  isDraft?: boolean;
  timezoneAbbr: string;
  timezoneId: string;
}

const OpenCloseDateForm = ({
  isLoading,
  isDraft,
  timezoneAbbr,
  timezoneId,
}: IProps) => {
  const { getValues, trigger } = useFormContext();

  const timezone = useWatch({
    name: "timezone",
  });

  // re-validate if timezone changes
  useEffect(() => {
    const { openDate, openTime, closeDate, closeTime } = getValues();
    if (openDate && openTime) {
      trigger(["openDate", "openTime"]);
    }
    if (closeDate && closeTime) {
      trigger(["closeDate", "closeTime"]);
    }
  }, [timezone]);

  return (
    <>
      <Box>
        <Stack direction="row" spacing={2.5} mb={3} alignItems="center">
          <Text variant="subtitle1">
            Shareholder question submission period
          </Text>
          <TooltipInfoIcon
            title="Note: Say sends shareholders communications at the open date
                time, as well as 24 hours before the close date time. Please
                schedule this time range within US business hours."
            placement="right"
            iconColor="black"
          />
        </Stack>
        <Text variant="body2">
          The time range shareholders are allowed to submit and upvote
          questions. We recommend starting this period 7 days before your event
          and ending this period within 1 business day before the event.
        </Text>
      </Box>
      <Stack>
        <OpenDateAndTime
          isDisabled={isLoading}
          isDraft={isDraft}
          timezoneAbbr={timezoneAbbr}
          timezoneId={timezoneId}
        />
      </Stack>
      <Stack>
        <CloseDateAndTime
          isDisabled={isLoading}
          isDraft={isDraft}
          timezoneAbbr={timezoneAbbr}
          timezoneId={timezoneId}
        />
      </Stack>
      <FormSectionDivider />
      <SortOrderForm />
    </>
  );
};

export default OpenCloseDateForm;
