import { useNavigate } from "react-router-dom";
// components
import { Stack } from "@mui/material";
import { Text, ButtonV2 } from "@asayinc/component-library";
import { EventStatusOptions } from "../../../../../types/Events";
import { useAppDispatch } from "../../../../../store/hooks";
import { setSnackbar } from "../../../../../store/common";

interface IProps {
  eventTitle: string;
  eventSlug: string;
  status: string;
}

const Header = ({ eventTitle, eventSlug, status }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showEditButton = status !== EventStatusOptions.COMPLETED;
  /**
   * Route to Edit QA page
   */
  const goToEditQA = () => {
    navigate(`/qa/${eventSlug}/edit`);
  };

  /**
   * Copy URL to clipboard
   */
  const previewUrl = `${window.config.REACT_APP_QA_URL}${eventSlug}`;
  const copyUrl = () => {
    window.navigator.clipboard.writeText(previewUrl);
    dispatch(
      setSnackbar({
        message: `Link copied to clicpboard`,
        open: true,
        severity: "success",
      })
    );
  };

  /**
   * Open preview url in a new 800x600 window
   */
  const openPreviewUrl = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.open(previewUrl, "newwindow", "width=800,height=600");
  };

  return (
    <Stack direction="row">
      <Text variant="h4">{eventTitle}</Text>
      <Stack direction="row" gap={3} ml="auto">
        <ButtonV2
          data-testid="event-preview-button"
          variant="secondary"
          onClick={openPreviewUrl}
        >
          Preview
        </ButtonV2>
        <ButtonV2
          data-testid="event-copy-link-button"
          variant={showEditButton ? "secondary" : "primary"}
          onClick={copyUrl}
        >
          Copy event link
        </ButtonV2>
        {showEditButton && (
          <ButtonV2 data-testid="event-edit-button" onClick={goToEditQA}>
            Edit
          </ButtonV2>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;
