import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  IQuickReplyOptionObj,
  IShareholderComposeMessage,
  QuickReplyTypeOptions,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
import {
  QuickReplyEmojisFormSection,
  QuickReplyTextFormSection,
  QuickReplyPreviews,
} from "./components";
import { FORM_FIELDS } from "../../../../constants";

interface IProps {
  isLoading?: boolean;
}

const QuickReplySection = ({ isLoading }: IProps) => {
  const [emojiOptionsState, setEmojiOptionsState] = useState<string[] | null>(
    null
  );
  const [textOptionsState, setTextOptionsState] = useState<
    IQuickReplyOptionObj[] | null
  >(null);
  const { messageId } = useParams() as { messageId: string };
  const { data } = useGetMessageQuery(messageId);
  const messageData = data as IShareholderComposeMessage;
  const quickReply = useWatch({
    name: "quickReplies",
  });

  const { setValue, trigger } = useFormContext();
  /**
   * handle swapping between emoji form text form or no form
   */
  const handleSelection = (type: QuickReplyTypeOptions | null) => {
    trigger(FORM_FIELDS.quickReplies.textData.options);
    trigger(FORM_FIELDS.quickReplies.emojiData.options);
    setValue(FORM_FIELDS.quickReplies.type, type, {
      shouldDirty: messageData?.content?.quickReplies?.type !== type,
    });
    if (type === QuickReplyTypeOptions.Emoji && emojiOptionsState) {
      setValue(FORM_FIELDS.quickReplies.emojiData.options, emojiOptionsState);
    } else if (type === QuickReplyTypeOptions.Text && textOptionsState) {
      setValue(FORM_FIELDS.quickReplies.textData.options, textOptionsState);
    }
  };

  if (quickReply?.type === "text") {
    return (
      <QuickReplyTextFormSection
        goBack={handleSelection}
        setTextOptionsState={setTextOptionsState}
        disabled={isLoading}
      />
    );
  }
  if (quickReply?.type === "emoji") {
    return (
      <QuickReplyEmojisFormSection
        goBack={handleSelection}
        setEmojiOptionsState={setEmojiOptionsState}
        disabled={isLoading}
      />
    );
  }

  return (
    <QuickReplyPreviews
      handleSelection={handleSelection}
      disabled={isLoading}
    />
  );
};

export default QuickReplySection;
