import { useNavigate } from "react-router-dom";
import { useCallback, useMemo } from "react";
// components
import { TableV2 as Table } from "@asayinc/component-library";
// router / store
import { useGetAllProxyEventsQuery } from "../../../../../../../store/proxyEvents";
// hooks
import { useTableSearchParams } from "../../../../../../../hooks";
// table constants and helper fns
import { ROW_OPTIONS } from "../../../../../../../constants";
import { COLUMNS, NO_RESULTS_DATA } from "./constants";
import { getColumnData, getRowData } from "./factories";
// types
import { IProxyEvent } from "../../../../../../../store/proxyEvents/types";

const PastEventsTable = () => {
  const navigate = useNavigate();

  const {
    data: events = [] as IProxyEvent[],
    isLoading,
    isFetching,
  } = useGetAllProxyEventsQuery(false);
  const count = events.length;

  /**
   * navigate to event
   */
  const goToEvent = useCallback((data: unknown) => {
    const { id } = data as { id: string };
    navigate(`/vote/${id}/`);
  }, []);

  // row and column data for the table
  const rowData = useMemo(() => getRowData({ events }), [events]);
  const columnData = useMemo(() => getColumnData({ goToEvent }), []);

  const { limit, page, handlePageChange, handleRowsChange } =
    useTableSearchParams({
      updateUrl: false,
    });

  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  return (
    <Table
      testid="past-event-table"
      title={`Past voting events (${count})`}
      tableMinWidth={700}
      tableLayout="auto"
      memoCells
      isFetching={isFetching}
      isLoading={isLoading}
      rows={rowData}
      columnData={columnData}
      columns={COLUMNS}
      count={count}
      noResultsData={NO_RESULTS_DATA}
      paginateProps={paginateProps}
    />
  );
};

export default PastEventsTable;
