import { DATE_FORMAT, INPUT_TIME_FORMAT } from "@asayinc/component-library";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  EVENT_SORT_ORDER_OPTIONS,
  QaPublishOptions,
} from "../../../../constants";
import { TIME_ZONE_VALUE_MAPPING } from "../../../../constants/timezones";
import {
  EventSortOptions,
  EventTypeOptions,
  ICompanyLeader,
  IDraftEvent,
} from "../../../../types/Events";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { IFormFields } from "../../types";
import {
  generateEventShortTitle,
  generateEventSlug,
  generateEventTitle,
} from "../getMetaData";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

/**
 * if date has been set format that date
 * otherwise use todays date
 */
export const formatDate = (date?: Dayjs | null, time?: Dayjs | null) =>
  date
    ? dayjs(date).format(DATE_FORMAT)
    : time
    ? dayjs().format(DATE_FORMAT)
    : undefined;

/**
 * If time exists set to that time, otherwise set to 9am est
 * always return in UTC time for storing on backend
 */
export const formatDateTime = (
  timezone: string,
  formattedDate?: string,
  formTime?: Dayjs | null
) => {
  if (formattedDate) {
    const time = formTime
      ? formTime.format(INPUT_TIME_FORMAT)
      : dayjs
          .tz(
            `${formattedDate}:t09:00`,
            `${DATE_FORMAT}THH:mm`,
            "America/New_York"
          )
          .tz(timezone)
          .format(INPUT_TIME_FORMAT);
    return dayjs
      .tz(
        `${formattedDate} ${time}`,
        `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`,
        timezone
      )
      .utc()
      .format();
  }
  return "";
};

/**
 * based on the eventType selected, set the appropriate meta data
 */
const parseEventTypeData = ({ meta, eventType }: IFormFields) => {
  const {
    fiscalQuarter,
    fiscalYear,
    investorDayName,
    productLaunchName,
    cadence,
    webinarName,
  } = meta || {};
  // fields set are dependent on event type
  switch (eventType) {
    case EventTypeOptions.EARNINGS_CALL:
    default: {
      return {
        fiscalYear,
        fiscalQuarter,
      };
    }
    case EventTypeOptions.INVESTOR_DAY:
      return {
        investorDayName,
      };
    case EventTypeOptions.PRODUCT_LAUNCH:
      return {
        productLaunchName,
      };
    case EventTypeOptions.SHAREHOLDER_MEETING:
      return {
        fiscalYear,
        cadence,
      };
    case EventTypeOptions.WEBINAR:
      return {
        webinarName,
      };
  }
};

/**
 * convert form data to api formatted data for all fields in step 1 of the form
 */
const parseStep1Data = (data: IFormFields, companyName: string) => {
  const eventTypeData = parseEventTypeData(data);
  return {
    title: generateEventTitle(companyName, data),
    eventType: data.eventType,
    shortTitle: generateEventShortTitle(data),
    slug: generateEventSlug(companyName, data),
    meta: {
      ...eventTypeData,
    },
  };
};

/**
 * convert form data to api formatted data for all fields in step 1 of the form
 */
const parseStep2Data = (data: IFormFields) => {
  const timezoneObj =
    TIME_ZONE_VALUE_MAPPING[data?.timezone?.id || "America/New_York"];
  const timezoneId = timezoneObj?.utc?.[0];

  const formattedEventDate = formatDate(data.eventDate, data.eventTime);
  const formattedOpenDate = formatDate(data.openDate, data.openTime);
  const formattedCloseDate = formatDate(data.closeDate, data.closeTime);
  const formattedPublishDate = formatDate(data.publishDate, data.publishTime);
  const openDatetime = formatDateTime(
    timezoneId,
    formattedOpenDate,
    data.openTime
  );
  const closeDatetime = formatDateTime(
    timezoneId,
    formattedCloseDate,
    data.closeTime
  );
  const eventStartDatetime = formatDateTime(
    timezoneId,
    formattedEventDate,
    data.eventTime
  );
  const publishDatetime =
    data.publishType === QaPublishOptions.OnceApproved
      ? null
      : formatDateTime(timezoneId, formattedPublishDate, data.publishTime);
  return {
    eventStartDatetime,
    openDatetime,
    // only pass publishDatetime if user did not select OnceApproved
    publishDatetime: publishDatetime,
    closeDatetime,
    timezone: data.timezone?.id,
    publishType: data.publishType,
    ordering: data.sortOrder
      ? convertSortOrderToList(data.sortOrder)
      : undefined,
  };
};

/**
 * api needs a list of ids of leaders on the event and theyre order, convert form array to this
 */
const convertLeadershipToEnabledIds = (leaders?: ICompanyLeader[]) =>
  leaders
    ?.filter((leader) => leader.enabled)
    .map((leader, idx) => ({
      leaderId: leader.id,
      order: idx,
    }));

/**
 * api needs a full list of sorted sort order options
 */
const convertSortOrderToList = (sortOrder: EventSortOptions) => [
  { order: 0, value: sortOrder },
  ...EVENT_SORT_ORDER_OPTIONS.filter(
    (sort) =>
      sort.value !== sortOrder && sort.value !== EventSortOptions.RECOMMENDED
  ).map((sort, idx) => ({
    value: sort.value,
    order: idx + 1,
  })),
];

/**
 * convert form data to api formatted data for all fields in step 2 of the form
 */
const parseStep3Data = ({ leaders, faqs }: IFormFields) => ({
  leaders: convertLeadershipToEnabledIds(leaders),
  faqs: (faqs || []).map(({ bodyMarkdown, subject }, idx) => ({
    order: idx,
    bodyMarkdown,
    subject,
  })),
});

export const parseFormData = (
  formData: IFormFields,
  step: number,
  companyName: string
): Partial<IDraftEvent> => {
  switch (step) {
    case 0:
    default: {
      return parseStep1Data(formData, companyName);
    }
    case 1: {
      return parseStep2Data(formData);
    }
    case 2: {
      return parseStep3Data(formData);
    }
    case 3: {
      return {
        ...parseStep1Data(formData, companyName),
        ...parseStep2Data(formData),
        ...parseStep3Data(formData),
      };
    }
  }
};

// if opendate is in past then only send faq leadership and ordering
export const parseSubmittedEventData = (event: IDraftEvent) => {
  if (dayjs(event.openDatetime).isBefore(dayjs())) {
    return {
      ordering: event.ordering,
      leaders: event.leaders,
      faqs: event.faqs,
    };
  }
  return event;
};
