import {
  ColumnType,
  DATE_FORMAT,
  getInitials,
  nFormatter,
  Row,
} from "@asayinc/component-library";
import { CheckBoxOutlineBlank, LabelOutlined } from "@mui/icons-material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SORT } from "../../../../constants";
import { DELETED_USER_ID } from "../../../../constants/settings";
import { IShareholderUpvote } from "../../../../types/QuestionUpvotes";
import { Tag } from "../../../../types/Common";

dayjs.extend(utc);

interface ColumnDataProps {
  goToShareholder: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
  asDrawer?: boolean;
}

/**
 * Column data for table
 */
export const getColumnData = ({
  goToShareholder,
  getSort,
  sortFn,
  asDrawer,
}: ColumnDataProps): {
  checkbox: ColumnType;
  shareholder: ColumnType;
  sharesOwnedAtUpvote: ColumnType;
  upvotedAt: ColumnType;
} => ({
  checkbox: { title: "", style: "popoverMenu", width: "36px" },
  shareholder: {
    title: "Shareholder",
    sort: getSort(SORT.shareholder),
    sortFn,
    style: "user",
    width: "440px",
    clickHandler: goToShareholder,
    align: "left",
  },
  sharesOwnedAtUpvote: {
    title: "Shares owned",
    sort: getSort(SORT.sharesOwnedAtUpvote),
    sortFn,
    style: "text",
    width: "440px",
    clickHandler: goToShareholder,
    align: "left",
  },
  upvotedAt: {
    title: "Submitted",
    sort: getSort(SORT.upvotedAt),
    sortFn,
    style: "text",
    width: asDrawer ? "200px" : "440px",
    clickHandler: goToShareholder,
    align: "left",
  },
});

interface RowDataProps {
  shareholderUpvotes: IShareholderUpvote[];
  selectedIds: string[];
  checkRow: (data: unknown) => void;
  openTagDialog: (data: unknown) => void;
}

/**
 * Each rows specific data for table
 */
export const getRowData = ({
  shareholderUpvotes,
  selectedIds,
  checkRow,
  openTagDialog,
}: RowDataProps): Row[] =>
  shareholderUpvotes.map((upvote, idx) =>
    createRowData({
      tags: upvote.tags,
      checked: selectedIds.includes(upvote.globalShareholderId),
      shareholder: upvote.name,
      profileColor: upvote.profileColor,
      sharesOwnedAtUpvote: nFormatter(upvote.sharesOwnedAtUpvote),
      upvotedAt: dayjs(upvote.upvoteCreated).format(DATE_FORMAT),
      id: upvote.globalUserId,
      data: {
        id: upvote.globalShareholderId,
        idx,
      },
      checkRow,
      openTagDialog,
      areRowsSelected: selectedIds.length > 0,
    })
  );

export interface CreateUpvoteQuestionRowData {
  checked: boolean;
  shareholder: string;
  sharesOwnedAtUpvote: string;
  upvotedAt: string;
  profileColor: string;
  id: string;
  data: {
    id: string;
    idx: number;
  };
  checkRow: (data: unknown) => void;
  openTagDialog: (data: unknown) => void;
  areRowsSelected: boolean;
  tags: Tag[];
}

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */

export const createRowData = ({
  checked,
  shareholder,
  sharesOwnedAtUpvote,
  profileColor,
  upvotedAt,
  id,
  openTagDialog,
  data,
  checkRow,
  areRowsSelected,
  tags,
}: CreateUpvoteQuestionRowData): Row => ({
  rowData: {
    checkbox: {
      popoverMenuCellData: {
        selected: checked,
        handleCheckboxClick: checkRow,
        menuItems: [
          // if any row is selected, we'll hide the tag menuItem
          ...(!areRowsSelected
            ? [
                {
                  icon: <LabelOutlined />,
                  id: "tag",
                  onClick: openTagDialog,
                },
              ]
            : []),
          // popoverMenuCells will not render menuItems when selected, so this
          // item can be added in the menuItems array without conditions
          {
            id: "check",
            icon: <CheckBoxOutlineBlank />,
            onClick: checkRow,
          },
        ],
      },
      checked: checked,
    },
    shareholder: {
      userCellData: {
        isDeletedUser: id === DELETED_USER_ID,
        userName: shareholder,
        initials: getInitials(shareholder),
        profileColor: profileColor,
      },
      tagsData: {
        tags,
        tagsToShow: 1,
      },
    },
    sharesOwnedAtUpvote: { content: sharesOwnedAtUpvote },
    upvotedAt: { content: upvotedAt },
  },
  data,
  id,
});
