import {
  minMaxMessaging,
  selectListMessaging,
  useIsMounted,
} from "@asayinc/component-library";
import { useEffect, useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { track } from "../../../../analytics";
import { useGetEventFilterOptionsQuery } from "../../../../store/event";
import {
  IFilterQuery,
  IListFilter,
  initialState,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
import { useGetMessageFilterOptionsQuery } from "../../../../store/messageLists";
import { useGetSettingsQuery } from "../../../../store/settings";
import { ISettings } from "../../../../store/settings/types";
import { getCityStateMessaging } from "../../../../utils/messages";
import { FILTER_MESSAGING_MAP } from "../../../../constants";
import { CREATION_STEP_MAPPING } from "../../../../pages/Messages/subPages/CreateAndEdit/constants";
import { FORM_PATH } from "./constants";

/**
 * watches all recipient filters and tracks when theyre added to or removed
 */
export const useFilterTracking = () => {
  const [isInstantiated, setIsInstantiated] = useState(false);
  const isMounted = useIsMounted();
  // current message id
  const { messageId: id = "" } = useParams() as { messageId: string };
  const { data: composeData = initialState, isSuccess: messageSuccess } =
    useGetMessageQuery(id);
  const { data: settings = {}, isSuccess: settingsSuccess } =
    useGetSettingsQuery();
  const { name: companyName = "" } = settings as ISettings;
  // info for messaging for qas and tagging
  const { data: messages } = useGetMessageFilterOptionsQuery();
  const { data: events } = useGetEventFilterOptionsQuery();

  // watch all form fields we want to track
  const p = FORM_PATH;
  const sharesOwned = useWatch({ name: `${p}.sharesOwned` });
  const tags = useWatch({ name: `${p}.tags` });
  const state = useWatch({ name: `${p}.state` });
  const city = useWatch({ name: `${p}.city` });
  const qasParticipated = useWatch({ name: `${p}.qasParticipated` });

  // tags are used by name, so we dont need to pull all tags to get the reference, can generate for the required
  const tagOptions = useMemo(
    () =>
      tags?.values
        ? (tags as IListFilter).values.map((t) => ({ id: t, name: t }))
        : [],
    [tags]
  );
  // data needed for messaging formulation
  const autoCompleteOptions = {
    qasParticipated: events,
    tags: tagOptions,
    messagesReceived: messages,
  };

  const getMessaging = (fieldData: IFilterQuery, field: string) => {
    if ("range" in fieldData) {
      return minMaxMessaging(
        fieldData.range?.gte?.toString(),
        fieldData.range?.lte?.toString(),
        FILTER_MESSAGING_MAP[field].chipStartWord,
        FILTER_MESSAGING_MAP[field].chipEndWord,
        FILTER_MESSAGING_MAP[field]?.isPercent
      );
    } else if ("values" in fieldData) {
      return selectListMessaging(
        (fieldData as IListFilter).values as (string | number)[],
        autoCompleteOptions[field],
        FILTER_MESSAGING_MAP[field].chipStartWord,
        `${FILTER_MESSAGING_MAP[field].chipEndWord}s`
      );
    }
    // return null for radio filter
    return null;
  };

  // wait for page to instantiate so this doesnt auto run on pageload with analytics
  useEffect(() => {
    if (settingsSuccess && messageSuccess) {
      setTimeout(() => {
        if (isMounted()) {
          setIsInstantiated(true);
        }
      }, 100);
    }
  }, [messageSuccess, settingsSuccess]);

  // shares owned
  useEffect(() => {
    if (isInstantiated) {
      track({
        name: "Message Campaign Edited",
        client: companyName,
        campaign: composeData.campaignName,
        campaignStatus: composeData.status,
        composeStep: CREATION_STEP_MAPPING[1],
        filter: "Shares owned",
        filterValue: sharesOwned
          ? minMaxMessaging(
              sharesOwned.range?.gte?.toString(),
              sharesOwned.range?.lte?.toString(),
              FILTER_MESSAGING_MAP.sharesOwned.chipStartWord,
              FILTER_MESSAGING_MAP.sharesOwned.chipEndWord
            )
          : "deselected",
      });
    }
  }, [sharesOwned]);

  // Tags
  useEffect(() => {
    if (isInstantiated) {
      let filterValue = "deselected" as string | null;
      if (tags) {
        filterValue = getMessaging(tags, "tags");
      }
      track({
        name: "Message Campaign Edited",
        client: companyName,
        campaign: composeData.campaignName,
        campaignStatus: composeData.status,
        composeStep: CREATION_STEP_MAPPING[1],
        filter: "Shareholder tags",
        filterValue: filterValue,
      });
    }
  }, [tags]);

  // Location
  useEffect(() => {
    let filterValue = "deselected" as string | null;
    if (state) {
      filterValue = getCityStateMessaging(
        { city, state },
        city ? "city" : "state"
      );
    }
    if (isInstantiated) {
      track({
        name: "Message Campaign Edited",
        client: companyName,
        campaign: composeData.campaignName,
        campaignStatus: composeData.status,
        composeStep: CREATION_STEP_MAPPING[1],
        filter: "Location",
        filterValue: filterValue,
      });
    }
  }, [state]);

  // Participated
  useEffect(() => {
    if (isInstantiated) {
      let filterValue = "deselected" as string | null;
      if (qasParticipated) {
        filterValue = getMessaging(qasParticipated, "qasParticipated");
      }
      track({
        name: "Message Campaign Edited",
        client: companyName,
        campaign: composeData.campaignName,
        campaignStatus: composeData.status,
        composeStep: CREATION_STEP_MAPPING[1],
        filter: "Q&As participated",
        filterValue: filterValue,
      });
    }
  }, [qasParticipated]);
};
