import { useFormContext } from "react-hook-form";
import { FilterSelectionFlow } from "@asayinc/component-library";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
  fieldName: string;
  minParam: string;
  maxParam: string;
  chipLabel: string;
  disabled?: boolean;
  stateSearchParams?: URLSearchParams;
  stateSetSearchParams?: (params: URLSearchParams) => void;
}

/**
 * Uses FilterSelectionFlow from component library to update
 * URL search params according to param props. Requires form
 * context in parent or ancestor component.
 */

const RangeFilter = ({
  fieldName,
  minParam,
  maxParam,
  chipLabel,
  disabled,
  stateSearchParams,
  stateSetSearchParams,
}: IProps) => {
  // allows for updating url or not updating url and using state params instead
  const [urlSearchParams, urlSetSearchParams] = useSearchParams();
  const searchParams = stateSearchParams || urlSearchParams;
  const setSearchParams = stateSetSearchParams || urlSetSearchParams;
  const [firstRender, setFirstRender] = useState(true);
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const rangeFormValues = watch(fieldName);

  /**
   * set formValues on page load
   */
  useEffect(() => {
    const min = searchParams.get(minParam) || undefined;
    const max = searchParams.get(maxParam) || undefined;

    if (min || max) {
      setValue(fieldName, { range: { gte: min, lte: max } });
    }
  }, []);

  /**
   * use rangeFormValues to update URL search params
   */
  useEffect(() => {
    // prevent running on first render to avoid duplicate history states
    if (!firstRender) {
      if (rangeFormValues?.range) {
        const { range } = rangeFormValues;
        const { gte: min, lte: max } = range;
        if (min) {
          searchParams.set(minParam, min);
        } else {
          searchParams.delete(minParam);
        }
        if (max) {
          searchParams.set(maxParam, max);
        } else {
          searchParams.delete(maxParam);
        }
        setSearchParams(searchParams);
      }
    } else {
      setFirstRender(false);
    }
  }, [rangeFormValues]);

  const onClear = () => {
    searchParams.delete(minParam);
    searchParams.delete(maxParam);
    setSearchParams(searchParams);
  };

  return (
    <FilterSelectionFlow
      skipMessaging
      chipProps={{
        disabled,
      }}
      withinForm={{ fieldName }}
      clearable
      onClear={onClear}
      chipLabel={chipLabel}
      filterTypeOptions={[
        {
          type: "range",
          label: "range selection",
          options: {},
        },
      ]}
    />
  );
};

export default RangeFilter;
