import { useParams } from "react-router-dom";
import {
  MessageType,
  useGetMessagesQuery,
  NOT_SENT_TYPES,
  DEFAULT_MESSAGE_LIMIT,
} from "../../../../../../../../../store/messageLists";
import { MessageCardList } from "../../../../../../../../../components/Messages";

const NotSent = () => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { data, isLoading } = useGetMessagesQuery({
    proxyEventId: proxyEventId,
    limit: DEFAULT_MESSAGE_LIMIT.toString(),
    offset: 0,
    messageType: MessageType.ProxySM,
    messageStatus: NOT_SENT_TYPES,
  });

  return (
    <MessageCardList
      messages={data?.results || []}
      title="In progress"
      count={data?.count || 0}
      isLoading={isLoading}
    />
  );
};

export default NotSent;
