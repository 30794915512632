import { useEffect } from "react";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";
// components
import {
  HookDatePicker,
  Text,
  HookTimePicker,
  HookRadioButton,
  nFormatter,
  pluralize,
} from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { TimeZoneAutocomplete } from "../../../../../../components/Common";
import { CollapsingAlert } from "../../../../../../components/Messages";
// dayjs
import { Dayjs } from "dayjs";
import { isDateAfterToday } from "../utils";

// constants
import { SendOptions } from "../constants";
import { MessageStatus } from "../../../../../../types/Messages";
import { isAfterVoteCutoff } from "../../../../../../pages/ProxyVoting/utils";
import { useGetProxyEventDetailsQuery } from "../../../../../../store/proxyEvent";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface IProps {
  methods: UseFormReturn<
    {
      timezone:
        | {
            id: string;
            offset: number;
            name: string;
          }
        | undefined;
      scheduledDate: Dayjs | null;
      scheduledTime: Dayjs | null;
      sendOption: SendOptions;
    },
    unknown
  >;
  disabled: boolean;
  onSubmit: (fieldvalues: FieldValues) => void;
  numShareholders: number;
  status: MessageStatus;
  proxyEventId?: string | null;
}

const Form = ({
  methods,
  onSubmit,
  disabled,
  numShareholders,
  status,
  proxyEventId,
}: IProps) => {
  const { trigger } = methods;
  const { scheduledDate, scheduledTime, timezone, sendOption } =
    methods.watch();

  const { data: proxyData } = useGetProxyEventDetailsQuery(
    proxyEventId || skipToken
  );

  /**
   * Revalidate other fields based on selections
   */
  useEffect(() => {
    if (scheduledTime) {
      trigger(["scheduledDate", "scheduledTime", "timezone"]);
    }
  }, [scheduledDate, timezone?.id]);

  return (
    <Box width="100%">
      <Text variant="body1">
        Once your message has been approved, it will be sent to shareholders.
        You can view the status of your message later.
      </Text>
      {status === MessageStatus.PendingApproval && (
        <CollapsingAlert
          showAlert={true}
          alertProps={{ severity: "warning" }}
          message={`This message is being reviewed by Say team. It will be sent to ${nFormatter(
            numShareholders
          )} ${pluralize(
            "shareholder",
            "shareholders",
            numShareholders
          )} at your scheduled time once approved.`}
          sx={{
            mt: 6,
            width: "100%",
          }}
        />
      )}

      <FormProvider {...methods}>
        <form id="schedule-form" onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack gap={6} mt={6}>
            <HookRadioButton
              id="sendOption"
              name="sendOption"
              radioOptions={[
                { label: "Send once approved", value: SendOptions.sendNow },
                { label: "Schedule for later", value: SendOptions.sendLater },
              ]}
              sx={{
                flexDirection: "row",
                label: { mr: 6, ml: 0 },
              }}
            />
            {sendOption === SendOptions.sendLater && (
              <>
                <Stack direction="row" gap={6}>
                  <Box flex="1">
                    <HookDatePicker
                      dateFieldProps={{
                        slotProps: {
                          textField: {
                            inputSize: "medium",
                            outerLabel: "Schedule Date",
                            placeholder: "Schedule Date",
                            sx: { width: "100%" },
                            id: "scheduledDate",
                          },
                        },
                        disabled,
                      }}
                      calendarProps={{
                        shouldDisableDate: disabled
                          ? undefined
                          : (date) =>
                              isDateAfterToday({
                                time: scheduledTime,
                                date: date,
                                timezoneId: timezone?.id,
                                type: "date",
                              }) as boolean,
                      }}
                      rules={{
                        required: {
                          value: true,
                          message: "Schedule date is required.",
                        },
                        validate: {
                          ...(proxyEventId
                            ? {
                                isOnOrBeforeVoteCutoff: (val: null | Dayjs) =>
                                  !isAfterVoteCutoff(
                                    proxyData?.voteCutoff,
                                    val
                                  ) ||
                                  "Must schedule message to send at a date and time before the event's closure",
                              }
                            : {}),
                        },
                      }}
                      name="scheduledDate"
                    />
                  </Box>
                  <Box flex="1">
                    <HookTimePicker
                      disabled={disabled}
                      slotProps={{
                        textField: {
                          outerLabel: "Schedule Time",
                          id: "scheduledTime",
                          placeholder: "--:-- --",
                        },
                      }}
                      name="scheduledTime"
                      rules={{
                        required: {
                          value: true,
                          message: "Schedule Time is required.",
                        },
                        validate: {
                          afterNow: (val: null | Dayjs) =>
                            isDateAfterToday({
                              time: val,
                              date: scheduledDate,
                              timezoneId: timezone?.id,
                              type: "time",
                            }),
                        },
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Box>
                </Stack>
                <TimeZoneAutocomplete disabled={disabled} />
              </>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

export default Form;
