import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { Event, EventStatusOptions } from "../../../../../types/Events";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const getRightSectionData = (event?: Event): string | undefined => {
  switch (event?.status) {
    case EventStatusOptions.DRAFT:
      return dayjs.utc(event.lastEditAt).local().fromNow();
    case EventStatusOptions.PENDING:
      return dayjs.utc(event.submittedAt).local().fromNow();
    case EventStatusOptions.APPROVED:
      return dayjs.utc(event.approvedAt).local().fromNow();
    case EventStatusOptions.PUBLISHED:
      return dayjs.utc(event.publishDatetime).local().fromNow();
    case EventStatusOptions.ACTIVE:
      const {
        questionsCount: { analyst, institutional, retail },
      } = event;
      return String(analyst + institutional + retail);
    default:
      return;
  }
};
