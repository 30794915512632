import { MessageStep } from "../../../../types/Messages";

// field used to store session data on most recently edited message
export const SESSION_KEY = "compose-flow-step";

export const CREATION_STEP_MAPPING = [
  MessageStep.NameCampaign,
  MessageStep.ChooseRecipients,
  MessageStep.DraftMessage,
  MessageStep.Review,
];
