import { Text } from "@asayinc/component-library";
import { sanitize } from "dompurify";
import { Box, Stack } from "@mui/material";
import { marked } from "marked";
import { IFaq } from "../../../../../types/Events";
import { ReviewDetailsSection } from "../../Organisms";

interface IProps {
  faqs: IFaq[];
  editStep: (section: string) => void;
}

const FAQ = ({ faq }: { faq: IFaq }) => {
  return (
    <Box>
      <Text variant="subtitle1" data-testid="text-faqs-subject" mb={2}>
        {faq.subject}
      </Text>
      <Text
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: sanitize(
            marked.parse(faq.bodyMarkdown || "", {
              breaks: true,
              mangle: false,
              headerIds: false,
            })
          ),
        }}
      />
    </Box>
  );
};

export const ReviewFAQ = ({ faqs, editStep }: IProps) => {
  return (
    <ReviewDetailsSection title="FAQs" cardName="faq" editStep={editStep}>
      <Stack mt={6} spacing={6}>
        {faqs.map((faq, idx) => (
          <FAQ key={idx} faq={faq} />
        ))}
      </Stack>
    </ReviewDetailsSection>
  );
};

export default ReviewFAQ;
