import { COLORS, IconButton, Text } from "@asayinc/component-library";
import EditOutlineIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Stack } from "@mui/material";
import { IImportantLink } from "../../../../../../store/messageCompose";
import ImportantLinksDialog from "../ImportantLinksDialog";
import { useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface IProps {
  link: IImportantLink;
  idx: number;
  isSomethingDragging: boolean;
  isLoading?: boolean;
  removeLink: (idx: number) => void;
  saveOrAddLink: () => void;
}

const ImportantLink = ({
  link,
  idx,
  isLoading,
  saveOrAddLink,
  removeLink,
}: IProps) => {
  const { title, description, image, url } = link;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onEdit = () => {
    setIsDialogOpen(true);
  };

  const onDelete = () => {
    removeLink(idx);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Draggable
        index={idx}
        draggableId={link.id as string}
        isDragDisabled={isLoading}
      >
        {(provided) => (
          <Stack
            data-testid={`addedit-link-${idx}`}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <Stack
              direction="row"
              pb={6}
              borderBottom={`1px solid ${COLORS.P_OUTLINE}`}
            >
              <Box
                {...provided.dragHandleProps}
                sx={{ width: "32px" }}
                data-testid={`scrubber-${link.id}`}
                mr={3}
                ml={-3}
              >
                <DragIndicatorIcon
                  style={{ color: "black", display: "block" }}
                  fontSize="small"
                />
              </Box>
              <Stack flex={1} overflow="hidden">
                <Stack direction="row" justifyContent="space-between">
                  <Text variant="subtitle1" data-testid="link-title">
                    {title}
                  </Text>
                  <Stack direction="row">
                    <IconButton
                      onClick={onEdit}
                      data-testid={`edit-link-${idx}`}
                      disabled={isLoading}
                    >
                      <EditOutlineIcon />
                    </IconButton>
                    <IconButton
                      data-testid={`delete-link-${idx}`}
                      onClick={onDelete}
                      sx={{ ml: "auto" }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  gap={12}
                  mt={3}
                  pr={8}
                >
                  <Stack maxWidth="calc(100% - 392px)">
                    <Text variant="body2" mb={2}>
                      Link
                    </Text>
                    <Stack height={42} justifyContent="center">
                      <Text
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {url}
                      </Text>
                    </Stack>
                  </Stack>
                  <Stack minWidth={312}>
                    <Text variant="body2" mb={2}>
                      Thumbnail
                    </Text>
                    <Stack
                      height={42}
                      bgcolor="#F0F0F0"
                      justifyContent="center"
                      px={4}
                      borderRadius={2}
                    >
                      <Text emphasis="medium_low">
                        {image
                          ? `thumbnail-${idx}.png`
                          : url.slice(-3) === "pdf"
                          ? "PDF"
                          : "No thumbnail image available"}
                      </Text>
                    </Stack>
                  </Stack>
                </Stack>
                {description && (
                  <Stack mt={3}>
                    <Text variant="body2" mb={2}>
                      Description
                    </Text>
                    <Text>{description}</Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Draggable>
      <ImportantLinksDialog
        open={isDialogOpen}
        closeDialog={closeDialog}
        isDisabled={isLoading}
        idx={idx}
        saveOrAddLink={saveOrAddLink}
        link={link}
      />
    </>
  );
};

export default ImportantLink;
