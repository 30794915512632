import { IURL_PARAMS } from "../../types/Common";
import {
  IShareholderUpvotesAgg,
  IShareholderUpvotesData,
} from "../../types/QuestionUpvotes";
import { rootApi } from "../rootApi";

// Define a question upvotes Api
export const shareholderUpvotesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // Upvotes on question data
    getShareholderUpvotes: builder.query<
      IShareholderUpvotesData | IShareholderUpvotesAgg,
      {
        questionId: string;
        params: IURL_PARAMS;
      }
    >({
      query: ({ questionId, params }) => {
        const queryString = new URLSearchParams(params).toString();
        return `questions/${questionId}/upvotes/?${queryString}`;
      },
      transformResponse: (
        response: IShareholderUpvotesData | IShareholderUpvotesAgg,
        _,
        arg
      ) => {
        if (arg?.params?.agg) {
          return response as IShareholderUpvotesAgg;
        }
        const upvotesData = response as IShareholderUpvotesData;
        return {
          ...upvotesData,
          ids: upvotesData.results.map(
            ({ globalShareholderId }) => globalShareholderId
          ),
        };
      },
      providesTags: ["ShareholderTags"],
    }),
  }),
});

export const { useGetShareholderUpvotesQuery } = shareholderUpvotesApi;
