import dayjs from "dayjs";
import { DATE_FORMAT, Row } from "@asayinc/component-library";
import { createColumnData } from "../../../../../../../../../../utils";
import { IShareholderMessageReceived } from "../../../../../../../../../../types/Shareholder";

interface ColumnDataProps {
  goToCampaign?: (data: unknown) => void;
}

export const getColumnData = ({ goToCampaign }: ColumnDataProps) => ({
  campaignName: createColumnData({
    title: "Campaign name",
    clickHandler: goToCampaign,
    style: "text",
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
        sx: { wordBreak: "break-word" },
      },
    },
  }),
  sentDate: createColumnData({
    title: "Sent date",
    style: "text",
    clickHandler: goToCampaign,
  }),
  engagement: createColumnData({
    title: "Engagement",
    style: "text",
    clickHandler: goToCampaign,
  }),
  opened: createColumnData({
    title: "Opened",
    style: "text",
    clickHandler: goToCampaign,
  }),
  clicked: createColumnData({
    title: "Links clicked",
    style: "text",
    clickHandler: goToCampaign,
  }),
  replied: createColumnData({
    title: "Replied",
    style: "text",
    clickHandler: goToCampaign,
  }),
});

const getTrackingColumnValue = (containsData: boolean, engaged: boolean) => {
  return containsData ? (engaged ? "Yes" : "No") : "-";
};

const createRowData = (
  {
    id,
    campaignName,
    engagement,
    sentDate,
    containsLinks,
    containsReplies,
  }: IShareholderMessageReceived,
  idx: number
): Row => ({
  id,
  data: {
    id,
    idx,
  },
  rowData: {
    campaignName: { content: campaignName },
    sentDate: { content: dayjs(sentDate).format(DATE_FORMAT) },
    engagement: {
      content: `${engagement.opened ? "Opened<br/>" : ""}${
        engagement.clicked ? "Clicked links<br/>" : ""
      }${engagement.replied ? "Replied<br/>" : ""}`,
      asHTML: true,
    },
    opened: {
      content: engagement.opened ? "Yes" : "No",
    },
    clicked: {
      content: getTrackingColumnValue(containsLinks, engagement.clicked),
    },
    replied: {
      content: getTrackingColumnValue(containsReplies, engagement.replied),
    },
  },
});

export const getRowData = (messages: IShareholderMessageReceived[]) =>
  messages.map((message, idx) => createRowData(message, idx));
