import { Stack, Box } from "@mui/material";
import { useEffect, useState, useMemo, useCallback } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { COLORS, Tabs } from "@asayinc/component-library";
import {
  ShareholderHeaderV2,
  ShareholderSummary,
  QAActivity,
  MessagesActivity,
} from "./components";
// data / redux
import { useGetShareholderMetaQuery } from "../../../../store/shareholder";
import { Context } from "./context";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetShareholderMessageActivityQuery } from "../../../../store/shareholder";
import { useGetShareholderQuestionsUpvotedQuery } from "../../../../store/shareholder";
// constants
import { TabOptions } from "./constants";
import { IShareholderMeta } from "../../../../types/Shareholder";
import { URL_PARAMS } from "../../../../constants";

const Shareholder = ({ asDrawer = false }: { asDrawer?: boolean }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation() as { state: { goBackText?: string } };
  const goBackText = state?.goBackText;
  const shareholderDrawerId = searchParams.get(URL_PARAMS.sid);
  // shareholder data for faking data

  const [activeTab, setActiveTab] = useState<string>(TabOptions.summary.value);
  const tabClickHandler = (tab: string) => {
    setActiveTab(tab);
  };

  const { shareholderId: paramsId } = useParams() as { shareholderId: string };
  // if asDrawer, use shareholder ID from global state
  const shareholderId = asDrawer ? shareholderDrawerId : paramsId;

  const { data, isLoading, isFetching } = useGetShareholderMetaQuery(
    shareholderId ? { shareholderId } : skipToken
  );

  const globalUserId = data?.globalUserId;
  // summary questions data
  const { data: questionsData, isSuccess: isQaDataSuccess } =
    useGetShareholderQuestionsUpvotedQuery(
      globalUserId ? { globalUserId, offset: 0 } : skipToken
    );
  const hasQaActivity = !!questionsData?.count;

  // summary messages data
  const { data: messagesData, isSuccess: isMessagesDataSuccess } =
    useGetShareholderMessageActivityQuery(
      data?.globalShareholderId
        ? {
            shareholderId: data.globalShareholderId,
            offset: "0",
            limit: "1",
          }
        : skipToken
    );
  const messageCount = messagesData?.count || 0;
  const hasMessagesActivity = messageCount > 0;

  const contextData = useMemo(
    () => ({
      setActiveTab: setActiveTab,
      data: data as IShareholderMeta,
      isLoading,
      isFetching,
      globalUserId,
      asDrawer,
      shareholderId,
      hasQaActivity,
      isQaDataSuccess,
      hasMessagesActivity,
      isMessagesDataSuccess,
    }),
    [isLoading, shareholderId, globalUserId]
  );

  const renderTabs = hasQaActivity || hasMessagesActivity;
  const tabs = useMemo(() => {
    const tabArr = [TabOptions.summary];
    if (hasQaActivity) {
      tabArr.push(TabOptions.qa);
    }
    if (hasMessagesActivity) {
      tabArr.push(TabOptions.messages);
    }
    return tabArr;
  }, [hasQaActivity, hasMessagesActivity]);

  // set page title
  useEffect(() => {
    if (data?.firstName && data.lastName && !asDrawer)
      document.title = `${data.firstName} ${data.lastName} Profile | Say Issuer Portal`;
  }, [isLoading]);

  // expand to full page
  const expand = useCallback(() => {
    navigate(`/shareholders/${shareholderDrawerId}`, {
      state: { goBackText },
    });
  }, [shareholderDrawerId]);

  // close drawer
  const close = useCallback(() => {
    searchParams.delete(URL_PARAMS.sid);
    setSearchParams(searchParams);
  }, []);

  return (
    <Stack
      p={10}
      width="100%"
      maxWidth={1240}
      m="0 auto"
      data-testid="shareholder-profile-page"
    >
      <ShareholderHeaderV2
        name={data?.firstName ? `${data.firstName} ${data.lastName}` : ""}
        sharesOwned={data?.sharesOwned || 0}
        profileColor={data?.profileColor || ""}
        asDrawer={asDrawer}
        isLoading={isLoading}
        expand={expand}
        close={close}
      />
      <Stack>
        {renderTabs && (
          <Box borderBottom={`1px solid ${COLORS.P_OUTLINE}`} mt={3}>
            <Tabs
              activeTab={activeTab}
              tabs={tabs}
              clickHandler={tabClickHandler}
            />
          </Box>
        )}
        <Box mt={8}>
          <Context.Provider value={contextData}>
            {activeTab === TabOptions.summary.value && <ShareholderSummary />}
            {activeTab === TabOptions.qa.value && <QAActivity />}
            {activeTab === TabOptions.messages.value && <MessagesActivity />}
          </Context.Provider>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Shareholder;
