import { Text } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { useWatch } from "react-hook-form";
import { HookChipSelection } from "../../../../../components/Common";
import { EVENT_SORT_ORDER_OPTIONS } from "../../../../../constants";

interface IProps {
  isLoading?: boolean;
}

const FIELD_NAME = "sortOrder";

const SortOrderForm = ({ isLoading }: IProps) => {
  const sortOrder = useWatch({
    name: FIELD_NAME,
  });

  return (
    <Stack>
      <Stack>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Shareholder question default sort order
        </Text>
        <Text variant="body2">
          Select your preferred way to display the questions on your Q&amp;A
          page. Say&apos;s default is the Recommended sort option.
        </Text>
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        {EVENT_SORT_ORDER_OPTIONS.map((option) => (
          <Box key={option.value} mt={6} mr={3}>
            <HookChipSelection
              {...option}
              disabled={isLoading}
              selected={option.value === sortOrder}
              fieldName={FIELD_NAME}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default SortOrderForm;
