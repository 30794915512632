import { cloneElement } from "react";
import { Stack } from "@mui/material";
import { COLORS, Text } from "@asayinc/component-library";

interface IProps {
  icon: JSX.Element | null;
  text: string;
  disabled?: boolean;
}

const LifecyclePill = ({ icon, text, disabled = false }: IProps) => (
  <Stack
    direction="row"
    alignItems="center"
    bgcolor={disabled ? COLORS.DISABLED_NIGHT : COLORS.P_MED_HIGH_EMPHASIS}
    borderRadius="100px"
    spacing={1}
    px={3}
    py={1.5}
    color={COLORS.WHITE}
  >
    {icon
      ? cloneElement(icon, {
          sx: {
            width: "16px",
            height: "16px",
          },
        })
      : null}
    <Text variant="subtitle2" category="primary" shade="light">
      {text}
    </Text>
  </Stack>
);

export default LifecyclePill;
