import { FieldNamesMarkedBoolean } from "react-hook-form";
import { IFormFields } from "../pages/CreateAndEditEvent/types";
import { ISettingsFormFields } from "../pages/Settings/types";
import { ICompanyLeader, IDraftEvent } from "../types/Events";
import { IImportantLink } from "../store/messageCompose";

/**
 * Parse out all form values that are not dirty
 */
export const getDirtyFormValues = (
  allValues:
    | ISettingsFormFields
    | IFormFields
    | ICompanyLeader
    | IDraftEvent
    | IImportantLink,
  dirtyFields: FieldNamesMarkedBoolean<ISettingsFormFields> | boolean
):
  | ISettingsFormFields
  | IFormFields
  | ICompanyLeader
  | IDraftEvent
  | IImportantLink => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues;

  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyFormValues(allValues[key], dirtyFields[key]),
    ])
  );
};
