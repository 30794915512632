/* istanbul ignore file */
// Unused currently

import {
  FilterSelectionFlow,
  FilterType,
  IFilter,
} from "@asayinc/component-library";
import { useMemo, forwardRef, Ref } from "react";
import { useGetMessageFilterOptionsQuery } from "../../../../../../store/messageLists";
import { FILTER_MESSAGING_MAP } from "../../../../../../constants";

interface IProps {
  isLoading?: boolean;
  onClose?: () => void;
}

const MessagesReceived = (
  { isLoading, onClose }: IProps,
  ref: Ref<{ triggerPopover: () => void }> // used to simulate click event on chip from parent
) => {
  const { data } = useGetMessageFilterOptionsQuery();

  const options = useMemo(
    () =>
      [
        {
          type: FilterType.Unselected,
          label: "Not applied",
        },
        {
          type: FilterType.Values,
          label: "Received specific messages",
          options: {
            groupLabel: "Messages",
            options: data,
          },
        },
        {
          type: FilterType.Range,
          label: "Message count",
        },
      ] as IFilter[],
    [data]
  );

  return (
    <>
      <FilterSelectionFlow
        withinForm={{
          fieldName: "filterMap.query.messagesReceived",
        }}
        clearable
        chipProps={{
          disabled: isLoading,
        }}
        onClose={onClose}
        ref={ref}
        testid="chip-messagesReceived"
        chipLabel="Messages received"
        filterTypeOptions={options}
        {...FILTER_MESSAGING_MAP.messagesReceived}
      />
    </>
  );
};

export default forwardRef(MessagesReceived);
