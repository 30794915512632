import { track } from "../../analytics";

/**
 * Track save notes
 */
export const trackSaveNotes = ({
  eventSlug,
  questionId,
}: {
  eventSlug: string;
  questionId: string;
}): void => {
  track({ name: "Save Notes Clicked", slug: eventSlug, questionId });
};

/**
 * Track remove question click
 */
export const trackRemoveQuestion = ({
  eventSlug,
  questionId,
  action,
}: {
  eventSlug: string;
  questionId: string;
  action: string;
}): void => {
  track({
    name: "Remove Question Action",
    action,
    slug: eventSlug,
    questionId,
  });
};
