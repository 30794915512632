// components
import { Draggable } from "@hello-pangea/dnd";
import {
  HookTextField,
  Text,
  COLORS,
  ElementType,
  HookWYSIWYG,
  IconButton,
} from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// types
import { FormFaq } from "../../types";

interface IProps {
  faq: FormFaq;
  idx: number;
  isDisabled?: boolean;
  isSomethingDragging?: boolean;
  removeFaq: (idx: number) => void;
}

const field = "faqs";

const AddEditFaq = ({ faq, idx, isDisabled, removeFaq }: IProps) => {
  /**
   * Delete FAQ from form
   */
  const onClick = () => {
    removeFaq(idx);
  };

  const faqMaxLength = 25000;

  return (
    <Draggable index={idx} draggableId={faq.id} isDragDisabled={isDisabled}>
      {(provided, _snapshot) => (
        <Box
          key={faq.id}
          mt={6}
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{
            borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
          }}
        >
          <Stack direction="row" alignItems="center">
            <Box
              {...provided.dragHandleProps}
              sx={{ width: "32px", cursor: "grab" }}
              data-testid={`scrubber-${faq.id}`}
            >
              <DragIndicatorIcon
                style={{ color: "black", display: "block" }}
                fontSize="small"
              />
            </Box>
            <Text variant="subtitle1">FAQ {idx + 1}</Text>
            <IconButton
              data-testid={`delete-faq-${idx}`}
              onClick={onClick}
              sx={{ ml: "auto" }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Stack>
          <Stack width="100%" data-testid={`addedit-${idx}`} p={8}>
            <Stack width="100%" flex={1} direction="row" spacing={12}>
              <Box width="50%">
                <HookTextField
                  fullWidth
                  name={`${field}[${idx}].subject`}
                  data-testid="input-subject"
                  placeholder="e.g., If I have questions after the call, what should I do?"
                  outerLabel="Question"
                  id={`subject-${idx}`}
                  disabled={isDisabled}
                  inputSize="small"
                  defaultValue={faq.subject}
                  rules={{
                    required: {
                      value: true,
                      message: "Question is required.",
                    },
                  }}
                />
              </Box>
              <Box width="50%">
                <Text emphasis="medium" variant="body2" mb={2}>
                  Answer
                </Text>
                <HookWYSIWYG
                  placeholder="Write something to your shareholders"
                  fieldName={`${field}[${idx}].bodyMarkdown`}
                  savedValue={faq.bodyMarkdown}
                  controls={[ElementType.Link]}
                  rules={{
                    required: {
                      value: true,
                      message: "Answer is required.",
                    },
                    maxLength: {
                      value: faqMaxLength,
                      message: `Message exceeds ${faqMaxLength} characters.`,
                    },
                  }}
                  showCharCount
                  maxLength={faqMaxLength}
                />
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
    </Draggable>
  );
};

export default AddEditFaq;
