import { Box, Stack } from "@mui/material";
import { COLORS, Text } from "@asayinc/component-library";

const newPill = (
  <Stack
    component="span"
    data-testid="new-feature-pill"
    sx={{
      ml: 2,
      bgcolor: COLORS.SHADED_COBALT,
      borderRadius: 2,
      minWidth: "41px",
      height: "24px",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text variant="overline" component="span" category="primary" shade="light">
      NEW
    </Text>
  </Stack>
);

interface IProps {
  showPill: boolean;
  label: string;
}

const SideNavItem = ({ showPill, label }: IProps) => (
  <Stack direction="row" component="span" alignItems="center" maxWidth="117px">
    <Box
      component="span"
      sx={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      {label}
    </Box>
    {showPill && newPill}
  </Stack>
);

export default SideNavItem;
