import { useParams, useSearchParams } from "react-router-dom";
import {
  MessageType,
  SENT_TYPES,
  useGetMessagesQuery,
} from "../store/messageLists";
import { validateAllParams } from "../utils/validateParam";
import { SORT, SORT_SYMBOL, URL_PARAMS } from "../constants";

export const useHasSentMessage = (
  messageType: MessageType
): {
  hasSentMessage: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const [params] = useSearchParams();
  const {
    [URL_PARAMS.limit]: limit = "10",
    [URL_PARAMS.limit]: ordering = `${SORT_SYMBOL.desc}${SORT.scheduledSendAt}`,
  } = validateAllParams(Object.fromEntries(new URLSearchParams(params)));
  // sent messages
  const {
    data: sentData,
    isLoading: isSentLoading,
    isError: isSentError,
  } = useGetMessagesQuery({
    search: "",
    limit,
    offset: 0,
    ordering,
    messageType: messageType,
    messageStatus: SENT_TYPES,
    proxyEventId,
  });

  return {
    hasSentMessage: !!sentData?.count,
    isLoading: isSentLoading,
    isError: isSentError,
  };
};
