import { CreateQuestionColumnData } from "../pages/EventDetail/subPages/Questions/components/Organisms/QuestionsTable/types";
import { ColumnType } from "@asayinc/component-library";
/**
 * factory for generating column data
 * @param title thead cell copy
 * @param style style of cell text/checkbox/icon
 * @param sortFn callback when sort is clicked
 * @param sort current column sort value
 * @param clickHandler callback when tbody cell is clicked
 */
export const createColumnData = ({
  title,
  style,
  sortFn,
  sort,
  clickHandler,
  tdBodyProps = {},
  tdHeadProps = {},
  width,
  align,
  tooltip = "",
  secondaryTooltip = "",
  data,
}: CreateQuestionColumnData): ColumnType => ({
  title,
  style,
  sort,
  sortFn,
  clickHandler,
  tdBodyProps,
  tdHeadProps,
  width,
  align,
  tooltip,
  secondaryTooltip,
  data,
});
