import { useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// store
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  initialState,
  MessageDialog,
  selectMessageComposeData,
  setMessageDialog,
  useGetFilterStatsQuery,
  useGetMessageQuery,
  useUpdateMessageMutation,
} from "../../../../../store/messageCompose";
// components
import { ScheduleMessageDialog } from "../../../../../components/Common";

const ScheduleShareholderMessage = ({ forceOpen }: { forceOpen?: boolean }) => {
  const dispatch = useAppDispatch();
  const { messageDialogId, messageDialog, proxyEventDialogId } = useAppSelector(
    selectMessageComposeData
  );
  const [
    updateMessage,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
    },
  ] = useUpdateMessageMutation();

  const {
    data,
    data: { status, filterMap } = initialState,
    isSuccess,
    isFetching,
    isLoading,
  } = useGetMessageQuery(
    messageDialog === MessageDialog.Schedule && messageDialogId
      ? messageDialogId
      : skipToken
  );

  // if schedule dialog is active and message data is loaded, get filter stats
  const {
    data: { numShareholders } = {
      numShareholders: 0,
    },
  } = useGetFilterStatsQuery(
    isLoading || messageDialog !== MessageDialog.Schedule
      ? skipToken
      : {
          query: JSON.stringify(filterMap?.query || {}),
          proxyEventId: proxyEventDialogId,
        }
  );

  /**
   * close dialog
   */
  const close = () => {
    dispatch(
      setMessageDialog({
        messageDialogId: null,
        messageDialog: null,
      })
    );
  };

  // close dialog and go to messages page
  const navigate = useNavigate();
  const goToAllMessages = () => {
    close();
    navigate(
      proxyEventDialogId ? `/vote/${proxyEventDialogId}/messages` : "/messages"
    );
  };

  return (
    <ScheduleMessageDialog
      messageData={data}
      messageDialogId={messageDialogId}
      status={status}
      proxyEventId={proxyEventDialogId}
      open={messageDialog === MessageDialog.Schedule || !!forceOpen}
      updateMessage={updateMessage}
      isUpdateLoading={isUpdateLoading}
      isUpdateSuccess={isUpdateSuccess}
      isUpdateError={isUpdateError}
      close={close}
      successAction={goToAllMessages}
      numShareholders={numShareholders}
      isMessageSuccess={isSuccess}
      isMessageFetching={isFetching}
    />
  );
};

export default ScheduleShareholderMessage;
