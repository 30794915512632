import { useState, useCallback } from "react";
import { useWatch, useFieldArray, useFormContext } from "react-hook-form";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
// components
import { ButtonV2, useIsMounted } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { AddEditFAQ } from "./components";
// utils
import { generateRandomString } from "../../../../../utils/generateRandomString";
// types
import { FormFaq } from "./types";

interface IProps {
  isLoading?: boolean;
  addCallback?: () => void;
}

const field = "faqs";

const FaqEditor = ({ isLoading, addCallback }: IProps) => {
  const isMounted = useIsMounted();
  /**
   * we're displaying both inputs and using the input data to render faqs
   * So we need to watch the entire object so we can display changes
   */
  const faqsSelected: FormFaq[] = useWatch({ name: field });
  const { resetField, trigger } = useFormContext();
  const { move, append, remove } = useFieldArray({
    name: field,
  });

  const [tempFaqs, setTempFaqs] = useState<FormFaq[] | null>(null);
  const faqsToUse = tempFaqs || faqsSelected || [];

  const addFaq = () => {
    if (addCallback) {
      addCallback();
    }
    append({
      subject: "",
      bodyMarkdown: "",
      order: faqsToUse.length,
      id: generateRandomString(),
    });
  };

  const removeFaq = useCallback(
    (idx: number) => {
      const newFaqs = Array.from(faqsSelected);
      newFaqs.splice(idx, 1);
      remove(idx);
      resetField(field, { defaultValue: newFaqs, keepDirty: true });
      // even though form is valid, react-hook-form isnt being correctly updated until validation is re-triggered
      setTimeout(() => {
        if (isMounted()) {
          trigger(field);
        }
      }, 100);
    },
    [faqsSelected]
  );

  //latest version of DND does not seem testable with react-testing-library
  /* istanbul ignore next */
  const dragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination && result.source) {
        ////// temporary array to avoid flicker //////
        const newFaqs = Array.from(faqsSelected);
        newFaqs.splice(result.source.index, 1);
        newFaqs.splice(
          result.destination.index,
          0,
          faqsSelected[result.source.index]
        );
        setTempFaqs(newFaqs);
        // reset tempFaqs after next rerender
        /* istanbul ignore next */
        setTimeout(() => {
          if (isMounted()) {
            setTempFaqs(null);
          }
        }, 300);
        /////// end temporary array /////
        move(result.source.index, result.destination.index);
      }
    },
    [faqsSelected]
  );

  return (
    <>
      <Stack>
        <DragDropContext onDragEnd={dragEnd}>
          <Droppable droppableId="faqs" direction="vertical">
            {(provided, snapshot) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef}>
                {faqsToUse.map((faq, idx) => (
                  <AddEditFAQ
                    faq={faq}
                    idx={idx}
                    key={faq.id}
                    isSomethingDragging={snapshot.isUsingPlaceholder}
                    removeFaq={removeFaq}
                  />
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>
      <Box mt={6}>
        <ButtonV2
          data-testid="button-add-faq"
          variant="secondary"
          disabled={isLoading}
          onClick={addFaq}
        >
          Add another FAQ
        </ButtonV2>
      </Box>
    </>
  );
};

export default FaqEditor;
