import React, { useContext } from "react";
// components
import { Box, Stack, lighten } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import InsightsIcon from "@mui/icons-material/Insights";
import {
  AutocompleteChip,
  COLORS,
  nFormatter,
  pluralize,
  Text,
  ChipV2,
} from "@asayinc/component-library";
import { ShareholderInitials } from "../../../../../../../components/Common";
// constants
import { URL_PARAMS } from "../../../../../../../constants";
// data
import { Context } from "../../../context";
// types
import { IShareholderQuestion } from "../../../../../../../types/Shareholder";
import { useSearchParams } from "react-router-dom";

interface IProps {
  question: IShareholderQuestion;
  qaEventSlug: string;
  isLastItem: boolean;
}

const QuestionListItem = ({
  question,
  qaEventSlug,
  isLastItem,
}: IProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    id,
    authorName,
    questionText,
    profileColor,
    countSharesUpvoted,
    countUsersUpvoted,
    tags,
    globalUserId,
    status,
  } = question;
  const addAnsweredTag = status === "answered";

  const title = `Shareholder Profile`;
  // click specific question
  const goToQuestion = () => {
    searchParams.set(URL_PARAMS.qid, id);
    searchParams.set(URL_PARAMS.eid, qaEventSlug);
    setSearchParams(searchParams, { state: { goBackText: title } });
  };

  const { data } = useContext(Context);
  const shareholderId = data?.globalUserId;
  const isUsersQuestion = shareholderId === globalUserId;

  return (
    <Box position="relative">
      <Stack
        data-testid={`list-item-${id}`}
        onClick={goToQuestion}
        px={5}
        pb={6}
        pt={5}
        mb={isLastItem ? 0 : 2}
        sx={{
          cursor: "pointer",
          borderRadius: 2,
          border: `1px solid ${COLORS.P_OUTLINE}`,
          "&:hover": {
            background: lighten(COLORS.SAY_COBALT, 0.88),
            cursor: "pointer",
          },
        }}
      >
        <Stack direction="row" minHeight="32px" alignItems="center" mb={3}>
          <Stack
            height="32px"
            width="32px"
            minWidth="32px"
            justifyContent="center"
            alignItems="center"
          >
            {isUsersQuestion ? (
              <ShareholderInitials
                authorName={authorName}
                profileColor={profileColor}
                size={32}
              />
            ) : (
              <ArrowUpwardIcon />
            )}
          </Stack>
          <Text variant="body1" ml={3}>
            {questionText}
          </Text>
          {addAnsweredTag && (
            <Box sx={{ ml: "auto" }}>
              <ChipV2
                variant="secondary"
                icon={<InsightsIcon />}
                label="Answered"
                selected={true}
              />
            </Box>
          )}
        </Stack>
        <Text variant="body2" emphasis="medium" ml={11} mb={4}>
          {nFormatter(countUsersUpvoted)}{" "}
          {pluralize("vote", "votes", countUsersUpvoted)} ·{" "}
          {nFormatter(countSharesUpvoted)}{" "}
          {pluralize("share", "shares", countSharesUpvoted)} represented
        </Text>
        {tags && tags.length ? (
          <Stack direction="row" flexWrap="wrap" ml={11} mb={-2}>
            {tags.map((tag) => (
              <AutocompleteChip
                key={tag.id}
                option={tag}
                sx={{ mr: 2, mb: 2 }}
              />
            ))}
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

export default React.memo(QuestionListItem);
