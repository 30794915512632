import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
// components
import { Stack } from "@mui/material";
import { Dialog, IButtonAction, Text } from "@asayinc/component-library";
import { BrandAssetsColors, BrandAssetsImages } from "../../../../Common";
// data
import {
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../../../../store/settings";
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
// types
import { ISettings } from "../../../../../store/settings/types";
import { IBrandAssetsFormFields } from "./types";
// utils
import { getDirtyFormValues } from "../../../../../utils";

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

const getDefaultValues = ({
  settings: { logo, primaryColor },
}: {
  settings: ISettings;
}) => ({ logo, primaryColor });

const BrandAssetsDialog = ({ open, closeDialog }: IProps) => {
  const { data: settings = {}, isSuccess, isFetching } = useGetSettingsQuery();
  const [
    updateSettings,
    { isSuccess: isUpdateSuccess, isError: isUpdateError },
  ] = useUpdateSettingsMutation();

  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: getDefaultValues({ settings: settings as ISettings }),
  });
  const {
    getValues,
    reset,
    handleSubmit,
    formState: { isDirty, isValid, dirtyFields, errors },
  } = methods;

  // are there any errors
  const hasError = Object.keys(errors).length > 0;

  /**
   * set default values after pulling settingsData
   */
  useEffect(() => {
    if (isSuccess && !isFetching) {
      reset(getDefaultValues({ settings: settings as ISettings }));
    }
  }, [isSuccess, isFetching]);

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg:
      "Your company profile updates are submitted! Changes will be reflected upon approval by Say (within 1 business day).",
    errorMsg: "Failed to submit brand assets, please try again.",
    errorAction: closeDialog,
    successAction: closeDialog,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  });

  const onSubmitClick = () => {
    const formValues = getDirtyFormValues(
      getValues(),
      dirtyFields
    ) as IBrandAssetsFormFields;
    const patchBody = {
      ...formValues,
      logo: formValues.logoFilename,
      logoFilename: undefined,
    } as Partial<ISettings>;

    updateSettings({ companyProfile: patchBody, action: "submit" });
  };

  const canSave = (!hasError || isValid) && isDirty;
  const buttonActions = [
    {
      label: "Save",
      disabled: !canSave,
      onClick: handleSubmit(onSubmitClick),
    },
  ] as IButtonAction[];

  return (
    <Dialog
      titleTextVariant="subtitle1"
      title="Brand assets"
      data-testid="brand-assets-dialog"
      handleClose={closeDialog}
      buttonActions={buttonActions}
      content={
        <Stack spacing={6}>
          <Text variant="body1" width="279px">
            Customize your message by adding your company&apos;s branding. The
            changes you make here will be applied everywhere on Say.
          </Text>
          <FormProvider {...methods}>
            <BrandAssetsColors isLoading={isFetching} showSecondary={false} />
            <BrandAssetsImages isLoading={isFetching} />
          </FormProvider>
        </Stack>
      }
      open={open}
    />
  );
};

export default BrandAssetsDialog;
