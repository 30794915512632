import { useMemo, useState } from "react";
import { useWatch } from "react-hook-form";
// components
import { Text, HookSelect, IconButton } from "@asayinc/component-library";
import { MenuItem, Avatar, Box, Stack } from "@mui/material";
import SignatureDialog from "./SignatureDialog";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckIcon from "@mui/icons-material/Check";
// redux / data
import { useGetLeadersQuery } from "../../../../store/companyLeadership";
// utils
import {
  getSignatureById,
  mapLeaderToSignature,
} from "../../../../pages/Messages/subPages/CreateAndEdit/utils";
// types
import { IComposeSignatureOption } from "../../../../store/messageCompose";
import { ICompanyLeader } from "../../../../types/Events";
import { useGetSettingsQuery } from "../../../../store/settings";

const fieldName = "content.signatureId";

interface IProps {
  isLoading?: boolean;
}

const SignatureSelect = ({ isLoading }: IProps): JSX.Element => {
  const signature = useWatch({ name: fieldName });
  const { data: leaders = [] } = useGetLeadersQuery();
  const { data: { name: companyName } = { name: "" } } = useGetSettingsQuery();
  const selectOptions = useMemo(
    () =>
      leaders.map((leader) => mapLeaderToSignature({ ...leader, companyName })),
    [leaders]
  );

  const [dialogLeader, setDialogLeader] = useState<ICompanyLeader | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const openEditDialog = (option: IComposeSignatureOption) => {
    const leader = leaders.find(
      (leader) => leader.id === option.id
    ) as ICompanyLeader;
    setDialogLeader(leader);
    setDialogOpen(true);
  };

  const openCreateDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogLeader(null);
    setDialogOpen(false);
  };

  return (
    <>
      <HookSelect
        name={fieldName}
        id={fieldName}
        data-testid="select-signature"
        fullWidth
        disabled={isLoading}
        value={signature}
        displayEmpty
        sx={{
          ".MuiSelect-outlined.MuiSelect-select": {
            py: "11px",
            lineHeight: "24px",
          },
        }}
        renderValue={(value) => {
          const signature = getSignatureById(value as string, selectOptions);
          if (!value || !signature) {
            return "No signature";
          }
          return (
            <Stack direction="row" data-testid={`selected-${value}`}>
              <Avatar
                sx={{ width: 48, height: 48, mr: 4 }}
                src={signature.photo}
              />
              <Box>
                <Text variant="subtitle1" data-testid="selected-fullname">
                  {signature.name}
                </Text>
                <Text variant="body1">{signature.title}</Text>
              </Box>
            </Stack>
          );
        }}
      >
        {selectOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            data-testid={`menu-item-${option.id}`}
            sx={{
              ".edit-button": {
                opacity: 0,
              },
              "&:hover .edit-button": {
                opacity: 1,
                transition: "opacity 0.5s linear",
              },
            }}
          >
            {option.id === signature && <CheckIcon sx={{ mr: 3 }} />}
            <Avatar
              sx={{
                width: 48,
                height: 48,
                mr: 4,
                ml: option.id === signature ? 0 : 9,
              }}
              src={option.photo}
            />
            <Box>
              <Text variant="subtitle1" data-testid="text-leader-fullname">
                {option.name}
              </Text>
              <Text variant="body1">{option.title}</Text>
            </Box>
            <IconButton
              className="edit-button"
              sx={{ marginLeft: "auto" }}
              onClick={() => {
                openEditDialog(option);
              }}
              data-testid={`button-edit-${option.id}`}
            >
              <EditOutlinedIcon />
            </IconButton>
          </MenuItem>
        ))}
        <MenuItem
          value="clear"
          data-testid={`menu-item-clear`}
          sx={{ height: "60px" }}
        >
          <Text variant="body1" ml={9}>
            No signature
          </Text>
        </MenuItem>
        <Box>
          <MenuItem
            sx={{ height: "60px" }}
            data-testid={`menu-item-add`}
            onClick={openCreateDialog}
          >
            <Text variant="body1" ml={9}>
              Add
            </Text>
          </MenuItem>
        </Box>
      </HookSelect>
      <SignatureDialog
        open={dialogOpen}
        closeDialog={closeDialog}
        leader={dialogLeader}
        leaders={leaders}
        isCreate={!dialogLeader}
      />
    </>
  );
};

export default SignatureSelect;
