import { HookTextField, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useWatch } from "react-hook-form";

interface IProps {
  isDisabled?: boolean;
  charLimit: number;
  companyName: string;
}

const ProductLaunchFields = ({
  isDisabled,
  charLimit,
  companyName,
}: IProps) => {
  // this inputs current value
  const productLaunchName = useWatch({
    name: "meta.productLaunchName",
  });

  return (
    <>
      <Text variant="subtitle1">Product Launch name</Text>
      <Box width="100%">
        <HookTextField
          variant="outlined"
          outerLabel={`${companyName} ${productLaunchName || ""} Q&A`}
          id="productLaunchName"
          inputSize="small"
          disabled={isDisabled}
          maxLength={charLimit}
          showCharChount
          name="meta.productLaunchName"
          placeholder="e.g., Q1 2023 New Product Launch"
          rules={{
            required: {
              value: true,
              message: "Product Launch name is required.",
            },
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </>
  );
};

export default ProductLaunchFields;
