import { useEffect } from "react";
import { useGetProxyEventDetailsQuery } from "../../../store/proxyEvent";
import { useNavigate, useParams } from "react-router-dom";
import { isAfterVoteCutoff } from "../utils";
import { useAppDispatch } from "../../../store/hooks";
import { setSnackbar } from "../../../store/common";

export const useRedirectPastCutoff = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { proxyEventId } = useParams() as {
    proxyEventId: string;
  };
  const { data: proxyData, isLoading } =
    useGetProxyEventDetailsQuery(proxyEventId);

  useEffect(() => {
    if (!isLoading && isAfterVoteCutoff(proxyData?.voteCutoff)) {
      dispatch(
        setSnackbar({
          message:
            "Cannot edit or create messages to send after event's closure",
          open: true,
          severity: "error",
        })
      );
      navigate(`/vote/${proxyEventId}`);
    }
  }, [proxyData]);
};
