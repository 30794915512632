import { Stack } from "@mui/material";
import { Text } from "@asayinc/component-library";

interface IProps {
  emptyHeader: string;
  emptySubHeader: string;
}
/**
 * What displays when there is no data for a given Qa participation view
 */
const EmptyQuestionView = ({ emptyHeader, emptySubHeader }: IProps) => {
  return (
    <Stack
      data-testid="empty-question-view"
      alignItems="center"
      justifyContent="center"
      height="394px"
      mt={-6}
      mb={6}
    >
      <Text variant="h5" emphasis="medium_high">
        {emptyHeader}
      </Text>
      <Text variant="body2" emphasis="medium" mt={2}>
        {emptySubHeader}
      </Text>
    </Stack>
  );
};

export default EmptyQuestionView;
