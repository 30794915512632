import { IconButton } from "@mui/material";
import React from "react";
import { Emoji } from "../../../../../../Common";

interface IProps {
  onClick: (emoji: string) => void;
  emoji: string;
}

const AddEmojiOption = ({ onClick, emoji, ...rest }: IProps) => {
  const handleClick = () => {
    onClick(emoji);
  };

  return (
    <IconButton key={emoji} sx={{ height: 40 }} onClick={handleClick} {...rest}>
      <Emoji emoji={emoji} className="greyscale" />
    </IconButton>
  );
};

export default React.memo(AddEmojiOption);
