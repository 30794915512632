import { Text, TooltipInfoIcon } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { FormImageUpload } from "../";

interface IProps {
  isLoading?: boolean;
}

const BrandAssetsImages = ({ isLoading }: IProps) => {
  const { control, setValue, register, setError } = useFormContext();

  return (
    <Box data-testid="Logo" mr={12} flexGrow={1}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2.5}
        sx={{
          mb: 1,
        }}
      >
        <Text variant="subtitle1">Logo</Text>
        <TooltipInfoIcon
          title={
            <>
              For the best experience, provide logos that:
              <br />
              -are pictorial
              <br />
              -are not wordmarks
              <br />
              -contain as few colors as possible
              <br />
              -do not contain gradients
            </>
          }
          iconColor="black"
          tooltipColor="black"
          placement="right"
        />
      </Stack>

      <Text
        variant="body2"
        sx={{
          mb: 2,
        }}
      >
        Default logo
      </Text>
      <FormImageUpload
        size="medium"
        name="logo"
        fileNameField="logoFilename"
        width={200}
        height={200}
        disabled={isLoading}
        description="Use a high quality PNG image: 200x200px"
        circularCrop={true}
        type="company"
        control={control}
        data-testid="company-logo"
        setValue={setValue}
        setError={setError}
        register={register}
      />
    </Box>
  );
};

export default BrandAssetsImages;
