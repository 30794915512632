import { Stack } from "@mui/material";
import { CircularProgress } from "@asayinc/component-library";

const LoadingSpinner = () => (
  <Stack
    data-testid="shareholder-activity-loader"
    height="394px"
    alignItems="center"
    justifyContent="center"
    mt={-6}
    mb={6}
  >
    <CircularProgress />
  </Stack>
);

export default LoadingSpinner;
