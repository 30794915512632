import { Stack } from "@mui/material";
import { useGetMessageQuery } from "../../../../../../../store/messageCompose";
import { useMessageParams } from "../../../../../../../hooks";
import {
  useGetMessageOverviewQuery,
  initialState,
} from "../../../../../../../store/messageStats";
import { MessageStatus } from "../../../../../../../types/Messages";
import { MessageStat } from "../../../../../../../pages/Messages/components";

interface IProps {
  asDrawer?: boolean;
}

const MessageSummary = ({ asDrawer }: IProps) => {
  const { messageId } = useMessageParams(asDrawer);

  const {
    data = initialState,
    isLoading,
    isError,
  } = useGetMessageOverviewQuery(messageId);
  const { data: messageData } = useGetMessageQuery(messageId);

  const stillSending =
    messageData?.status !== MessageStatus.Attached &&
    messageData?.status !== MessageStatus.Sent;

  return (
    <Stack
      direction="row"
      mt={6}
      gap={[8, 12]}
      px={asDrawer ? 0 : 8}
      flexWrap={["wrap", asDrawer ? "wrap" : "nowrap"]}
      sx={{
        // CSS Quantity queries to make sure that when the flex row wraps,
        // each row has a minimum of two items
        "> div:first-of-type:nth-last-of-type(3), > div:first-of-type:nth-last-of-type(3) ~ div":
          {
            flexBasis: ["25%", asDrawer ? "25%" : "auto"],
          },
        "> div:first-of-type:nth-last-of-type(4), > div:first-of-type:nth-last-of-type(4) ~ div":
          {
            flexBasis: ["44%", asDrawer ? "44%" : "auto"],
          },
      }}
    >
      <MessageStat
        isLoading={isLoading || isError}
        headline="Total reached"
        shareholderCount={data.stats.deliveredCount}
        sharesCount={data.stats.deliveredShares}
        statsPending={stillSending}
      />
      <MessageStat
        isLoading={isLoading || isError}
        headline="Opened"
        shareholderCount={data.stats.openedCount}
        sharesCount={data.stats.openedShares}
        statsPending={stillSending}
      />
      {data.containsLinks && (
        <MessageStat
          isLoading={isLoading || isError}
          headline="Clicked"
          shareholderCount={data.stats.clickedCount}
          sharesCount={data.stats.clickedShares}
          statsPending={stillSending}
        />
      )}
      {data.containsReplies && (
        <MessageStat
          isLoading={isLoading || isError}
          headline="Replied"
          shareholderCount={data.stats.repliedCount}
          sharesCount={data.stats.repliedShares}
          statsPending={stillSending}
        />
      )}
    </Stack>
  );
};

export default MessageSummary;
