// types
import {
  EventStatusOptions,
  IActiveCompanyLeader,
} from "../../../types/Events";
// constants
import { DATE_FORMAT, TIME_FORMAT } from "@asayinc/component-library";
import {
  EVENT_TYPE_LABEL_MAP,
  EVENT_STATUS_BADGE_MAPPING,
} from "../../../constants";
// dayjs
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface IProps {
  status: EventStatusOptions;
  goToStep: (step: number) => void;
  eventType?: string;
  eventStartDatetime?: string | null;
  leaders: IActiveCompanyLeader[];
  draftStep: number;
}

const step3completeStatus = [
  EventStatusOptions.PENDING,
  EventStatusOptions.APPROVED,
  EventStatusOptions.PUBLISHED,
];

export const getStepIndicatorData = ({
  status,
  goToStep,
  eventType,
  eventStartDatetime,
  leaders,
  draftStep,
}: IProps) => [
  {
    stepName: "Event type",
    onClick: () => goToStep(0),
    complete: draftStep > 0 || status !== EventStatusOptions.DRAFT,
    additionalText: eventType ? EVENT_TYPE_LABEL_MAP[eventType] : undefined,
  },
  {
    stepName: "Basic Information",
    onClick:
      draftStep > 0 || status !== EventStatusOptions.DRAFT
        ? () => goToStep(1)
        : undefined,
    complete: draftStep > 1 || status !== EventStatusOptions.DRAFT,
    additionalText: dayjs(eventStartDatetime).format(
      `${DATE_FORMAT} [at] ${TIME_FORMAT}`
    ),
  },
  {
    stepName: "Additional Details",
    onClick:
      draftStep > 1 || status !== EventStatusOptions.DRAFT
        ? () => goToStep(2)
        : undefined,
    complete: draftStep > 2 || status !== EventStatusOptions.DRAFT,
    additionalText: `${leaders.length} Company Participants`,
  },
  {
    stepName: "Review and Submit",
    onClick:
      draftStep > 2 || status !== EventStatusOptions.DRAFT
        ? () => goToStep(3)
        : undefined,
    complete: draftStep > 3 || status !== EventStatusOptions.DRAFT,
    additionalText: step3completeStatus.includes(status)
      ? EVENT_STATUS_BADGE_MAPPING[status].label
      : undefined,
  },
];
