import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";
// mui
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Stack } from "@mui/material";
// components
import {
  IconButton,
  ErrorMessage,
  HookTextField,
  Text,
  useIsMounted,
} from "@asayinc/component-library";
import { AddMoreTextReplies, TextOption } from "../../Molecules";
// constants
import { IQuickReplyOptionObj } from "../../../../../../../store/messageCompose";
import { FORM_FIELDS } from "../../../../../../../constants";

interface IProps {
  goBack: (type: null) => void;
  setTextOptionsState: (texts: IQuickReplyOptionObj[]) => void;
  disabled?: boolean;
}

const QuickReplyTextFormSection = ({
  goBack,
  setTextOptionsState,
  disabled,
}: IProps) => {
  const isMounted = useIsMounted();
  const [isNew, setIsNew] = useState(false);
  const textRepliesSelected: IQuickReplyOptionObj[] = useWatch({
    name: FORM_FIELDS.quickReplies.textData.options,
  });

  const {
    formState: { errors },
    trigger,
    unregister,
  } = useFormContext();

  const { move, append, remove } = useFieldArray({
    name: FORM_FIELDS.quickReplies.textData.options,
    rules: {
      validate: {
        minimumTwo: (values) => {
          const options = values as IQuickReplyOptionObj[];
          if (options && options.length < 2) {
            return "Must add at least 2 response options.";
          }
          return true;
        },
      },
    },
  });

  /**
   * This state is used as a buffer for the time it takes react-hook-form to update values and re-render
   * Without it there would be a flicker in the time drop finishes and state updates
   */
  const [temporaryTextReplies, setTemporaryTextReplies] = useState<
    IQuickReplyOptionObj[] | null
  >(null);

  useEffect(() => {
    trigger(FORM_FIELDS.quickReplies.textData.name);
    setIsNew(true);
  }, []);

  const textRepliesToUse = temporaryTextReplies || textRepliesSelected || [];
  const optionsPath = FORM_FIELDS.quickReplies.textData.options;

  /**
   * After removing a text Reply, revalidate incase there are less than 2
   */
  const removeTextReply = useCallback(
    (idx: number) => {
      remove(idx);
      // even though form is valid, react-hook-form isnt being correctly updated until validation is re-triggered
      setTimeout(() => {
        if (isMounted()) {
          trigger(FORM_FIELDS.quickReplies.textData.name);
        }
      }, 100);
    },
    [textRepliesSelected]
  );

  /**
   * leave quick reply texts
   */
  const handleGoBack = useCallback(() => {
    setTextOptionsState(textRepliesSelected);
    // different than emoji because this is the correct way to handle, but react-hook-form is throwing an error
    // when unregistering an array of strings vs objects
    unregister(FORM_FIELDS.quickReplies.textData.options);
    goBack(null);
  }, [textRepliesSelected]);

  /**
   * when drag completes update react hook form by 'move'ing the item that was dragged
   * to its new location.
   * Also need to temporarily set the new order in local state to prevent a flicker
   * latest version of DND does not seem testable with react-testing-library
   */
  /* istanbul ignore next */
  const dragEnd = (result: DropResult) => {
    if (result.destination && result.source) {
      ////// temporary array to avoid flicker //////
      const newTextReplies = Array.from(textRepliesSelected);
      newTextReplies.splice(result.source.index, 1);
      newTextReplies.splice(
        result.destination.index,
        0,
        textRepliesSelected[result.source.index]
      );
      setTemporaryTextReplies(newTextReplies);
      // reset temp text replies after next rerender
      /* istanbul ignore next */
      setTimeout(() => {
        if (isMounted()) {
          setTemporaryTextReplies(null);
        }
      }, 300);
      /////// end temporary array /////
      move(result.source.index, result.destination.index);
    }
  };

  return (
    <Stack>
      <Box display="inline-flex">
        <Stack direction="row" mb={6} alignItems="center">
          <IconButton
            onClick={disabled ? undefined : handleGoBack}
            data-testid="btn-text-goback"
            sx={{ ml: -2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Text variant="subtitle1" ml={2}>
            Text responses
          </Text>
        </Stack>
      </Box>
      <HookTextField
        sx={{ width: "100%" }}
        name={FORM_FIELDS.quickReplies.textData.prompt}
        id="quickReplies-prompt"
        placeholder="e.g. Reply to this message"
        disabled={disabled}
        outerLabel="Write a prompt"
        showCharChount
        maxLength={40}
        rules={{
          required: {
            value: true,
            message: "Prompt is required.",
          },
        }}
      />
      <Text variant="body2" mb={3} mt={6}>
        Response options
      </Text>

      <Stack direction="row">
        {textRepliesToUse && !!textRepliesToUse.length && (
          <DragDropContext onDragEnd={dragEnd}>
            <Droppable droppableId="textReplies" direction="horizontal">
              {(provided, snapshot) => (
                <Stack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  direction="row"
                  alignItems="flex-start"
                  sx={{
                    overflowX: "scroll",
                  }}
                >
                  {textRepliesToUse.map((textReply, idx) => (
                    <TextOption
                      key={textReply.id}
                      idx={idx}
                      disabled={disabled}
                      textOption={textReply}
                      removeTextOption={removeTextReply}
                      isSomethingDragging={snapshot.isUsingPlaceholder}
                      isNew={isNew}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <AddMoreTextReplies
          textRepliesSelected={textRepliesSelected || []}
          append={append}
          disabled={disabled}
        />
      </Stack>
      <ErrorMessage
        errors={errors}
        name={`${optionsPath}.root`}
        sx={{ mt: 1 }}
      />
    </Stack>
  );
};

export default QuickReplyTextFormSection;
