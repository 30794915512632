import { useLocation, useNavigate } from "react-router-dom";
// material
import { Box, Stack } from "@mui/material";
import {
  OpenInFull,
  Close,
  StarBorder,
  Star,
  ArrowBack,
  DeleteOutline,
} from "@mui/icons-material";
// say
import { Text, IconButton, Tooltip } from "@asayinc/component-library";
// Api data
import { usePatchQuestionMutation } from "../../../../../store/question";
import { useAppDispatch } from "../../../../../store/hooks";
import { questionsApi } from "../../../../../store/questions";
// helpers
import { track } from "../../../../../analytics";
// types
import { Question } from "../../../../../types/Questions";

interface Props {
  active: boolean;
  questionId: string;
  eventSlug: string;
  question?: Question;
  expand: () => void;
  close: () => void;
  openRemoveDialog: () => void;
  asDrawer?: boolean;
}
const QuestionDetailHeaderV2 = ({
  active,
  questionId,
  eventSlug,
  question,
  expand,
  close,
  openRemoveDialog,
  asDrawer = false,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [patchQuestion] = usePatchQuestionMutation();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: { goBackText?: string } };

  /**
   * star question within overlay
   */
  const starQuestion = async () => {
    await patchQuestion({
      questionId,
      eventSlug,
      patchData: { isSelectedByIssuer: !active },
    });
    dispatch(questionsApi.util.invalidateTags(["Questions"]));
    trackStarQuestion();
  };

  /**
   * track star question
   */
  const trackStarQuestion = () => {
    track({
      name: "Star Question Action",
      context: "From Dialog",
      questionId: questionId,
      slug: eventSlug,
      action: active ? "Removed" : "Added",
    });
  };

  /**
   * if history state exists for go back text use it and trigger browser back
   * else navigate to all qa page
   */
  const goBack = () => {
    if (state?.goBackText || !question) {
      navigate(-1);
    } else {
      navigate(`/qa/${question.eventSlug}/questions`);
    }
  };

  return (
    <Box data-testid="question-detail-header" mb={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          {!asDrawer && (
            <IconButton
              sx={{ height: "20px", width: "20px", mr: 2, ml: "-3.5px" }}
              onClick={goBack}
            >
              <ArrowBack />
            </IconButton>
          )}
          <Text variant="subtitle2">{asDrawer ? "Question" : "Q&A"}</Text>
        </Stack>
        {asDrawer && (
          <Stack direction="row" gap={2.5}>
            <Tooltip
              data-testid="dialog-star-tooltip"
              title={active ? "Remove from Favorites" : "Add to Favorites"}
            >
              <IconButton
                onClick={starQuestion}
                data-testid="drawer-btn-star"
                selected={active}
              >
                {active ? <Star /> : <StarBorder />}
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={openRemoveDialog}
              data-testid="drawer-btn-trash"
            >
              <DeleteOutline />
            </IconButton>
            <IconButton onClick={expand} data-testid="drawer-expand-button">
              <OpenInFull />
            </IconButton>
            <IconButton onClick={close} data-testid="drawer-close-button">
              <Close />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" mt={asDrawer ? 1.5 : 2} alignItems="center">
        <Text variant="h5">{question?.questionText}</Text>
        {!asDrawer && (
          <Stack direction="row" gap={2.5} ml={2.5}>
            <Tooltip
              data-testid="dialog-star-tooltip"
              title={active ? "Remove from Favorites" : "Add to Favorites"}
            >
              <IconButton
                onClick={starQuestion}
                data-testid="drawer-btn-star"
                selected={active}
              >
                {active ? <Star /> : <StarBorder />}
              </IconButton>
            </Tooltip>
            <IconButton
              onClick={openRemoveDialog}
              data-testid="drawer-btn-trash"
            >
              <DeleteOutline />
            </IconButton>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default QuestionDetailHeaderV2;
