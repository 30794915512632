import { ShareholderType } from "../../types/Questions";

interface Props {
  type: string;
  authorName: string;
  institutionName: string;
}

export const getAuthorName = ({
  type,
  authorName,
  institutionName,
}: Props): string => {
  switch (type) {
    case ShareholderType.Institutional:
    case ShareholderType.Analyst:
      if (institutionName) {
        return `${authorName} at ${institutionName}`;
      }
      return authorName;
    default:
      return authorName;
  }
};
