import { TrendingUp, TrendingDown } from "@mui/icons-material";
import { COLORS, Text, TooltipInfoIcon } from "@asayinc/component-library";
import { Stack } from "@mui/material";

interface IProps {
  title: string;
  countMessage?: string | number;
  trendMessage?: string | number;
  trendUp: boolean;
  tooltip?: string;
  isLoading?: boolean;
}

const QAStats = ({
  title,
  countMessage,
  trendMessage,
  isLoading,
  trendUp,
  tooltip,
}: IProps) => {
  const color = trendUp ? COLORS.SATURATED_GREEN : COLORS.SATURATED_RED;

  return (
    <Stack
      width="50%"
      mr={12}
      sx={{ ":last-child": { mr: 0 } }}
      data-testid={`stat-${title}`}
    >
      <Stack direction="row" alignItems="center" mb={tooltip ? 1.5 : 3}>
        <Text variant="subtitle2" mr={tooltip ? 1 : 0}>
          {title}
        </Text>
        {tooltip && <TooltipInfoIcon title={tooltip} iconColor="black" />}
      </Stack>
      <Text variant="h5">{isLoading ? undefined : countMessage}</Text>
      <Stack mt={2} direction="row" alignItems="center">
        {!isLoading &&
          (trendUp ? (
            <TrendingUp fontSize="small" style={{ color }} />
          ) : (
            <TrendingDown fontSize="small" style={{ color }} />
          ))}
        <Text style={{ color }} ml={1} variant="body2">
          {isLoading ? undefined : trendMessage}
        </Text>
      </Stack>
    </Stack>
  );
};

export default QAStats;
