import { useEffect, useRef } from "react";
import { track } from "../../../../../../../analytics";
import { useTableSearchParams } from "../../../../../../../hooks";

interface IProps {
  slug?: string;
  eventStatus?: string;
  companyName?: string;
}

export const useTracking = ({ slug, eventStatus, companyName }: IProps) => {
  const { paramObj } = useTableSearchParams({});
  const didMount = useRef<boolean>(false);
  const baseProps = {
    name: "Questions Filtered",
    qaEvent: slug,
    eventStatus: eventStatus,
    client: companyName,
  };

  const isLoaded = didMount.current && companyName && slug;

  /**
   * Track search
   */
  useEffect(() => {
    if (isLoaded && paramObj.search) {
      track({
        ...baseProps,
        filter: "search",
        filterValue: paramObj.search,
      });
    }
  }, [paramObj.search]);

  /**
   * Track tags
   */
  useEffect(() => {
    if (isLoaded && (paramObj.tags || paramObj.filter)) {
      track({
        ...baseProps,
        filter: "tags",
        filterValue: paramObj.tags || paramObj.filter,
      });
    }
  }, [paramObj.tags || paramObj.filter]);

  /**
   * Track sharesUpvoted
   */
  useEffect(() => {
    if (isLoaded && (paramObj.sharesUpvotedMin || paramObj.sharesUpvotedMax)) {
      track({
        ...baseProps,
        filter: "shares",
        filterValue: `min: ${paramObj.sharesUpvotedMin} - max: ${paramObj.sharesUpvotedMax}`,
      });
    }
  }, [paramObj.sharesUpvotedMin || paramObj.sharesUpvotedMax]);

  /**
   * Track upvoteCount
   */
  useEffect(() => {
    if (isLoaded && (paramObj.upvoteCountMin || paramObj.upvoteCountMax)) {
      track({
        ...baseProps,
        filter: "upvoteCount",
        filterValue: `min: ${paramObj.upvoteCountMin} - max: ${paramObj.upvoteCountMax}`,
      });
    }
  }, [paramObj.upvoteCountMax || paramObj.upvoteCountMin]);

  /**
   * Track isAnswered
   */
  useEffect(() => {
    if (isLoaded) {
      track({
        ...baseProps,
        filter: "answered",
        filterValue: paramObj.isAnswered,
      });
    }
  }, [paramObj.isAnswered]);

  /**
   * Track submitted date
   */
  useEffect(() => {
    if (isLoaded && (paramObj.submittedBefore || paramObj.submittedAfter)) {
      track({
        ...baseProps,
        filter: "participated",
        filterValue: `before: ${paramObj.submittedBefore} - after: ${paramObj.submittedAfter}`,
      });
    }
  }, [paramObj.submittedBefore || paramObj.submittedAfter]);

  /**
   * Track shareholder tags
   */
  useEffect(() => {
    if (
      isLoaded &&
      (paramObj.shareholdersNotTagged || paramObj.shareholderTagIds)
    ) {
      track({
        ...baseProps,
        filter: "shareholderTags",
        filterValue:
          paramObj.shareholdersNotTagged || paramObj.shareholderTagIds,
      });
    }
  }, [paramObj.shareholderTagIds || paramObj.shareholdersNotTagged]);

  // set component has mounted, avoids running tracking on render
  useEffect(() => {
    didMount.current = true;
  }, []);
};
