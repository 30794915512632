// feature disabled due to BE limitation atm
// TODO
/* istanbul ignore file */
import { ChipV2 as Chip, Select } from "@asayinc/component-library";
//Mui
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { MenuItem, SelectChangeEvent } from "@mui/material";
// constants
import { MORE_FILTER_LABEL_MAP } from "../../constants";

interface IInactiveMoreFilter {
  label: string;
  value: string;
}

interface IProps {
  isLoading?: boolean;
  handleMoreChange: (e: SelectChangeEvent<unknown>) => void;
  inactiveMoreFilters: IInactiveMoreFilter[];
}

const MoreWaysToTarget = ({
  isLoading,
  handleMoreChange,
  inactiveMoreFilters,
}: IProps) => {
  return (
    <Select
      chipProps={{
        disabled: isLoading,
        variant: "secondary",
        icon: <AddIcon />,
      }}
      onChange={handleMoreChange}
      customDisplayValue="More ways to target"
      valueMapping={MORE_FILTER_LABEL_MAP}
      optionIcon={<FilterListIcon fontSize="small" sx={{ mr: 3 }} />}
      name="more filters"
      menuTitle="MORE FILTERS"
      value={""}
      asChip
    >
      {inactiveMoreFilters.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={`menu-item-${option.value}`}
          sx={{
            "&:hover": {
              background: "none",
            },
            my: 4,
            py: 0,
            pl: 2.5,
            "&:last-of-type": {
              mb: 0,
            },
            "&:first-of-type": {
              mt: 0,
            },
          }}
        >
          <Chip
            data-testid="minmax-chip"
            style={{
              cursor: "pointer",
            }}
            variant="secondary"
            icon={<FilterListIcon />}
            label={option.label}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default MoreWaysToTarget;
