import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// components
import { Stack, Box } from "@mui/material";
import { Text, pluralize } from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../../../../components/Common";
// types
import { IShareholderMessageStats } from "../../../../../../../../../../types/Shareholder";
// utils
import { formatAsPercentage } from "../../../../../../../../../../utils";
// data
import { Context } from "../../../../../../context";
import { useGetShareholderMessageStatsQuery } from "../../../../../../../../../../store/shareholder";

const headlineLoadingProps = { sx: { width: "100px" } };
const subheadlineLoadingProps = { sx: { width: "200px" } };

const Summary = () => {
  const { asDrawer, data, isLoading: isMetaDataLoading } = useContext(Context);
  const globalShareholderId = data?.globalShareholderId;
  const {
    data: messageStatsData = {},
    isLoading: isStatsDataLoading,
    isError: isStatsError,
  } = useGetShareholderMessageStatsQuery(
    globalShareholderId ? globalShareholderId : skipToken
  );

  const { totalReceived, openRate, linkClickRate, replyRate } =
    messageStatsData as IShareholderMessageStats;

  const isLoading = isMetaDataLoading || isStatsDataLoading;

  if (isStatsError) {
    return null;
  }

  return (
    <CardWrapper cardName="messages-summary">
      <Stack direction="row" flexWrap="wrap">
        <Box width={asDrawer ? "100%" : "25%"} mb={asDrawer ? 6 : 0}>
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Total received"}
          </Text>
          <Text variant="h5" loadingProps={subheadlineLoadingProps}>
            {isLoading
              ? undefined
              : `${totalReceived} ${pluralize(
                  "message",
                  "messages",
                  totalReceived
                )}`}
          </Text>
        </Box>
        <Box width={asDrawer ? "33%" : "25%"}>
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Open rate"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : formatAsPercentage(openRate)}
          </Text>
        </Box>
        <Box width={asDrawer ? "33%" : "25%"}>
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Link click rate"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : formatAsPercentage(linkClickRate)}
          </Text>
        </Box>
        <Box width={asDrawer ? "33%" : "25%"}>
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Reply rate"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : formatAsPercentage(replyRate)}
          </Text>
        </Box>
      </Stack>
    </CardWrapper>
  );
};

export default Summary;
