import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../LoadingSpinner";

interface IProps {
  dataLength: number;
  next: () => void;
  hasMore: boolean;
  isLoading: boolean;
  reCalculateTrigger?: string;
  children: JSX.Element[];
}

const InfiniteScrollWrapper = ({
  isLoading,
  dataLength,
  next,
  hasMore,
  children,
  reCalculateTrigger,
}: IProps): JSX.Element => {
  /**
   * When the dynamic content passed to InfiniteScroll is less than the height
   * of the container, InfiniteScroll will never call getMoreItems, preventing
   * any additional items from being requested. The below useEffect function calls
   * getMoreItems when this occurs to fill the container and allow InfiniteScroll
   * to work.
   * This can also occur when opening/closing accordians, so reCalculateTrigger is an additional
   * param that can be passed in to let the component know when to re-check
   * https://github.com/ankeetmaini/react-infinite-scroll-component/issues/309
   */
  useEffect(() => {
    const root = document.getElementById("scroll-container");
    if (
      !isLoading &&
      hasMore &&
      root &&
      root.scrollHeight <= root.clientHeight
    ) {
      next();
    }
  }, [isLoading, hasMore, reCalculateTrigger]);

  return (
    <div id="scroll-container" style={{ position: "relative" }}>
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        hasMore={hasMore}
        height={394}
        loader={<LoadingSpinner />}
      >
        {children}
        <div style={{ height: 24 }} />
      </InfiniteScroll>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: "34px",
          background:
            "linear-gradient(to top, rgb(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0) 100%)",
        }}
      />
    </div>
  );
};

export default InfiniteScrollWrapper;
