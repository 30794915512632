import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageDialog } from "./types";

export interface IComposeStore {
  messageDialog: MessageDialog | null;
  templateId?: string | null;
  messageDialogId: string | null;
  proxyEventDialogId?: string | null;
  messageDrawerId?: string | null;
  messageDrawerProxyEventId?: string | null;
}

const initialState: IComposeStore = {
  templateId: null,
  messageDialog: null,
  messageDialogId: null,
  proxyEventDialogId: null,
  messageDrawerId: null,
  messageDrawerProxyEventId: null,
};

export const messageComposeSlice = createSlice({
  name: "messageCompose",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTemplateId: (state, action: PayloadAction<string>) => {
      state.templateId = action.payload;
    },
    setMessageDialog: (
      state,
      action: PayloadAction<{
        messageDialog: MessageDialog | null;
        messageDialogId: string | null;
        proxyEventDialogId?: string | null;
      }>
    ) => {
      state.messageDialog = action.payload.messageDialog;
      state.messageDialogId = action.payload.messageDialogId;
      state.proxyEventDialogId = action.payload.proxyEventDialogId || null;
    },
    setMessageDrawer: (
      state,
      action: PayloadAction<{
        messageDrawerId?: string | null;
        messageDrawerProxyEventId?: string | null;
      }>
    ) => {
      state.messageDrawerId = action.payload.messageDrawerId;
      state.messageDrawerProxyEventId =
        action.payload.messageDrawerProxyEventId;
    },
  },
});

export const { setMessageDialog, setMessageDrawer, setTemplateId } =
  messageComposeSlice.actions;

export const messageComposeReducer = messageComposeSlice.reducer;
