import { useCallback, useMemo } from "react";
// comp lib
import { TableV2 as Table } from "@asayinc/component-library";
// mui
// import FilterListIcon from "@mui/icons-material/FilterList"; TODO not part of initial release
import { Box } from "@mui/material";
// router / store
import { setMessageDrawer } from "../../../../../../../store/messageCompose";
import { useAppDispatch } from "../../../../../../../store/hooks";
import {
  MessageType,
  useGetMessagesQuery,
} from "../../../../../../../store/messageLists";
// constants
import { COLUMNS, NO_RESULTS_DATA } from "./constants";
import {
  ROW_OPTIONS,
  SORT,
  SORT_SYMBOL,
  URL_PARAMS,
} from "../../../../../../../constants";
// helpers
import { useSearchFns } from "../../../../../../../hooks/useSearchFns";
import { getColumnData, getRowData } from "./factories";
import { useTableSearchParams } from "../../../../../../../hooks";
import { SENT_TYPES } from "../../../../../../../store/messageLists/constants";

// components
// import { FilterList } from "../../Templates"; TODO: not part of initial release

const Sent = () => {
  const searchFns = useSearchFns(URL_PARAMS.search);
  const dispatch = useAppDispatch();
  const {
    limit,
    page,
    ordering,
    search,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
  } = useTableSearchParams({
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.scheduledSendAt}`,
  });

  const {
    data = { next: null, prev: null, count: 0, results: [] },
    isFetching,
    isLoading,
  } = useGetMessagesQuery({
    search,
    limit,
    ordering,
    offset: (parseInt(page) - 1) * parseInt(limit),
    messageStatus: SENT_TYPES,
    messageType: MessageType.SM,
  });

  const { results: messages, count } = data;

  /**
   * navigate to message that has been selected
   */
  const goToMessage = useCallback((data: unknown) => {
    const { id } = data as { id: string };
    dispatch(
      setMessageDrawer({
        messageDrawerId: id,
        messageDrawerProxyEventId: null,
      })
    );
  }, []);

  /**
   * props for pagination toolbar
   */
  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search for a message",
  };

  // row and column data for the table
  const rowData = getRowData({ messages });
  const columnData = useMemo(
    () => getColumnData({ goToMessage, getSort, sortFn }),
    [getSort, sortFn]
  );

  /* TODO: not part of initial release
  // toolbar dropdown filter list
  const content = <FilterList />;

  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent: content,
  };
  */

  return (
    <Box data-testid="sent-table">
      <Table
        isFetching={isFetching}
        isLoading={isLoading}
        rows={rowData}
        data-test
        testid="sentMessage-table"
        memoCells
        tableLayout="auto"
        columnData={columnData}
        // toolbarCollapse={toolbarCollapse} TODO: not part of initial release
        tableMinWidth={700}
        searchBarProps={searchBarProps}
        title={`Sent (${count})`}
        columns={COLUMNS}
        paginateProps={paginateProps}
        count={count}
        noResultsData={NO_RESULTS_DATA}
      />
    </Box>
  );
};

export default Sent;
