import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { NotSent, Sent } from "./components";
import { useHasAMessage } from "../../../../hooks/useHasAMessage";
import { MessageDrawer } from "../../../../components/Messages";
import { MessageType } from "../../../../store/messageLists";

const AllMessages = () => {
  const { hasAMessage, hasSentMessage, hasNotSentMessage, loading, isError } =
    useHasAMessage(MessageType.SM);

  // show snackbar if data load fails
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please refresh and try again.",
    isError,
  });

  useEffect(() => {
    document.title = "Messages | Say Issuer Portal";
  }, []);

  if (hasAMessage || loading) {
    return (
      <Stack>
        {(hasNotSentMessage || loading) && <NotSent />}
        {(hasSentMessage || loading) && <Sent />}
        <MessageDrawer />
      </Stack>
    );
  }

  return null;
};

export default AllMessages;
