import { useMemo } from "react";
// components
import { nFormatter, pluralize } from "@asayinc/component-library";
// utils
import { getStepIndicatorData } from "../../../../pages/Messages/subPages/CreateAndEdit/utils";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetFilterStatsQuery,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
import { generateDateString } from "../../../../utils";
import { EditHeader } from "../../../Common";
import { IShareholderComposeMessage } from "../../../../store/messageCompose";
import { useParams } from "react-router-dom";
import { MessageStatus } from "../../../../types/Messages";

interface IProps {
  currentStep: number;
  isCreate?: boolean;
  goToStep: (step: number) => void;
  messageStatus: MessageStatus;
  id: string;
}

const Header = ({
  currentStep,
  isCreate,
  goToStep,
  messageStatus,
  id,
}: IProps) => {
  const { proxyEventId } = useParams();
  const { data, isFetching } = useGetMessageQuery(isCreate ? skipToken : id);
  const {
    data: { numShareholders } = {
      numShareholders: 0,
      sharesRepresented: 0,
    },
    isFetching: isFilterStatsFetching,
  } = useGetFilterStatsQuery(
    isFetching
      ? skipToken
      : {
          query: JSON.stringify(data?.filterMap?.query || {}),
          proxyEventId,
        }
  );

  // string to display for additional text in step indicator
  const scheduleString = useMemo(
    () =>
      generateDateString({
        scheduledSendAt: data?.scheduledSendAt,
        scheduledSendAtTimezone: data?.scheduledSendAtTimezone,
      }),
    [data?.scheduledSendAt, data?.scheduledSendAtTimezone]
  );

  // data for displaying step indicator
  const stepIndicatorData = useMemo(
    () =>
      getStepIndicatorData({
        isProxyMessage: !!proxyEventId,
        pushNotificationTemplateId: (data as IShareholderComposeMessage)
          ?.pushNotificationTemplateId,
        goToStep,
        campaignName: data?.campaignName,
        subject: data?.content?.subject,
        bodyShort: data?.content?.bodyShort,
        scheduleString,
        query: data?.filterMap?.query, // if query hasnt been set yet then user hasnt passed step 2 (index 1)
        reach: isFilterStatsFetching
          ? ""
          : `${nFormatter(numShareholders)} shareholder${pluralize(
              "",
              "s",
              numShareholders
            )}`,
      }),
    [currentStep, data, numShareholders, goToStep]
  );

  let header = "Draft message";
  if (isCreate) {
    header = "New message";
  } else if (messageStatus === MessageStatus.Scheduled) {
    header = "Scheduled message";
  } else if (messageStatus === MessageStatus.PendingApproval) {
    header = "Pending Approval";
  }

  return (
    <EditHeader
      header={header}
      stepIndicatorData={stepIndicatorData}
      currentStep={currentStep}
    />
  );
};

export default Header;
