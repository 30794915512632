export const TabOptions = {
  summary: {
    label: "Summary",
    value: "summary",
  },
  qa: {
    label: "Q&A activities",
    value: "qa",
  },
  messages: {
    label: "Messages",
    value: "messages",
  },
};
