import { useEffect, useRef, useState, FocusEvent } from "react";
import {
  DeepMap,
  FieldError,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { Draggable } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";

import { Box } from "@mui/material";
// components
import { BaseOptionChip } from "../../Atoms";
import { IQuickReplyOptionObj } from "../../../../../../../store/messageCompose";
import {
  ErrorMessage,
  HookTextField,
  Text,
  IconButton,
} from "@asayinc/component-library";
import { FORM_FIELDS } from "../../../../../../../constants";

interface IProps {
  textOption: IQuickReplyOptionObj;
  idx: number;
  removeTextOption: (idx: number) => void;
  isSomethingDragging?: boolean;
  isNew?: boolean;
  disabled?: boolean;
}

const MAX_LENGTH = 25;

const TextOption = ({
  textOption,
  removeTextOption,
  idx,
  isSomethingDragging,
  isNew,
  disabled,
}: IProps) => {
  const [focus, setFocus] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);
  const spanRef = useRef<HTMLSpanElement>(null);
  const onDeleteClick = () => {
    removeTextOption(idx);
  };

  const fieldName = `${FORM_FIELDS.quickReplies.textData.options}.${idx}.value`;

  const {
    formState: { errors },
  } = useFormContext();

  const hasError = !!(errors as DeepMap<FieldValues, FieldError>)?.quickReplies
    ?.textData?.options?.[idx];

  useEffect(() => {
    setInputWidth(spanRef?.current?.offsetWidth || 0);
  }, [textOption.value]);

  /**
   * delete empty inputs on blur
   */
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      onDeleteClick();
    } else {
      setFocus(false);
    }
  };

  /**
   * set Focus for styling
   */
  const onFocus = () => {
    setFocus(true);
  };

  const charCount = textOption.value.length;
  const charCountCategory = charCount >= MAX_LENGTH ? "error" : "primary";

  return (
    <Draggable
      index={idx}
      draggableId={textOption.id}
      isDragDisabled={disabled}
    >
      {(provided, snapshot) => (
        <Box
          {...provided.draggableProps}
          ref={provided.innerRef}
          sx={{
            mr: 3,
            ":last-child": {
              mr: 0,
            },
          }}
        >
          <Box
            component="span"
            visibility="hidden"
            position="absolute"
            fontSize="16px"
            sx={{ pointerEvents: "none" }}
            ref={spanRef}
          >
            {textOption.value}
          </Box>
          <BaseOptionChip
            sx={{
              px: 3,
              justifyContent: "space-around",
            }}
            error={hasError}
            focused={focus}
            isDragging={snapshot.isDragging}
          >
            <Box
              {...provided.dragHandleProps}
              data-testid={`scrubber-${textOption.id}`}
              mr={2}
            >
              <DragIndicatorIcon
                style={{ color: "black", display: "block" }}
                fontSize="small"
              />
            </Box>
            <HookTextField
              inputSize="small"
              name={fieldName}
              data-testid="input-textReply"
              showErrors={false}
              id={`reply-input-${textOption.id}`}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
              maxLength={MAX_LENGTH}
              autoFocus={isNew}
              sx={{
                fieldset: {
                  border: "none",
                },
                input: {
                  padding: "0 !important",
                  width: inputWidth + 5,
                  minWidth: 44,
                },
                pointerEvents: isSomethingDragging ? "none" : undefined,
              }}
              rules={{
                required: {
                  value: true,
                  message: "Field is required",
                },
              }}
              inputProps={{
                size: textOption.value.length || 1,
              }}
            />
            {focus && (
              <Text
                data-testid="text-textfield-charcount"
                category={charCountCategory}
                variant="body3"
                sx={{ ml: 2.5 }}
              >
                {charCount}/{MAX_LENGTH}
              </Text>
            )}
            <IconButton
              size="small"
              onClick={onDeleteClick}
              disabled={disabled}
              sx={{
                pointerEvents: isSomethingDragging ? "none" : undefined,
                ml: 1.5,
              }}
            >
              <CloseIcon data-testid={`delete-${textOption.id}`} />
            </IconButton>
          </BaseOptionChip>
          <ErrorMessage
            errors={errors}
            name={fieldName}
            sx={{
              whiteSpace: "nowrap",
              mt: "3px",
            }}
          />
        </Box>
      )}
    </Draggable>
  );
};

export default TextOption;
