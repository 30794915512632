/**
 * Wrapper around useSearchParams and useState to
 * - allow useTableSearchParams to be used without updating the URL
 * - allow two tables to be rendered at once that both use useTableSearchParams
 */

import { useState } from "react";
import {
  useSearchParams,
  SetURLSearchParams,
  NavigateOptions,
} from "react-router-dom";

export const useParams = (
  updateUrl: boolean
): [
  URLSearchParams,
  (
    | SetURLSearchParams
    | ((searchParams: URLSearchParams, navOpts?: NavigateOptions) => void)
  )
] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(new URLSearchParams());

  // must create a new object for useState to recognize there's been an update
  const updateParams = (searchParams: URLSearchParams) => {
    setParams(new URLSearchParams(searchParams));
  };

  return [
    updateUrl ? searchParams : params,
    updateUrl ? setSearchParams : updateParams,
  ];
};
