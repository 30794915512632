/**
 * generate a list of years with the range (today - from) to (today + from)
 * today = 2022
 * from = -2
 * to = 4
 * result ["2020", "2021", "2022", "2023", "2024", "2025"]
 */
export const generateYears = (from: number, to: number) => {
  const years: string[] = [];
  const thisYear = new Date().getFullYear();
  for (let i = from; i < to; i++) {
    years.push((thisYear + i).toString());
  }

  return years;
};
