import { ButtonV2, Text, IconButton } from "@asayinc/component-library";
import { Stack, StackProps, SxProps } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  title?: string;
  sx?: SxProps;
  buttonProps?: {
    url?: string;
    text: string;
    disabled?: boolean;
    onClick?: () => void;
  };
  displayCta?: boolean;
  sticky?: boolean;
  statusNode?: React.ReactNode;
  goBackProps?: {
    text: string;
    onClick: () => void;
  };
}

const stickyProps = {
  position: "sticky",
  top: 40,
} as StackProps;

const ListHeader = ({
  title,
  buttonProps,
  displayCta = true,
  sticky,
  goBackProps,
  statusNode,
  sx,
}: IProps): JSX.Element => {
  const navigate = useNavigate();
  const buttonUrl = buttonProps?.url || "";
  const onClick = useCallback(() => {
    navigate(buttonUrl);
  }, [buttonUrl]);

  return (
    <Stack mt={10} pb={6} sx={sx} {...(sticky ? stickyProps : {})}>
      {goBackProps && (
        <Stack direction="row" alignItems="center" mb={0.5}>
          <IconButton
            sx={{ height: "20px", width: "20px", mr: 2, ml: "-3.5px" }}
            onClick={goBackProps.onClick}
          >
            <ArrowBack />
          </IconButton>
          <Text variant="subtitle2">{goBackProps.text}</Text>
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={3}>
          <Text
            variant="h4"
            loadingProps={{ sx: { width: 150 } }}
            textTransform="capitalize"
          >
            {title}
          </Text>
          {statusNode}
        </Stack>
        {displayCta && buttonProps && (
          <ButtonV2
            data-testid="header-button"
            onClick={buttonProps.onClick || onClick}
            disabled={buttonProps.disabled}
          >
            {buttonProps.text}
          </ButtonV2>
        )}
      </Stack>
    </Stack>
  );
};

export default ListHeader;
