import { Stack } from "@mui/material";
import { useWatch } from "react-hook-form";
import { CardWrapper } from "../../../../../components/Common";
import { TIME_ZONE_VALUE_MAPPING } from "../../../../../constants/timezones";
import { abbreviateTimeZone } from "../../../helpers";
import { EventDateAndTime } from "../../Molecules";
import { OpenCloseDateForm, PublishDateForm } from "../../Organisms";

interface IProps {
  isLoading?: boolean;
  isDraft?: boolean;
}

const BasicInfoTemplate = ({ isLoading, isDraft }: IProps): JSX.Element => {
  // get timezone object and abbreviation
  const timezone = useWatch({
    name: "timezone",
  });
  const timezoneObj = TIME_ZONE_VALUE_MAPPING[timezone?.id];
  const timezoneId = timezoneObj?.utc?.[0] || "America/New_York";
  const timezoneAbbr = abbreviateTimeZone(timezoneId);

  return (
    <Stack spacing={8}>
      <CardWrapper cardName="eventDate">
        <Stack spacing={6}>
          <EventDateAndTime isDisabled={isLoading} isDraft={isDraft} />
        </Stack>
      </CardWrapper>
      <CardWrapper cardName="openCloseDate">
        <Stack spacing={6}>
          <OpenCloseDateForm
            isLoading={isLoading}
            isDraft={isDraft}
            timezoneAbbr={timezoneAbbr}
            timezoneId={timezoneId}
          />
        </Stack>
      </CardWrapper>
      <CardWrapper cardName="publishDate">
        <Stack spacing={6}>
          <PublishDateForm
            isLoading={isLoading}
            timezoneId={timezoneId}
            timezoneAbbr={timezoneAbbr}
          />
        </Stack>
      </CardWrapper>
    </Stack>
  );
};

export default BasicInfoTemplate;
