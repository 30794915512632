import { Params } from "../../types/Common";

/**
 * Get no results messaging
 */
export const getNoResultsProps = (
  params: Params
): { title: string; subtitle: string } => {
  const hasFilterApplied = params.search || params.status;
  return {
    title: hasFilterApplied ? "No Q&As found" : "There are no Q&As yet",
    subtitle: hasFilterApplied
      ? "There are no Q&As found with this criteria. Try changing the criteria"
      : "Please set up your company profile",
  };
};
