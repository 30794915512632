import {
  COLORS,
  ButtonV2,
  Text,
  useScreenSize,
} from "@asayinc/component-library";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import { QuickReplyTypeOptions } from "../../../../../../../store/messageCompose";
import { EmojiResponsePreview, TextResponsePreview } from "../../Atoms";

interface IProps {
  type: QuickReplyTypeOptions;
  onClick: (type: QuickReplyTypeOptions) => void;
  disabled?: boolean;
}

const QuickReplyType = ({ type, onClick, disabled }: IProps) => {
  const { isMedium } = useScreenSize();
  const title = useMemo(
    () => (type === "emoji" ? "emoticon reactions" : "text responses"),
    []
  );
  const description = `Gives your shareholders a way to respond with customizable ${title}`;

  const handleClick = useCallback(() => {
    onClick(type);
  }, []);

  const responsePreview = useMemo(
    () =>
      type === "emoji" ? <EmojiResponsePreview /> : <TextResponsePreview />,
    []
  );

  return (
    <Stack
      direction={["column", "column", "row"]}
      justifyContent="space-between"
      width="100%"
    >
      <Stack mr={12}>
        <Text
          variant="subtitle1"
          mb={2}
          sx={{
            ":first-letter": {
              textTransform: "capitalize",
            },
          }}
        >
          {title}
        </Text>
        <Text variant="body2" mb={[8, 8, 22]}>
          {description}
        </Text>
        {isMedium && (
          <ButtonV2
            onClick={handleClick}
            data-testid={`btn-quickreply-${type}`}
            disabled={disabled}
            sx={{ marginTop: "auto", alignSelf: "start" }}
          >
            Add
          </ButtonV2>
        )}
      </Stack>
      <Stack justifyContent="center" mt={["auto", "auto", 0]} mb={[8, 8, 0]}>
        <Stack
          alignItems="center"
          width={["100%", "100%", 211]}
          border={`1px solid ${COLORS.P_OUTLINE}`}
          justifyContent="center"
          height={234}
          borderRadius={4}
        >
          <Stack direction="row" alignItems="center" mb={6}>
            <ChatBubbleIcon
              sx={{
                fontSize: 16,
                mr: 3,
              }}
            />
            <Text variant="subtitle2">Your prompt</Text>
          </Stack>
          {responsePreview}
        </Stack>
      </Stack>
      {!isMedium && (
        <ButtonV2
          onClick={handleClick}
          data-testid={`btn-quickreply-ipad-${type}`}
          disabled={disabled}
          sx={{ marginTop: "auto", alignSelf: "start" }}
        >
          Add
        </ButtonV2>
      )}
    </Stack>
  );
};
// 6
// 16

export default QuickReplyType;
