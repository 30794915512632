export const initialState = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  ids: [],
};

export const initialAggState = {
  numShares: null,
  numVotes: null,
};
