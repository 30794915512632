import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// config helpers
import { useAppSelector } from "../../../store/hooks";
// store
import { useGetLeadersQuery } from "../../../store/companyLeadership";
import { useGetSettingsQuery } from "../../../store/settings";
import { selectStatus } from "../../../store/common";
import {
  emptyInitialState,
  initialState as eventInitialState,
  useGetEventQuery,
} from "../../../store/event";
// types
import { ICompanyLeader, IDraftEvent } from "../../../types/Events";
import { ISettings } from "../../../store/settings/types";

interface CreateQAPageData {
  event: IDraftEvent;
  isSuccess: boolean;
  companyName: string;
  allCompanyLeaders: ICompanyLeader[];
  isLoading: boolean;
  leadersQueryIsLoading: boolean;
}

/**
 * set all store fields needed for Create QA event page
 */
export const useCreateQAData = (isCreate?: boolean): CreateQAPageData => {
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };
  // which initialState to use
  const initialState = eventInitialState;
  // create, event and draft event data
  const createResponse = {
    data: emptyInitialState,
    isSuccess: false,
    isFetching: false,
  };
  const eventData = useGetEventQuery(isCreate ? skipToken : eventSlug);
  // which event data response to use
  const eventResponse = isCreate ? createResponse : eventData;

  // get event data
  const { isFetching, isSuccess, data: event = initialState } = eventResponse;
  // other data needed
  const commonStatus = useAppSelector(selectStatus);
  const {
    data: settings = {},
    isLoading: settingsQueryIsLoading,
    isSuccess: settingsQueryisSuccess,
  } = useGetSettingsQuery();
  const { name: companyName = "" } = settings as ISettings;
  const {
    data: allCompanyLeaders = [],
    isLoading: leadersQueryIsLoading,
    isSuccess: leadersQueryisSuccess,
  } = useGetLeadersQuery();

  return {
    event,
    isSuccess: isSuccess && leadersQueryisSuccess && settingsQueryisSuccess,
    companyName,
    leadersQueryIsLoading,
    allCompanyLeaders,
    isLoading:
      isFetching ||
      leadersQueryIsLoading ||
      settingsQueryIsLoading ||
      commonStatus === "loading",
  };
};
