import { IMessageOverviewAPIResponse } from "./types";

export const initialState: IMessageOverviewAPIResponse = {
  campaignName: "",
  containsLinks: false,
  containsReplies: false,
  stats: {
    count: 0,
    openedCount: 0,
    openedShares: 0,
    clickedCount: 0,
    deliveredCount: 0,
    deliveredShares: 0,
    repliedCount: 0,
    repliedShares: 0,
    openedRate: 0,
    clickedRate: 0,
    clickedShares: 0,
    deliveredRate: 0,
    repliedRate: 0,
    votedCount: 0,
    votedShares: 0,
    votedRate: 0,
    gainedPct: 0,
  },
};
