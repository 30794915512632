import { Event } from "./types";

const track = ({ name, ...rest }: Event): void => {
  window.analytics.track(name, {
    product: "Issuer Portal",
    ...rest,
  });
};

export { track };
