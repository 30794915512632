import { skipToken } from "@reduxjs/toolkit/dist/query";
import { List, ListItem, Stack } from "@mui/material";
import { Text, HookTextField } from "@asayinc/component-library";
import { CardWrapper } from "../../../Common";
// rtk/redux
import {
  MessageType,
  useGetMessagesQuery,
} from "../../../../store/messageLists";
import { useGetTemplateDataQuery } from "../../../../store/messageCompose";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { SENT_TYPES } from "../../../../store/messageLists/constants";

interface IProps {
  isLoading?: boolean;
  type: MessageType;
  proxyEventId?: string;
  templateId?: string | null;
}

const NameCampaign = ({
  isLoading,
  type,
  proxyEventId,
  templateId,
}: IProps) => {
  const { data: templateData, isError: isTemplateDataError } =
    useGetTemplateDataQuery(templateId ? templateId : skipToken);
  const { data, isError: isMessagesError } = useGetMessagesQuery(
    templateId
      ? skipToken
      : {
          limit: "5",
          offset: 0,
          search: "",
          messageStatus: SENT_TYPES,
          messageType: type,
          proxyEventId,
        }
  );
  const recentCampaigns = data?.results || [];
  const recentCampaignNames = recentCampaigns.map(
    ({ campaignName }) => campaignName
  );
  const templateCampaigns = templateData?.suggestions?.campaignName || [];
  const campaignNames = templateId ? templateCampaigns : recentCampaignNames;

  // snackbar for if any apis fail
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please refresh and try again.",
    isError: isMessagesError || isTemplateDataError,
  });

  const charLimit = 255;

  return (
    <CardWrapper full cardName="campaignName">
      <Stack
        direction={["column", "row"]}
        alignItems="center"
        justifyContent="center"
        flexWrap={["wrap", "nowrap"]}
        height="100%"
        minHeight="292px"
      >
        <Stack width={["100%", "50%"]} px={10} mb={[12, 0]}>
          <HookTextField
            name="campaignName"
            placeholder="Give this campaign an internal name"
            id="campaignName"
            disabled={isLoading}
            variant="outlined"
            fullWidth
            showCharChount
            maxLength={charLimit}
            rules={{
              required: "A campaign name is required.",
            }}
          />
        </Stack>
        {campaignNames.length > 0 && (
          <Stack width={["100%", "50%"]} px={10}>
            <Text variant="body2" emphasis="medium" mb={3}>
              {templateId ? "Example names:" : "Recent names:"}
            </Text>
            <List
              sx={{
                listStyleType: "disc",
                py: 0,
                pl: 5,
              }}
            >
              {campaignNames.map((campaignName) => (
                <ListItem
                  key={campaignName}
                  sx={{
                    display: "list-item",
                    py: 0,
                    pl: 0,
                  }}
                >
                  <Text variant="body2">{campaignName}</Text>
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
      </Stack>
    </CardWrapper>
  );
};

export default NameCampaign;
