import React from "react";
import { Card, Text, IconButton } from "@asayinc/component-library";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Stack } from "@mui/material";
import {
  MessageExportTypes,
  useExportMessageDataMutation,
} from "../../../../../../../store/messageStats";
import { useMessageParams } from "../../../../../../../hooks";
import { track } from "../../../../../../../analytics";

interface IProps {
  title: string;
  exportType: MessageExportTypes;
  isLoading: boolean;
  children: React.ReactNode;
  campaignName: string;
  companyName: string;
  asDrawer?: boolean;
}

const ReportingSection = ({
  asDrawer,
  title,
  exportType,
  isLoading,
  children,
  campaignName,
  companyName,
}: IProps) => {
  const { messageId } = useMessageParams(asDrawer);
  const [getExportedData, { isLoading: isExporting }] =
    useExportMessageDataMutation();

  // export csv for given reporting section
  const exportData = () => {
    track({
      name: "List Downloaded",
      client: companyName,
      campaign: campaignName,
      campaignStatus: "sent",
      reportComponent: exportType,
    });
    getExportedData({ exportType, messageId });
  };

  return (
    <Card
      hasHoverBg={false}
      sx={{
        p: 8,
      }}
      elevation={5}
    >
      <Stack data-testid="message-summary">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="subtitle2" loadingProps={{ sx: { width: "320px" } }}>
            {isLoading ? undefined : title}
          </Text>
          {isLoading ? null : (
            <IconButton
              data-testid="reporting-download-button"
              onClick={exportData}
              disabled={isExporting}
            >
              <SaveAltIcon />
            </IconButton>
          )}
        </Stack>
        {children}
      </Stack>
    </Card>
  );
};

export default ReportingSection;
