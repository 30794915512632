import { sanitize } from "dompurify";
import React, { CSSProperties } from "react";
import { EMOJI_FONT_FAMILY } from "../../../../constants";

interface IProps {
  emoji: string;
  styles?: CSSProperties;
  className?: string;
}

const Emoji = ({ emoji, styles, className }: IProps) => {
  return (
    <span
      className={className}
      data-testid="emoji-icon"
      style={{
        fontSize: "24px",
        fontFamily: EMOJI_FONT_FAMILY,
        color: "black",
        ...styles,
      }}
      dangerouslySetInnerHTML={{ __html: sanitize(emoji) }}
    />
  );
};

export default React.memo(Emoji);
