import { useGetLeadersQuery } from "../../../store/companyLeadership";
import {
  useGetSettingsQuery,
  useGetDisclaimerQuery,
  useGetFeaturesQuery,
  INIT_FEATURES_STATE,
} from "../../../store/settings";
import { useAppSelector } from "../../../store/hooks";
import { IFeatures, ISettings } from "../../../store/settings/types";
import { ICompanyLeader } from "../../../types/Events";
import { selectStatus } from "../../../store/common";

interface ISettingsPageData {
  settings: ISettings | undefined;
  allCompanyLeaders: ICompanyLeader[];
  disclaimer: string;
  commonStatus: string;
  isSuccess: boolean;
  isApiFetching: boolean;
  isFeaturesLoading: boolean;
  isImageLeaderLoading: boolean;
  isLeaderSuccess: boolean;
  isSettingsLoading: boolean;
  features: IFeatures;
}

export const useSettingsData = (): ISettingsPageData => {
  const commonStatus = useAppSelector(selectStatus);
  const {
    data: allCompanyLeaders = [],
    isFetching: leadersQueryIsFetching,
    isSuccess: isLeaderSuccess,
  } = useGetLeadersQuery();
  const {
    data: settings,
    isLoading: isSettingsLoading,
    isSuccess: settingsQueryIsSuccess,
    isFetching: settingsQueryIsFetching,
  } = useGetSettingsQuery();
  const {
    data: disclaimerData = { disclaimer: "" },
    isSuccess: isDisclaimerSuccess,
    isFetching: isDisclaimerFetching,
  } = useGetDisclaimerQuery();

  const {
    data: features = INIT_FEATURES_STATE,
    isLoading: isFeaturesLoading,
    isSuccess: isFeaturesSuccess,
    isFetching: isFeaturesFetching,
  } = useGetFeaturesQuery();

  // disclaimer and settings data are updated via the pages save fn, these items need to be trackked
  // seperately from Image and Leadership changes so that changes to image and leader dont effect form save state
  const isSuccess =
    settingsQueryIsSuccess && isDisclaimerSuccess && isFeaturesSuccess;
  const isApiFetching =
    isFeaturesFetching ||
    settingsQueryIsFetching ||
    isDisclaimerFetching ||
    leadersQueryIsFetching;
  const isImageLeaderLoading =
    commonStatus === "loading" || leadersQueryIsFetching;

  return {
    settings,
    allCompanyLeaders,
    disclaimer: disclaimerData.disclaimer,
    commonStatus,
    features,
    isSettingsLoading,
    isSuccess,
    isApiFetching,
    isImageLeaderLoading,
    isFeaturesLoading,
    isLeaderSuccess,
  };
};
