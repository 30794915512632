import { createBrowserRouter } from "react-router-dom";

import { GlobalLayout } from "./components/Common";
import { MessagesLayout } from "./components/Messages/Layouts";

// Pages
import {
  Login,
  Shareholder,
  AllShareholders,
  Shareholders,
  AllMessages,
  CreateAndEditMessage,
  AllEvents,
  EventDetail,
  Question,
  HomePage,
  ForgotPassword,
  ResetPassword,
  CreateAndEditEvent,
  Settings,
  Messages,
  ShareholderMessage,
  ProxyMessage,
  MessageOverview,
  MessageReport,
  MessageRecipients,
  ProxyMessageOverview,
  ProxyMessageRecipients,
  ProxyVotingLayout,
  AllProxyVoting,
  ProxyVote,
  ProxyVoteDetails,
  ProxyVoteAllMessages,
  ProxyCreateAndEdit,
  FourOFour,
} from "./pages";
// App
import App from "./App";

export const routerObj = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "*",
        element: <FourOFour />,
      },
      {
        path: "/",
        element: <GlobalLayout />,
        children: [
          {
            path: "/",
            element: <HomePage />,
          },
          {
            path: "/login",
            element: <Login />,
          },
          {
            path: "/qa/:eventSlug/questions/:questionId/*",
            element: <Question />,
          },
          {
            path: "/qa/:eventSlug/*",
            element: <EventDetail />,
          },
          {
            path: "/qa/:eventSlug/edit",
            element: <CreateAndEditEvent />,
          },
          {
            path: "/qa/create",
            element: <CreateAndEditEvent isCreate />,
          },
          {
            path: "/qa",
            element: <AllEvents />,
          },
          {
            path: "/shareholders",
            element: <Shareholders />,
            children: [
              {
                path: "/shareholders/",
                element: <AllShareholders />,
              },
              {
                path: "/shareholders/:shareholderId",
                element: <Shareholder />,
              },
            ],
          },
          {
            path: "/settings",
            element: <Settings />,
          },
          {
            path: "/messages",
            element: <MessagesLayout />,
            children: [
              {
                path: "/messages/",
                element: <Messages />,
                children: [{ path: "/messages/", element: <AllMessages /> }],
              },
              {
                path: "/messages/create",
                element: <CreateAndEditMessage isCreate />,
              },
              {
                path: "/messages/:messageId/edit",
                element: <CreateAndEditMessage />,
              },
            ],
          },
          {
            path: "/messages/:messageId",
            element: <ShareholderMessage />,
            children: [
              {
                path: "/messages/:messageId/overview",
                element: <MessageOverview />,
              },
              {
                path: "/messages/:messageId/report",
                element: <MessageReport />,
              },
              {
                path: "/messages/:messageId/recipients",
                element: <MessageRecipients />,
              },
            ],
          },
          {
            path: "/vote/:proxyEventId/create/",
            element: <MessagesLayout />,
            children: [
              {
                path: "/vote/:proxyEventId/create/",
                element: <ProxyCreateAndEdit isCreate />,
              },
            ],
          },
          {
            path: "/vote/:proxyEventId/messages/:messageId/edit",
            element: <MessagesLayout />,
            children: [
              {
                path: "/vote/:proxyEventId/messages/:messageId/edit",
                element: <ProxyCreateAndEdit />,
              },
            ],
          },
          {
            path: "/vote/:proxyEventId/messages/:messageId",
            element: <ProxyMessage />,
            children: [
              {
                path: "/vote/:proxyEventId/messages/:messageId/overview",
                element: <ProxyMessageOverview />,
              },
              {
                path: "/vote/:proxyEventId/messages/:messageId/recipients",
                element: <ProxyMessageRecipients />,
              },
            ],
          },
          {
            path: "/vote",
            element: <ProxyVotingLayout />,
            children: [
              {
                path: "/vote/",
                element: <AllProxyVoting />,
              },
              {
                path: "/vote/:proxyEventId",
                element: <ProxyVote />,
                children: [
                  {
                    path: "/vote/:proxyEventId/overview",
                    element: <ProxyVoteDetails />,
                  },
                  {
                    path: "/vote/:proxyEventId/messages",
                    element: <ProxyVoteAllMessages />,
                  },
                ],
              },
            ],
          },
          {
            path: "/forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "/reset-password",
            element: <ResetPassword />,
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routerObj);
