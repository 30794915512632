import { Stack } from "@mui/material";
import {
  ProposalBreakDown,
  ProxyTimeline,
  ProxyDetailGraphCard,
} from "./components";

const ProxyVoteDetails = () => {
  return (
    <Stack gap={8} minWidth={1128} pr={10} width="calc(100% + 40px)">
      <ProxyDetailGraphCard />
      <Stack direction="row" gap={8} alignItems="flex-start">
        <ProposalBreakDown />
        <ProxyTimeline />
      </Stack>
    </Stack>
  );
};

export default ProxyVoteDetails;
