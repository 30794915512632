import { COLORS } from "@asayinc/component-library";
import { TipsAndUpdates } from "@mui/icons-material";
import CollapsingAlert from "../CollapsingAlert";

const actions = [
  {
    label: "Open guidelines",
    onClick: () =>
      window.open(
        "http://saytechnologies.com/shareholder-messages-content-guidelines",
        "newwindow",
        "width=800,height=600"
      ),
  },
];

const icon = <TipsAndUpdates style={{ color: COLORS.BRIGHT_GREEN }} />;

const GuidelinesSnackbar = ({ showSnackbar }: { showSnackbar: boolean }) => (
  <CollapsingAlert
    showAlert={showSnackbar}
    actions={actions}
    icon={icon}
    message="Before you can send your first message, you'll need to confirm that you're happy to comply with these guidelines."
    collapseMargin={8}
    sx={{
      width: "100%",
      px: "32px !important",
    }}
  />
);

export default GuidelinesSnackbar;
