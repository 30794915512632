import { FieldNamesMarkedBoolean } from "react-hook-form";
import { ISettings } from "../../../../store/settings/types";
import { getDirtyFormValues } from "../../../../utils";
import { ISettingsFormFields } from "../../types";

const removeEmpty = (obj: { [key: string]: string | undefined }) =>
  Object.keys(obj).forEach((key) =>
    obj[key] === undefined ? delete obj[key] : {}
  );

/**
 * parse settings form fields to send to api, only send dirty fields
 */
export const parseFormData = (
  formData: ISettingsFormFields,
  dirtyFields: FieldNamesMarkedBoolean<ISettingsFormFields>
): Partial<ISettings> => {
  const dirtyValues = getDirtyFormValues(
    formData,
    dirtyFields
  ) as ISettingsFormFields;
  const valuesToUpdate = {
    logo: dirtyValues.logoFilename || undefined,
    darkModeLogo: dirtyValues.darkModeLogoFilename || undefined,
    qaEventPreviewImage: dirtyValues.qaEventPreviewImageFilename || undefined,
    name: dirtyValues.companyName || undefined,
    primaryColor: dirtyValues.primaryColor || undefined,
    secondaryColor: dirtyValues.secondaryColor || undefined,
    twitterHandle: dirtyValues.twitterHandle || undefined,
    investorRelationsUrl: dirtyValues.investorRelationsUrl || undefined,
  };
  removeEmpty(valuesToUpdate);
  return valuesToUpdate;
};
