import { EventTypeOptions } from "../../../types/Events";
import { IFormFields } from "../types";

/**
 * Create events Title based on the combination of eventType the eventTypes
 * subfields
 */
export const generateEventTitle = (
  companyName: string,
  { eventType, meta }: IFormFields
) => {
  const {
    fiscalQuarter,
    fiscalYear,
    investorDayName,
    productLaunchName,
    cadence,
    webinarName,
  } = meta || {};
  switch (eventType) {
    case EventTypeOptions.EARNINGS_CALL:
      if (!fiscalQuarter || !fiscalYear) return undefined;
      return `${companyName} ${fiscalQuarter} ${fiscalYear} Earnings Q&A`;
    case EventTypeOptions.SHAREHOLDER_MEETING:
      if (!cadence || !fiscalYear) return undefined;
      return `${companyName} ${fiscalYear} ${
        cadence.charAt(0).toUpperCase() + cadence.slice(1)
      } Meeting Q&A`;
    case EventTypeOptions.INVESTOR_DAY:
      if (!investorDayName) return undefined;
      return `${companyName} ${investorDayName} Q&A`;
    case EventTypeOptions.PRODUCT_LAUNCH:
      if (!productLaunchName) return undefined;
      return `${companyName} ${productLaunchName} Q&A`;
    case EventTypeOptions.WEBINAR:
      if (!webinarName) return undefined;
      return `${companyName} ${webinarName} Q&A`;
    default:
      return undefined;
  }
};

/**
 * Create events short title based on the combination of eventType the eventTypes
 * subfields
 */
export const generateEventShortTitle = ({ eventType, meta }: IFormFields) => {
  const {
    fiscalQuarter,
    fiscalYear,
    investorDayName,
    productLaunchName,
    cadence,
    webinarName,
  } = meta || {};
  switch (eventType) {
    case EventTypeOptions.EARNINGS_CALL:
      if (!fiscalQuarter || !fiscalYear) return undefined;
      return `${fiscalQuarter} ${fiscalYear} Earnings`;
    case EventTypeOptions.SHAREHOLDER_MEETING:
      if (!cadence || !fiscalYear) return undefined;
      return `${fiscalYear} ${
        cadence.charAt(0).toUpperCase() + cadence.slice(1)
      } Meeting`;
    case EventTypeOptions.INVESTOR_DAY:
      if (!investorDayName) return undefined;
      return `${investorDayName}`;
    case EventTypeOptions.PRODUCT_LAUNCH:
      if (!productLaunchName) return undefined;
      return `${productLaunchName}`;
    case EventTypeOptions.WEBINAR:
      if (!webinarName) return undefined;
      return `${webinarName}`;
    default:
      return undefined;
  }
};

// convert spaces to -, and lowercase string
const toLowercaseHyphened = (str: string) =>
  str.replace(/\s+/g, "-").toLowerCase();

// remove everything except letters numbers and spaces
export const removeSpecialChars = (str: string) =>
  str.replace(/[^a-zA-Z0-9 ]/g, "");

/**
 * generate the events slug based on eventType and its subfields
 */
export const generateEventSlug = (
  companyName: string,
  { eventType, meta }: IFormFields
) => {
  const {
    fiscalQuarter,
    fiscalYear,
    investorDayName,
    productLaunchName,
    cadence,
    webinarName,
  } = meta || {};
  const slugCompany = toLowercaseHyphened(removeSpecialChars(companyName));
  switch (eventType) {
    case EventTypeOptions.EARNINGS_CALL:
      if (!fiscalQuarter || !fiscalYear) return undefined;
      return `${slugCompany}-${fiscalYear}-${fiscalQuarter.toLowerCase()}`;
    case EventTypeOptions.SHAREHOLDER_MEETING:
      if (!cadence || !fiscalYear) return undefined;
      return `${slugCompany}-${fiscalYear}-${cadence}`;
    case EventTypeOptions.INVESTOR_DAY:
      if (!investorDayName) return undefined;
      return `${slugCompany}-${toLowercaseHyphened(
        removeSpecialChars(investorDayName)
      )}`;
    case EventTypeOptions.PRODUCT_LAUNCH:
      if (!productLaunchName) return undefined;
      return `${slugCompany}-${toLowercaseHyphened(
        removeSpecialChars(productLaunchName)
      )}`;
    case EventTypeOptions.WEBINAR:
      if (!webinarName) return undefined;
      return `${slugCompany}-${toLowercaseHyphened(
        removeSpecialChars(webinarName)
      )}`;
    default:
      return undefined;
  }
};
