import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EmailPreview } from "../../Molecules/PreviewCard/components";
import {
  ButtonV2 as Button,
  COLORS,
  Text,
  IconButton,
  useIsMounted,
} from "@asayinc/component-library";
import { CardWrapper } from "../../../Common";
import { useAppDispatch } from "../../../../store/hooks";
import {
  IMessageTemplate,
  setTemplateId,
} from "../../../../store/messageCompose";

interface IProps {
  template: IMessageTemplate;
  closeDialog: () => void;
  goBack?: () => void;
  standalone?: boolean;
}

const EmailPreviewDialogContent = ({
  template: { htmlPreview, name: title, description, id, subject },
  closeDialog,
  standalone,
  goBack,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [emailHtml, setEmailHtml] = useState("");
  const isMounted = useIsMounted();
  useEffect(() => {
    const getHtml = async () => {
      if (!htmlPreview) {
        return;
      }
      const res = await fetch(htmlPreview as string).then((res) => res.text());
      if (isMounted()) {
        setEmailHtml(res);
      }
    };

    getHtml();
  }, [htmlPreview]);
  const isLoading = !emailHtml;

  const createFromTemplate = () => {
    dispatch(setTemplateId(id));
    navigate("/messages/create");
  };

  return (
    <Stack alignItems="center">
      <Stack
        height="106px"
        width="100%"
        bgcolor={COLORS.WHITE}
        top={0}
        left={0}
        pl={8}
        pt={standalone ? 8 : 14}
      >
        <Text variant="h5">{title}</Text>
        {standalone && <Text mt={2}>{description}</Text>}
        <IconButton
          aria-label="close"
          sx={{
            right: (theme) => theme.spacing(4),
            top: (theme) => theme.spacing(4),
            position: "absolute",
          }}
          data-testid="dialog-close"
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        {!standalone && (
          <IconButton
            aria-label="close"
            sx={{
              left: (theme) => theme.spacing(6),
              top: (theme) => theme.spacing(4),
              position: "absolute",
            }}
            data-testid="dialog-arrow-back"
            onClick={goBack}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
      </Stack>
      <CardWrapper
        sx={{ my: 12, width: "100%", maxWidth: "849px" }}
        cardName="template-preview-html"
      >
        <EmailPreview
          isLoading={isLoading}
          subject={subject}
          emailHtml={emailHtml}
        />
      </CardWrapper>
      <Stack
        height="96px"
        position="sticky"
        width="100%"
        bottom={0}
        left={0}
        justifyContent="center"
        alignItems="center"
        bgcolor={COLORS.WHITE}
      >
        <Button onClick={createFromTemplate}>Use template</Button>
      </Stack>
    </Stack>
  );
};

export default EmailPreviewDialogContent;
