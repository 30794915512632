import { useContext } from "react";
import { Box, Stack } from "@mui/material";
import { Text, nFormatter, pluralize } from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../../../../components/Common";
import { Context } from "../../../../../../context";

const headlineLoadingProps = { sx: { width: "100px" } };
const subheadlineLoadingProps = { sx: { width: "200px" } };

const BasicInfo = () => {
  const { data, isLoading } = useContext(Context);
  return (
    <CardWrapper cardName="shareholder-basic-info">
      <Stack direction="row">
        <Box width="50%">
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Owned"}
          </Text>
          <Text variant="h5" loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : (
              <>
                {nFormatter(data?.sharesOwned)}{" "}
                {pluralize("share", "shares", data?.sharesOwned)}
              </>
            )}
          </Text>
        </Box>
        {data?.location ? (
          <Box width="50%">
            <Text
              variant="subtitle2"
              mb={3}
              loadingProps={headlineLoadingProps}
            >
              {isLoading ? undefined : "Location"}
            </Text>
            <Text variant="h5" loadingProps={subheadlineLoadingProps}>
              {isLoading ? undefined : data.location}
            </Text>
          </Box>
        ) : null}
      </Stack>
    </CardWrapper>
  );
};

export default BasicInfo;
