import { COLORS } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { Emoji } from "../../../../../../Common";
import { Emojis } from "../../../../../../../constants";

const emojiContainerStyles = {
  border: `1px solid ${COLORS.P_OUTLINE}`,
  borderRadius: "100px",
  width: "48px",
  height: "48px",
  justifyContent: "center",
  alignItems: "center",
};

const EmojiResponsePreview = () => {
  return (
    <Box>
      <Stack direction="row" gap={6} mb={6}>
        <Stack sx={emojiContainerStyles}>
          <Emoji emoji={Emojis.ThumbsUp} />
        </Stack>
        <Stack sx={emojiContainerStyles}>
          <Emoji emoji={Emojis.ThumbsDown} />
        </Stack>
      </Stack>
      <Stack direction="row" gap={6}>
        <Stack sx={emojiContainerStyles}>
          <Emoji emoji={Emojis.GrinningFace} />
        </Stack>
        <Stack sx={emojiContainerStyles}>
          <Emoji emoji={Emojis.CryingFace} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default EmojiResponsePreview;
