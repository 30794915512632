import { useContext, useState } from "react";
import { Stack } from "@mui/material";
import { ActionablePopover } from "@asayinc/component-library";
import { useParams } from "react-router-dom";
// API
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  initialState,
  IShareholderComposeMessage,
  useGetFilterStatsQuery,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
import { useGetSettingsQuery } from "../../../../store/settings";
// Components
import {
  CampaignNameCard,
  ScheduledAlertBar,
  BrandAssetsDialog,
  DisclaimerDialog,
} from "../../../../components/Messages";
import { PreviewCard, RecipientsCard } from "../../Molecules";
import { IncompleteProfileCallout } from "../../../Common";
// types
import { MessageStatus, MessageStep } from "../../../../types/Messages";
import { ISettings } from "../../../../store/settings/types";
import { track } from "../../../../analytics";
import { Context } from "../../../../context/Messages";

enum PageDialogs {
  BrandAssets = "brandAssets",
  Disclaimer = "disclaimer",
}

interface IProps {
  stepMapping: string[];
}

const ReviewMessage = ({ stepMapping }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [dialog, setDialog] = useState<PageDialogs | null>(null);
  // pull company name
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName = "" } = settings as ISettings;
  const { messageId, proxyEventId } = useParams();
  const { data = initialState, isLoading } = useGetMessageQuery(
    messageId as string
  );
  const {
    data: { numShareholders, sharesRepresented } = {
      numShareholders: 0,
      sharesRepresented: 0,
    },
  } = useGetFilterStatsQuery(
    isLoading
      ? skipToken
      : { query: JSON.stringify(data.filterMap?.query || {}), proxyEventId }
  );
  const { campaignName, htmlPreview, status, content } =
    data as IShareholderComposeMessage;

  const { setStep } = useContext(Context);

  // analytics in all reviewEdit events
  const baseReviewEditAnalytics = {
    name: "Message Edited from Review",
    client: companyName,
    campaign: data.campaignName,
    campaignStatus: data.status,
    composeStep: MessageStep.Review,
  };

  const onEditRecipients = () => {
    track({
      ...baseReviewEditAnalytics,
      reviewEdit: "Recipients",
    });
    setStep(stepMapping.indexOf(MessageStep.ChooseRecipients));
  };

  // action when edit icon is clicked
  const onEditCampaignName = () => {
    track({
      ...baseReviewEditAnalytics,
      reviewEdit: "Campaign name",
    });
    setStep(stepMapping.indexOf(MessageStep.NameCampaign));
  };

  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      track({
        ...baseReviewEditAnalytics,
        reviewEdit: "Message Details",
      });
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editMessage = () => {
    handleClose();
    setStep(stepMapping.indexOf(MessageStep.DraftMessage));
  };

  const editInAppMessage = () => {
    handleClose();
    setStep(stepMapping.indexOf(MessageStep.DraftInAppMessage));
  };

  const openBrandAssetsDialog = () => {
    handleClose();
    setDialog(PageDialogs.BrandAssets);
  };

  const editDisclaimer = () => {
    handleClose();
    setDialog(PageDialogs.Disclaimer);
  };

  const closeDialog = () => {
    setDialog(null);
  };

  // actions for popover
  const popoverActions = [
    {
      onClick: editMessage,
      text: "Edit message",
      testid: "edit-message-btn",
    },
    {
      onClick: editInAppMessage,
      text: "Edit in-app message",
      testid: "edit-inapp-message-btn",
    },
    {
      onClick: openBrandAssetsDialog,
      text: "Edit brand assets",
      testid: "edit-brand-assets-btn",
    },
    {
      onClick: editDisclaimer,
      text: "Edit disclaimer",
      testid: "edit-disclaimer-btn",
    },
  ];

  return (
    <Stack height="100%">
      <IncompleteProfileCallout text="Your company’s brand assets are required to be submitted before sending a message." />
      {status === MessageStatus.Scheduled && <ScheduledAlertBar />}
      <Stack mt={8} gap={[8, 10]} direction={["column", "row"]} height="100%">
        <Stack flex={[undefined, "1"]} gap={[8, 10]}>
          <CampaignNameCard
            campaignName={campaignName}
            onEdit={onEditCampaignName}
          />
          <RecipientsCard
            messageType={data?.type}
            shareholderCount={numShareholders}
            sharesCount={sharesRepresented}
            query={data.filterMap?.query}
            onEdit={onEditRecipients}
            isSentMessage={false}
          />
        </Stack>
        <Stack flex="1" minHeight={[600, 0]}>
          <PreviewCard
            htmlPreview={htmlPreview}
            onEdit={toggleAnchorEl}
            subject={content?.subject}
            messageId={messageId as string}
            proxyEventId={proxyEventId}
          />
        </Stack>
      </Stack>
      <ActionablePopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        actions={popoverActions}
      />
      <BrandAssetsDialog
        open={dialog === PageDialogs.BrandAssets}
        closeDialog={closeDialog}
      />
      <DisclaimerDialog
        open={dialog === PageDialogs.Disclaimer}
        closeDialog={closeDialog}
      />
    </Stack>
  );
};

export default ReviewMessage;
