import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilterSelectionFlow,
  FilterType,
  IFilter,
} from "@asayinc/component-library";
import { useMemo, forwardRef, Ref } from "react";
import {
  useGetStateCityMappingQuery,
  useGetStateFilterOptionsQuery,
} from "../../../../store/staticJson";
import { FILTER_MESSAGING_MAP } from "../../../../constants";

interface IProps {
  updateUrl?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
}

const LocationCityFilter = (
  { updateUrl = false, isLoading, onClose }: IProps,
  ref: Ref<{ triggerPopover: () => void }> // used to simulate click event on chip from parent
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstRender, setFirstRender] = useState(true);
  const { data: stateData, isLoading: statesLoading } =
    useGetStateFilterOptionsQuery();
  const { data: cityMap, isLoading: cityMapLoading } =
    useGetStateCityMappingQuery();

  const options = useMemo(
    () =>
      [
        {
          type: FilterType.Location,
          label: "Location selection",
          options: {
            stateOptions: {
              multiple: false,
              optionType: "checkicon",
              groupLabel: "State",
              options: statesLoading || cityMapLoading ? [] : stateData,
              mapping: cityMap,
            },
            cityOptions: {
              groupLabel: "City",
              multiple: false,
              optionType: "checkicon",
            },
          },
        },
      ] as IFilter[],
    [stateData, cityMap]
  );

  const { watch, setValue } = useFormContext();
  /**
   * set formValues on page load
   */
  useEffect(() => {
    if (updateUrl) {
      const city = searchParams.get("city") || undefined;
      const state = searchParams.get("state") || undefined;

      if (state) setValue("filterMap.query.state", { values: [state] });
      if (city) setValue("filterMap.query.city", { values: [city] });
    }
  }, []);

  /**
   * update url params if updateURL is true
   */
  const city = watch("filterMap.query.city");
  const state = watch("filterMap.query.state");
  useEffect(() => {
    // prevent running on first render to avoid duplicate history states
    if (!firstRender) {
      if (updateUrl) {
        if (state?.values) searchParams.set("state", state.values[0]);
        if (city?.values) searchParams.set("city", city.values[0]);

        setSearchParams(searchParams);
      }
    } else {
      setFirstRender(false);
    }
  }, [city, state]);

  const onClear = () => {
    if (updateUrl) {
      searchParams.delete("city");
      searchParams.delete("state");
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <FilterSelectionFlow
        withinForm={{
          fieldName: "filterMap.query.state",
          fieldName2: "filterMap.query.city",
        }}
        clearable
        chipProps={{
          disabled: isLoading || statesLoading || cityMapLoading,
        }}
        onClear={onClear}
        onClose={onClose}
        ref={ref}
        testid="chip-city"
        chipLabel="Location"
        filterTypeOptions={options}
        {...FILTER_MESSAGING_MAP.location}
      />
    </>
  );
};

export default forwardRef(LocationCityFilter);
