import { Text } from "@asayinc/component-library";
import { Leader } from "../../../../../components/Common/Organisms/CompanyLeadershipEditor";
import { useGetSettingsQuery } from "../../../../../store/settings";
import { ICompanyLeader } from "../../../../../types/Events";
import { ReviewDetailsSection } from "../../Organisms";

interface IProps {
  enabledLeadership: ICompanyLeader[];
  editStep: (section: string) => void;
}

export const ReviewParticipants = ({ enabledLeadership, editStep }: IProps) => {
  const { data } = useGetSettingsQuery();
  return (
    <ReviewDetailsSection
      title="Company participants"
      cardName="companyParticipants"
      editStep={editStep}
    >
      <Text variant="body1" mt={4} mb={6}>
        These leaders from {data?.name} may address questions during this event.
      </Text>
      {enabledLeadership.map((leader, idx) => (
        <Leader key={leader.id} leader={leader} idx={idx} />
      ))}
    </ReviewDetailsSection>
  );
};

export default ReviewParticipants;
