import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// material
import { Stack } from "@mui/material";
import { OpenInFull, Close, ArrowBack } from "@mui/icons-material";
// components
import {
  Text,
  IconButton,
  nFormatter,
  pluralize,
} from "@asayinc/component-library";
import { ShareholderInitials } from "../../../../../../../components/Common";

interface IProps {
  name: string;
  sharesOwned: number;
  profileColor: string;
  isLoading: boolean;
  expand?: () => void;
  close?: () => void;
  asDrawer: boolean;
}

const ShareholderHeaderV2 = ({
  name,
  sharesOwned,
  profileColor,
  expand,
  close,
  asDrawer = false,
  isLoading,
}: IProps) => {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: { goBackText?: string } };

  /**
   * state.goBackText is being cleared by some other component. The below
   * useState + useEffect are being used to cache the correct value before
   * it's cleared.
   */
  const [goBackText, setGoBackText] = useState<string | undefined>("");
  useEffect(() => {
    setGoBackText(state?.goBackText);
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const backButtonText = goBackText || "Shareholders";

  return (
    <Stack data-testid="shareholder-header">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          {!asDrawer && (
            <IconButton
              sx={{ height: "20px", width: "20px", mr: 2, ml: "-3.5px" }}
              onClick={goBack}
            >
              <ArrowBack />
            </IconButton>
          )}
          <Text variant="subtitle2">
            {asDrawer ? "Shareholder" : backButtonText}
          </Text>
        </Stack>
        {asDrawer && (
          <Stack direction="row" gap="10px">
            <IconButton onClick={expand} data-testid="drawer-expand-button">
              <OpenInFull />
            </IconButton>
            <IconButton onClick={close} data-testid="drawer-close-button">
              <Close />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" mt={4}>
        <ShareholderInitials
          authorName={name}
          profileColor={profileColor}
          size={48}
          textVariant="subtitle2"
        />
        <Stack ml={4}>
          <Stack direction="row" mb={2}>
            <Text variant="h5" mr={2} loadingProps={{ sx: { width: "100px" } }}>
              {isLoading ? undefined : name}
            </Text>
          </Stack>
          <Text variant="body2" loadingProps={{ sx: { width: "200px" } }}>
            {isLoading
              ? undefined
              : `${nFormatter(sharesOwned)} ${pluralize(
                  "share",
                  "shares",
                  sharesOwned
                )}`}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ShareholderHeaderV2;
