import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilterSelectionFlow,
  FilterType,
  IFilter,
} from "@asayinc/component-library";
import { validateParam } from "../../../../utils";
import { URL_PARAMS } from "../../../../constants";

interface FilterOption {
  name: string;
  id: string | number;
  color?: string;
}

interface IProps {
  tagOptions: FilterOption[];
  chipLabel: string;
  fieldName: string;
  tagIdsParameter: string;
  noTagsParameter?: string;
  noTagsValue?: string;
  isLoading: boolean;
  valuesLabel: string;
  noValueLabel?: string;
  stateSearchParams?: URLSearchParams;
  stateSetSearchParams?: (params: URLSearchParams) => void;
}

/**
 * Uses FilterSelectionFlow from component library to update
 * URL search params according to param props. Requires form
 * context in parent or ancestor component.
 */

const TagFilter = ({
  stateSearchParams,
  stateSetSearchParams,
  tagOptions,
  chipLabel,
  fieldName,
  tagIdsParameter,
  noTagsParameter,
  noTagsValue,
  isLoading,
  valuesLabel,
  noValueLabel,
}: IProps) => {
  // allows for updating url or not updating url and using state params instead
  const [urlSearchParams, urlSetSearchParams] = useSearchParams();
  const searchParams = stateSearchParams || urlSearchParams;
  const setSearchParams = stateSetSearchParams || urlSetSearchParams;
  const [firstRender, setFirstRender] = useState(true);
  const methods = useFormContext();
  const { watch, setValue } = methods;
  const formValues = watch(fieldName);

  /**
   * set formValues on page load
   */
  useEffect(() => {
    const tagIdsString: string = searchParams.get(tagIdsParameter) || "";

    // use URL_PARAMS.tags validation for all tag parameters
    const validatedValue = validateParam(URL_PARAMS.tags, tagIdsString);
    // update query params if value changed during validation
    if (validatedValue !== tagIdsString) {
      searchParams.set(tagIdsParameter, validatedValue);
    }
    if (validatedValue.length > 0 && tagOptions.length > 0) {
      const tagIdsList = validatedValue.split(",");
      const pageLoadTags = tagOptions.filter((tag) =>
        tagIdsList.includes(tag.id.toString())
      );
      const formValTags = pageLoadTags.map((tag) => tag.id);
      setValue(fieldName, { values: formValTags });
    }

    if (noTagsParameter) {
      const filterString: string = searchParams.get(noTagsParameter) || "";
      if (filterString === noTagsValue) {
        setValue(fieldName, { range: { lte: 0, gte: 0 } });
      }
    }
  }, [isLoading]);

  /**
   * use rangeFormValues to update URL search params
   */
  useEffect(() => {
    // prevent running on first render to avoid duplicate history states
    if (!firstRender) {
      if (formValues) {
        const { range = {}, values = [] } = formValues;

        // if range properties exist, "Is not tagged" was selected
        if (noTagsParameter && noTagsValue) {
          if (range.gte === 0 || range.lte === 0) {
            searchParams.set(noTagsParameter, noTagsValue);
          } else {
            searchParams.delete(noTagsParameter);
          }
        }

        // if values exist, set tagIdsParameter
        if (values.length) {
          const tagParamValue = values.join(",");
          searchParams.set(tagIdsParameter, tagParamValue);
        } else {
          searchParams.delete(tagIdsParameter);
        }
      }
      setSearchParams(searchParams);
    } else {
      setFirstRender(false);
    }
  }, [formValues]);

  const filterTypeOptions = [
    {
      type: FilterType.Unselected,
      label: "Not applied",
    },
    {
      type: "values",
      label: valuesLabel,
      options: {
        groupLabel: "Tags",
        options: tagOptions,
      },
    },
    ...(noTagsParameter && noTagsParameter && noValueLabel
      ? [
          {
            type: "none",
            label: noValueLabel,
          },
        ]
      : []),
  ] as IFilter[];

  const onClear = () => {
    searchParams.delete(tagIdsParameter);
    if (noTagsParameter) {
      searchParams.delete(noTagsParameter);
    }
    setSearchParams(searchParams);
  };

  return (
    <FilterSelectionFlow
      skipMessaging
      onClear={onClear}
      withinForm={{ fieldName }}
      clearable
      chipLabel={chipLabel}
      filterTypeOptions={filterTypeOptions}
    />
  );
};

export default TagFilter;
