import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// components
import { Box, Stack } from "@mui/material";
import {
  OpenInFull,
  Close as CloseIcon,
  MoreVert as MoreVertIcon,
} from "@mui/icons-material";
import {
  Text,
  IconButton,
  ActionablePopover,
} from "@asayinc/component-library";
import { StatusBadge } from "../../Atoms";
// redux / data
import {
  useGetMessageQuery,
  useDuplicateMessageMutation,
  initialState,
  setMessageDrawer,
} from "../../../../store/messageCompose";
import { DuplicateError } from "../../../../pages/Messages/subPages/CreateAndEdit/types";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { MessageStatus } from "../../../../types/Messages";
import { isMessageSent } from "../../../../utils/messages/isMessageSent";
import { useAppDispatch } from "../../../../store/hooks";

interface IProps {
  messageId: string;
  proxyEventId?: string;
  asDrawer?: boolean;
}

const MessageHeader = ({
  messageId,
  proxyEventId,
  asDrawer,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  // its possible a duplicate already exists in which case we set this id and pull that messages data
  // to determine its type
  const [existingDuplicateId, setexistingDuplicateId] = useState("");
  const navigate = useNavigate();
  const { data: messageData = initialState, isLoading } =
    useGetMessageQuery(messageId);

  const isSent = isMessageSent(messageData.status);
  const { campaignName } = messageData;
  const [postMessage, postResult] = useDuplicateMessageMutation();
  const {
    data: existingDuplicateMessageData,
    isSuccess,
    isLoading: isDuplicateLoading,
  } = useGetMessageQuery(existingDuplicateId || skipToken);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // when duplication completes if it was successful go to the new messages created compose flow
  useEffect(() => {
    const { isLoading, isSuccess, data } = postResult;
    if (!isLoading && isSuccess) {
      const { id } = data;
      const duplicateMessageUrl = proxyEventId
        ? `/vote/${proxyEventId}/messages/${id}/edit`
        : `/messages/${id}/edit`;
      window.open(duplicateMessageUrl, "_blank");
    }
  }, [postResult]);

  // if a duplicated message id is successfully located, redirect to it
  useEffect(() => {
    if (isSuccess) {
      const duplicateMessagePath = proxyEventId
        ? `/vote/${proxyEventId}/messages/${existingDuplicateMessageData?.id}`
        : `/messages/${existingDuplicateMessageData?.id}`;
      // draft or scheduled go to edit
      if (
        existingDuplicateMessageData?.status === MessageStatus.Draft ||
        existingDuplicateMessageData?.status === MessageStatus.Scheduled
      ) {
        navigate(`${duplicateMessagePath}/edit`);
      } // any other status go to overview
      else {
        navigate(`${duplicateMessagePath}/overview`);
      }
    }
  }, [isSuccess]);

  // show success/error snackbar on mutation api callbacks
  const errorMsg = useMemo(
    () =>
      (postResult?.error as DuplicateError)?.data?.duplicate
        ? "Message has already been duplicated. Redirecting to the duplicated message."
        : "Failed to duplicate message, please try again.",
    [postResult?.error]
  );

  // if message is a duplicate pull data on it to redirect user
  const handleDuplicateMessage = () => {
    if ((postResult?.error as DuplicateError)?.data?.duplicate) {
      setexistingDuplicateId(
        (postResult?.error as DuplicateError).data.duplicate
      );
    }
  };

  // show error if fail to duplicate
  useSuccessErrorSnacks({
    errorMsg,
    isError: postResult.isError,
    errorAction: handleDuplicateMessage,
  });

  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // close popover and attempt to duplicate message
  const duplicateMessage = () => {
    handleClose();
    postMessage(messageId);
  };

  // when closing drawer
  const onCloseDrawer = () => {
    dispatch(
      setMessageDrawer({
        messageDrawerId: null,
        messageDrawerProxyEventId: null,
      })
    );
  };

  // when expanding drawer
  const expandDrawer = () => {
    if (proxyEventId) {
      navigate(`/vote/${proxyEventId}/messages/${messageId}/overview`);
    } else {
      navigate(`/messages/${messageId}/overview`);
    }
  };
  const title = proxyEventId ? "Voting message" : "Message";

  return (
    <>
      <Stack justifyContent="space-between" alignItems="center" direction="row">
        <Text variant="subtitle2">{title}</Text>
        {asDrawer && (
          <Stack direction="row">
            <IconButton
              onClick={expandDrawer}
              data-testid="drawer-expand-button"
            >
              <OpenInFull />
            </IconButton>
            <IconButton onClick={onCloseDrawer}>
              <CloseIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Box my={2}>
        <Stack direction="row" alignItems="center">
          <Text variant="h5" loadingProps={{ sx: { width: "250px" } }}>
            {campaignName || undefined}
          </Text>
          <Box ml={2}>
            {!isDuplicateLoading && !isLoading && (
              <StatusBadge messageStatus={messageData?.status} />
            )}
          </Box>
          {isSent && (
            <>
              <IconButton
                onClick={toggleAnchorEl}
                disabled={
                  isDuplicateLoading || isLoading || postResult.isLoading
                }
                sx={{ ml: "7px" }}
              >
                <MoreVertIcon />
              </IconButton>
              <ActionablePopover
                anchorEl={anchorEl}
                handleClose={handleClose}
                actions={[
                  {
                    testid: "duplicate-btn",
                    text: "Duplicate",
                    onClick: duplicateMessage,
                  },
                ]}
              />
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default MessageHeader;
