import { useCallback, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// components
import { Box } from "@mui/material";
import { TableV2 as Table } from "@asayinc/component-library";
// redux / data
import { Context } from "../../../../../../context";
import { useGetShareholderMessageActivityQuery } from "../../../../../../../../../../store/shareholder";
// utils
import { useTableSearchParams } from "../../../../../../../../../../hooks";
import { getColumnData, getRowData } from "./factories";
import { IShareholderMessagesData } from "../../../../../../../../../../types/Shareholder";
// constants
import { ROW_OPTIONS } from "../../../../../../../../../../constants";

const MessagesTable = () => {
  const {
    asDrawer,
    data,
    isLoading: isMetaDataLoading,
    isFetching: isMetaDataFetching,
  } = useContext(Context);
  const { limit, page, handlePageChange, handleRowsChange } =
    useTableSearchParams({ updateUrl: !asDrawer });

  const globalShareholderId = data?.globalShareholderId;
  const {
    data: messagesData,
    isLoading,
    isFetching,
  } = useGetShareholderMessageActivityQuery(
    globalShareholderId
      ? {
          shareholderId: globalShareholderId,
          offset: String((parseInt(page) - 1) * parseInt(limit)),
          limit,
        }
      : skipToken
  );

  const navigate = useNavigate();
  const goToCampaign = useCallback((data: unknown) => {
    const { id } = data as { id: string };
    navigate(`/messages/${id}/overview`);
  }, []);
  /**
   * Table Data
   */
  const columnData = getColumnData({ goToCampaign });

  const columns = useMemo(() => {
    const columns = ["campaignName", "sentDate"];
    if (asDrawer) {
      columns.push("engagement");
    } else {
      columns.push("opened", "clicked", "replied");
    }
    return columns;
  }, [asDrawer]);

  const messagesReceived =
    (messagesData as IShareholderMessagesData)?.results || [];
  const rows = getRowData(messagesReceived);
  const count = (messagesData as IShareholderMessagesData)?.count || 0;

  /**
   * props for pagination toolbar
   */
  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  return (
    <Box>
      <Table
        title={`${count} received`}
        isLoading={isLoading || isMetaDataLoading}
        isFetching={isFetching || isMetaDataFetching}
        columns={columns}
        columnData={columnData}
        rows={rows}
        inDrawer={asDrawer}
        count={count}
        toolbarInside
        titleVariant="subtitle2"
        tableLayout="auto"
        tableMinWidth={asDrawer ? 453 : 800}
        paginateProps={paginateProps}
        loadingRows={10}
        testid="messages-received-table"
        memoCells
      />
    </Box>
  );
};

export default MessagesTable;
