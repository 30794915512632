// Components
import { CardWrapper } from "../../../../../components/Common";
import {
  BrandAssets,
  CompanyDetails,
  CompanyLeadership,
  CompanySelector,
} from "../../Organisms";
import { useIsSuperUser } from "../../../../../hooks";

interface IProps {
  isLoading?: boolean;
}

const CompanyProfile = ({ isLoading }: IProps) => {
  const isSuperUser = useIsSuperUser();

  return (
    <>
      {isSuperUser && (
        <CardWrapper cardName="companySelector" id="company-selector">
          <CompanySelector />
        </CardWrapper>
      )}
      <CardWrapper
        cardName="companyDetails"
        className="settingsSection"
        id="company-details"
      >
        <CompanyDetails isLoading={isLoading} />
      </CardWrapper>
      <CardWrapper
        className="settingsSection"
        id="brand-assets"
        cardName="brandAssets"
      >
        <BrandAssets isLoading={isLoading} />
      </CardWrapper>
      <CardWrapper
        className="settingsSection"
        id="company-leadership"
        cardName="companyLeadership"
      >
        <CompanyLeadership isLoading={isLoading} />
      </CardWrapper>
    </>
  );
};

export default CompanyProfile;
