import { Text } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { track } from "../../../../../analytics";
import { CREATION_STEP_MAPPING } from "../../../constants";
import { useCreateQAData } from "../../../hooks";
import FAQEditor from "../FAQEditor";

interface IProps {
  isLoading?: boolean;
}

// Section of form where FAQ's are added
const EventDetailsForm = ({ isLoading }: IProps) => {
  const { companyName, event } = useCreateQAData();
  // analytics for add a faq
  const onFAQAdd = () => {
    track({
      name: "Add FAQ",
      client: companyName,
      qaEvent: event.slug,
      qaStatus: event.status,
      composeStep: CREATION_STEP_MAPPING[2],
    });
  };

  return (
    <Stack>
      <Box>
        <Text variant="subtitle1" mb={2}>
          FAQs
        </Text>
        <Text variant="body2">
          Add additional information for your shareholders in question and
          answer format. (Optional)
        </Text>
      </Box>
      <Box>
        <FAQEditor isLoading={isLoading} addCallback={onFAQAdd} />
      </Box>
    </Stack>
  );
};

export default EventDetailsForm;
