// types
import { IDraftEvent } from "../../../types/Events";
import {
  initialState as eventInitialState,
  useGetEventQuery,
} from "../../../store/event";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

/**
 * return either a draft event or submitted event based on bool provided
 */
export const useEventData = (): { event: IDraftEvent; isLoading?: boolean } => {
  const { eventSlug } = useParams();
  // which initialState to use
  const initialState = eventInitialState;
  const eventData = useGetEventQuery(eventSlug ? eventSlug : skipToken);
  // which event data to use
  const eventResponse = eventData;
  const { data: event = initialState, isLoading } = eventResponse;
  return {
    event,
    isLoading,
  };
};
