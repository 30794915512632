import { ICompanyLeader } from "../../types/Events";
import { rootApi } from "../rootApi";

export const companyLeadershipApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getLeaders: builder.query<ICompanyLeader[], void>({
      query: () => "company-profile/leaders/",
      providesTags: ["LeadershipTags"],
    }),
    patchLeader: builder.mutation<
      ICompanyLeader,
      {
        leader: Partial<ICompanyLeader>;
        id: string;
      }
    >({
      query: ({ leader, id }) => ({
        url: `company-profile/leaders/${id}/`,
        method: "PATCH",
        body: {
          ...leader,
        },
      }),
      invalidatesTags: ["LeadershipTags"],
    }),
    postLeader: builder.mutation<ICompanyLeader, ICompanyLeader>({
      query: (leader) => ({
        url: "company-profile/leaders/",
        method: "POST",
        body: {
          ...leader,
        },
      }),
      invalidatesTags: ["LeadershipTags"],
    }),
    deleteLeader: builder.mutation<ICompanyLeader, string>({
      query: (id) => ({
        url: `company-profile/leaders/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["LeadershipTags"],
    }),
  }),
});

export const {
  useGetLeadersQuery,
  usePatchLeaderMutation,
  usePostLeaderMutation,
  useDeleteLeaderMutation,
} = companyLeadershipApi;
