import {
  ButtonV2 as Button,
  ErrorMessage,
  IconButton,
  Text,
} from "@asayinc/component-library";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Stack } from "@mui/material";
import { useRef } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface IProps {
  isLoading?: boolean;
  isDraft?: boolean;
}

const InvestorCsvUpload = ({ isLoading, isDraft }: IProps) => {
  const uploadInputRef = useRef<HTMLInputElement | null>(null);

  const {
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  // csv file
  const csvFile = useWatch({
    name: "audience",
  });

  /**
   * when a file is uplaoded, convert it to data uri and set it to the form
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];

    if (file) {
      setValue(
        "audience",
        { file: file, name: file.name, url: null },
        { shouldDirty: true }
      );
      clearErrors("audience");
    }
  };

  // fake click on file upload input
  const openUploadFile = () => {
    uploadInputRef.current && uploadInputRef.current.click();
  };

  // remove uploaded csv
  const deleteCsv = () => {
    uploadInputRef.current && (uploadInputRef.current.value = "");
    setValue("audience", null, { shouldDirty: true });
    clearErrors("audience");
  };

  return (
    <Stack gap={6}>
      <Box>
        <Text variant="subtitle1" mb={2}>
          Investor upload
        </Text>
        <Text variant="body2">
          Upload a formatted CSV with a list of investors you want to include on
          Say. See a sample{" "}
          <a href="/examples/audience_example_file.csv">here</a>. (Optional)
        </Text>
      </Box>
      <Box>
        <input
          ref={uploadInputRef}
          type="file"
          id="csv-file-upload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          style={{ display: "none" }}
          onChange={onChange}
        />
        {!!csvFile ? (
          <Stack>
            <Stack justifyContent="space-between" direction="row">
              <a href={csvFile.url} target="_blank" rel="noreferrer">
                <Text>{csvFile.name} ✅</Text>
              </a>
              <Stack direction="row" gap={3}>
                <IconButton data-testid={"edit-csv"} onClick={openUploadFile}>
                  <EditIcon />
                </IconButton>
                <IconButton data-testid={"delete-csv"} onClick={deleteCsv}>
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>
            </Stack>
            <ErrorMessage
              errors={errors}
              name={"audience"}
              sx={{
                whiteSpace: "nowrap",
                mt: "3px",
              }}
            />
          </Stack>
        ) : (
          <>
            <Button
              onClick={openUploadFile}
              data-testid="button-add-csv"
              disabled={isLoading && isDraft}
              variant="secondary"
            >
              Add a list
            </Button>
          </>
        )}
      </Box>
    </Stack>
  );
};

export default InvestorCsvUpload;
