import { Message } from "../../../../components/Messages";
import {
  ConfirmEditDialog,
  ScheduleShareholderMessage,
  TestEmailDialog,
} from "../../../../components/Messages";

const ShareholderMessage = (): JSX.Element => {
  return (
    <>
      <Message />
      <ScheduleShareholderMessage />
      <TestEmailDialog />
      <ConfirmEditDialog />
    </>
  );
};

export default ShareholderMessage;
