import { Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import { track } from "../../../../../../../analytics";
import { FilterTag, GenericFilter } from "../../../../../../Common";
import {
  REPLIED_STATUS_OPTIONS,
  URL_PARAMS,
} from "../../../../../../../constants";
import { useGetShareholderTagsQuery } from "../../../../../../../store/shareholderTags";

interface IProps {
  containsReplies?: boolean;
  companyName: string;
  campaignName: string;
  paramObj: { [key: string]: string };
  // allows for updating url or not updating url and using state params instead
  stateSearchParams?: URLSearchParams;
  stateSetSearchParams?: (params: URLSearchParams) => void;
}

const FilterList = ({
  containsReplies,
  companyName,
  campaignName,
  paramObj,
  stateSearchParams,
  stateSetSearchParams,
}: IProps) => {
  const { data: shareholderTags = [], isLoading } =
    useGetShareholderTagsQuery();
  const didMount = useRef<boolean>(false);

  /**
   * Track replied
   */
  useEffect(() => {
    if (paramObj[URL_PARAMS.replied] && didMount.current) {
      track({
        name: "Recipients Filtered",
        client: companyName,
        campaign: campaignName,
        campaignStatus: "sent",
        filter: URL_PARAMS.replied,
        filterValue: paramObj[URL_PARAMS.replied],
      });
    }
  }, [paramObj[URL_PARAMS.replied]]);

  /**
   * Track search
   */
  useEffect(() => {
    if (paramObj[URL_PARAMS.search] && didMount.current) {
      track({
        name: "Recipients Filtered",
        client: companyName,
        campaign: campaignName,
        campaignStatus: "sent",
        filter: URL_PARAMS.search,
        filterValue: paramObj[URL_PARAMS.search],
      });
    }
  }, [paramObj[URL_PARAMS.search]]);

  /**
   * Track tags
   */
  useEffect(() => {
    if (paramObj.tags && didMount.current) {
      track({
        name: "Recipients Filtered",
        client: companyName,
        campaign: campaignName,
        campaignStatus: "sent",
        filter: "tags",
        filterValue: paramObj.tags,
      });
    }
  }, [paramObj.tags]);

  // set component has mounted, avoids running tracking on render
  useEffect(() => {
    didMount.current = true;
  }, []);

  return (
    <Stack direction="row-reverse" gap={4} mt={2} width="100%">
      {containsReplies && (
        <GenericFilter
          options={REPLIED_STATUS_OPTIONS}
          label="Replied"
          queryParameter={URL_PARAMS.replied}
          stateSearchParams={stateSearchParams}
          stateSetSearchParams={stateSetSearchParams}
        />
      )}

      <FilterTag
        tagOptions={shareholderTags}
        isLoading={isLoading}
        ctaTitle="Shareholder Tag"
        stateSearchParams={stateSearchParams}
        stateSetSearchParams={stateSetSearchParams}
      />
    </Stack>
  );
};

export default FilterList;
