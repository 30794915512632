import { useFormContext } from "react-hook-form";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  COLORS,
  FilterSelectionFlow,
  FilterType,
  IFilter,
  Tooltip,
} from "@asayinc/component-library";
import { useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetFeaturesQuery } from "../../../../../../store/settings";

interface IProps {
  isLoading?: boolean;
}

const fieldName = "filterMap.query.sharesStatus";

const SharesStatusFilter = ({ isLoading }: IProps) => {
  const { proxyEventId } = useParams();
  const { data: features, isLoading: isFeaturesLoading } =
    useGetFeaturesQuery();
  const { watch, setValue } = useFormContext();
  const fieldValue = watch(fieldName);

  useEffect(() => {
    // clear selected value when "any" is selected
    if (fieldValue?.radio === "any") {
      setValue(fieldName, undefined);
    }
  }, [fieldValue]);

  const options = useMemo(
    () =>
      [
        {
          type: FilterType.Radio,
          label: "Not applied",
          options: {
            defaultValueLabel: "All shareholders",
            options: [
              {
                label:
                  "Shareholders who hold shares at record date, but no longer hold",
                value: "has_sold",
              },
            ],
          },
        },
      ] as IFilter[],
    []
  );
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <FilterSelectionFlow
        withinForm={{ fieldName }}
        clearable
        chipLabel="Shares status"
        filterTypeOptions={options}
        chipProps={{
          disabled: isLoading || (!!proxyEventId && !features?.messages),
        }}
      />
      {!!proxyEventId && !isFeaturesLoading && !features?.messages && (
        <Tooltip title="Enable messages to filter by share status" wrap={false}>
          <HelpOutlineIcon
            fontSize="small"
            style={{ color: COLORS.ERROR_DARK }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export default SharesStatusFilter;
