import { COLORS, IconName, SayIcon, Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";

interface IProps {
  url: string;
  closeDialog: () => void;
  title: string;
  description: string;
  icon: IconName;
  color: string;
  testId: string;
}

// Display option within the explore features dialog
const FeatureOption = ({
  url,
  closeDialog,
  title,
  description,
  icon,
  testId,
  color,
}: IProps) => (
  <Link
    to={url}
    style={{ textDecoration: "none", flex: 1 }}
    onClick={closeDialog}
  >
    <Stack
      sx={{
        cursor: "pointer",
        ":hover": {
          boxShadow:
            "0px 12px 24px 0px rgba(0, 0, 0, 0.06), 0px -12px 24px 0px rgba(0, 0, 0, 0.02)",
        },
      }}
      p={4}
      gap={4}
      height={"100%"}
      border={`1px solid ${COLORS.P_OUTLINE}`}
      borderRadius={2}
      data-testid={`feature-option-${testId}`}
    >
      <Stack
        width={56}
        minWidth={56}
        height={56}
        borderRadius={2}
        bgcolor={color}
        alignItems="center"
        justifyContent="center"
      >
        <SayIcon name={icon} />
      </Stack>
      <Box>
        <Text variant="subtitle2">{title}</Text>
        <Text variant="body2">{description}</Text>
      </Box>
    </Stack>
  </Link>
);
export default FeatureOption;
