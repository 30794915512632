import { Link, LinkProps, To } from "react-router-dom";

interface IProps extends Omit<LinkProps, "to"> {
  children: React.ReactNode;
  to?: To;
  condition: boolean;
}

export default function ConditionalLink({
  children,
  condition,
  ...props
}: IProps) {
  const { to } = props;
  return !!condition && to ? (
    <Link {...props} to={to}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
}
