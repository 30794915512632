export const COLUMNS = [
  "eventName",
  "meetingDate",
  "recordDate",
  "sharesVoted",
  "shareholdersVoted",
  "messagesSent",
];

/**
 * Get no results messaging
 */
export const NO_RESULTS_DATA = {
  title: "No past voting events",
  subtitle: "Your past voting event will be displayed here",
};
