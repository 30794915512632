import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// components
import { Stack, Box } from "@mui/material";

import { Text, pluralize } from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../../../../components/Common";
// data
import { Context } from "../../../../../../context";
import { useGetShareholderStatsQuery } from "../../../../../../../../../../store/shareholder";
// types
import { IShareholderStats } from "../../../../../../../../../../types/Shareholder";

const headlineLoadingProps = { sx: { width: "100px" } };
const subheadlineLoadingProps = { sx: { width: "200px" } };

const Summary = () => {
  const { data, asDrawer } = useContext(Context);
  const globalUserId = data?.globalUserId;
  const { data: statsData = {} } = useGetShareholderStatsQuery(
    globalUserId ? globalUserId : skipToken
  );

  const {
    numQaParticipated,
    numQuestionsAsked,
    numQuestionsAnswered,
    numUpvotes,
    numUpvotedQuestionsAnswered,
  } = statsData as IShareholderStats;

  const askedAnsweredPercentage = `${
    !numQuestionsAnswered || !numQuestionsAsked
      ? 0
      : Math.round((numQuestionsAnswered / numQuestionsAsked) * 100)
  }%`;
  const upvotedAnsweredPercentage = `${
    !numUpvotedQuestionsAnswered || !numUpvotes
      ? 0
      : Math.round((numUpvotedQuestionsAnswered / numUpvotes) * 100)
  }%`;

  return (
    <CardWrapper cardName="qa-summary">
      <Stack direction="row" flexWrap="wrap">
        <Box width={asDrawer ? "100%" : "33%"} mb={asDrawer ? 6 : 0}>
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            Participated in
          </Text>
          <Text variant="h5" loadingProps={subheadlineLoadingProps}>
            {numQaParticipated} {pluralize("Q&A", "Q&As", numQaParticipated)}
          </Text>
        </Box>
        <Box width={asDrawer ? "50%" : "33%"}>
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            Asked
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {numQuestionsAsked}{" "}
            {pluralize("question", "questions", numQuestionsAsked)}
          </Text>
          <Text variant="body2" loadingProps={subheadlineLoadingProps}>
            {askedAnsweredPercentage}({numQuestionsAnswered}) got answered
          </Text>
        </Box>
        <Box width={asDrawer ? "50%" : "33%"}>
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            Upvoted
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {numUpvotes} {pluralize("question", "questions", numUpvotes)}
          </Text>
          <Text variant="body2" loadingProps={subheadlineLoadingProps}>
            {upvotedAnsweredPercentage}({numUpvotedQuestionsAnswered}) got
            answered
          </Text>
        </Box>
      </Stack>
    </CardWrapper>
  );
};

export default Summary;
