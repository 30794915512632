import { useCallback, useState } from "react";
import { track } from "../analytics";
import { FLIP_SORT, SORT, SORT_SYMBOL } from "../constants";
import { TableEventData } from "../types/Table";

interface ITrackingData {
  name: string;
  [key: string]: string | number;
}

interface IProps {
  defaultSort: string;
  trackingData?: ITrackingData;
}

export const useStateSorting = ({ defaultSort, trackingData }: IProps) => {
  const [sortValue, setSortValue] = useState<string>(defaultSort);
  /**
   * Flip current sort and sort on column
   * Default sort to "asc" so new column clicks get flipped to desc
   * @param data TableEventData
   */
  const sortFn = useCallback(
    (data: unknown) => {
      const { id, sort = "asc" } = data as TableEventData;
      const sortType = SORT?.[id as keyof typeof SORT];
      const newSort = FLIP_SORT[sort as keyof typeof FLIP_SORT];
      const sortValue = `${
        SORT_SYMBOL[newSort as keyof typeof SORT_SYMBOL]
      }${sortType}`;
      // if tracking data is provided track the sort fn
      if (trackingData) {
        track({
          sortColumn: sortType,
          sort: newSort,
          ...trackingData,
        });
      }
      setSortValue(sortValue);
    },
    [sortValue, trackingData]
  );

  /**
   * calculate current sort based on state value
   */
  const getSort = useCallback(
    (value: string) => {
      const isFieldSorting = sortValue.indexOf(value) !== -1;
      if (isFieldSorting) {
        return sortValue.indexOf("-") !== -1 ? "desc" : "asc";
      }
      return undefined;
    },
    [sortValue]
  );

  return {
    getSort,
    sortFn,
    sortValue,
  };
};
