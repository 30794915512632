import { TableV2 as Table } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";
import { track } from "../../../../../../../analytics";
import { SORT } from "../../../../../../../constants";
import { useMessageParams } from "../../../../../../../hooks";
import { useStateSorting } from "../../../../../../../hooks/useStateSorting";
import { useGetMessageClicksQuery } from "../../../../../../../store/messageLists";
import {
  MessageExportTypes,
  useExportMessageDataMutation,
} from "../../../../../../../store/messageStats";
import { getColumnData, getRowData } from "./factories";

export const COLUMNS = ["link", "clickPercent", "count", "shares"];

interface IProps {
  companyName: string;
  campaignName: string;
  asDrawer?: boolean;
}

const ClickedLinks = ({ companyName, campaignName, asDrawer }: IProps) => {
  const { messageId } = useMessageParams(asDrawer);
  // tracking values for sort
  const trackingData = useMemo(
    () => ({
      name: "Clicked Links Sorted",
      client: companyName,
      campaign: campaignName,
      campaignStatus: "sent",
    }),
    [companyName, campaignName]
  );
  // for sorting columns
  const { sortFn, sortValue, getSort } = useStateSorting({
    defaultSort: SORT.clickPercent,
    trackingData,
  });
  const {
    data: clickData = [],
    isFetching,
    isLoading,
    isError,
  } = useGetMessageClicksQuery({
    messageId,
    sortValue,
  });

  const [getExportedData, { isLoading: isExporting }] =
    useExportMessageDataMutation();

  // export csv for given reporting section
  const exportData = useCallback(() => {
    track({
      name: "List Downloaded",
      client: companyName,
      campaign: campaignName,
      campaignStatus: "sent",
      reportComponent: MessageExportTypes.Clicks,
    });
    getExportedData({ exportType: MessageExportTypes.Clicks, messageId });
  }, [messageId]);

  /**
   * navigate to url that has been clicked
   */
  const goToLink = useCallback((data: unknown) => {
    const { link } = data as { link: string };
    window.open(link, "_blank");
  }, []);

  // row and column data for the table
  const rowData = useMemo(() => getRowData({ clickData }), [clickData]);
  const columnData = useMemo(
    () => getColumnData({ goToLink, getSort, sortFn }),
    [sortFn, getSort]
  );

  // props for download icon in table
  const downloadProps = useMemo(
    () => ({
      active: !isExporting,
      action: exportData,
      tooltip: "Download clicked links.",
    }),
    [isExporting]
  );

  return (
    <Box>
      <Table
        isLoading={isLoading || isError}
        isFetching={isFetching}
        loadingRows={4}
        rows={rowData}
        testid="clicked-links-table"
        memoCells
        downloadProps={downloadProps}
        toolbarInside
        titleVariant="subtitle2"
        title="Clicked links"
        columnData={columnData}
        tableMinWidth={800}
        tableLayout="auto"
        inDrawer={asDrawer}
        columns={COLUMNS}
        count={clickData?.length || 0}
        disableHover={true}
      />
    </Box>
  );
};

export default ClickedLinks;
