export const COLUMNS = [
  "checkbox",
  "question",
  "votes",
  "sharesUpvoted",
  "category",
  "participant",
  "date",
  "answered",
];
