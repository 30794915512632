import { useEffect, useState } from "react";
import { useGetAllEventsQuery } from "../../../../../store/allEvents";
import EventCardList from "../EventCardList";
import { DEFAULT_LIMIT } from "../../../constants";
import { EventStatusOptions } from "../../../../../types/Events";
import { useDeleteDraftEventMutation } from "../../../../../store/event";

interface IProps {
  status: string;
  title: string;
  showLoadingState?: boolean;
}

const EventsListContainer = ({
  status,
  title,
  showLoadingState,
}: IProps): JSX.Element => {
  const [offset, setOffset] = useState(0);

  // listen for deletion of an event
  const [, { isSuccess }] = useDeleteDraftEventMutation({
    fixedCacheKey: "shared-delete-event",
  });

  // reset offset on delete of draft to get new set of data
  useEffect(() => {
    if (isSuccess && status === EventStatusOptions.DRAFT) {
      setOffset(0);
    }
  }, [isSuccess]);

  const { data, isLoading } = useGetAllEventsQuery({
    offset: String(offset),
    limit: String(DEFAULT_LIMIT),
    status,
  });

  return (
    <EventCardList
      events={data?.results || []}
      title={title}
      count={data?.count || 0}
      isLoading={isLoading || !!showLoadingState}
      offset={offset}
    />
  );
};

export default EventsListContainer;
