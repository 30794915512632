import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ResetPassword as ResetPasswordComponent,
  ResetPasswordConfirmation,
  MessageContainer,
} from "@asayinc/component-library";
import { useResetPasswordMutation } from "../../store/ssoUser";

interface IFormValues {
  password: string;
  passwordConfirmation: string;
}

const ResetPassword = (): JSX.Element => {
  document.title = "Reset Password | Say Issuer Portal";
  const [token, setToken] = useState<string>("");
  const [uid, setUid] = useState<string>("");
  const navigate = useNavigate();
  const parsedParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const [resetPassword, resetPasswordResponse] = useResetPasswordMutation();

  const submitForm = (values: IFormValues, token: string, uid: string) => {
    const data = {
      new_password1: values.password,
      new_password2: values.passwordConfirmation,
      token,
      uid,
    };
    resetPassword(data);
  };

  useEffect(() => {
    if (resetPasswordResponse.isSuccess) {
      window.setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [resetPasswordResponse]);

  useEffect(() => {
    /**
     * parse query strings and set them to an empty string if there are no uids or tokens
     */
    if (window.location) {
      const queryStrings = parsedParams;
      setToken(
        queryStrings.token && !Array.isArray(queryStrings.token)
          ? queryStrings.token
          : ""
      );
      setUid(
        queryStrings.uid && !Array.isArray(queryStrings.uid)
          ? queryStrings.uid
          : ""
      );
    }
  }, [window.location]);
  return (
    <MessageContainer>
      {resetPasswordResponse.isSuccess ? (
        <ResetPasswordConfirmation />
      ) : (
        <ResetPasswordComponent
          submitForm={(values: IFormValues) => submitForm(values, token, uid)}
        />
      )}
    </MessageContainer>
  );
};

export default ResetPassword;
