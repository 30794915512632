import { Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useCallback } from "react";
import { EditIcon } from "../../../../../components/Common";

interface IProps {
  title: string;
  cardName: string;
  children: React.ReactNode;
  editStep: (section: string) => void;
}

const ReviewDetailsSection = ({
  title,
  children,
  cardName,
  editStep,
}: IProps) => {
  // go to step on edit click of review detial section edit
  const onEdit = useCallback(() => {
    editStep(cardName);
  }, []);

  return (
    <Stack
      sx={{
        position: "relative",
        "&:hover .editSectionIcon": {
          opacity: 1,
        },
        ".editSectionIcon": {
          opacity: 0,
          transition: "all 0.2s linear",
        },
      }}
      data-testid={`review-${cardName}`}
    >
      <Text variant="h5">{title}</Text>
      <EditIcon
        className="editSectionIcon"
        cardName={cardName}
        onEdit={onEdit}
      />

      {children}
    </Stack>
  );
};

export default ReviewDetailsSection;
