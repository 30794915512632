import { Stack } from "@mui/material";
import { Summary, MessagesTable } from "./components";

const MessagesActivity = () => {
  return (
    <Stack data-testid="shareholder-messages-received" spacing={8}>
      <Summary />
      <MessagesTable />
    </Stack>
  );
};

export default MessagesActivity;
