import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import HowToVoteRoundedIcon from "@mui/icons-material/HowToVoteRounded";
import DoDisturbOnRoundedIcon from "@mui/icons-material/DoDisturbOnRounded";
import dayjs from "dayjs";
import { COLORS, DATE_FORMAT, pluralize } from "@asayinc/component-library";
import { Box } from "@mui/material";

interface ILifecycleData {
  meetingDate?: string | null;
  voteCutoff?: string | null;
}

export const getLifeCycleData = ({
  meetingDate,
  voteCutoff,
}: ILifecycleData) => {
  // format to day from day/time level so day differences are integers
  const dayjsMeetingDate = dayjs(dayjs(meetingDate).format(DATE_FORMAT));
  const dayjsVoteCutoff = dayjs(dayjs(voteCutoff).format(DATE_FORMAT));
  const dayjsToday = dayjs(dayjs().format(DATE_FORMAT));
  const isVoteCutoff = dayjsToday.isSame(dayjsVoteCutoff, "day");
  // if votecutoff day show votecutoff
  if (voteCutoff && isVoteCutoff) {
    return {
      text: "Proxy voting cutoff",
      icon: (
        <DoDisturbOnRoundedIcon
          style={{
            transform: "rotate(45deg)",
            fontSize: "16px",
            color: COLORS.WHITE,
          }}
        />
      ),
    };
  }
  if (meetingDate) {
    const daysBetween = Math.abs(dayjsToday.diff(dayjsMeetingDate, "day"));
    if (dayjsToday.isBefore(dayjsMeetingDate)) {
      // if over 14 days away show ongoing
      if (daysBetween > 14) {
        return {
          text: "Ongoing",
          icon: (
            <HowToVoteRoundedIcon
              style={{ fontSize: "16px", color: COLORS.WHITE }}
            />
          ),
        };
      }
      // if over 11-14 days away show 2 weeks
      if (daysBetween > 10) {
        return {
          text: "2 weeks left",
          icon: (
            <AccessTimeFilledIcon
              style={{ fontSize: "16px", color: COLORS.WHITE }}
            />
          ),
        };
      }
      // if over 1-10 days away show exact days
      return {
        text: `${daysBetween} day${pluralize("", "s", daysBetween)} left`,
        icon: (
          <AccessTimeFilledIcon
            style={{ fontSize: "16px", color: COLORS.WHITE }}
          />
        ),
      };
    } else {
      const isMeetingDay = dayjsToday.isSame(dayjsMeetingDate, "day");
      // if meeting day show it
      if (isMeetingDay) {
        return {
          text: "Meeting day",
          icon: (
            <Box
              width={9}
              height={9}
              bgcolor={COLORS.WHITE}
              borderRadius="50%"
            />
          ),
        };
      }
      // if one day past meeting day show ongoing
      if (daysBetween === 1) {
        return {
          text: "Ongoing",
          icon: (
            <HowToVoteRoundedIcon
              style={{ fontSize: "16px", color: COLORS.WHITE }}
            />
          ),
        };
      }
      // if over 1 day past meeting day show closed
      return {
        text: "Closed",
        disabled: true,
        icon: null,
      };
    }
  }

  return {};
};
