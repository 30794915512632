import { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
// components
import { Box, Stack } from "@mui/material";
import {
  PreviewCard,
  RecipientsCard,
  CampaignNameCard,
  ScheduledAlertBar,
} from "../../Molecules";
// redux / data
import {
  useGetMessageQuery,
  initialState,
} from "../../../../store/messageCompose";
import { useGetMessageOverviewQuery } from "../../../../store/messageStats";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { useGetSettingsQuery } from "../../../../store/settings";
// constants
import { DATE_FORMAT } from "@asayinc/component-library";
// types
import { ISentMessage } from "../../../../store/messageLists";
import { ISettings } from "../../../../store/settings/types";
// utils
import { track } from "../../../../analytics";
import { useMessageParams } from "../../../../hooks/useMessageParams";
import { isMessageSent } from "../../../../utils/messages/isMessageSent";
import {
  CREATION_STEP_MAPPING as SM_STEPS,
  SESSION_KEY as SM_KEY,
} from "../../../../pages/Messages/subPages/CreateAndEdit/constants";
import {
  CREATION_STEP_MAPPING as PROXY_STEPS,
  SESSION_KEY as PROXY_KEY,
} from "../../../../pages/ProxyVoting/subPages/ProxyCreateAndEdit/constants";
import { MessageStatus, MessageStep } from "../../../../types/Messages";
import { useMessageDialogHooks } from "../../../../hooks";

interface IProps {
  asDrawer?: boolean;
}

const Overview = ({ asDrawer }: IProps): JSX.Element => {
  const navigate = useNavigate();
  const { messageId, proxyEventId } = useMessageParams(asDrawer);
  const { openConfirmEditDialog } = useMessageDialogHooks(
    messageId,
    proxyEventId
  );
  const {
    data: messageData = initialState,
    isLoading: isMessageLoading,
    isError: isMessageError,
  } = useGetMessageQuery(messageId);

  // either SM or ProxySM key
  const sessionKey = proxyEventId ? PROXY_KEY : SM_KEY;
  // either SM or proxySM step mapping for
  const stepMapping = proxyEventId ? PROXY_STEPS : SM_STEPS;
  const isSent = isMessageSent(messageData.status);

  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;
  const {
    data: overviewData = {
      stats: {
        deliveredCount: 0,
        deliveredShares: 0,
        votedCount: 0,
        votedShares: 0,
        gainedPct: 0,
      },
    },
    isLoading: isOverviewLoading,
    isError: isOverviewStatsError,
  } = useGetMessageOverviewQuery(messageId);
  const { scheduledSendAt, htmlPreview, content } = messageData as ISentMessage;
  const sentOnDate = dayjs(scheduledSendAt).format(DATE_FORMAT);

  // set page title
  useEffect(() => {
    document.title = `${messageData.campaignName} Overview | Say Issuer Portal`;
  }, [messageData.campaignName]);

  /**
   * Track data upon landing on this tab
   */
  useEffect(() => {
    if (companyName && messageData.campaignName) {
      track({
        name: "Message Campaign Selected",
        client: companyName,
        campaign: messageData.campaignName,
        campaignStatus: "sent",
        menuTab: "Overview",
      });
    }
  }, [companyName, messageData.campaignName]);

  // navigate to messages if apis fail
  const getRequestErrorAction = useCallback(() => {
    if (proxyEventId) {
      navigate(`/vote/${proxyEventId}/messages`);
    } else {
      navigate("/messages");
    }
  }, [proxyEventId]);

  // snackbar for if any apis fail
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please try again.",
    isError: isOverviewStatsError || isMessageError,
    errorAction: getRequestErrorAction,
  });

  /**
   * EDIT ACTIONS FOR EACH SECTION
   *
   * on all scenarios set session storage to auto navigate to the specific step when loading compose flow
   */
  const goToEdit = (step: MessageStep) => {
    window.sessionStorage.setItem(
      sessionKey,
      JSON.stringify({
        step: stepMapping.indexOf(step),
        id: messageId,
      })
    );
    if (messageData.status === MessageStatus.Scheduled) {
      // show confim dialog if message is scheduled
      openConfirmEditDialog();
    } else {
      if (proxyEventId) {
        navigate(`/vote/${proxyEventId}/messages/${messageData.id}/edit`);
      } else {
        navigate(`/messages/${messageData.id}/edit`);
      }
    }
  };

  const onEditRecipients = () => {
    goToEdit(MessageStep.ChooseRecipients);
  };
  const onEditCampaignName = () => {
    goToEdit(MessageStep.NameCampaign);
  };

  const onEditMessage = () => {
    goToEdit(MessageStep.DraftMessage);
  };
  /**
   * END EDIT ACTIONS
   */

  const isLoading = isMessageLoading || isOverviewLoading;

  return (
    <Stack gap={8}>
      {messageData.status === MessageStatus.Scheduled && (
        <ScheduledAlertBar asDrawer={asDrawer} />
      )}
      <Stack gap={8} direction={["column", asDrawer ? "column" : "row"]}>
        <Stack gap={8} width={asDrawer ? "100%" : ["100%", "50%"]}>
          {!isSent && (
            <CampaignNameCard
              campaignName={messageData.campaignName}
              onEdit={onEditCampaignName}
            />
          )}
          <RecipientsCard
            messageType={messageData?.type}
            isSentMessage={messageData?.status === MessageStatus.Sent}
            isLoading={isLoading}
            onEdit={!isSent ? onEditRecipients : undefined}
            shareholderCount={overviewData?.stats?.deliveredCount || 0}
            sharesCount={overviewData?.stats?.deliveredShares}
            votedCount={overviewData?.stats?.votedCount || 0}
            votedShares={overviewData?.stats?.votedShares || 0}
            gainedPct={overviewData?.stats?.gainedPct || 0}
            query={messageData?.filterMap?.query}
            sentOnDate={sentOnDate}
          />
        </Stack>
        <Box width={asDrawer ? "100%" : ["100%", "50%"]}>
          <PreviewCard
            htmlPreview={htmlPreview}
            messageId={messageId}
            subject={content?.subject}
            onEdit={!isSent ? onEditMessage : undefined}
            proxyEventId={proxyEventId}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export default Overview;
