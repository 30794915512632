import {
  INPUT_TIME_FORMAT,
  IconButton,
  Text,
  Tooltip,
  TooltipInfoIcon,
} from "@asayinc/component-library";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { CardWrapper } from "../../../../../../../../../components/Common";
import {
  useExportVoteDataMutation,
  useGetProxyEventDetailsQuery,
} from "../../../../../../../../../store/proxyEvent";
import { Stack } from "@mui/material";
import ProposalSection from "../../Organisms/ProposalSection/ProposalSection";
import { useSuccessErrorSnacks } from "../../../../../../../../../hooks/useSuccessErrorSnacks";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const ProposalBreakDown = () => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { data, isLoading } = useGetProxyEventDetailsQuery(proxyEventId);

  const [getExportedData, { isLoading: isExporting, isError, isSuccess }] =
    useExportVoteDataMutation();

  // export csv for given reporting section
  const exportData = () => {
    getExportedData(proxyEventId);
  };

  // snackbar for if export succeeds/fails
  useSuccessErrorSnacks({
    errorMsg: "Failed to export please try again.",
    isError,
    successMsg: `Vote ID ${proxyEventId} exported.`,
    isSuccess,
  });

  const proposals = data?.proposals || [];
  // dummy array to iterate over for 4 loading skeletons
  const loadingSections = [1, 2, 3, 4];
  return (
    <CardWrapper cardName="proposal-breakdown" sx={{ width: "100%" }}>
      <Stack gap={6} minWidth={608}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center">
            <Text
              variant="subtitle2"
              loadingProps={{ sx: { width: "112px" } }}
              mr={1}
            >
              {isLoading ? undefined : "Proposal breakdown"}
            </Text>
            <TooltipInfoIcon
              iconColor="black"
              title={
                <span>
                  Proposal details (in shares voted) will be sent by Say to your
                  meeting tabulator automatically.
                  <br /> Last updated{" "}
                  {dayjs(data?.proxyEventStats?.modified).format(
                    INPUT_TIME_FORMAT
                  )}{" "}
                  today.
                </span>
              }
            />
          </Stack>
          {isLoading ? null : (
            <Tooltip title="Download">
              <IconButton
                data-testid="reporting-download-button"
                onClick={exportData}
                disabled={isExporting}
              >
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        {isLoading
          ? loadingSections.map((section, idx) => (
              <ProposalSection
                key={section}
                isLoading={isLoading}
                proposals={[]}
                idx={idx}
              />
            ))
          : proposals.map((proposalArray, i) => (
              <ProposalSection key={i} proposals={proposalArray} idx={i} />
            ))}
      </Stack>
    </CardWrapper>
  );
};

export default ProposalBreakDown;
