import { useEffect } from "react";
import { Stack, Box } from "@mui/material";
import { HookDatePicker, HookTimePicker } from "@asayinc/component-library";
import { useFormContext, useWatch } from "react-hook-form";
// dayjs
import dayjs from "dayjs";
import timezonePlugin from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import dayjsBusinessDays from "dayjs-business-days";
// helpers
import { betweenEightAndEight, isCloseDateValid } from "../../../helpers";

dayjs.extend(utc);
dayjs.extend(timezonePlugin);
dayjs.extend(dayjsBusinessDays);

interface IProps {
  isDisabled?: boolean;
  isDraft?: boolean;
  timezoneAbbr: string;
  timezoneId: string;
}

/**
 * event close date and time selection
 */
const CloseDateAndTime = ({
  isDisabled,
  isDraft,
  timezoneAbbr,
  timezoneId,
}: IProps) => {
  const { trigger } = useFormContext();
  const eventDate = useWatch({
    name: "eventDate",
  });
  const openDate = useWatch({
    name: "openDate",
  });
  const closeDate = useWatch({
    name: "closeDate",
  });

  // re-validate other date inputs
  useEffect(() => {
    if (closeDate) {
      trigger(["openDate", "eventDate"]);
    }
  }, [closeDate]);

  const openDateDayjs = dayjs(openDate).isValid() ? dayjs(openDate) : undefined;

  // set default month to month of other selections
  const defaultMonth = eventDate ? dayjs(eventDate) : openDateDayjs;

  // check if invalid date on a non-draft event
  const nonDraftInvalid =
    closeDate &&
    !isDraft &&
    isCloseDateValid({
      eventStartDatetime: eventDate,
      openDatetime: openDate,
      closeDatetime: closeDate,
    }) !== true;

  // check if opendatetime is in the past
  const pastOpenDateTime =
    !isDraft && openDateDayjs && openDateDayjs.isBefore(dayjs());

  // if invalid date on a non-draft event it cannot be edited, or if opendate is in past
  const isUneditable = nonDraftInvalid || pastOpenDateTime;

  const disabled = !!(isDisabled || isUneditable);

  return (
    <Stack direction="row" spacing={12}>
      <Box width="100%">
        <HookDatePicker
          dateFieldProps={{
            slotProps: {
              textField: {
                inputSize: "medium",
                outerLabel: "Close Date",
                placeholder: "MM / DD / YYYY",
                sx: { width: "100%" },
                id: "closeDate",
              },
            },
            disabled,
          }}
          calendarProps={{
            defaultCalendarMonth: defaultMonth,
          }}
          rules={{
            validate: {
              isDateValid: (date) =>
                isCloseDateValid({
                  eventStartDatetime: eventDate,
                  openDatetime: openDate,
                  closeDatetime: date,
                  disabled,
                }),
            },
          }}
          name="closeDate"
        />
      </Box>
      <Box width="100%">
        <HookTimePicker
          disabled={disabled}
          slotProps={{
            textField: {
              outerLabel: `End Time (${timezoneAbbr})`,
              id: "closeTime",
              placeholder: "--:-- --",
            },
          }}
          name="closeTime"
          rules={{
            validate: {
              betweenEightAndEight: (date) =>
                betweenEightAndEight(date, timezoneId),
            },
            required: {
              value: true,
              message: "Close Time is required.",
            },
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </Stack>
  );
};

export default CloseDateAndTime;
