import { useEffect } from "react";
import { Stack, Box } from "@mui/material";
import {
  HookDatePicker,
  HookTimePicker,
  Text,
} from "@asayinc/component-library";
import { useFormContext, useWatch } from "react-hook-form";
// dayjs
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";
// helpers
import { isEventDateValid } from "../../../helpers/dateValidation";
import { TimeZoneAutocomplete } from "../../../../../components/Common";

dayjs.extend(dayjsBusinessDays);
interface IProps {
  isDisabled?: boolean;
  isDraft?: boolean;
}

/**
 * event date and time selection
 */
const EventDateAndTime = ({ isDisabled, isDraft }: IProps) => {
  const { trigger, getValues } = useFormContext();

  const [closeDate, openDate, eventDate, timezone] = useWatch({
    name: ["closeDate", "openDate", "eventDate", "timezone"],
  });

  // re-validate other date inputs
  useEffect(() => {
    if (closeDate) {
      trigger(["openDate", "closeDate"]);
    }
  }, [closeDate]);

  // re-validate if timezone changes
  useEffect(() => {
    const { eventTime } = getValues();
    if (eventDate && eventTime) {
      trigger(["eventDate", "eventTime"]);
    }
  }, [timezone]);

  const openDateDayjs = dayjs(openDate).isValid() ? dayjs(openDate) : undefined;

  // check if opendatetime is in the past
  const pastOpenDateTime =
    !isDraft && openDateDayjs && openDateDayjs.isBefore(dayjs());

  // check if invalid date on a non-draft event
  const nonDraftInvalid =
    eventDate &&
    !isDraft &&
    isEventDateValid({
      eventStartDatetime: eventDate,
      openDatetime: openDate,
      closeDatetime: closeDate,
    }) !== true;

  // if invalid date on a non-draft event it cannot be edited, or if opendate is in past
  const isUneditable = nonDraftInvalid || pastOpenDateTime;

  const disabled = !!(isDisabled || isUneditable);

  return (
    <>
      <Box>
        <Text variant="subtitle1" mb={2}>
          Event date and time
        </Text>
        <Text variant="body2">
          The date, time, and timezone this event will take place.
        </Text>
      </Box>
      <Stack direction="row" spacing={12}>
        <Box width="100%">
          <HookDatePicker
            dateFieldProps={{
              slotProps: {
                textField: {
                  inputSize: "medium",
                  outerLabel: "Date",
                  placeholder: "MM / DD / YYYY",
                  sx: { width: "100%" },
                  id: "eventDate",
                },
              },
              disabled: disabled,
            }}
            rules={{
              validate: {
                isDateValid: (date) =>
                  isEventDateValid({
                    eventStartDatetime: date,
                    openDatetime: openDate,
                    closeDatetime: closeDate,
                    disabled,
                  }),
              },
            }}
            name="eventDate"
          />
        </Box>
        <Box width="100%">
          <HookTimePicker
            disabled={disabled}
            slotProps={{
              textField: {
                outerLabel: "Time",
                id: "eventTime",
                placeholder: "--:-- --",
              },
            }}
            name="eventTime"
            rules={{
              required: {
                value: true,
                message: "Event Time is required.",
              },
            }}
            sx={{ width: "100%" }}
          />
        </Box>
        <Box width="100%">
          <TimeZoneAutocomplete disabled={disabled} validateOnChange={false} />
        </Box>
      </Stack>
    </>
  );
};

export default EventDateAndTime;
