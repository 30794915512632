import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../store/hooks";
import {
  selectMessageComposeData,
  useGetMessageQuery,
  initialState,
} from "../store/messageCompose";
import { useMemo } from "react";
import { isMessageSent } from "../utils/messages/isMessageSent";
import { TabOptions } from "../constants/message";

export const useMessageParams = (asDrawer?: boolean) => {
  const { messageId: paramMessageId, proxyEventId: paramProxyEventId } =
    useParams() as { messageId: string; proxyEventId: string };

  const { messageDrawerId, messageDrawerProxyEventId } = useAppSelector(
    selectMessageComposeData
  );

  // based on if in drawer use id's from redux or useParams ids
  const messageId = asDrawer ? (messageDrawerId as string) : paramMessageId;
  const proxyEventId = asDrawer
    ? (messageDrawerProxyEventId as string)
    : paramProxyEventId;

  const { data: messageData = initialState, isLoading: isMessageLoading } =
    useGetMessageQuery(messageId || skipToken);

  /**
   * Only show recipients if sent
   * Only show report if not proxy and sent
   */
  const tabs = useMemo(() => {
    const tabArr = [TabOptions.overview];
    const messageSent = isMessageSent(messageData.status);
    if (messageSent && !proxyEventId) {
      tabArr.push(TabOptions.report);
    }
    if (messageSent) {
      tabArr.push(TabOptions.recipients);
    }

    return tabArr;
  }, [messageData]);

  return {
    messageId,
    proxyEventId,
    tabs,
    isMessageLoading,
  };
};
