import { useCallback, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
// components
import { ButtonV2, useIsMounted } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import ImportantLinksDialog from "../ImportantLinksDialog";
// redux / data

// types
import { generateRandomString } from "../../../../../../utils/generateRandomString";
import { IImportantLink } from "../../../../../../store/messageCompose";
import ImportantLink from "../ImportantLink";

interface IProps {
  isLoading?: boolean;
  addCallback: () => void;
  editCallback: () => void;
}

const field = "content.importantLinks";

const ImportantLinksEditor = ({
  isLoading,
  addCallback,
  editCallback,
}: IProps) => {
  const isMounted = useIsMounted();

  /**
   * we're displaying both inputs and using the input data to render links
   * So we need to watch the entire object so we can display changes
   */
  const linksSelected: IImportantLink[] = useWatch({ name: field });
  const { resetField, trigger } = useFormContext();
  const { move, remove } = useFieldArray({
    name: field,
  });
  const [tempLinks, setTempLinks] = useState<IImportantLink[] | null>(null);
  const linksToUse = tempLinks || linksSelected || [];

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  // latest version of DND does not seem testable with react-testing-library
  /* istanbul ignore next */
  const dragEnd = useCallback(
    (result: DropResult) => {
      if (result.destination && result.source) {
        ////// temporary array to avoid flicker //////
        const newLinks = Array.from(linksSelected);
        newLinks.splice(result.source.index, 1);
        newLinks.splice(
          result.destination.index,
          0,
          linksSelected[result.source.index]
        );
        setTempLinks(newLinks);
        // reset tempLinks after next rerender
        /* istanbul ignore next */
        setTimeout(() => {
          if (isMounted()) {
            setTempLinks(null);
          }
        }, 300);
        /////// end temporary array /////
        move(result.source.index, result.destination.index);
      }
    },
    [linksSelected]
  );

  /**
   * save changes to add new important link
   */
  const saveOrAddLink = (add?: boolean) => {
    if (add) {
      addCallback();
    } else {
      editCallback();
    }
  };

  /**
   * remove important link
   */
  const removeLink = useCallback(
    (idx: number) => {
      const newLinks = Array.from(linksSelected);
      newLinks.splice(idx, 1);
      remove(idx);
      resetField(field, { defaultValue: newLinks, keepDirty: true });
      // even though form is valid, react-hook-form isnt being correctly updated until validation is re-triggered
      setTimeout(() => {
        if (isMounted()) {
          trigger(field);
        }
      }, 100);
    },
    [linksSelected]
  );

  return (
    <>
      <Stack>
        <DragDropContext onDragEnd={dragEnd}>
          <Droppable droppableId="links" direction="vertical">
            {(provided, snapshot) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                gap={6}
              >
                {linksToUse.map((link, idx) => (
                  <ImportantLink
                    link={link}
                    idx={idx}
                    isLoading={isLoading}
                    key={link.id}
                    saveOrAddLink={saveOrAddLink}
                    isSomethingDragging={snapshot.isUsingPlaceholder}
                    removeLink={removeLink}
                  />
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>
      {!isLoading ? (
        <Box mt={6}>
          <ButtonV2
            variant="secondary"
            onClick={openDialog}
            data-testid="button-addMoreLinks"
          >
            Add link
          </ButtonV2>
        </Box>
      ) : null}

      <ImportantLinksDialog
        open={isDialogOpen}
        closeDialog={closeDialog}
        addNew={true}
        isDisabled={isLoading}
        idx={linksToUse.length}
        saveOrAddLink={saveOrAddLink}
        link={{
          description: "",
          title: "",
          id: generateRandomString(),
          url: "",
          image: "",
        }}
      />
    </>
  );
};

export default ImportantLinksEditor;
