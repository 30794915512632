import { Text } from "@asayinc/component-library";
import { CardWrapper } from "../../../Common";

interface IProps {
  campaignName: string;
  onEdit?: () => void;
}

const CampaignNameCard = ({ campaignName, onEdit }: IProps) => {
  return (
    <CardWrapper sx={{ p: 8 }} onEdit={onEdit} cardName="campaign">
      <Text variant="subtitle2" sx={{ mb: 3 }}>
        Internal name
      </Text>
      <Text variant="h5" loadingProps={{ sx: { width: "250px" } }}>
        {campaignName || undefined}
      </Text>
    </CardWrapper>
  );
};

export default CampaignNameCard;
