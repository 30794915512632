import { useState } from "react";
import { COLORS, Dialog, Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { MessageTemplateTile } from "../../../Molecules";
import { CardWrapper } from "../../../../Common";
import { EmailPreviewDialogContent } from "../../../Organisms";
import {
  IMessageTemplate,
  useGetMessageTemplatesQuery,
} from "../../../../../store/messageCompose";

const FeaturedTemplates = () => {
  const { data: templates = [] } = useGetMessageTemplatesQuery();
  const [template, setTemplate] = useState<IMessageTemplate | null>(null);

  const closeDialog = () => {
    setTemplate(null);
  };

  // open template preview dialog
  const viewTemplate = (template: IMessageTemplate) => {
    setTemplate(template);
  };

  return (
    <CardWrapper
      cardName="featured-templates"
      sx={{ overflow: "hidden", mb: 10 }}
    >
      <Text variant="h5" mb={2}>
        Featured templates
      </Text>
      <Text variant="body2" mb={6}>
        Tell the story of your company with popular templates used by investor
        relation teams.
      </Text>
      <Stack direction="row" flexWrap={["wrap", "nowrap"]} gap={6}>
        {templates.map((template) => (
          <MessageTemplateTile
            key={template.name}
            template={template}
            width={["calc(50% - 12px)", "calc(25% - 16px)"]}
            viewTemplate={viewTemplate}
          />
        ))}
      </Stack>
      <Dialog
        fullBleed
        data-testid="template-preview-dialog"
        handleClose={closeDialog}
        content={
          !!template ? (
            <EmailPreviewDialogContent
              template={template}
              closeDialog={closeDialog}
              standalone
            />
          ) : null
        }
        open={!!template}
        sx={{
          ".MuiDialog-container > .MuiPaper-root": {
            maxWidth: 1206,
            bgcolor: COLORS.P_OUTLINE,
            width: "100%",
          },
        }}
      />
    </CardWrapper>
  );
};

export default FeaturedTemplates;
