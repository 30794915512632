import { CommonState } from "./types";

export const initialState: CommonState = {
  snackbar: {
    message: "",
    questionId: "",
    open: false,
    type: "",
    idx: undefined,
    severity: "success",
  },
  prevPage: "",
  status: "idle",
};
