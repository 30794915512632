import { stripEmptyParams } from "../apiTools";
import { EventsApiResponse } from "./types";
import { Params } from "../../types/Common";
import { rootApi } from "../rootApi";

export const allEventsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllEvents: builder.query<EventsApiResponse, Params>({
      query: (params) => {
        const queryString = new URLSearchParams(
          stripEmptyParams(params)
        ).toString();
        return `qa-events/?${queryString}`;
      },
      providesTags: ["AllEvents"],
    }),
    // example api for load more in rtkquery
    /* istanbul ignore next */
    getAllSubmittedEvents2: builder.query<EventsApiResponse, Params>({
      query: (params) => {
        const queryString = new URLSearchParams(
          stripEmptyParams(params)
        ).toString();
        return `qa-events/?${queryString}`;
      },
      // define which cache entry this query will be stored
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}-${queryArgs.status}`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.next = newItems.next;
        currentCache.results.push(...newItems.results);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.offset !== previousArg?.offset;
      },
      providesTags: ["AllEvents"],
    }),
  }),
});

export const { useGetAllSubmittedEvents2Query, useGetAllEventsQuery } =
  allEventsApi;
