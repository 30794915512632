import { HookSwitch, Text } from "@asayinc/component-library";
import { Stack, Link as MuiLink } from "@mui/material";
import { CardWrapper } from "../../../../../components/Common";

interface IProps {
  isLoading?: boolean;
  enabled?: boolean;
}

const AuthFeature = ({ isLoading, enabled }: IProps) => {
  return (
    <CardWrapper
      cardName="auth-feature"
      className="settingsSection"
      id="auth-with-say"
    >
      <Stack>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Auth with Say
        </Text>
        <Text variant="body2" emphasis="medium">
          Auth with Say allows companies to authorize shareholders with
          Say&apos;s API to create shareholder-only experiences on their website
          or in their app. Enabling this feature indicates that you agree to
          these{" "}
          <MuiLink
            color="primary.main"
            sx={{ fontWeight: 600 }}
            href="https://www.saytechnologies.com/auth-with-say-terms"
            target="_blank"
            rel="noreferrer"
          >
            terms and conditions
          </MuiLink>
          .
        </Text>

        <Stack direction="row" alignItems="center" mt={6}>
          <HookSwitch
            disableTypography
            label=""
            disabled={isLoading}
            data-testid="authWithSay"
            name="features.authWithSay"
            sx={{
              mr: 0,
            }}
          />
          <Text variant="subtitle2">{enabled ? "Enabled" : "Disabled"}</Text>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default AuthFeature;
