// components
import { Stack, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Text, HookTextField, COLORS } from "@asayinc/component-library";
import { FormImageUpload } from "../../../../components/Common/Molecules";
// types
import { ICompanyLeader } from "../../../../types/Events";
import {
  UseFormRegister,
  FieldValues,
  Control,
  UseFormSetValue,
  UseFormSetError,
} from "react-hook-form";
import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";

interface IProps {
  control: Control;
  register: UseFormRegister<FieldValues>;
  setLeaderValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  companyId: string;
  leader: ICompanyLeader;
  isDisabled?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const LeaderForm = ({
  control,
  register,
  setLeaderValue,
  setError,
  companyId,
  leader,
  isDisabled = false,
  dragHandleProps,
}: IProps) => {
  return (
    <Stack width="598px">
      <Box mb={6}>
        <Text variant="body1">
          The profiles of your company leaders will appear throughout
          shareholder experiences managed through Say.
        </Text>
      </Box>
      <Stack direction="row">
        <Stack direction="row" width="100%" data-testid="leader-form">
          {dragHandleProps && (
            <Box className="icons" mr={3} {...dragHandleProps} display="none">
              <MoreVertIcon style={{ color: COLORS.P_MED_EMPHASIS }} />
            </Box>
          )}
          <input
            type="hidden"
            {...register("enabled", {
              value: leader?.enabled,
            })}
          />
          <input
            type="hidden"
            {...register("companyId", {
              value: companyId,
            })}
          />
          <input type="hidden" {...register("id", { value: leader?.id })} />
          <Box width={140} mr={1}>
            <Text sx={{ mb: 2 }} variant="body2">
              Headshot
            </Text>
            <FormImageUpload
              name="photo"
              fileNameField="photoFilename"
              type="leader"
              size="medium"
              width={200}
              control={control}
              disabled={isDisabled}
              defaultValue={leader.photo}
              height={200}
              description="Use a high quality PNG image: 200x200px"
              data-testid={`photo-${leader.id || "new-leader"}`}
              circularCrop={true}
              setValue={setLeaderValue}
              setError={setError}
              register={register}
            />
          </Box>
          <Box width="100%" flex={1}>
            <Box>
              <HookTextField
                sx={{ width: "100%" }}
                name="fullName"
                placeholder="e.g., Bob Loblaw"
                control={control}
                defaultValue={leader.fullName}
                disabled={isDisabled}
                outerLabel="Name"
                id={`fullName-${leader.id}`}
                inputSize="small"
                rules={{
                  required: {
                    value: true,
                    message: "Name is required.",
                  },
                }}
              />
            </Box>
            <Box mt={3}>
              <HookTextField
                sx={{ width: "100%" }}
                name="longTitle"
                placeholder="e.g., Chief Executive Officer"
                outerLabel="Full Title"
                id={`longTitle-${leader.id}`}
                inputSize="small"
                control={control}
                disabled={isDisabled}
                defaultValue={leader.longTitle}
                rules={{
                  required: {
                    value: true,
                    message: "Full Title is required.",
                  },
                }}
              />
            </Box>
            <Box mt={3}>
              <HookTextField
                sx={{ width: "100%" }}
                fullWidth
                name="shortTitle"
                placeholder="e.g., CEO"
                inputSize="small"
                control={control}
                disabled={isDisabled}
                id={`shortTitle-${leader.id}`}
                defaultValue={leader.shortTitle}
                outerLabel="Short Title"
                rules={{
                  required: {
                    value: true,
                    message: "Short Title is required.",
                  },
                }}
              />
            </Box>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default LeaderForm;
