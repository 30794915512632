import { DATE_FORMAT, INPUT_TIME_FORMAT } from "@asayinc/component-library";
import dayjs from "dayjs";
import { QaPublishOptions } from "../../../../constants";
import {
  TIME_ZONE_VALUE_MAPPING,
  USER_TIMEZONE_OBJ,
} from "../../../../constants/timezones";
import {
  EventSortOptions,
  IActiveCompanyLeader,
  ICompanyLeader,
  IDraftEvent,
  ISortOrderObj,
} from "../../../../types/Events";
import { generateRandomString } from "../../../../utils/generateRandomString";
import { IFormFields } from "../../types";
import {
  isCloseDateValid,
  isEventDateValid,
  isOpenDateValid,
} from "../dateValidation";

/**
 * check if leader should be enabled
 */
const findLeaderOnId = (arr: IActiveCompanyLeader[], id: string) => {
  const item = arr.find((leader) => leader.leaderId === id);
  if (item) {
    return {
      ...item,
      enabled: true,
    };
  }
  return item;
};

/**
 * combine list of all leaders with ids of enabled leaders
 */
const parseLeadership = (
  allLeadership: ICompanyLeader[],
  activeLeaders: IActiveCompanyLeader[]
) => {
  const mergedLeaders = allLeadership.map((leader, idx) => ({
    ...leader,
    enabled: false,
    order: activeLeaders.length + idx,
    ...findLeaderOnId(activeLeaders, leader.id),
  }));

  return mergedLeaders.sort((a, b) => (a.order > b.order ? 1 : -1));
};

/**
 * sort the ordering response by order in the ordering array
 */
const getSortedList = (ordering: ISortOrderObj[] = []) =>
  [...ordering].sort((a, b) => (a.order > b.order ? 1 : -1));

/**
 * only set the fields for the current page so that we can use
 * react-hook-forms internal functions for checking that the current page isValid
 */
export const getDefaultValues = (
  {
    leaders,
    faqs,
    openDatetime,
    publishType,
    eventStartDatetime,
    publishDatetime,
    closeDatetime,
    ordering = [],
    meta,
    timezone,
    audience,
    eventType,
  }: IDraftEvent,
  allCompanyLeaders: ICompanyLeader[],
  currentStep: number,
  isDraft?: boolean
): IFormFields => {
  switch (currentStep) {
    case 0:
    default:
      return {
        eventType,
        meta: {
          ...meta,
          cadence: meta?.cadence || meta?.shareholderType,
        },
      };
    case 1:
      // use users timezone if none stored
      const tzObject = TIME_ZONE_VALUE_MAPPING[timezone] || USER_TIMEZONE_OBJ;
      const timezoneId = tzObject.utc[0];
      const sortedSorts = getSortedList(ordering);

      // TODO: dates are double wrapped to get rid of timezone format as datepicker doesnt support
      // Validate all date/time fields if this is a draft event, set to blank if invalid
      // this is so that if this is an old draft that has sat beyond todays date, the user
      // is able to select new date and times
      const dateFields = {
        eventStartDatetime: eventStartDatetime
          ? dayjs(
              dayjs(eventStartDatetime)
                .tz(timezoneId)
                .format(`${DATE_FORMAT} ${INPUT_TIME_FORMAT}`),
              `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`
            )
          : null,
        openDatetime: openDatetime
          ? dayjs(
              dayjs(openDatetime)
                .tz(timezoneId)
                .format(`${DATE_FORMAT} ${INPUT_TIME_FORMAT}`),
              `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`
            )
          : null,
        closeDatetime: closeDatetime
          ? dayjs(
              dayjs(closeDatetime)
                .tz(timezoneId)
                .format(`${DATE_FORMAT} ${INPUT_TIME_FORMAT}`),
              `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`
            )
          : null,
      };

      // only display dates if its a non-draft or dates ar valid
      const openDateValid = !isDraft || isOpenDateValid(dateFields) === true;
      const closeDateValid = !isDraft || isCloseDateValid(dateFields) === true;
      const eventDateValid = !isDraft || isEventDateValid(dateFields) === true;

      const eventDate = eventDateValid ? dateFields.eventStartDatetime : null;

      const publishDate = publishDatetime
        ? dayjs(
            dayjs(publishDatetime)
              .tz(timezoneId)
              .format(`${DATE_FORMAT} ${INPUT_TIME_FORMAT}`),
            `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`
          )
        : null;

      const openDate = openDateValid ? dateFields.openDatetime : null;

      const closeDate = closeDateValid ? dateFields.closeDatetime : null;

      return {
        publishType: publishType || QaPublishOptions.Specific,
        sortOrder: sortedSorts.length
          ? sortedSorts[0].value
          : EventSortOptions.RECOMMENDED,
        eventDate,
        publishDate: publishDate,
        publishTime: publishDate,
        openDate: openDate,
        closeDate: closeDate,
        eventTime: eventDate,
        openTime: openDate,
        closeTime: closeDate,
        timezone: tzObject
          ? {
              id: tzObject.value,
              name: tzObject.value,
              offset: tzObject.offset,
              group: "",
            }
          : undefined,
      };
    case 2:
      const parsedLeadership = parseLeadership(
        allCompanyLeaders,
        leaders || []
      );
      const parsedFaqs = (faqs || []).map((faq) => ({
        ...faq,
        id: generateRandomString(),
      }));
      return {
        leaders: parsedLeadership,
        faqs: parsedFaqs,
        audience,
      };
  }
};
