import React from "react";
import { Draggable } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@asayinc/component-library";
import { Stack } from "@mui/material";
// components
import { BaseOptionChip } from "../../Atoms";
import { Emoji } from "../../../../../../Common";
import { useFormContext } from "react-hook-form";
import { FORM_FIELDS } from "../../../../../../../constants";

interface IProps {
  emoji: string;
  idx: number;
  removeEmoji: (idx: number) => void;
  isSomethingDragging?: boolean;
  disabled?: boolean;
}

const EmojiOption = ({
  emoji,
  removeEmoji,
  idx,
  isSomethingDragging,
  disabled,
}: IProps) => {
  const { register } = useFormContext();

  const fieldName = `${FORM_FIELDS.quickReplies.emojiData.options}.${idx}`;

  /**
   * delete using useFieldArray on index
   */
  const onDeleteClick = () => {
    removeEmoji(idx);
  };

  return (
    <Draggable index={idx} draggableId={emoji} isDragDisabled={disabled}>
      {(provided, snapshot) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <BaseOptionChip
            sx={{
              width: 132,
              px: 3,
              mr: 3,
              justifyContent: "space-around",
            }}
            isDragging={snapshot.isDragging}
          >
            <input type="hidden" {...register(fieldName)} />
            <Stack
              {...provided.dragHandleProps}
              data-testid={`scrubber-${emoji}`}
              width={32}
              mr={1.5}
              alignItems="center"
            >
              <DragIndicatorIcon
                style={{ color: "black", display: "block" }}
                fontSize="small"
              />
            </Stack>
            <Emoji emoji={emoji} />
            <IconButton
              onClick={onDeleteClick}
              sx={{
                pointerEvents: isSomethingDragging ? "none" : undefined,
                ml: 1.5,
              }}
            >
              <CloseIcon data-testid={`delete-${emoji}`} />
            </IconButton>
          </BaseOptionChip>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(EmojiOption);
