import { COLORS } from "@asayinc/component-library";
import { ProfileStateOptions } from "../types/Settings";

export const COMPANY_STATE_MAPPING = {
  [ProfileStateOptions.APPROVED]: {
    color: COLORS.LIGHT_GREEN,
    content: "Approved",
  },
  [ProfileStateOptions.IN_PROGRESS]: {
    color: COLORS.ICE_BLUE,
    content: "In Progress",
  },
  [ProfileStateOptions.PENDING]: {
    color: COLORS.LIGHT_YELLOW,
    content: "Pending Approval",
  },
};

export const TESLA_ID = "450d645a-83b6-4646-bc77-52db6be7b18f";

export const SAY_ID = "c0899595-aa9f-4aec-b170-20a92753399f";

export const DELETED_USER_ID = "00000000-0000-0000-0000-000000000000";
