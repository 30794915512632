import { MessageStatus } from "../../types/Messages";

export const SENT_TYPES = `${MessageStatus.Attached},${MessageStatus.Attaching},${MessageStatus.Fetched},${MessageStatus.Fetching},${MessageStatus.ReadyForFetching},${MessageStatus.RefreshingIndex},${MessageStatus.Sending},${MessageStatus.Sent}`;
export const NOT_SENT_TYPES = `${MessageStatus.Draft},${MessageStatus.Scheduled},${MessageStatus.PendingApproval}`;

export const DEFAULT_MESSAGE_LIMIT = 200;

export const NOT_SENT_SORT_ORDER = {
  [MessageStatus.Draft]: 0,
  [MessageStatus.Scheduled]: 1,
  [MessageStatus.PendingApproval]: 2,
};
