import ShareholderDrawer from "../../../ShareholderDrawer";
import QuestionDrawer from "../../../QuestionDrawer";

const Drawers = () => {
  return (
    <>
      <ShareholderDrawer />
      <QuestionDrawer />
    </>
  );
};

export default Drawers;
