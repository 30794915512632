import { Stack } from "@mui/material";
import { Tooltip } from "@asayinc/component-library";

interface IProps {
  tooltip: string;
  icon: JSX.Element | null;
}

const EventIcon = ({ tooltip, icon }: IProps) => {
  if (!icon) {
    return null;
  }

  return (
    <Tooltip title={tooltip} placement="top">
      <Stack
        alignItems="center"
        justifyContent="center"
        width="32px"
        height="32px"
        bgcolor="#f0f0f0"
        borderRadius={9}
        boxShadow="2px 2px 12px rgba(0, 0, 0, 0.02)"
      >
        {icon}
      </Stack>
    </Tooltip>
  );
};

export default EventIcon;
