import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd";
import { useForm, useFormContext } from "react-hook-form";
import { ICompanyLeader } from "../../../../../../types/Events";
import { getDirtyFormValues } from "../../../../../../utils";
// components
import { Dialog, IButtonAction } from "@asayinc/component-library";
import { LeaderForm } from "../../../../Molecules";

interface IProps {
  open: boolean;
  closeDialog: () => void;
  addNew?: boolean;
  leader: ICompanyLeader;
  idx: number;
  saveOrAddLeader: (data: {
    leader: Partial<ICompanyLeader>;
    add?: boolean;
    id: string;
  }) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  isDisabled?: boolean;
}

const LeaderDialog = ({
  open,
  closeDialog,
  leader,
  idx,
  addNew,
  saveOrAddLeader,
  dragHandleProps,
  isDisabled,
}: IProps) => {
  const {
    getValues,
    control,
    register,
    handleSubmit,
    setValue: setLeaderValue,
    setError,
    formState: { isDirty, isValid, dirtyFields, errors },
  } = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
  });

  const { setValue } = useFormContext();

  const hasError = Object.keys(errors).length > 0;

  // location and index this field is in the form
  const locationInForm = `leaders.${idx}`;
  // if no errors and the appropriate amount of fields are dirty, we can save
  const canSave = (!hasError || isValid) && isDirty;

  /**
   * When saving we replace the existing state of the leader with the new values from the edit state
   * Then call parent function to hit api and update with a patch or post
   */
  const save = () => {
    const updatedValues = getValues() as ICompanyLeader;
    const dirtyLeader = getDirtyFormValues(
      updatedValues,
      dirtyFields
    ) as Partial<ICompanyLeader>;

    setValue(locationInForm, updatedValues, { shouldTouch: true });

    // use all values if its create new, otherwise only dirty
    const leaderToUpload = addNew ? updatedValues : dirtyLeader;
    // set the filename to the photo for posts
    saveOrAddLeader({
      leader: {
        ...leaderToUpload,
        photo: leaderToUpload.photoFilename,
        photoFilename: undefined,
      },
      id: updatedValues.id,
      add: addNew,
    });
    closeDialog();
  };

  const actions = [
    {
      onClick: handleSubmit(save),
      disabled: !canSave || isDisabled,
      label: "Save",
    },
  ] as IButtonAction[];

  return (
    <Dialog
      data-testid="leader-dialog"
      open={open}
      title="Company Leadership"
      handleClose={closeDialog}
      buttonActions={actions}
      content={
        <LeaderForm
          control={control}
          register={register}
          setLeaderValue={setLeaderValue}
          setError={setError}
          leader={leader}
          companyId={leader.companyId}
          isDisabled={isDisabled}
          dragHandleProps={dragHandleProps}
        />
      }
    />
  );
};

export default LeaderDialog;
