import { Dialog, Text, IButtonAction } from "@asayinc/component-library";
import { unstable_Blocker as Blocker } from "react-router";

interface IProps {
  blocker: Blocker;
  save: () => void;
}

const SaveDialog = ({ blocker, save }: IProps) => {
  const buttonActions = [
    {
      label: "Save",
      onClick: () => {
        save();
        blocker.proceed?.();
      },
    },
    {
      label: "Discard",
      variant: "secondary",
      onClick: () => blocker.proceed?.(),
    },
  ] as IButtonAction[];

  const closeDialog = () => {
    // remain on page
    blocker.reset?.();
  };

  return (
    <Dialog
      titleTextVariant="subtitle1"
      title="Save as draft?"
      data-testid="save-dialog"
      handleClose={closeDialog}
      buttonActions={buttonActions}
      content={
        <Text variant="body1" width="279px">
          You’re leaving the page now. Save as draft so you can come back later.
        </Text>
      }
      open={blocker.state === "blocked"}
    />
  );
};

export default SaveDialog;
