import { MessageStatus } from "../../types/Messages";
import { MessageType } from "../messageLists";

export enum QuickReplyTypeOptions {
  Text = "text",
  Emoji = "emoji",
}

export enum MessageDialog {
  Schedule = "schedule",
  Email = "email",
  Send = "send",
  Confirmation = "confirmation",
  Delete = "delete",
  ConfirmEdit = "confirm_edit",
}

export interface IImportantLink {
  id?: string;
  title: string;
  url: string;
  description: string;
  image: string;
}

export interface IBaseComposeMessage {
  id: string;
  campaignName: string;
  status:
    | MessageStatus.Scheduled
    | MessageStatus.Draft
    | MessageStatus.PendingApproval;
  filterMap?: IFilterMap;
  type: MessageType;
  proxyEventId?: string;
  templateId?: string;
  pushNotificationTemplateId?: number;
  content?: Partial<IComposeMessageContent> | null;
}

export interface IComposeSignatureOption {
  photo: string;
  name: string;
  title: string;
  id: string;
}

export interface IComposeSignature {
  leaderId: string;
}

export interface IQuickReply {
  type: QuickReplyTypeOptions;
  prompt: string;
  options: string[];
}

export interface IQuickReplyOptionObj {
  value: string;
  id: string;
}

export interface IQuickReplyObjectVariant {
  type: QuickReplyTypeOptions;
  prompt: string;
  options: IQuickReplyOptionObj[];
}

export interface IComposeMessageContent {
  subject: string;
  greetings: string;
  body: string;
  bodyShort: string;
  quickReplies?: IQuickReply;
  signature?: IComposeSignature;
  importantLinks: IImportantLink[];
}

interface IScheduledMessageFields {
  htmlPreview: string;
  scheduledSendAt: string | null;
  scheduledSendAtTimezone: string | null;
}

export interface IScheduledComposeMessage
  extends IBaseComposeMessage,
    IScheduledMessageFields {
  status: MessageStatus.Scheduled | MessageStatus.PendingApproval;
}

export interface IDraftComposeMessage
  extends IBaseComposeMessage,
    Partial<IScheduledMessageFields> {
  status: MessageStatus.Draft;
}

export type IShareholderComposeMessage =
  | IScheduledComposeMessage
  | IDraftComposeMessage;

export interface IMessageFilterStats {
  numShareholders: number;
  sharesRepresented: number;
}

export interface IFilterMap {
  query: IFilterQueries;
}

export interface IFilterQueries {
  [key: string]: IFilterQuery;
}

export interface IRangeFilter {
  range: {
    lte?: number | string;
    gte?: number | string;
  };
}

export interface IListFilter {
  values: string[] | number[] | boolean[];
}

export interface IRadioFilter {
  radio: string;
}

export type IFilterQuery = IRangeFilter | IListFilter | IRadioFilter;
export interface IFilterEventOption {
  id: number;
  slug: string;
  title: string;
}

export interface IFilterMessageOption {
  id: number;
  slug: string;
  campaignName: string;
}

export interface IMessageSuggestions {
  campaignName: string[];
  subject: string[];
  body: string[];
}

export interface IMessageTemplate {
  imageAssets: {
    sm: string;
    md: string;
  };
  id: string;
  name: string;
  description: string;
  isBlank?: boolean;
  htmlPreview: string;
  subject: string;
  suggestions: IMessageSuggestions;
}

export interface ISEOMetaData {
  title?: string;
  description?: string;
  image?: string;
}
