import { DATE_FORMAT, nFormatter } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
// components
import { QuestionStat } from "../../Molecules";
// api
import { useGetShareholderMetaQuery } from "../../../../../../../store/shareholder";
// types
import { Question } from "../../../../../../../types/Questions";

interface IProps {
  asDrawer?: boolean;
  question: Question;
}

const QuestionStats = ({ asDrawer, question }: IProps) => {
  const { data: metaData } = useGetShareholderMetaQuery({
    shareholderId: question.globalShareholderId,
  });
  // 2 columns in drawer, 4 on page
  return (
    <Stack direction="row" flexWrap={asDrawer ? "wrap" : "nowrap"}>
      <Stack
        direction="row"
        width={asDrawer ? "100%" : "50%"}
        gap={asDrawer ? 12 : 8}
      >
        <QuestionStat
          title="Upvotes"
          value={nFormatter(question.countUsersUpvoted)}
        />
        <QuestionStat
          title="Shares represented"
          value={nFormatter(question.countSharesUpvoted)}
        />
      </Stack>
      <Stack
        direction="row"
        width={asDrawer ? "100%" : "50%"}
        gap={asDrawer ? 12 : 8}
        mt={asDrawer ? 6 : 0}
      >
        <QuestionStat
          title="Asked by"
          value={question.authorName}
          profileColor={metaData?.profileColor}
          tags={metaData?.tags}
        />
        <QuestionStat
          title="Submitted"
          value={dayjs(question.dateAsked).format(DATE_FORMAT)}
        />
      </Stack>
    </Stack>
  );
};

export default QuestionStats;
