import { useEffect, useState } from "react";
import { Text, useIsMounted, SecondaryTabs } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { CardWrapper } from "../../../Common";
import { EmailPreview, InAppPreview } from "./components";
import { useGetMessageQuery } from "../../../../store/messageCompose";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetPushNotificationTemplatesQuery } from "../../../../store/messageLists";

interface IProps {
  htmlPreview?: string;
  subject?: string;
  onEdit?: (e: React.MouseEvent<HTMLElement>) => void;
  proxyEventId?: string | null;
  messageId: string;
}

const PreviewCard = ({
  htmlPreview,
  onEdit,
  subject,
  proxyEventId,
  messageId,
}: IProps) => {
  const { data: messageData } = useGetMessageQuery(messageId as string);
  const { data: notificationTemplates = [] } =
    useGetPushNotificationTemplatesQuery(proxyEventId || skipToken);
  const [emailHtml, setEmailHtml] = useState("");
  const [activeTab, setActiveTab] = useState("email");
  const isMounted = useIsMounted();

  const activeNotification = notificationTemplates.find(
    (n) => n.id === messageData?.pushNotificationTemplateId
  );

  useEffect(() => {
    const getHtml = async () => {
      if (!htmlPreview) {
        return;
      }
      const res = await fetch(htmlPreview as string).then((res) => res.text());
      if (isMounted()) {
        setEmailHtml(res);
      }
    };

    getHtml();
  }, [htmlPreview]);
  const isLoading = !emailHtml;

  const tabData = [
    {
      label: "Email",
      value: "email",
      loading: isLoading,
    },
    {
      label: "In-app message",
      value: "inapp",
      loading: isLoading,
    },
  ];

  const handleClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <CardWrapper sx={{ p: 8 }} onEdit={onEdit} cardName="preview">
      <Stack height="100%">
        {proxyEventId && (
          <Box mb={6}>
            <Text variant="subtitle2" mb={4}>
              Message preview
            </Text>
            <Stack alignItems="center">
              <Box width={isLoading ? "323px" : "262px"}>
                <SecondaryTabs
                  tabs={tabData}
                  activeTab={activeTab}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  clickHandler={!isLoading ? handleClick : () => {}}
                />
              </Box>
            </Stack>
          </Box>
        )}
        {activeTab === "email" ? (
          <EmailPreview
            subject={subject}
            isLoading={isLoading}
            emailHtml={emailHtml}
          />
        ) : (
          <InAppPreview
            messageText={messageData?.content?.bodyShort}
            notification={activeNotification}
            isStacked
          />
        )}
      </Stack>
    </CardWrapper>
  );
};

export default PreviewCard;
