import { IStep } from "@asayinc/component-library";

interface IProps {
  isProxyMessage: boolean;
  goToStep: (step: number) => void;
  campaignName?: string;
  subject?: string;
  scheduleString?: string;
  bodyShort?: string;
  query?: unknown;
  reach?: string;
  pushNotificationTemplateId?: number;
}

/**
 * get step indicator data for message flow header
 */
export const getStepIndicatorData = ({
  isProxyMessage,
  goToStep,
  campaignName = "",
  subject = "",
  scheduleString = "",
  bodyShort = "",
  query,
  reach,
  pushNotificationTemplateId,
}: IProps): IStep[] => {
  // information to power the step indicators
  const stepData = [
    {
      stepName: "Name your campaign",
      additionalText: campaignName,
      complete: !!campaignName,
      onClick: () => goToStep(0),
    },
    {
      stepName: "Choose recipients",
      additionalText: reach,
      complete: !!query,
      onClick: !!campaignName ? () => goToStep(1) : undefined,
    },
    {
      stepName: "Draft email",
      complete: !!subject && !!query,
      additionalText: subject,
      onClick: !!query ? () => goToStep(2) : undefined,
    },
    ...(isProxyMessage
      ? [
          {
            stepName: "Draft in-app message",
            complete: !!pushNotificationTemplateId,
            additionalText: bodyShort,
            onClick: !!query ? () => goToStep(3) : undefined,
          },
        ]
      : []),
    {
      stepName: "Review and send",
      complete: !!scheduleString,
      additionalText: `Scheduled for ${scheduleString}`,
      onClick: !!subject ? () => goToStep(isProxyMessage ? 4 : 3) : undefined,
    },
  ];

  return stepData;
};
