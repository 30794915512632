import { IComposeSignatureOption } from "../../../../../store/messageCompose";
import { ICompanyLeader } from "../../../../../types/Events";

interface IMapSignature extends ICompanyLeader {
  companyName: string;
}

/**
 * convert leader data to signature object model
 */
export const mapLeaderToSignature = ({
  fullName,
  photo,
  companyName,
  longTitle,
  id,
}: IMapSignature): IComposeSignatureOption => ({
  name: fullName,
  title: `${longTitle}, ${companyName}`,
  photo,
  id,
});
