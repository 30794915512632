import { IFilterQueries, IListFilter } from "../../store/messageCompose";
import { FILTER_MESSAGING_MAP } from "../../constants";

/**
 * Messaging for result of filtering by city/state
 */
export const getCityStateMessaging = (
  query: IFilterQueries,
  key: "city" | "state"
) => {
  const { chipStartWord, chipEndWord } = FILTER_MESSAGING_MAP.location;

  if (key === "city") {
    return `${chipStartWord} ${
      (query[key] as IListFilter)?.values?.[0]
    } ${chipEndWord}`.trim();
  }
  if (key === "state" && !(query?.["city"] as IListFilter)?.values?.[0]) {
    return `${chipStartWord} ${
      (query[key] as IListFilter)?.values?.[0]
    } ${chipEndWord}`.trim();
  }
  return "";
};
