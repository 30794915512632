import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
// utils
import debounce from "lodash.debounce";
// constants
import { URL_PARAMS } from "../constants";
import { validateParam } from "../utils";
import { useParams } from "./useParams";

export const useSearchFns = (queryParam = "search", updateUrl = true) => {
  const [searchParams, setSearchParams] = useParams(updateUrl);
  const { [URL_PARAMS[queryParam]]: search = "" } = Object.fromEntries(
    new URLSearchParams(searchParams)
  );
  const [searchTerm, setSearchTerm] = useState(search || "");
  const didMount = useRef<boolean>(false);

  const triggerSearch = useCallback(
    debounce((searchTerm) => {
      if (searchTerm) {
        searchParams.set(URL_PARAMS[queryParam], searchTerm);
      } else {
        searchParams.delete(URL_PARAMS[queryParam]);
      }
      searchParams.set(URL_PARAMS.page, "1");
      setSearchParams(searchParams);
    }, 150),
    [searchParams]
  );

  useEffect(() => {
    if (didMount.current) {
      const validatedValue = validateParam(URL_PARAMS[queryParam], searchTerm);
      triggerSearch(validatedValue);
    } else {
      didMount.current = true;
    }
  }, [searchTerm]);

  const clearSearch = () => {
    searchParams.delete(URL_PARAMS[queryParam]);
    searchParams.set(URL_PARAMS.page, "1");
    setSearchTerm("");
    setSearchParams(searchParams);
  };

  const updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return {
    updateSearch,
    clearSearch,
    searchTerm,
  };
};
