import { useCallback } from "react";
import { useAppDispatch } from "../store/hooks";
import { MessageDialog, setMessageDialog } from "../store/messageCompose";

export const useMessageDialogHooks = (id?: string, proxyEventId?: string) => {
  const dispatch = useAppDispatch();
  const scheduleSend = useCallback(() => {
    dispatch(
      setMessageDialog({
        messageDialog: MessageDialog.Schedule,
        messageDialogId: id as string,
        proxyEventDialogId: proxyEventId,
      })
    );
  }, [id]);

  const testEmail = useCallback(() => {
    dispatch(
      setMessageDialog({
        messageDialog: MessageDialog.Email,
        messageDialogId: id as string,
        proxyEventDialogId: proxyEventId,
      })
    );
  }, [id]);

  const openDeleteDialog = useCallback(() => {
    dispatch(
      setMessageDialog({
        messageDialog: MessageDialog.Delete,
        messageDialogId: id as string,
        proxyEventDialogId: proxyEventId,
      })
    );
  }, [id]);

  const openConfirmEditDialog = useCallback(() => {
    dispatch(
      setMessageDialog({
        messageDialog: MessageDialog.ConfirmEdit,
        messageDialogId: id as string,
        proxyEventDialogId: proxyEventId,
      })
    );
  }, [id]);

  return {
    scheduleSend,
    testEmail,
    openDeleteDialog,
    openConfirmEditDialog,
  };
};
