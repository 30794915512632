import { Stack } from "@mui/material";
import {
  CompanyParticipantsForm,
  EventDetailsForm,
  InvestorCsvUpload,
} from "../../Organisms";
import {
  CardWrapper,
  FormSectionDivider,
} from "../../../../../components/Common";

interface IProps {
  isLoading?: boolean;
}

const AdditionalDetailsTemplate = ({ isLoading }: IProps): JSX.Element => {
  return (
    <Stack spacing={8}>
      <CardWrapper cardName="companyParticipants">
        <CompanyParticipantsForm isLoading={isLoading} />
      </CardWrapper>
      <FormSectionDivider />
      <CardWrapper cardName="eventDetails">
        <EventDetailsForm isLoading={isLoading} />
      </CardWrapper>
      <CardWrapper cardName="audience">
        <InvestorCsvUpload isLoading={isLoading} />
      </CardWrapper>
    </Stack>
  );
};

export default AdditionalDetailsTemplate;
