import { useMemo } from "react";
import { EditHeader } from "../../../../../components/Common";
import { IDraftEvent } from "../../../../../types/Events";
import { getStepIndicatorData } from "../../../helpers/getStepIndicatorData";
import { useEventData } from "../../../hooks";

interface IProps {
  goToStep: (step: number) => void;
  currentStep: number;
  event: IDraftEvent;
}

const Header = ({ goToStep, event, currentStep }: IProps) => {
  const {
    event: { title },
    isLoading,
  } = useEventData();

  // step data to render the step indicators
  const { status, eventType, eventStartDatetime, draftStep, leaders } = event;
  const stepIndicatorData = useMemo(
    () =>
      getStepIndicatorData({
        status,
        goToStep,
        eventType,
        eventStartDatetime,
        leaders,
        draftStep,
      }),
    [status, eventType, leaders, eventStartDatetime, draftStep, currentStep]
  );

  const header = title ? title : "New Q&A";

  return (
    <EditHeader
      header={isLoading ? undefined : header}
      stepIndicatorData={stepIndicatorData}
      currentStep={currentStep}
    />
  );
};

export default Header;
