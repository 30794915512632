export const COLUMNS = [
  "campaignName",
  "deliveredCount",
  "scheduledSendAt",
  "openedRate",
  "clickedRate",
  "repliedRate",
];

/**
 * Get no results messaging
 */
export const NO_RESULTS_DATA = {
  title: "No sent messages in this filtered view",
  subtitle:
    "Sorry, there are no sent messages that match your applied filters.",
};
