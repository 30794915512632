import { useCallback, useMemo, useState } from "react";
import { FieldValues, UseFieldArrayAppend } from "react-hook-form";
import { ButtonV2, Text, IconButton } from "@asayinc/component-library";
import CloseIcon from "@mui/icons-material/Close";
// constants
import { EMOJI_LIST } from "../../../../../../../constants";
// components
import { BaseOptionChip } from "../../Atoms";
import AddEmojiOption from "./AddEmojiOption";

interface IProps {
  emojisSelected: string[];
  append: UseFieldArrayAppend<FieldValues, string>;
  disabled?: boolean;
}

const AddMoreEmojis = ({ emojisSelected, append, disabled }: IProps) => {
  const [addingMore, setAddingMore] = useState(false);

  // open/close add more emojis
  const toggleAddMore = useCallback(() => {
    setAddingMore((bool) => !bool);
  }, []);

  /**
   * Add emoji and if at max, remove emoji options
   */
  const handleAddEmoji = useCallback(
    (emoji: string) => {
      if (emojisSelected.length > 3) {
        setAddingMore(false);
      }
      append(emoji);
    },
    [emojisSelected]
  );

  const emojisAvailable = useMemo(() => {
    return EMOJI_LIST.filter((emoji) => !emojisSelected.includes(emoji));
  }, [emojisSelected]);

  if (addingMore && !disabled) {
    return (
      <BaseOptionChip sx={{ px: 2, gap: 2 }}>
        {emojisAvailable.map((emoji) => (
          <AddEmojiOption
            key={emoji}
            onClick={handleAddEmoji}
            emoji={emoji}
            data-testid={`add-${emoji}`}
          />
        ))}
        <IconButton onClick={toggleAddMore} data-testid="btn-close-addmore">
          <CloseIcon />
        </IconButton>
      </BaseOptionChip>
    );
  }

  if (emojisSelected.length < 5) {
    return (
      <ButtonV2
        defaultIcon
        variant="secondary"
        disabled={disabled}
        onClick={toggleAddMore}
        data-testid="button-addMoreEmoji"
      >
        Add
      </ButtonV2>
    );
  }

  return (
    <Text variant="body2" emphasis="disabled">
      You can only add 5 options
    </Text>
  );
};

export default AddMoreEmojis;
