import { NextPrev, Tag } from "./Common";
import { ICompanyLeader } from "./Events";
import { ICategory } from "../store/questionCategories";

export enum ShareholderType {
  Retail = "retail",
  Institutional = "institutional",
  Analyst = "analyst",
  All = "all",
}

export enum QuestionStatus {
  Answered = "answered",
  Pending = "pending_approval",
  Published = "published",
  Rejected = "rejected",
}

export enum QuestionFilterStatusType {
  Tagged = "contains_tags",
  NotTagged = "does_not_contain_tags",
  HasNotes = "contains_notes",
  NoNotes = "does_not_contain_notes",
  Favorited = "selected",
  Answered = "answered",
  Published = "published",
}

export interface Note {
  textJson?: string | null;
  textPlain?: string | null;
  userId?: number;
}

export interface ICompanyLeadershipResponse {
  companyLeader: ICompanyLeader;
  response: string;
}

export interface Question {
  institutionName: string;
  isSelectedByIssuer: boolean;
  dateAsked: string;
  authorName: string;
  highlightedQuestionText: string | null;
  highlightedQuestionAuthor: string | null;
  eventSlug: string;
  notes?: Note;
  id: string;
  isAnonymous: boolean;
  questionText: string;
  eventHostResponse: string;
  countSharesUpvoted: number;
  status: QuestionStatus;
  tags: Tag[];
  type: ShareholderType;
  countUsersUpvoted: number;
  userSharesOwned: number;
  profileColor: string;
  numShareholderTags: number;
  globalUserId: string;
  globalShareholderId: string;
  companyLeadershipResponse: ICompanyLeadershipResponse[];
  shareholderTags: Tag[];
  specificCategory: Partial<ICategory> | null;
}

export interface Questions {
  count: number;
  next: NextPrev;
  previous: NextPrev;
  questions: Question[];
}
