import dayjs from "dayjs";
import {
  DoDisturbOnRounded,
  AccessTimeFilled,
  HowToVote,
  FiberManualRecord,
  Email,
} from "@mui/icons-material";
import {
  COLORS,
  DATE_FORMAT,
  nFormatter,
  SayIcon,
} from "@asayinc/component-library";
// types
import {
  IVoteSolicitation,
  IAdditionalMailing,
} from "../../../../../../../store/proxyEvents/types";
import { IProxyTimelineStat } from "../../../../../../../store/proxyEvent/types";
import { PointData } from "./types";

export const getLifecyclePillProps = (
  meetingDateStr: string,
  voteCutoffStr: string
) => {
  const currentDate = dayjs();
  const meetingDate = dayjs(meetingDateStr);
  const voteCutoff = dayjs(voteCutoffStr);

  let text = "Ongoing";
  let icon = null;
  let disabled = false;

  const daysTillMeetingDate = meetingDate.diff(currentDate, "day");

  if (daysTillMeetingDate >= 15) {
    text = "Ongoing";
    icon = <HowToVote />;
  } else if (daysTillMeetingDate <= 14 && daysTillMeetingDate >= 11) {
    text = "2 weeks left";
    icon = <AccessTimeFilled />;
  } else if (daysTillMeetingDate <= 10 && daysTillMeetingDate >= 2) {
    text = `${daysTillMeetingDate} days left`;
    icon = <AccessTimeFilled />;
  } else if (currentDate.isSame(voteCutoff, "date")) {
    text = "Proxy voting cutoff";
    icon = (
      <DoDisturbOnRounded
        style={{
          transform: "rotate(45deg)",
          fontSize: "16px",
        }}
      />
    );
  } else if (currentDate.isSame(meetingDate, "date")) {
    text = "Meeting day";
    icon = <FiberManualRecord />;
  } else if (daysTillMeetingDate === -1) {
    text = "Ongoing";
    icon = <HowToVote />;
  } else if (daysTillMeetingDate <= -2) {
    text = "Closed";
    disabled = true;
  }

  return { icon, text, disabled };
};

const votingMessageIcon = (
  <Email sx={{ width: "16px", height: "16px", fill: COLORS.BLACK }} />
);
const proxyMailIcon = <SayIcon name="LocalPostOffice" size="16px" />;

export const getEventIconProps = (
  voteSolicitations: IVoteSolicitation[],
  additionalMailings: IAdditionalMailing[]
) => {
  if (voteSolicitations.length === 0 && additionalMailings.length === 0) {
    return { icon: null, tooltip: "" };
  }

  const lastSolicitation = voteSolicitations[0];
  const lastMailing = additionalMailings[0];

  let tooltip = "";
  let icon = null;

  // if both communications are defined, we choose the most recent for the tooltip text
  if (lastSolicitation && lastMailing) {
    if (
      dayjs(lastSolicitation.scheduledSendAt).isAfter(
        lastMailing.scheduledSendAt
      )
    ) {
      tooltip = `Voting message ${voteSolicitations.length} sent on ${dayjs(
        lastSolicitation.scheduledSendAt
      ).format(DATE_FORMAT)}`;
      icon = votingMessageIcon;
    } else {
      tooltip = `Additional mailing ${
        additionalMailings.length
      } sent on ${dayjs(lastMailing.scheduledSendAt).format(DATE_FORMAT)}`;
      icon = proxyMailIcon;
    }
  } else {
    // handle when only 1 communication is defined
    if (lastSolicitation) {
      tooltip = `Voting message ${voteSolicitations.length} sent on ${dayjs(
        lastSolicitation.scheduledSendAt
      ).format(DATE_FORMAT)}`;
      icon = votingMessageIcon;
    } else {
      tooltip = `Additional mailing ${
        additionalMailings.length
      } sent on ${dayjs(lastMailing.scheduledSendAt).format(DATE_FORMAT)}`;
      icon = proxyMailIcon;
    }
  }

  return { icon, tooltip };
};

export const getTrendDataProps = (trendData?: IProxyTimelineStat[]) => {
  if (!trendData) return {};

  // Shares voted - get data for line chart
  let cumulativeSharesVoted = 0;
  const sharesVotedChartData = [] as PointData[];
  trendData.forEach((curr, i) => {
    cumulativeSharesVoted += parseFloat(curr?.totalSharesVoted || "0");
    sharesVotedChartData[i] = { val: cumulativeSharesVoted };
  });

  // Shareholders voted - get data for line chart
  let cumulativeShareholdersVoted = 0;
  const shareholdersVotedChartData = [] as PointData[];
  trendData.forEach((curr, i) => {
    cumulativeShareholdersVoted += curr?.customerCount || 0;
    shareholdersVotedChartData[i] = { val: cumulativeShareholdersVoted };
  });

  // Shares voted - get trend over data set for badge
  const sharesVotedBadgeText = `${nFormatter(cumulativeSharesVoted)} last ${
    trendData.length
  } days`;

  // Shareholders voted - get trend over data set for badge
  const shareholdersVotedBadgeText = `${nFormatter(
    cumulativeShareholdersVoted
  )} last ${trendData.length} days`;

  return {
    sharesVotedChartData,
    shareholdersVotedChartData,
    sharesVotedBadgeText,
    shareholdersVotedBadgeText,
  };
};
