import { useCallback, useState } from "react";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { Link } from "react-router-dom";
// types
import { Event, EventStatusOptions } from "../../../../../types/Events";
// components
import {
  ButtonV2,
  Text,
  CircularProgress,
  COLORS,
  Card,
  Tooltip,
  IButtonAction,
  IconButton,
  ActionablePopover,
} from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MiniDialog } from "../../../../../components/Common";
// constants
import {
  EventStatusButtonMapping,
  EventCardButtons,
  RightSectionHeaders,
} from "./constants";
// utils
import { getRightSectionData } from "./utils";
// data / redux
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
import { useDeleteDraftEventMutation } from "../../../../../store/event";

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface IProps {
  event?: Event;
  isLoading?: boolean;
}

const EventCard = ({ event, isLoading = false }: IProps) => {
  const [confirmOpen, setConfirm] = useState(false);
  const [removeDraft, { isSuccess, isError }] = useDeleteDraftEventMutation({
    fixedCacheKey: "shared-delete-event",
  });
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  // close delete draft dialog
  const closeDialog = useCallback(() => {
    setConfirm(false);
  }, []);

  // open delete dialog
  const openDialog = useCallback(() => {
    setConfirm(true);
  }, []);

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg: "Event successfully deleted.",
    errorMsg: "Failed to delete event please try again.",
    errorAction: closeDialog,
    successAction: closeDialog,
    isSuccess: isSuccess,
    isError: isError,
  });

  const deleteDraft = () => {
    removeDraft((event as Event).slug);
  };

  const buttonActions = [
    {
      onClick: deleteDraft,
      label: "Delete",
      variant: "warning",
    },
    {
      onClick: closeDialog,
      label: "Cancel",
      variant: "secondary",
    },
  ] as IButtonAction[];

  const status = event?.status || "";
  const buttons = status ? EventStatusButtonMapping[status] : [];
  const rightSectionHeader = RightSectionHeaders[status];
  const rightSectionData = getRightSectionData(event);

  const showTooltip = (event?.title?.length || 0) > 30;

  return (
    <Card
      hasHoverBg={false}
      elevation={5}
      sx={{
        p: 8,
        overflow: "visible",
      }}
    >
      <Stack minWidth={330} minHeight={190} data-testid={`card-${event?.slug}`}>
        <Stack direction="row" justifyContent="space-between">
          <Tooltip
            placement="top"
            title={isLoading || !showTooltip ? "" : (event as Event).title}
          >
            <Text
              mb={6}
              variant="subtitle1"
              sx={{
                width: "286px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              loadingProps={{ sx: { width: "286px" } }}
            >
              {isLoading ? undefined : event?.title}
            </Text>
          </Tooltip>
          {isLoading ? (
            <CircularProgress
              data-testid="loading-menu-btn"
              size="20px"
              sx={{ color: COLORS.P_OUTLINE }}
            />
          ) : (
            event?.status === EventStatusOptions.DRAFT && (
              <Box>
                <IconButton
                  data-testid="event-card-menu-btn"
                  onClick={toggleAnchorEl}
                  sx={{ mt: -1, mr: -1 }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            )
          )}
          {event?.status === EventStatusOptions.DRAFT && (
            <>
              <ActionablePopover
                anchorEl={anchorEl}
                handleClose={handleClose}
                actions={[
                  {
                    testid: "delete-btn",
                    text: "Delete",
                    onClick: openDialog,
                  },
                ]}
              />

              <MiniDialog
                open={confirmOpen}
                title="Delete Q&A Draft?"
                content={
                  <Box>
                    Are you sure you want to delete? This action can&apos;t be
                    undone.
                  </Box>
                }
                buttonActions={buttonActions}
                close={closeDialog}
              />
            </>
          )}
        </Stack>
        <Stack direction="row" mb={6} gap={8}>
          <Stack flexBasis="100%">
            <Text emphasis="medium" variant="body2" mb={1}>
              {isLoading ? undefined : "Event date and time"}
            </Text>
            <Text variant="body2" loadingProps={{ sx: { mt: 1 } }}>
              {isLoading
                ? undefined
                : `${
                    dayjs(event?.eventStartDatetime).isValid()
                      ? dayjs(event?.eventStartDatetime).format(
                          "MMMM D, YYYY h:mm A"
                        )
                      : ""
                  }`}
            </Text>
          </Stack>
          <Stack flexBasis="100%">
            <Text emphasis="medium" variant="body2" mb={1}>
              {isLoading ? undefined : rightSectionHeader}
            </Text>
            <Text variant="body2" loadingProps={{ sx: { mt: 1 } }}>
              {isLoading ? undefined : rightSectionData}
            </Text>
          </Stack>
        </Stack>
        <Stack marginTop="auto">
          {isLoading ? (
            <ButtonV2 variant="secondary" size="mini">
              <CircularProgress
                size="18px"
                sx={{ color: COLORS.SAY_COBALT, verticalAlign: "middle" }}
              />
            </ButtonV2>
          ) : (
            <Stack direction="row" gap={4}>
              {buttons.includes(EventCardButtons.ViewDetails) && (
                <Link
                  to={`/qa/${event?.slug}/overview`}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonV2 variant="secondary" size="mini">
                    View details
                  </ButtonV2>
                </Link>
              )}
              {buttons.includes(EventCardButtons.Edit) && (
                <Link
                  to={`/qa/${event?.slug}/edit`}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonV2 variant="secondary" size="mini">
                    Edit
                  </ButtonV2>
                </Link>
              )}
              {buttons.includes(EventCardButtons.Questions) && (
                <Link
                  data-testid="event-questions-link"
                  to={`/qa/${event?.slug}/questions`}
                  style={{ textDecoration: "none" }}
                >
                  <ButtonV2 variant="secondary" size="mini">
                    Questions
                  </ButtonV2>
                </Link>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default EventCard;
