// material
import { Box, Stack } from "@mui/material";
// say
import {
  Text,
  CircularProgress,
  HookTextField,
} from "@asayinc/component-library";
// components
import { Question } from "../../../../types/Questions";
import { FormProvider, useForm } from "react-hook-form";
import { QuestionDetailAnsweredQuestions, QuestionStats } from "./components";
import { CardWrapper } from "../../../../components/Common";
import { QuestionDetailAddTags } from "../../components";
import { useEffect } from "react";
import debounce from "lodash.debounce";

interface Props {
  question?: Question;
  eventSlug: string;
  questionId: string;
  saveNotes: (notes: string) => void;
  asDrawer?: boolean;
  isLoading?: boolean;
}

const QuestionDetailContent = ({
  question,
  eventSlug,
  questionId,
  saveNotes,
  asDrawer,
  isLoading,
}: Props): JSX.Element => {
  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      notes: question?.notes?.textPlain,
    },
  });

  const { resetField } = methods;

  /**
   * reset notes defaultValue when question changes
   */
  useEffect(() => {
    resetField("notes", { defaultValue: question?.notes?.textPlain });
  }, [question]);

  /**
   * save notes every 1.5 seconds
   */
  const onSaveNotes = debounce((e) => {
    const notes = e?.target?.value;
    saveNotes(notes as string);
  }, 1500);

  return (
    <Stack pb={25} data-testid="page-questionDetail" gap={8}>
      {question ? null : (
        <Stack
          sx={{
            pt: 7,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Stack>
      )}
      {question ? (
        <>
          <CardWrapper cardName="question-stats">
            <QuestionStats question={question} asDrawer={asDrawer} />
          </CardWrapper>

          <CardWrapper cardName="question-tags">
            <QuestionDetailAddTags
              initTags={question.tags}
              eventSlug={eventSlug}
              questionId={questionId}
            />
          </CardWrapper>
          {question.companyLeadershipResponse?.length ? (
            <CardWrapper cardName="question-answered">
              <QuestionDetailAnsweredQuestions
                companyLeadershipResponse={question.companyLeadershipResponse}
              />
            </CardWrapper>
          ) : null}
        </>
      ) : null}

      <CardWrapper cardName="question-notes">
        <FormProvider {...methods}>
          <form>
            <Box>
              <Text variant="subtitle2" sx={{ mb: 4 }}>
                Notes
              </Text>
              <HookTextField
                variant="outlined"
                id="notes"
                inputSize="small"
                placeholder="Click to add notes here"
                name="notes"
                disabled={isLoading}
                callback={onSaveNotes}
                multiline
                rows={3}
                sx={{
                  width: "100%",
                }}
              />
            </Box>
          </form>
        </FormProvider>
      </CardWrapper>
    </Stack>
  );
};

export default QuestionDetailContent;
