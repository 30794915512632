import { useEffect, useState } from "react";
import { useLocation } from "react-router";

export const usePageViews = (): void => {
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location.pathname);

  useEffect(() => {
    if (
      location.pathname !== prevPathname &&
      window?.analytics?.page &&
      // do not run analytics or preve path if user is on reset password page
      !location.pathname.includes("reset-password")
    ) {
      window.analytics.page(location.pathname);
      setPrevPathname(location.pathname);
    }
  }, [location]);
};
