// material
import { Box } from "@mui/material";
// say
import { HookTextField, Text } from "@asayinc/component-library";
// components
import { RemoveQuestionReason, UserQuestion } from "../../Molecules";
import { Question } from "../../../../../../../types/Questions";

interface Props {
  question: Question;
  isLoading: boolean;
}
export const RemoveQuestionContent = ({
  question,
  isLoading,
}: Props): JSX.Element => {
  return (
    <Box width={684}>
      <Box mt={2}>
        <UserQuestion question={question} />
        <RemoveQuestionReason />

        <Text sx={{ mb: 4, mt: 6 }} variant="subtitle1">
          Please write a message explaining why this question has been removed.
          This will be sent to {question.authorName}&apos;s email address.
        </Text>
        <HookTextField
          variant="outlined"
          id="bodyHtml"
          inputSize="small"
          name="bodyHtml"
          label="Reason for removing this message."
          multiline
          disabled={isLoading}
          rows={3}
          rules={{
            required: {
              value: true,
              message: "Reason for removal is required.",
            },
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </Box>
  );
};

export default RemoveQuestionContent;
