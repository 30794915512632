import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Stack } from "@mui/material";
import { MessageStats, NotSent, SentTable } from "./components";
import { useSuccessErrorSnacks } from "../../../../../../hooks/useSuccessErrorSnacks";
import {
  MessageDrawer,
  NoMessages,
} from "../../../../../../components/Messages";
import { MessageType } from "../../../../../../store/messageLists";
import { useHasAMessage } from "../../../../../../hooks";

const ProxyVoteAllMessages = () => {
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { hasAMessage, hasNotSentMessage, hasSentMessage, loading, isError } =
    useHasAMessage(MessageType.ProxySM);

  // show snackbar if data load fails
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please refresh and try again.",
    isError,
  });

  useEffect(() => {
    document.title = "Proxy Messages | Say Issuer Portal";
  }, []);

  if (hasAMessage || loading) {
    return (
      <Stack>
        <MessageStats />
        {(hasNotSentMessage || loading) && <NotSent />}
        {(hasSentMessage || loading) && <SentTable />}
        <MessageDrawer />
      </Stack>
    );
  }

  return (
    <NoMessages
      heading="No Voting Messages"
      subheading="Create your first message"
      createUrl={`/vote/${proxyEventId}/messages/create`}
    />
  );
};

export default ProxyVoteAllMessages;
