import { Box } from "@mui/material";
import { Text, HookRadioButton } from "@asayinc/component-library";

const RemoveQuestionReason = (): JSX.Element => {
  return (
    <Box
      mt={6}
      sx={{
        label: { py: 1 },
        ".MuiFormControlLabel-root": { mr: 0, ml: -1.5 },
      }}
    >
      <Text variant="subtitle1" sx={{ mb: 3 }}>
        Please select why this question is being removed
      </Text>
      <HookRadioButton
        id="removalReason"
        rules={{
          required: "This field is required",
        }}
        name="removalReason"
        radioOptions={[
          {
            label: "Violates content guidelines",
            value: "violates_content_guidelines",
          },
          { label: "Inappropriate language", value: "inappropriate_language" },
          { label: "Repeat question", value: "repeat_question" },
          { label: "Other", value: "other" },
        ]}
      />
    </Box>
  );
};

export default RemoveQuestionReason;
