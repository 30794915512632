import { useEffect, useState } from "react";

/**
 * Update selectedIds on current page, every time the list of questions changes
 * Due to race conditions with apis and localstate changes, also need to re-run when selectedIds changes
 */
export const useCurrentlySelectedPageIds = (
  currentPageIds: string[],
  selectedIds: string[]
): string[] => {
  const [selectedIdsOnPage, setSelectedIdsOnPage] = useState<string[]>([]);

  useEffect(() => {
    const selectedOnPage = selectedIds.filter((id) =>
      currentPageIds.includes(id)
    );
    setSelectedIdsOnPage(selectedOnPage);
  }, [currentPageIds, selectedIds]);
  return selectedIdsOnPage;
};
