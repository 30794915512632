import { ColumnType, nFormatter, Row } from "@asayinc/component-library";
import { SORT, EMOJI_FONT_FAMILY } from "../../../../../../../constants";
import { IMessageReplyStats } from "../../../../../../../store/messageLists";

/**
 * Column data for table
 */
export const getColumnData = ({
  getSort,
  sortFn,
}: {
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}): {
  reply: ColumnType;
  totalReplyPercent: ColumnType;
  count: ColumnType;
  shares: ColumnType;
} => ({
  reply: {
    title: "Reply",
    style: "text",
    width: "225px",
    align: "left",
    sort: getSort(SORT.reply),
    sortFn,
    tdBodyProps: {
      textProps: {
        variant: "subtitle3",
      },
    },
  },
  totalReplyPercent: {
    title: "of total replies",
    style: "text",
    width: "225px",
    sort: getSort(SORT.totalReplyPercent),
    sortFn,
    align: "left",
  },
  count: {
    title: "Shareholders replied",
    style: "text",
    width: "225px",
    sort: getSort(SORT.count),
    sortFn,
    align: "left",
  },
  shares: {
    title: "Shares represented",
    style: "text",
    width: "225px",
    sort: getSort(SORT.shares),
    sortFn,
    align: "left",
  },
});

export const getRowData = (replyData: IMessageReplyStats[]): Row[] =>
  replyData.map((reply) => ({
    rowData: {
      reply: {
        content: reply.label
          ? `<div style='font-family:${EMOJI_FONT_FAMILY}; display:flex; align-items:center'><span style="font-size: 24px">${reply.value}</span><span style="margin-left: 16px">${reply.label}</span></div>`
          : `"${reply.value}"`,
        asHTML: true,
      },
      totalReplyPercent: {
        content: reply.shareOfTotalResponses
          ? `${Math.round(reply.shareOfTotalResponses * 100)}%`
          : "-",
      },
      count: { content: nFormatter(reply.count) },
      shares: { content: nFormatter(reply.sharesOwned) },
    },
    data: { value: reply.value },
    id: reply.id.toString(),
  }));
