import { ButtonV2, COLORS, Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { useMemo } from "react";
import { useMessageParams } from "../../../../hooks";
import {
  initialState,
  IShareholderComposeMessage,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
import { CardWrapper } from "../../../../components/Common";
// hooks/utils
import { useMessageDialogHooks } from "../../../../hooks";
import { generateDateString } from "../../../../utils";

interface IProps {
  asDrawer?: boolean;
}

const ScheduledAlertBar = ({ asDrawer }: IProps) => {
  const { messageId, proxyEventId } = useMessageParams(asDrawer);
  const { scheduleSend } = useMessageDialogHooks(messageId, proxyEventId);
  const { data = initialState } = useGetMessageQuery(messageId);

  const { scheduledSendAt, scheduledSendAtTimezone } =
    data as IShareholderComposeMessage;

  /**
   * format date/time string based on timezone
   */
  const dateString = useMemo(
    () =>
      generateDateString({
        scheduledSendAt,
        scheduledSendAtTimezone,
      }),
    [scheduledSendAt, scheduledSendAtTimezone]
  );

  return (
    <CardWrapper
      cardName="schedule-alert-bar"
      sx={{ py: 4, px: 8, bgcolor: COLORS.SHADED_GREY }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Text variant="body2" shade="light">
            This message is scheduled to send at {dateString}
          </Text>
        </Box>
        <Stack gap={8} direction="row">
          <ButtonV2
            variant="textDark"
            data-testid="button-schedule-send"
            onClick={scheduleSend}
          >
            Change schedule
          </ButtonV2>
        </Stack>
      </Stack>
    </CardWrapper>
  );
};

export default ScheduledAlertBar;
