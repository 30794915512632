import { Text, HookTextField } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface IProps {
  isLoading?: boolean;
}

const CompanyDetails = ({ isLoading }: IProps) => {
  const { setValue } = useFormContext();
  const twitterHandle = useWatch({
    name: "twitterHandle",
  });

  /**
   * TODO: MOVE THIS TO COMPONENT LIBRARY AS AN OPTIONAL MASKING FUNCTION
   * Mask twitter input, if its over 15 chars or has special chars, strip them
   * This would be better put as an option in component library
   */
  useEffect(() => {
    let newValue = twitterHandle;
    if (newValue) {
      newValue = newValue.replace(/[^a-zA-Z0-9_]/g, "");
      if (newValue.length > 15) {
        newValue = newValue.slice(0, 15);
      }
      if (newValue !== twitterHandle) {
        setValue("twitterHandle", newValue);
      }
    }
  });

  return (
    <Stack spacing={6}>
      <Box>
        <Text variant="subtitle1">Company details</Text>
        <Text variant="body2" component="p" sx={{ mt: 2 }}>
          Your company details will be applied globally across all shareholder
          experiences managed through Say.
        </Text>
      </Box>
      <Stack direction="row">
        <HookTextField
          wrapperSx={{ mr: 12, flexGrow: 1 }}
          sx={{ width: "100%" }}
          name="companyName"
          id="companyName"
          disabled={isLoading}
          placeholder="ex: Say Technologies"
          outerLabel="Company name"
          rules={{
            required: {
              value: true,
              message: "Company name is required.",
            },
          }}
        />
        <HookTextField
          wrapperSx={{ flexGrow: 1 }}
          sx={{ width: "100%" }}
          id="twitterHandle"
          disabled={isLoading}
          outerLabel="Twitter handle (Optional)"
          name="twitterHandle"
          placeholder="ex: say"
          tooltip={{
            title:
              "Your company Twitter handle is used to pre-populate copy for shareholders promoting questions on social media",
            iconColor: "black",
            tooltipColor: "black",
            placement: "right",
          }}
        />
      </Stack>

      <Stack>
        <HookTextField
          sx={{ width: "calc(50% - 24px)" }}
          name="investorRelationsUrl"
          outerLabel="Investor relations site"
          placeholder="ex: https://ir.company.com"
          id="investorRelationsUrl"
          disabled={isLoading}
          validationArr={["url"]}
        />
      </Stack>
    </Stack>
  );
};

export default CompanyDetails;
