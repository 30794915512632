import { pluralize } from "@asayinc/component-library";
import { Tag } from "../../types/Common";
import { rootApi } from "../rootApi";

// Define a question upvotes Api
export const questionTagsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get All Question Tag Options for a given Event
     */
    getQuestionTags: builder.query<Tag[], string>({
      query: (eventSlug) => `tags/?event_slug=${eventSlug}`,
    }),
    /**
     * Create new Question Tag
     */
    createQuestionTag: builder.mutation<
      Tag,
      { name: string; color: string; eventSlug: string }
    >({
      query: (options) => ({
        url: `tags/`,
        method: "POST",
        body: options,
      }),
      async onQueryStarted({ eventSlug }, { dispatch, queryFulfilled }) {
        try {
          const { data: tag } = await queryFulfilled;
          dispatch(
            questionTagsApi.util.updateQueryData(
              "getQuestionTags",
              eventSlug,
              (allTags) => {
                allTags.push(tag);
                return allTags;
              }
            )
          );
        } catch {}
      },
    }),
    /**
     * Add Tags to multiple questions
     */
    bulkAddTags: builder.mutation<
      {
        data: string;
        message: string;
      },
      {
        rowIds: string[];
        tagIds: number[];
      }
    >({
      query: ({ rowIds, tagIds }) => ({
        url: `tags/bulk-add/`,
        method: "POST",
        body: {
          questionIds: rowIds,
          tagIds,
        },
      }),
      transformResponse: (response: string, _, { rowIds }) => {
        return {
          data: response,
          message: `Tags added to ${rowIds.length} ${pluralize(
            "question",
            "questions",
            rowIds.length
          )}!`,
        };
      },
      invalidatesTags: (_response, _err, arg) => [
        ...arg.rowIds.map((id) => ({ type: "QuestionTagged" as const, id })),
        "Questions",
        "Question",
      ],
    }),
  }),
});

export const {
  useBulkAddTagsMutation,
  useCreateQuestionTagMutation,
  useGetQuestionTagsQuery,
} = questionTagsApi;
