import dayjs from "dayjs";
import {
  ColumnType,
  Row,
  BaseRowType,
  nFormatter,
  DATE_FORMAT,
} from "@asayinc/component-library";
import { calculateQuestionCount } from "../../utils";
import { Event } from "../../types/Events";
import { CreateEventRowData, CreateEventColumnData } from "./types";
import { SORT } from "../../constants";

/**
 * factory for generating column data
 * @param title thead cell copy
 * @param style style of cell text/checkbox/icon
 * @param sortFn callback when sort is clicked
 * @param sort current column sort value
 * @param clickHandler callback when tbody cell is clicked
 */
export const createColumnData = ({
  title,
  style,
  sortFn,
  sort,
  clickHandler,
  tdBodyProps = {},
  tdHeadProps = {},
  align,
  width,
}: CreateEventColumnData): ColumnType => ({
  title,
  style,
  sort,
  sortFn,
  clickHandler,
  tdBodyProps,
  tdHeadProps,
  align,
  width,
});

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */
export const createRowData = ({
  date,
  event,
  questions,
  answered,
  participants,
  shares,
  id,
  data,
}: CreateEventRowData): Row => ({
  rowData: {
    startTime: {
      content: date,
    },
    shortTitle: {
      content: event,
    } as BaseRowType,
    totalQuestions: {
      content: questions,
    },
    answeredCount: {
      content: answered,
    },
    totalShareholders: { content: participants },
    totalShares: { content: shares },
  },
  data,
  id,
});

interface ColumnDataProps {
  goToEvent: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}

/**
 * Column data for table
 */
export const getColumnData = ({
  goToEvent,
  getSort,
  sortFn,
}: ColumnDataProps): {
  shortTitle: ColumnType;
  startTime: ColumnType;
  totalQuestions: ColumnType;
  answeredCount: ColumnType;
  totalShareholders: ColumnType;
  totalShares: ColumnType;
} => ({
  shortTitle: createColumnData({
    title: "Event name",
    style: "text",
    clickHandler: goToEvent,
    sort: getSort(SORT.shortTitle),
    sortFn,
    align: "left",
    width: "185px",
    tdBodyProps: {
      textProps: {
        weight: 600,
      },
    },
  }),
  startTime: createColumnData({
    title: "Event date",
    style: "text",
    width: "100px",
    sort: getSort(SORT.startTime),
    sortFn,
    clickHandler: goToEvent,
    align: "left",
  }),
  totalQuestions: createColumnData({
    title: "Questions",
    style: "text",
    width: "100px",
    sort: getSort(SORT.totalQuestions),
    sortFn,
    clickHandler: goToEvent,
    align: "left",
  }),
  answeredCount: createColumnData({
    title: "Answered",
    style: "text",
    width: "100px",
    sort: getSort(SORT.answeredCount),
    sortFn,
    clickHandler: goToEvent,
    align: "left",
  }),
  totalShareholders: createColumnData({
    title: "Participants",
    style: "text",
    width: "100px",
    sort: getSort(SORT.totalShareholders),
    sortFn,
    clickHandler: goToEvent,
    align: "left",
  }),
  totalShares: createColumnData({
    title: "Shares represented",
    style: "text",
    width: "100px",
    sort: getSort(SORT.totalShares),
    sortFn,
    clickHandler: goToEvent,
    align: "left",
  }),
});

interface RowDataProps {
  completedEvents: Event[];
}

/**
 * Each rows specific data for table
 */
export const getRowData = ({ completedEvents }: RowDataProps): Row[] =>
  completedEvents.map((event, idx) =>
    createRowData({
      event: event.shortTitle,
      date: dayjs(event.eventStartDatetime, "YYYY-MM-DD").isValid()
        ? dayjs(event.eventStartDatetime, "YYYY-MM-DD").format(DATE_FORMAT)
        : "-",
      questions: nFormatter(calculateQuestionCount(event.questionsCount)),
      answered: nFormatter(event.questionsCount.answered),
      participants: nFormatter(event.totalShareholders),
      shares: nFormatter(event.totalShares),
      data: { id: event.slug, idx },
      id: event.slug,
    })
  );
