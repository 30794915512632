import {
  nFormatter,
  pluralize,
  TableV2 as Table,
} from "@asayinc/component-library";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useCallback, useMemo, useState } from "react";
// constants
import {
  ROW_OPTIONS,
  SORT,
  URL_PARAMS,
  DELETED_USER_ID,
} from "../../../../constants";
import { COLUMNS } from "./constants";
// hooks
import { useCurrentlySelectedPageIds } from "../../../../hooks/useCurrentlySelectedPageIds";
import { useGetQuestionQuery } from "../../../../store/question";
import { useTableSearchParams } from "../../../../hooks";
import { useSearchFns } from "../../../../hooks/useSearchFns";
import { useGetShareholderUpvotesQuery } from "../../../../store/shareholderUpvotes";
// factories/utils
import { getColumnData, getRowData } from "./factories";
import { getNoResultsProps, trackOrderingChange } from "./utils";
// types
import { IShareholderUpvotesData } from "../../../../types/QuestionUpvotes";
import { DialogTypes } from "../../../../components/Common/Organisms/Dialogs/ShareholderAddTagsDialog/types";
// components
import { ShareholderAddTagsDialog } from "../../../../components/Common";
import FilterList from "./FilterList";
import { TableEventData } from "../../../../types/Table";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface IProps {
  eventSlug: string;
  questionId: string;
  asDrawer?: boolean;
}

const Upvotes = ({ eventSlug, questionId, asDrawer }: IProps) => {
  const { data: question } = useGetQuestionQuery(
    eventSlug && questionId ? { eventSlug, questionId } : skipToken
  );
  const authorName = question?.authorName;
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [dialog, setDialog] = useState<DialogTypes | "">("");

  const sortCallback = (sortValue: string, searchParams: URLSearchParams) => {
    const paramObj = Object.fromEntries(new URLSearchParams(searchParams));
    trackOrderingChange(sortValue, paramObj, questionId as string);
  };

  // base params needed for table functionality
  const {
    paramObj,
    page,
    limit,
    search,
    tags: tagIds,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
    searchParams,
    setSearchParams,
  } = useTableSearchParams({
    defaultOrdering: SORT.upvotedAt,
    sortCallback,
    defaultAgg: "avg",
    updateUrl: !asDrawer,
  });

  const searchFns = useSearchFns(URL_PARAMS.search, !asDrawer);
  const apiParams = {
    offset: String((parseInt(page) - 1) * parseInt(limit)),
    search: searchFns.searchTerm,
    limit,
    tag_ids: tagIds,
    ordering: paramObj.ordering,
    shares_owned_min: paramObj.sharesOwnedMin,
    shares_owned_max: paramObj.sharesOwnedMax,
    submitted_before: paramObj.submittedBefore,
    submitted_after: paramObj.submittedAfter,
    num_shareholder_tags_max: paramObj.numShareholderTagsMax,
  };

  const defaultIds = useMemo(() => [], []);
  const {
    data = {
      ids: defaultIds,
      results: [],
      count: 0,
    },
    isFetching,
    isLoading,
  } = useGetShareholderUpvotesQuery(
    questionId
      ? {
          questionId: questionId as string,
          params: apiParams,
        }
      : skipToken
  );

  const { ids, results, count } = data as IShareholderUpvotesData;

  // selected on page and current page result ids
  const selectedIdsOnPage = useCurrentlySelectedPageIds(ids, selectedIds);

  const openDialog = useCallback(
    (dialog: DialogTypes) => setDialog(dialog),
    []
  );

  const filters = !!tagIds || !!search;

  /**
   * Select row
   * add/remove from list of selected rows
   */
  const checkRow = useCallback((data: unknown) => {
    const { id } = data as { id: string };
    setSelectedIds((curIds) => {
      if (curIds.includes(id)) {
        return curIds.filter((itm) => id !== itm);
      } else {
        return [...curIds, id];
      }
    });
  }, []);

  /**
   * select all rows
   */
  const handleToggleAllRows = useCallback(() => {
    setSelectedIds((curIds) => {
      if (selectedIdsOnPage.length) {
        return curIds.filter((id) => !selectedIdsOnPage.includes(id));
      } else {
        return [...curIds, ...ids];
      }
    });
  }, [selectedIdsOnPage, ids]);

  /**
   * navigate to shareholder that has been selected
   */
  const goToShareholder = useCallback(
    (data: unknown) => {
      const { id } = data as { id: string };
      if (id === DELETED_USER_ID) {
        return;
      }
      searchParams.set(URL_PARAMS.sid, id);
      setSearchParams(searchParams, { state: { goBackText: "Question" } });
    },
    [authorName, searchParams]
  );

  /**
   * Close the dialog and unselect selected shareholders on current page
   */
  const closeDialog = (dialog: DialogTypes, didSave?: boolean) => {
    setDialog("");
    if (didSave) {
      setSelectedIds(
        selectedIds.filter((id) => !selectedIdsOnPage.includes(id))
      );
    }
    if (dialog === "singleTag") {
      setSelectedIds([]);
    }
  };

  /**
   * props for bulk tag cta
   */
  const tagProps = {
    active: !!selectedIdsOnPage.length,
    action: () => openDialog("bulkTag"),
    tooltip: "Add a Tag",
  };

  /**
   * props for pagination toolbar
   */
  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  // props for when there are no results
  const noResultsData = useMemo(() => getNoResultsProps(filters), [filters]);

  // column table props
  const columnData = useMemo(
    () => getColumnData({ goToShareholder, sortFn, getSort, asDrawer }),
    [goToShareholder, sortFn, getSort, asDrawer]
  );

  /**
   * Opens tag dialog for single upvote
   * @param data TableEventData
   */
  const openTagDialog = useCallback(
    (data: unknown) => {
      const { id } = data as TableEventData;
      setSelectedIds([id]);
      setDialog("singleTag");
    },
    [questionId]
  );

  // row table props
  const rowData = getRowData({
    shareholderUpvotes: results,
    selectedIds,
    checkRow,
    openTagDialog,
  });

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search for a shareholder",
  };

  // toolbar dropdown filter list
  const collapseContent = (
    <FilterList searchParams={searchParams} setSearchParams={setSearchParams} />
  );

  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent,
  };

  return (
    <div data-testid="page-upvotes">
      <ShareholderAddTagsDialog
        participantIds={selectedIdsOnPage}
        dialog={dialog}
        handleClose={closeDialog}
      />
      <Table
        isLoading={isLoading}
        isFetching={isFetching}
        rows={rowData}
        toolbarInside
        inDrawer={asDrawer}
        memoCells
        columnData={columnData}
        tableMinWidth={asDrawer ? 486 : 700}
        searchBarProps={searchBarProps}
        toolbarCollapse={toolbarCollapse}
        testid="upvotes-table"
        titleVariant="subtitle2"
        tableLayout="auto"
        title={
          selectedIdsOnPage.length === 0
            ? `${nFormatter(count)} ${pluralize("Upvote", "Upvotes", count)}`
            : `${selectedIdsOnPage.length} selected`
        }
        columns={COLUMNS}
        tagProps={tagProps}
        paginateProps={paginateProps}
        count={count}
        noResultsData={noResultsData}
        toggleAll={handleToggleAllRows}
        numChecked={selectedIdsOnPage.length}
      />
    </div>
  );
};

export default Upvotes;
