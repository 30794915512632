import { useSearchParams } from "react-router-dom";
// components
import { Question } from "../../../../pages";
import { Drawer } from "@mui/material";
// constants
import { URL_PARAMS } from "../../../../constants";

const QuestionDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const questionId = searchParams.get(URL_PARAMS.qid);
  const eventSlug = searchParams.get(URL_PARAMS.eid);

  const open = !!questionId && !!eventSlug;

  const onClose = (_: unknown, reason: string) => {
    if (reason === "backdropClick") {
      searchParams.delete(URL_PARAMS.eid);
      searchParams.delete(URL_PARAMS.qid);
      setSearchParams(searchParams);
    }
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      ModalProps={{
        BackdropProps: {
          sx: { backgroundColor: "transparent" },
        },
      }}
      onClose={onClose}
      data-testid="question-drawer"
      PaperProps={{ elevation: 5 }}
      sx={{
        ".MuiDrawer-paper.MuiPaper-root": {
          width: "600px",
          backgroundColor: "#F0F0F0",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 3px 18px rgba(0, 0, 0, 0.1)",
          px: 4,
        },
      }}
    >
      <Question asDrawer />
    </Drawer>
  );
};

export default QuestionDrawer;
