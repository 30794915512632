export const TabOptions = {
  overview: {
    label: "Overview",
    value: "overview",
  },
  report: {
    label: "Report",
    value: "report",
  },
  recipients: {
    label: "Recipients",
    value: "recipients",
  },
};
