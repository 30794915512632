import { useState, useEffect } from "react";
// Material
import { Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
// Say
import {
  AutocompleteTagging,
  AutoCompleteOption,
  AutocompleteChip,
  COLORS,
  TagColor,
  Text,
} from "@asayinc/component-library";
// Data
import { usePatchTagsMutation } from "../../../../../store/question";
import {
  useCreateQuestionTagMutation,
  useGetQuestionTagsQuery,
} from "../../../../../store/questionTags";
import { track } from "../../../../../analytics";
import { Tag } from "../../../../../types/Common";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface Props {
  eventSlug: string;
  questionId: string;
  initTags: Tag[];
  forceAutocompleteOpen?: boolean;
}

const QuestionDetailAddTags = ({
  eventSlug,
  questionId,
  initTags,
  forceAutocompleteOpen = false,
}: Props): JSX.Element | null => {
  const [tags, setTags] = useState<AutoCompleteOption[]>(initTags);
  const [patchTags] = usePatchTagsMutation();
  const [createTag, { data: newTag }] = useCreateQuestionTagMutation();
  const { data, isLoading } = useGetQuestionTagsQuery(eventSlug || skipToken);

  const tagOptions = data || [];

  /**
   * reset tags state when initTags changes
   */
  useEffect(() => {
    setTags(initTags);
  }, [initTags]);

  /**
   * When a New Tag is created,
   * Update tags currently on the question in state and via api with the result of the create mutation
   */
  useEffect(() => {
    if (newTag) {
      const updatedTags = tags.map((tag) => {
        if (tag.name.toLowerCase() === newTag.name.toLowerCase()) {
          return newTag as AutoCompleteOption;
        }
        return tag;
      });
      const tagIds = updatedTags.map((tag) => tag.id as number);
      setTags(updatedTags);
      patchTags({
        questionId,
        eventSlug,
        tagIds,
        tags: updatedTags as Tag[],
      });
    }
  }, [newTag]);

  const trackOpen = () => {
    track({
      name: "Add a Tag Action",
      action: "Add a tag clicked",
      slug: eventSlug,
      questionId,
    });
  };

  const removeTag = (id: number) => {
    const updatedTags = tags.filter((tag) => tag.id !== id);
    setTags(updatedTags);
    const tagIds = updatedTags.map((tag) => tag.id as number);
    patchTags({
      questionId,
      eventSlug,
      tagIds,
      tags: updatedTags as Tag[],
    });
  };

  const handleChange = ({
    allValues: allTags,
    addedValue: newlyAddedTag,
  }: {
    allValues: AutoCompleteOption[];
    addedValue?: AutoCompleteOption;
  }) => {
    const tagIds = allTags.map((tag) => tag.id as number);
    if (newlyAddedTag?.new) {
      track({
        name: "Add a Tag Action",
        action: "Tag created",
        slug: eventSlug,
        questionId,
      });
      createTag({
        eventSlug,
        color: newlyAddedTag.color as TagColor,
        name: newlyAddedTag.name,
      });
    } else {
      track({
        name: "Add a Tag Action",
        action: "Tag selected",
        slug: eventSlug,
        questionId,
      });
      patchTags({
        questionId,
        eventSlug,
        tagIds,
        tags: allTags as Tag[],
      });
    }
    setTags(allTags);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={20}
      >
        <Text variant="subtitle2">Question tags</Text>
        <AutocompleteTagging
          options={tagOptions}
          variant="iconButton"
          forceAutocompleteOpen={forceAutocompleteOpen}
          iconButtonIcon={<Add />}
          displaySelected={false}
          onChange={handleChange}
          value={tags}
          title="Add a tag to question"
          ctaCallback={trackOpen}
        />
      </Stack>
      {tags && tags.length ? (
        <Stack direction="row" flexWrap="wrap" mt={6} mb={-2}>
          {tags.map((tag) => {
            return (
              <AutocompleteChip
                key={tag.id}
                option={tag}
                onDelete={() => removeTag(tag.id as number)}
                sx={{ mr: 2, mb: 2 }}
                deleteIcon={
                  <CloseIcon
                    data-testid={`${tag.id}-close-icon`}
                    style={{ color: COLORS.P_DISABLED }}
                  />
                }
              />
            );
          })}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default QuestionDetailAddTags;
