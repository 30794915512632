import { useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
// components
import { TableV2 as Table } from "@asayinc/component-library";
// router / store
import { useGetMessagesQuery } from "../../../../../../../../../store/messageLists";
// hooks
import { useTableSearchParams } from "../../../../../../../../../hooks";
// table helper fns
import { getColumnData, getRowData } from "./factories";
// constants
import { COLUMNS, NO_RESULTS_DATA } from "./constants";
import {
  ROW_OPTIONS,
  SORT,
  SORT_SYMBOL,
} from "../../../../../../../../../constants";
import { SENT_TYPES } from "../../../../../../../../../store/messageLists/constants";
// types
import { MessageType } from "../../../../../../../../../store/messageLists";
import { setMessageDrawer } from "../../../../../../../../../store/messageCompose";
import { useAppDispatch } from "../../../../../../../../../store/hooks";

const SentTable = () => {
  const dispatch = useAppDispatch();
  const { proxyEventId } = useParams() as { proxyEventId: string };

  const {
    limit,
    page,
    ordering,
    search,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
  } = useTableSearchParams({
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.scheduledSendAt}`,
  });

  const {
    data = { results: [] },
    isLoading,
    isFetching,
  } = useGetMessagesQuery({
    proxyEventId: proxyEventId,
    limit,
    ordering,
    offset: (parseInt(page) - 1) * parseInt(limit),
    messageType: MessageType.ProxySM,
    messageStatus: SENT_TYPES,
    search,
  });

  const { results: messages } = data;
  const count = messages.length;

  /**
   * navigate to event
   */
  const goToMessage = useCallback((data: unknown) => {
    const { id } = data as { id: string };
    dispatch(
      setMessageDrawer({
        messageDrawerId: id,
        messageDrawerProxyEventId: proxyEventId,
      })
    );
  }, []);

  // row and column data for the table
  const rowData = useMemo(() => getRowData({ messages }), [messages]);
  const columnData = useMemo(
    () => getColumnData({ goToMessage, getSort, sortFn }),
    [getSort, sortFn]
  );

  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  return (
    <Table
      testid="sent-message-table"
      title={`Sent (${count})`}
      tableMinWidth={700}
      tableLayout="auto"
      memoCells
      isFetching={isFetching}
      isLoading={isLoading}
      rows={rowData}
      columnData={columnData}
      columns={COLUMNS}
      count={count}
      noResultsData={NO_RESULTS_DATA}
      paginateProps={paginateProps}
    />
  );
};

export default SentTable;
