import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";
// mui
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Stack } from "@mui/material";
// components
import {
  IconButton,
  ErrorMessage,
  HookTextField,
  Text,
  useIsMounted,
} from "@asayinc/component-library";
import { AddMoreEmojis, EmojiOption } from "../../Molecules";
// constants
import { Emojis, FORM_FIELDS } from "../../../../../../../constants";

interface IProps {
  goBack: (type: null) => void;
  setEmojiOptionsState: (emojis: string[]) => void;
  disabled?: boolean;
}

const QuickReplyEmojisFormSection = ({
  goBack,
  setEmojiOptionsState,
  disabled,
}: IProps) => {
  const emojisSelected: string[] = useWatch({
    name: FORM_FIELDS.quickReplies.emojiData.options,
  });

  const { move, append, remove } = useFieldArray({
    name: FORM_FIELDS.quickReplies.emojiData.options,
    rules: {
      validate: {
        minimumTwo: (values) => {
          const options = values as string[];
          if (options && options.length < 2) {
            return "Must add at least 2 reaction options.";
          }
          return true;
        },
      },
    },
  });

  /**
   * This state is used as a buffer for the time it takes react-hook-form to update values and re-render
   * Without it there would be a flicker in the time drop finishes and state updates
   */
  const [temporaryEmojis, setTemporaryEmojis] = useState<string[] | null>(null);
  const isMounted = useIsMounted();

  const emojisToUse = temporaryEmojis || emojisSelected || [];

  const {
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext();

  useEffect(() => {
    trigger(FORM_FIELDS.quickReplies.emojiData.name);
  }, []);

  /**
   * After removing an emoji, revalidate incase there are less than 2
   */
  const removeEmoji = useCallback(
    (idx: number) => {
      remove(idx);
    },
    [emojisSelected]
  );

  /**
   * leave quick reply emojis
   */
  const handleGoBack = useCallback(() => {
    setEmojiOptionsState(emojisSelected);
    // different than text because react-hook-form is throwing an error
    // when unregistering an array of strings vs objects, so stubbing random items instead of unregistering
    setValue(FORM_FIELDS.quickReplies.emojiData.options, [
      Emojis.ThumbsUp,
      Emojis.CryingFace,
    ]);
    goBack(null);
  }, [emojisSelected]);

  /**
   * when drag completes update react hook form by 'move'ing the item that was dragged
   * to its new location.
   * Also need to temporarily set the new order in local state to prevent a flicker
   * latest version of DND does not seem testable with react-testing-library
   */
  /* istanbul ignore next */
  const dragEnd = (result: DropResult) => {
    if (result.destination && result.source) {
      ////// temporary array to avoid flicker //////
      const newEmojis = Array.from(emojisSelected);
      newEmojis.splice(result.source.index, 1);
      newEmojis.splice(
        result.destination.index,
        0,
        emojisSelected[result.source.index]
      );
      setTemporaryEmojis(newEmojis);
      // reset temp emojis after next rerender
      /* istanbul ignore next */
      setTimeout(() => {
        if (isMounted()) {
          setTemporaryEmojis(null);
        }
      }, 300);
      /////// end temporary array /////
      move(result.source.index, result.destination.index);
    }
  };

  return (
    <Stack>
      <Box display="inline-flex">
        <Stack direction="row" mb={6} alignItems="center">
          <IconButton
            onClick={handleGoBack}
            data-testid="btn-emoji-goback"
            sx={{ ml: -2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Text variant="subtitle1" ml={2}>
            Emoticon reactions
          </Text>
        </Stack>
      </Box>
      <HookTextField
        sx={{ width: "100%" }}
        name={FORM_FIELDS.quickReplies.emojiData.prompt}
        id="quickReplies-prompt"
        placeholder="e.g. React to this message"
        outerLabel="Write a prompt"
        showCharChount
        maxLength={40}
        rules={{
          required: {
            value: true,
            message: "Prompt is required.",
          },
        }}
      />
      <Text variant="body2" mb={3} mt={6}>
        Reaction options
      </Text>
      <Stack direction="row" alignItems="center">
        {emojisToUse && !!emojisToUse.length && (
          <DragDropContext onDragEnd={dragEnd}>
            <Droppable droppableId="emojis" direction="horizontal">
              {(provided, snapshot) => (
                <Stack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  direction="row"
                  alignItems="center"
                >
                  {emojisToUse.map((emoji, idx) => (
                    <EmojiOption
                      key={emoji}
                      disabled={disabled}
                      idx={idx}
                      emoji={emoji}
                      removeEmoji={removeEmoji}
                      isSomethingDragging={snapshot.isUsingPlaceholder}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <AddMoreEmojis
          emojisSelected={emojisSelected || []}
          append={append}
          disabled={disabled}
        />
      </Stack>
      <ErrorMessage
        errors={errors}
        name={`${FORM_FIELDS.quickReplies.emojiData.options}.root`}
      />
    </Stack>
  );
};

export default QuickReplyEmojisFormSection;
