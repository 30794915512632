import { MessageType } from "../store/messageLists";
import { useHasSentMessage } from "./useHasSentMessage";
import { useHasNotSentMessage } from "./useHasNotSentMessage";

export const useHasAMessage = (
  messageType: MessageType
): {
  hasAMessage: boolean;
  hasSentMessage: boolean;
  hasNotSentMessage: boolean;
  loading: boolean;
  isError: boolean;
} => {
  const {
    hasSentMessage,
    isLoading: isSentLoading,
    isError: isSentError,
  } = useHasSentMessage(messageType);
  const {
    hasNotSentMessage,
    isLoading: isNotSentLoading,
    isError: isNotSentError,
  } = useHasNotSentMessage(messageType);

  return {
    hasAMessage: hasSentMessage || hasNotSentMessage,
    hasSentMessage: hasSentMessage,
    hasNotSentMessage: hasNotSentMessage,
    loading: isSentLoading || isNotSentLoading,
    isError: isNotSentError || isSentError,
  };
};
