import { useSearchParams } from "react-router-dom";
import { Event, EventStatusOptions } from "../../types/Events";
import { useGetSettingsQuery } from "../../store/settings";
import { ISettings } from "../../store/settings/types";
import { useGetAllEventsQuery } from "../../store/allEvents";
import { URL_PARAMS } from "../../constants";
import { validateAllParams } from "../../utils/validateParam";
import { DEFAULT_LIMIT } from "./constants";

interface EventPageData {
  name: string;
  searchParams: URLSearchParams;
  completedEvents: Event[];
  count: number;
  isLoading: boolean;
  isFetching: boolean;
  page: string;
  limit: string;
  search: string;
  setPage: (newPage: string) => void;
  setLimit: (newLimit: string) => void;
}

export const useEventsPageData = ({
  ordering,
}: {
  ordering: string;
}): EventPageData => {
  const { data: settings = {} } = useGetSettingsQuery();
  const { name } = settings as ISettings;
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    [URL_PARAMS.limit]: limit = "10",
    [URL_PARAMS.page]: page = "1",
    [URL_PARAMS.search]: search = "",
  } = validateAllParams(Object.fromEntries(new URLSearchParams(searchParams)));
  const setPage = (newPage: string) => {
    searchParams.set(URL_PARAMS.page, newPage);
    setSearchParams(searchParams);
  };
  const setLimit = (newLimit: string) => {
    searchParams.set(URL_PARAMS.limit, newLimit);
    setSearchParams(searchParams);
  };

  const offset = parseInt(page) * parseInt(limit) - parseInt(limit);
  const params = {
    offset: String(offset),
    limit,
    search,
    ordering,
    status: EventStatusOptions.COMPLETED,
  };
  const {
    data = { results: [], count: 0 },
    isLoading,
    isFetching,
  } = useGetAllEventsQuery(params);
  const { count, results: completedEvents } = data;

  return {
    name,
    searchParams,
    page,
    limit,
    search,
    setPage,
    setLimit,
    completedEvents,
    count,
    isLoading,
    isFetching,
  };
};

const defaultParams = {
  offset: "0",
  limit: String(DEFAULT_LIMIT),
};

export const useHasAnEvent = (): {
  hasDraftEvent: boolean;
  hasPendingEvent: boolean;
  hasApprovedEvent: boolean;
  hasPublishedEvent: boolean;
  hasActiveEvent: boolean;
  hasCompletedEvent: boolean;
  hasAnEvent: boolean;
  loading: boolean;
  isError: boolean;
} => {
  // draft messages
  const {
    data: draftData,
    isLoading: isDraftLoading,
    isError: isDraftError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    status: EventStatusOptions.DRAFT,
  });
  const {
    data: pendingApprovalData,
    isLoading: isPendingApprovalLoading,
    isError: isPendingApprovalError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    status: EventStatusOptions.PENDING,
  });
  const {
    data: approvedData,
    isLoading: isApprovedLoading,
    isError: isApprovedError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    status: EventStatusOptions.APPROVED,
  });
  const {
    data: publishedData,
    isLoading: isPublishedLoading,
    isError: isPublishedError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    status: EventStatusOptions.PUBLISHED,
  });
  const {
    data: activeData,
    isLoading: isActiveLoading,
    isError: isActiveError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    status: EventStatusOptions.ACTIVE,
  });
  const {
    data: completedData,
    isLoading: isCompletedLoading,
    isError: isCompletedError,
  } = useGetAllEventsQuery({
    ...defaultParams,
    status: EventStatusOptions.COMPLETED,
  });

  return {
    hasDraftEvent: !!draftData?.count,
    hasPendingEvent: !!pendingApprovalData?.count,
    hasApprovedEvent: !!approvedData?.count,
    hasPublishedEvent: !!publishedData?.count,
    hasActiveEvent: !!activeData?.count,
    hasCompletedEvent: !!completedData?.count,
    hasAnEvent:
      !!draftData?.count ||
      !!pendingApprovalData?.count ||
      !!approvedData?.count ||
      !!publishedData?.count ||
      !!activeData?.count ||
      !!completedData?.count,
    loading:
      isDraftLoading ||
      isPendingApprovalLoading ||
      isApprovedLoading ||
      isPublishedLoading ||
      isActiveLoading ||
      isCompletedLoading,
    isError:
      isDraftError ||
      isPendingApprovalError ||
      isApprovedError ||
      isPublishedError ||
      isActiveError ||
      isCompletedError,
  };
};
