import {
  ActionablePopover,
  ButtonV2 as Button,
  IconButton,
  useScreenSize,
} from "@asayinc/component-library";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Stack } from "@mui/material";
import { useState } from "react";

interface IProps {
  handleView: () => void;
  createFromTemplate: () => void;
  goToCreate: () => void;
  isBlank?: boolean;
  isFeatureEnabled: boolean;
}

// CTA's display for when messageTemplateTile is displayed as a card
const CardCtas = ({
  handleView,
  createFromTemplate,
  isBlank,
  goToCreate,
  isFeatureEnabled,
}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { isMedium } = useScreenSize();

  // close popover
  const handleClose = () => {
    setAnchorEl(null);
  };

  // toggle popover
  const toggleAnchorEl = (e: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  return (
    <Stack direction="row" gap={4} mt={4}>
      {isBlank ? (
        <Button
          variant="secondary"
          size="small"
          onClick={goToCreate}
          data-testid="button-get-started"
        >
          Get started
        </Button>
      ) : (
        <>
          <Button
            variant="secondary"
            size="small"
            onClick={createFromTemplate}
            data-testid="button-usetemplate"
          >
            {isFeatureEnabled ? "Use template" : "Try for free"}
          </Button>
          {isMedium ? (
            <Button
              variant="secondary"
              size="small"
              data-testid="button-view"
              onClick={handleView}
            >
              View
            </Button>
          ) : (
            <IconButton variant="ctaAlt" onClick={toggleAnchorEl} size="medium">
              <MoreVertIcon />
            </IconButton>
          )}
        </>
      )}
      {/* popover for view button on mobile */}
      <ActionablePopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        actions={[
          {
            testid: "view-btn",
            text: "View",
            onClick: handleView,
          },
        ]}
      />
    </Stack>
  );
};

export default CardCtas;
