import {
  ColumnType,
  DATE_FORMAT,
  nFormatter,
  Row,
} from "@asayinc/component-library";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
// types
import {
  IProxyEvent,
  IVoteSolicitation,
} from "../../../../../../../store/proxyEvents/types";

dayjs.extend(relativeTime);
dayjs.extend(utc);

/**
 * Column data for table
 */
export const getColumnData = ({
  goToEvent,
}: {
  goToEvent: (data: unknown) => void;
}): {
  eventName: ColumnType;
  meetingDate: ColumnType;
  recordDate: ColumnType;
  sharesVoted: ColumnType;
  shareholdersVoted: ColumnType;
  messagesSent: ColumnType;
} => ({
  eventName: {
    title: "Event name",
    style: "text",
    align: "left",
    clickHandler: goToEvent,
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  },
  meetingDate: {
    title: "Meeting date",
    style: "text",
    align: "left",
    clickHandler: goToEvent,
  },
  recordDate: {
    title: "Record date",
    style: "text",
    align: "left",
    clickHandler: goToEvent,
  },
  sharesVoted: {
    title: "Shares voted",
    style: "text",
    align: "left",
    clickHandler: goToEvent,
  },
  shareholdersVoted: {
    title: "Shareholders voted",
    style: "text",
    align: "left",
    clickHandler: goToEvent,
  },
  messagesSent: {
    title: "Voting messages sent?",
    style: "text",
    clickHandler: goToEvent,
    align: "left",
  },
});

export interface CreateSentMessageRowData extends IProxyEvent {
  data: {
    id: string;
    idx: number;
  };
}

/**
 * Takes voteSoliciations and returns true if any message has
 * been sent in the past
 * @param voteSolicitations
 * @returns boolean
 */
const hasVotingMessageBeenSent = (
  voteSolicitations: IVoteSolicitation[]
): boolean =>
  voteSolicitations.some(({ scheduledSendAt }) =>
    dayjs(scheduledSendAt).isBefore(dayjs())
  );

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */

export const createRowData = ({
  title,
  recordDate,
  meeting: { meetingDate },
  proxyEventStats,
  voteSolicitations,
  id,
  data,
}: CreateSentMessageRowData): Row => ({
  rowData: {
    eventName: { content: title },
    meetingDate: {
      content: dayjs.utc(meetingDate).local().format(DATE_FORMAT),
    },
    recordDate: {
      content: dayjs.utc(recordDate).local().format(DATE_FORMAT),
    },
    sharesVoted: {
      content: nFormatter(
        parseFloat(proxyEventStats ? proxyEventStats.sharesVoted : "0")
      ),
    },
    shareholdersVoted: {
      content: nFormatter(
        parseFloat(proxyEventStats ? proxyEventStats.shareholdersVoted : "0")
      ),
    },
    messagesSent: {
      content: hasVotingMessageBeenSent(voteSolicitations) ? "Y" : "N",
    },
  },
  data,
  id,
});

/**
 * Each rows specific data for table
 */
export const getRowData = ({ events }: { events: IProxyEvent[] }): Row[] =>
  events.map((event, idx) =>
    createRowData({
      ...event,
      data: {
        id: event.id,
        idx,
      },
    })
  );
