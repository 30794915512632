import { Text, Timeline } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { CardWrapper } from "../../../../../../../../../components/Common";
import { useGetTimelineData } from "./useGetTimelineData";

const ProxyTimeline = () => {
  const { dates, isLoading } = useGetTimelineData();

  return (
    <CardWrapper cardName="proxy-timeline">
      <Stack gap={2} minWidth={312} maxWidth={312}>
        <Text variant="subtitle2" loadingProps={{ sx: { width: "112px" } }}>
          {isLoading ? undefined : "Communication timeline"}
        </Text>
        <Timeline dates={dates} stacked />
      </Stack>
    </CardWrapper>
  );
};

export default ProxyTimeline;
