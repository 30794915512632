import { Stack } from "@mui/material";
import { Text, IconButton } from "@asayinc/component-library";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  mw: number;
  onClose: () => void;
  children: React.ReactNode;
  title: string;
}

const ContentWrapper = ({ mw, onClose, children, title }: IProps) => {
  return (
    <Stack maxWidth={mw}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={5}
      >
        <Text variant="h5">{title}</Text>
        <IconButton
          aria-label="close"
          data-testid="dialog-close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      {children}
    </Stack>
  );
};

export default ContentWrapper;
