export const TabOptions = {
  overview: {
    label: "Overview",
    value: "overview",
  },
  questions: {
    label: "Questions",
    value: "questions",
  },
  participants: {
    label: "Participants",
    value: "participants",
  },
};

export const tabs = [
  TabOptions.overview,
  TabOptions.questions,
  TabOptions.participants,
];
