import { nFormatter } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useGetEventStatsQuery,
  initialStatsState,
} from "../../../../../../../store/event";
import { QAStat } from "../../Molecules";

const QAStats = () => {
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };
  const { data: eventStats = initialStatsState, isLoading: isStatsLoading } =
    useGetEventStatsQuery(eventSlug);

  const { trends } = eventStats;

  return (
    <Stack gap={6} data-testid="event-overview-stats">
      <Stack direction="row">
        <QAStat
          title="Questions asked"
          isLoading={isStatsLoading}
          countMessage={nFormatter(eventStats.questionsAsked)}
          trendMessage={`${nFormatter(
            trends.companyAverageQuestionsAskedDiff
          )}% (${nFormatter(
            eventStats.questionsAsked - trends.companyAverageQuestionsAsked
          )}) vs. average`}
          trendUp={trends.companyAverageQuestionsAskedDiff >= 0}
        />
        <QAStat
          title="Participants"
          isLoading={isStatsLoading}
          countMessage={nFormatter(eventStats.totalShareholders)}
          trendMessage={`${nFormatter(
            trends.companyAverageTotalShareholdersDiff
          )}% (${nFormatter(
            eventStats.totalShareholders -
              trends.companyAverageTotalShareholders
          )}) vs. average`}
          trendUp={trends.companyAverageTotalShareholdersDiff >= 0}
          tooltip="In comparison to all Q&A events' average"
        />
      </Stack>
      <Stack direction="row">
        <QAStat
          title="Questions answered"
          isLoading={isStatsLoading}
          countMessage={nFormatter(eventStats.questionsAnsweredCount)}
          trendMessage={`${nFormatter(
            trends.companyAverageQuestionsAnsweredCountDiff
          )}% (${nFormatter(
            eventStats.questionsAnsweredCount -
              trends.companyAverageQuestionsAnsweredCount
          )}) vs. average`}
          trendUp={trends.companyAverageQuestionsAnsweredCountDiff >= 0}
        />
        <QAStat
          title="Shareholders addressed"
          countMessage={`${nFormatter(
            eventStats.shareholdersAddressedPercent
          )}%`}
          trendMessage={`${nFormatter(
            trends.companyAverageShareholdersAddressedPercentDiff
          )}% vs. average`}
          isLoading={isStatsLoading}
          trendUp={trends.companyAverageShareholdersAddressedPercentDiff >= 0}
          tooltip="Shareholders who asked or upvoted the questions 
          you've answered divided by all shareholders who have 
          participated this Q&A."
        />
      </Stack>
    </Stack>
  );
};

export default QAStats;
