import { Stack, TypographyVariant } from "@mui/material";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { COLORS, Text, getInitials } from "@asayinc/component-library";
import React from "react";

interface IProps {
  authorName: string;
  profileColor?: string;
  isDeletedUser?: boolean;
  size?: number;
  textVariant?: TypographyVariant;
}

const ShareholderInitials = ({
  authorName,
  profileColor,
  isDeletedUser,
  textVariant = "caption",
  size = 24,
}: IProps) => {
  const initials = getInitials(authorName);
  let bgcolor = profileColor ? profileColor : COLORS.SATURATED_BLUE;
  if (isDeletedUser) {
    bgcolor = COLORS.P_DISABLED;
  }
  return (
    <Stack
      data-testid={`shareholder-initials-${initials}`}
      bgcolor={bgcolor}
      borderRadius="50%"
      width={size}
      minWidth={size}
      minHeight={size}
      height={size}
      alignItems="center"
      justifyContent="center"
    >
      {isDeletedUser ? (
        <PersonOffIcon
          data-testid="deleted-user-icon"
          sx={{ color: COLORS.WHITE, width: "16px", height: "16px" }}
        />
      ) : (
        <Text variant={textVariant} shade="light">
          {initials}
        </Text>
      )}
    </Stack>
  );
};

export default React.memo(ShareholderInitials);
