import { MessageStatus } from "../../types/Messages";
import { MessageType } from "../messageLists";
import { IShareholderComposeMessage } from "./types";

export const initialState: IShareholderComposeMessage = {
  id: "",
  status: MessageStatus.Draft,
  htmlPreview: "",
  campaignName: "",
  scheduledSendAt: "",
  type: MessageType.SM,
  scheduledSendAtTimezone: "",
};
