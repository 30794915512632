import { QaPublishOptions } from "../../constants";
import {
  Event,
  EventSortOptions,
  EventStateOptions,
  EventStatusOptions,
  EventTypeOptions,
  IEventStats,
} from "../../types/Events";

export const emptyInitialState = {
  archiveDatetime: null,
  closeDatetime: null,
  eventStartDatetime: null,
  id: 0,
  draftStep: 0,
  status: EventStatusOptions.DRAFT,
  logoUrl: null,
  publishDatetime: null,
  timezone: "",
  publishType: null,
  openDatetime: null,
  ordering: [
    {
      order: 0,
      value: EventSortOptions.RECOMMENDED,
    },
    {
      order: 1,
      value: EventSortOptions.TOP_VOTED,
    },
    {
      value: EventSortOptions.MOST_SHARES,
      order: 2,
    },
    {
      value: EventSortOptions.NEWEST,
      order: 3,
    },
  ],
  slug: "",
  title: "",
  shortTitle: "",
  leaders: [],
  faqs: null,
  meta: {},
};

export const initialState: Event = {
  archiveDatetime: "",
  closeDatetime: "",
  eventStartDatetime: "",
  openDatetime: "",
  eventState: EventStateOptions.UNPUBLISHED,
  draftStep: 0,
  id: 0,
  logoUrl: "",
  timezone: "",
  publishDatetime: "",
  publishType: QaPublishOptions.Specific,
  questionsCount: { retail: 0, institutional: 0, analyst: 0, answered: 0 },
  securities: [],
  slug: "",
  title: "",
  shortTitle: "",
  totalShareholders: 0,
  totalShares: 0,
  leaders: [],
  faqs: null,
  eventType: EventTypeOptions.EARNINGS_CALL,
  meta: {},
  status: EventStatusOptions.DRAFT,
  ordering: [
    {
      value: EventSortOptions.TOP_VOTED,
      order: 0,
    },
    {
      value: EventSortOptions.MOST_SHARES,
      order: 1,
    },
    {
      value: EventSortOptions.NEWEST,
      order: 2,
    },
  ],
};

export const initialStatsState: IEventStats = {
  isEventOpen: false,
  questionsAsked: 0,
  upvoteCount: 0,
  questionsAnsweredCount: 0,
  averageUpvotesPerQuestion: 0,
  medianPositionSize: 0,
  totalShareholders: 0,
  shareholdersAddressedPercent: 0,
  trends: {
    companyAverageQuestionsAsked: 0,
    companyAverageQuestionsAskedDiff: 0,
    companyAverageUpvoteCount: 0,
    companyAverageUpvoteCountDiff: 0,
    companyAverageUpvotesPerQuestion: 0,
    companyAverageUpvotesPerQuestionDiff: 0,
    companyAverageMedianPositionSize: 0,
    companyAverageMedianPositionSizeDiff: 0,
    companyAverageQuestionsAnsweredCount: 0,
    companyAverageQuestionsAnsweredCountDiff: 0,
    companyAverageShareholdersAddressedPercent: 0,
    companyAverageShareholdersAddressedPercentDiff: 0,
    companyAverageTotalShareholders: 0,
    companyAverageTotalShareholdersDiff: 0,
  },
};
