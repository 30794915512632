import { Text } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { SwiperSlide } from "swiper/react";
import { IMessageListItem } from "../../../../store/messageLists";
import { MessageCard } from "../../Molecules";
import { DeleteDialog } from "../Dialogs";
import { Carousel } from "../../../Common";

interface IProps {
  messages: IMessageListItem[];
  title: string;
  count: number;
  isLoading: boolean;
}

const MessageCardList = ({ messages, count, title, isLoading }: IProps) => {
  const loadingContent = Array(3)
    .fill(true)
    .map((_, i) => <MessageCard isLoading={true} key={"loading" + i} />);

  return (
    <Stack mb={4}>
      <Stack data-testid={`cards-${title}`} gap={6}>
        <Text variant="subtitle1" loadingProps={{ sx: { width: "80px" } }}>
          {isLoading ? undefined : `${title} (${count})`}
        </Text>
        {isLoading ? (
          <Stack direction="row" gap={8} pl="13px" ml="-13px" pr={10} pb={8}>
            {loadingContent}
          </Stack>
        ) : (
          <Carousel>
            {messages.map((message) => (
              <SwiperSlide key={message.id}>
                <MessageCard message={message} />
              </SwiperSlide>
            ))}
          </Carousel>
        )}
      </Stack>
      <DeleteDialog />
    </Stack>
  );
};

export default MessageCardList;
