import {
  Text,
  ButtonV2 as Button,
  Autocomplete,
  ChipV2,
  COLORS,
  AutocompleteOptionType,
} from "@asayinc/component-library";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Stack, Box, Popover } from "@mui/material";
import {
  useGetSettingsQuery,
  useGetSuperUserCompaniesQuery,
} from "../../../../../store/settings";
import { useEffect, useRef, useState } from "react";
import { ICompanyOption } from "../../../../../store/settings/types";

interface IProps {
  forceAutocompleteOpen?: boolean;
}

const CompanyDetails = ({ forceAutocompleteOpen }: IProps) => {
  // for placing the autocomplete popover
  const chipRef = useRef<HTMLDivElement>(null);
  // setting to open autocomplete after popover transition
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  // anchorEl point for popover when open
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // is anchor point set for popover
  const anchorSet = Boolean(anchorEl);
  // company selection
  const [companySelection, setCompanySelection] =
    useState<ICompanyOption | null>(null);
  const {
    data: allCompaniesData,
    isLoading: isAllCompaniesLoading,
    isSuccess: isAllCompaniesSuccess,
  } = useGetSuperUserCompaniesQuery();
  const {
    data: settings,
    isFetching: isSettingsLoading,
    isSuccess: isSettingsSuccess,
  } = useGetSettingsQuery();

  const isLoading = isAllCompaniesLoading || isSettingsLoading;

  // current selection in localStorage
  const storedCompany = window.localStorage.getItem("company-override");

  // set default value after companies load
  useEffect(() => {
    if (isSettingsSuccess && isAllCompaniesSuccess) {
      const defaultCompanyId = storedCompany || (settings?.companyId as string);
      const curCompany = allCompaniesData.find(
        (comp) => comp.id === defaultCompanyId
      );
      setCompanySelection(curCompany || null);
    }
  }, [isSettingsSuccess, isAllCompaniesSuccess]);

  /**
   * force close menu
   */
  const handleClose = () => {
    setAnchorEl(null);
    setAutocompleteOpen(false);
  };

  /**
   * when an autocomplete option is selected, set both the object and the query param then close everything
   */
  const onChange = (
    _event: React.SyntheticEvent<Element, Event> | null,
    onChangeData: unknown
  ) => {
    if (onChangeData) {
      const option = onChangeData as ICompanyOption;
      setCompanySelection(option);
      handleClose();
    }
  };

  // on save of company update
  const updateCompany = () => {
    window.localStorage.setItem(
      "company-override",
      companySelection?.id as string
    );
    window.location.reload();
  };

  /**
   *  wait for popover animation to end to open autocomplete so that its positioned correctly
   */
  /* istanbul ignore next */
  const onTransitionEnd = () => {
    setAutocompleteOpen(anchorSet);
  };

  /**
   * Set the chip as the anchor el when clicked
   */
  const handleChipClick = () => {
    setAnchorEl(chipRef?.current);
  };

  return (
    <Stack gap={6}>
      <Box>
        <Text variant="subtitle1">Company selector</Text>
        <Text variant="body2" component="p" sx={{ mt: 2 }}>
          Super users have the ability to choose which company they view. Choose
          your company below.
        </Text>
      </Box>
      <Stack gap={4} maxWidth="max-content">
        <ChipV2
          selected={!!companySelection || anchorSet}
          onClick={handleChipClick}
          disabled={isLoading}
          icon={<FilterListIcon />}
          deleteIcon={<ArrowDropDownIcon style={{ color: COLORS.BLACK }} />}
          variant="secondary"
          ref={chipRef}
          label={companySelection?.name || "Companies"}
        />
        <Popover
          id="company-selector-popover"
          data-testid="company-selector-popover"
          open={!!anchorEl}
          disableScrollLock
          PaperProps={{
            sx: {
              borderTopLeftRadius: 2,
              borderTopRightRadius: 2,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              width: "100%",
              maxWidth: 380,
            },
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onTransitionEnd={onTransitionEnd}
        >
          <Autocomplete
            options={allCompaniesData || []}
            value={companySelection}
            open={!!anchorEl && (!!autocompleteOpen || !!forceAutocompleteOpen)}
            maxHeight={100}
            onChange={onChange}
            size="small"
            optionType={AutocompleteOptionType.CheckIcon}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Popover>
        <Button
          onClick={updateCompany}
          data-testid="button-update-company"
          disabled={companySelection?.id === storedCompany || isLoading}
        >
          Update Company
        </Button>
      </Stack>
    </Stack>
  );
};

export default CompanyDetails;
