import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { Note, Question } from "../../types/Questions";
import { questionsApi, QuestionsApiResponse } from "../questions";
import { rootApi } from "../rootApi";
import {
  IPatchQuestionOptions,
  IPatchQuestionTags,
  PatchNotesOptions,
  PostRemoveQuestionOptions,
} from "./types";

// Question Api
export const questionApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * GET SINGLE QUESTION
     * */
    getQuestion: builder.query<
      Question,
      {
        eventSlug: string;
        questionId: string;
      }
    >({
      query: ({ eventSlug, questionId }) =>
        `qa-events/${eventSlug}/questions/${questionId}/`,
      providesTags: ["Question"],
    }),
    /**
     * Remove a question
     */
    removeQuestion: builder.mutation<
      { data: string; message: string },
      PostRemoveQuestionOptions
    >({
      query: ({ eventSlug, questionId, reason }) => ({
        url: `qa-events/${eventSlug}/questions/${questionId}/remove/`,
        method: "POST",
        body: reason,
      }),
      transformResponse: (response: string) => {
        return {
          data: response,
          message: "1 question removed!",
        };
      },
      invalidatesTags: ["Questions"],
    }),
    /**
     * Patch an individual question, optimistically update getQuestion and getQuestions,
     */
    patchNotes: builder.mutation<
      { data: Note; message: string },
      PatchNotesOptions
    >({
      query: ({ questionId, notes, eventSlug }) => ({
        url: `qa-events/${eventSlug}/questions/${questionId}/notes/`,
        method: "PATCH",
        body: notes,
      }),
      transformResponse: (response: Note) => {
        return {
          data: response,
          message: "Question notes saved!",
        };
      },
      invalidatesTags: ["Question", "Questions"],
    }),
    /**
     * Patch an individual question, optimistically update getQuestion and getQuestions,
     */
    patchTags: builder.mutation<Question, IPatchQuestionTags>({
      query: ({ questionId, tagIds, eventSlug }) => ({
        url: `qa-events/${eventSlug}/questions/${questionId}/`,
        method: "PATCH",
        body: {
          tagIds,
        },
      }),
      onQueryStarted(
        { questionId, eventSlug, tags },
        { dispatch, queryFulfilled }
      ) {
        // optimistcally update specific question
        const patchResultQuestion = dispatch(
          questionApi.util.updateQueryData(
            "getQuestion",
            { questionId, eventSlug },
            (draft) => {
              draft.tags = tags;
            }
          )
        );
        queryFulfilled.catch(() => {
          patchResultQuestion.undo();
        });
      },
      invalidatesTags: (_response, _err, arg) => [
        { type: "QuestionTagged", id: arg.questionId },
        "Questions",
      ],
    }),
    /**
     * Patch an individual questions tags, optimistcally update getQuestion, force invalidate getQuestions
     */
    patchQuestion: builder.mutation<Question, IPatchQuestionOptions>({
      query: ({ questionId, patchData, eventSlug }) => ({
        url: `qa-events/${eventSlug}/questions/${questionId}/`,
        method: "PATCH",
        body: patchData,
      }),
      onQueryStarted(
        { questionId, eventSlug, params, ...patch },
        { dispatch, queryFulfilled }
      ) {
        // optimistcally update specific question
        const patchResultQuestion = dispatch(
          questionApi.util.updateQueryData(
            "getQuestion",
            { questionId, eventSlug },
            (draft) => {
              Object.assign(draft, patch.patchData);
            }
          )
        );
        let patchResultQuestions: PatchCollection;
        // only optimistic update if patch is from questions page (params exist)
        if (params) {
          patchResultQuestions = dispatch(
            questionsApi.util.updateQueryData(
              "getQuestions",
              { eventSlug, params },
              (draft) => {
                const question = (draft as QuestionsApiResponse).results.find(
                  (q) => {
                    return q.id === questionId;
                  }
                );
                if (question) {
                  Object.assign(question, patch.patchData);
                }
              }
            )
          );
        }
        queryFulfilled.catch(() => {
          patchResultQuestion.undo();
          if (patchResultQuestions) {
            patchResultQuestions.undo();
          }
        });
      },
      invalidatesTags: (_response, _err, arg) => [
        { type: "QuestionTagged", id: arg.questionId },
      ],
    }),
  }),
});

export const {
  useGetQuestionQuery,
  usePatchQuestionMutation,
  usePatchNotesMutation,
  useRemoveQuestionMutation,
  usePatchTagsMutation,
} = questionApi;
