import { HookSelect, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { EVENT_QUARTER_SELECT_OPTIONS } from "../../../../../../constants";
import { generateYears } from "../../../../helpers/generateYears";

const quarterMapping = EVENT_QUARTER_SELECT_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {}
);

interface IProps {
  isDisabled?: boolean;
}

const EarningsCallFields = ({ isDisabled }: IProps) => {
  /**
   * generate select values for the previous 1 year to the next 5
   */
  const years = generateYears(-1, 5);
  const yearsOptions = years.map((itm) => ({ label: itm, value: itm }));
  const yearsMapping = yearsOptions.reduce(
    (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
    {}
  );

  return (
    <>
      <Text variant="subtitle1">Earnings period</Text>
      <Box width="100%">
        <HookSelect
          valueMapping={yearsMapping}
          clearable
          disabled={isDisabled}
          outerLabel="Year"
          menuTitle="Year"
          placeholder="e.g., 2023"
          displayEmpty
          size="medium"
          name="meta.fiscalYear"
          options={yearsOptions}
          rules={{
            required: "Year is required",
          }}
        />
      </Box>
      <Box width="100%">
        <HookSelect
          valueMapping={quarterMapping}
          clearable
          disabled={isDisabled}
          outerLabel="Fiscal Quarter"
          displayEmpty
          placeholder="e.g., Q4"
          menuTitle="Fiscal Quarter"
          size="medium"
          name="meta.fiscalQuarter"
          options={EVENT_QUARTER_SELECT_OPTIONS}
          rules={{
            required: "Fiscal Quarter is required",
          }}
        />
      </Box>
    </>
  );
};

export default EarningsCallFields;
