import { IComposeSignatureOption } from "../../../../../store/messageCompose";

/**
 * get signature object by id
 */
export const getSignatureById = (
  id: string | undefined,
  options: IComposeSignatureOption[]
): IComposeSignatureOption | undefined =>
  options.find((option) => option.id === id);
