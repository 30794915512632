import { useEffect, useRef } from "react";
import { track } from "../../../../../../../analytics";
import { useTableSearchParams } from "../../../../../../../hooks";

interface IProps {
  slug?: string;
  eventStatus?: string;
  companyName?: string;
}

export const useTracking = ({ slug, eventStatus, companyName }: IProps) => {
  const { paramObj } = useTableSearchParams({});
  const didMount = useRef<boolean>(false);
  const baseProps = {
    name: "Participants Filtered",
    qaEvent: slug,
    eventStatus: eventStatus,
    client: companyName,
  };

  const isLoaded = didMount.current && companyName && slug;

  /**
   * Track update search
   */
  useEffect(() => {
    if (isLoaded && paramObj.search) {
      track({
        ...baseProps,
        filter: "search",
        filterValue: paramObj.search,
      });
    }
  }, [paramObj.search]);

  /**
   * Track update tags
   */
  useEffect(() => {
    if (isLoaded && (paramObj.shareholdersNotTagged || paramObj.tags)) {
      track({
        ...baseProps,
        filter: "tags",
        filterValue: paramObj.shareholdersNotTagged || paramObj.tags,
      });
    }
  }, [paramObj.tags || paramObj.shareholdersNotTagged]);

  /**
   * Track update shares
   */
  useEffect(() => {
    if (isLoaded && (paramObj.sharesOwnedMin || paramObj.sharesOwnedMax)) {
      track({
        ...baseProps,
        filter: "shares",
        filterValue: `min: ${paramObj.sharesOwnedMin} - max: ${paramObj.sharesOwnedMax}`,
      });
    }
  }, [paramObj.sharesOwnedMin || paramObj.sharesOwnedMax]);

  /**
   * Track asked
   */
  useEffect(() => {
    if (
      isLoaded &&
      (paramObj.questionsAskedMin || paramObj.questionsAskedMax)
    ) {
      track({
        ...baseProps,
        filter: "asked",
        filterValue: `min: ${paramObj.questionsAskedMin} - max: ${paramObj.questionsAskedMax}`,
      });
    }
  }, [paramObj.questionsAskedMax || paramObj.questionsAskedMin]);

  /**
   * Track upvoted
   */
  useEffect(() => {
    if (
      isLoaded &&
      (paramObj.questionsUpvotedMin || paramObj.questionsUpvotedMax)
    ) {
      track({
        ...baseProps,
        filter: "upvoted",
        filterValue: `min: ${paramObj.questionsUpvotedMin} - max: ${paramObj.questionsUpvotedMax}`,
      });
    }
  }, [paramObj.questionsUpvotedMax || paramObj.questionsUpvotedMin]);

  /**
   * Track answered
   */
  useEffect(() => {
    if (
      isLoaded &&
      (paramObj.questionsAnsweredMin || paramObj.questionsAnsweredMax)
    ) {
      track({
        ...baseProps,
        filter: "answered",
        filterValue: `min: ${paramObj.questionsAnsweredMin} - max: ${paramObj.questionsAnsweredMax}`,
      });
    }
  }, [paramObj.questionsAnsweredMin || paramObj.questionsAnsweredMax]);

  /**
   * Track participated
   */
  useEffect(() => {
    if (
      isLoaded &&
      (paramObj.lastParticipatedBefore || paramObj.lastParticipatedAfter)
    ) {
      track({
        ...baseProps,
        filter: "participated",
        filterValue: `before: ${paramObj.lastParticipatedBefore} - after: ${paramObj.lastParticipatedAfter}`,
      });
    }
  }, [paramObj.lastParticipatedBefore || paramObj.lastParticipatedAfter]);

  // set component has mounted, avoids running tracking on render
  useEffect(() => {
    didMount.current = true;
  }, []);
};
