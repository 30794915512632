import { Dialog, IButtonAction } from "@asayinc/component-library";

interface IProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  buttonActions: IButtonAction[];
  close: () => void;
}

const MiniDialog = ({ open, title, content, buttonActions, close }: IProps) => {
  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": { maxWidth: 343 },
      }}
      title={title}
      buttonActions={buttonActions}
      handleClose={close}
      content={content}
    />
  );
};

export default MiniDialog;
