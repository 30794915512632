// dayjs
import { DATE_FORMAT, INPUT_TIME_FORMAT } from "@asayinc/component-library";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { TIME_ZONE_VALUE_MAPPING } from "../../../../../constants/timezones";
import { ITimeZone } from "../../../../../types/Timezone";
import { SendOptions } from "./constants";

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Parse utc time from BE to time and date individual fields
 */
export const parseUTCtoDateAndTime = (
  userTimeZone: ITimeZone,
  data: {
    scheduledSendAt?: string | null;
    scheduledSendAtTimezone?: string | null;
  }
) => {
  try {
    // get TZ object based on message  data or userTimezone object if no data saved yet
    const tzObject =
      (data.scheduledSendAtTimezone &&
        TIME_ZONE_VALUE_MAPPING?.[data?.scheduledSendAtTimezone]) ||
      userTimeZone;

    // ensure to use utc date so in case users local timezone is a different day than timezone/time selected
    // TODO: workaround for tz not working in latest mui datepicker
    // update when datepicker is updated with a fix
    const date = dayjs(data.scheduledSendAt).isValid()
      ? dayjs(
          dayjs(data.scheduledSendAt)
            .tz(tzObject.utc[0])
            .format(`${DATE_FORMAT} ${INPUT_TIME_FORMAT}`),
          `${DATE_FORMAT} ${INPUT_TIME_FORMAT}`
        )
      : null;

    const sendOption =
      date && dayjs().isBefore(date)
        ? SendOptions.sendLater
        : SendOptions.sendNow;

    return {
      timezone: tzObject
        ? {
            id: tzObject.value,
            name: tzObject.value,
            offset: tzObject.offset,
          }
        : undefined,

      scheduledDate: date,
      scheduledTime: date,
      sendOption,
    };
  } catch {
    return {
      scheduledDate: null,
      scheduledTime: null,
      sendOption: SendOptions.sendNow,
    };
  }
};

/**
 * determine if the given time date and zone values result in a time thats after today
 * Only calculate if all values are present.
 * revert to just checking if day is today or later for date check
 */
export const isDateAfterToday = ({
  time,
  date,
  timezoneId,
  type,
}: {
  time?: null | Dayjs;
  date?: null | Dayjs;
  timezoneId?: string;
  type: "time" | "date";
}) => {
  try {
    if (time && date && timezoneId) {
      const tzObject = TIME_ZONE_VALUE_MAPPING?.[timezoneId];
      const parsedTime = time.format("HH:mm");

      const dateOption = dayjs
        .utc(
          `${dayjs
            .utc(date)
            .tz(tzObject.utc[0], true)
            .format(DATE_FORMAT)} ${parsedTime}`,
          `${DATE_FORMAT} HH:mm`
        )
        .tz(tzObject.utc[0], true)
        .format();
      if (type === "date") {
        return dayjs().isAfter(dateOption, "minute");
      }

      if (dayjs().isAfter(dateOption, "minute")) {
        return "Must schedule message to send at a date and time in the future.";
      }

      if (
        dayjs()
          .add(3, "hours")
          .tz(tzObject.utc[0], true)
          .isAfter(dateOption, "minute")
      ) {
        return "Scheduled time must be +3 hours ahead for approvals.";
      }

      return true;
    }
    if (type === "date" && date) {
      return dayjs().isAfter(date, "day");
    }
    return true;
  } catch {
    return false;
  }
};
