import {
  greetingsOptions,
  NO_GREETING,
  VALUE_PAIRS,
  Emojis,
} from "../../constants";
import { generateRandomString } from "../generateRandomString";
import {
  IFilterQueries,
  IShareholderComposeMessage,
  QuickReplyTypeOptions,
} from "../../store/messageCompose";
import { ICompanyLeader } from "../../types/Events";
import { ComposeFlowFormFields } from "../../pages/Messages/subPages/CreateAndEdit/types";
import { MessageStep } from "../../types/Messages";

/**
 * FE uses single field for both range and values,
 * convert messagesOpenedRate {range} to messagesOpened {range}
 */
const convertReceivedValues = (query: IFilterQueries) => {
  if (query.hasSold) {
    query["sharesStatus"] = {
      radio: "has_sold",
    };
    delete query.hasSold;
  }
  for (const [to, from] of VALUE_PAIRS) {
    if (query?.[from]) {
      query[to] = {
        ...query[from],
      };
      delete query[from];
    }
  }
  return query;
};

export const getDefaultValues = (
  data: Partial<IShareholderComposeMessage>,
  currentStepName: MessageStep,
  leaders: ICompanyLeader[]
): Partial<ComposeFlowFormFields> => {
  switch (currentStepName) {
    case MessageStep.NameCampaign:
    default:
      return {
        campaignName: data.campaignName,
      };
    case MessageStep.ChooseRecipients:
      let query = { ...data.filterMap?.query };
      query = convertReceivedValues(query);

      return {
        filterMap: {
          query,
        },
      };
    case MessageStep.DraftInAppMessage:
      return {
        content: {
          bodyShort: data?.content?.bodyShort,
        },
        pushNotificationTemplateId: data?.pushNotificationTemplateId,
      };
    case MessageStep.DraftMessage:
      let greetings = data.content?.greetings as string;
      if (typeof data.content?.greetings === "undefined") {
        greetings = greetingsOptions[0].value;
      } else if (greetings === "") {
        greetings = NO_GREETING;
      }

      const defaultSignatureId = leaders.length > 0 ? leaders[0].id : "";
      const signatureId =
        typeof data.content?.signature?.leaderId === "undefined"
          ? defaultSignatureId
          : data.content?.signature?.leaderId;
      const quickReplyType = data?.content?.quickReplies?.type;
      const quickReplyEmojiData =
        quickReplyType === QuickReplyTypeOptions.Emoji
          ? data?.content?.quickReplies
          : {
              type: QuickReplyTypeOptions.Emoji,
              prompt: "React to this message",
              options: [
                Emojis.ThumbsUp,
                Emojis.ThumbsDown,
                Emojis.GrinningFace,
                Emojis.CryingFace,
              ],
            };
      const quickReplyTextData =
        quickReplyType === "text" && data?.content?.quickReplies
          ? {
              ...data?.content?.quickReplies,
              options: data?.content?.quickReplies?.options.map((reply) => ({
                id: generateRandomString(),
                value: reply,
              })),
            }
          : {
              type: QuickReplyTypeOptions.Text,
              prompt: "Reply to this message",
              options: [
                { value: "Option A", id: generateRandomString() },
                { value: "Option B", id: generateRandomString() },
              ],
            };

      const parsedLinks =
        data.content?.importantLinks?.map((faq) => ({
          ...faq,
          id: generateRandomString(),
        })) || [];

      return {
        content: {
          subject: data.content?.subject || "",
          greetings,
          body: data.content?.body || "",
          signatureId,
          ...(!data.proxyEventId ? { importantLinks: parsedLinks } : {}), // only add links if proxyEventId does not exist
        },
        quickReplies: {
          type: quickReplyType,
          emojiData: quickReplyEmojiData,
          textData: quickReplyTextData,
        },
      };
  }
};
