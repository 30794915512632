import { useState, useEffect } from "react";
import { track } from "../../../../../../../analytics";
// Material
import { Box, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// Say
import {
  AutocompleteTagging,
  AutoCompleteOption,
  AutocompleteChip,
  COLORS,
  TagColor,
  Text,
} from "@asayinc/component-library";
// Data
import { useUpdateTagsMutation } from "../../../../../../../store/shareholderTags";
import {
  useCreateShareHolderTagMutation,
  useGetShareholderTagsQuery,
} from "../../../../../../../store/shareholderTags";
// types
import { Tag } from "../../../../../../../types/Common";

interface IProps {
  shareholderId: string;
  initTags?: Tag[];
  forceAutocompleteOpen?: boolean;
}

const ShareholderAddTags = ({
  shareholderId,
  initTags = [],
  forceAutocompleteOpen = false,
}: IProps) => {
  const [updateTags] = useUpdateTagsMutation();
  const [createTag, { data: newTag }] = useCreateShareHolderTagMutation();
  const [tags, setTags] = useState<AutoCompleteOption[]>(initTags);
  const { data: tagOptions, isLoading } = useGetShareholderTagsQuery() as {
    data: Tag[];
    isLoading: boolean;
  };

  /**
   * When a New Tag is created,
   * Update tags currently on the user in state and via api with the result of the create mutation
   */
  useEffect(() => {
    if (newTag) {
      const updatedTags = tags.map((tag) => {
        if (tag.name.toLowerCase() === newTag.name.toLowerCase()) {
          return newTag as AutoCompleteOption;
        }
        return tag;
      });
      const tagIds = updatedTags.map((tag) => tag.id as number);
      setTags(updatedTags);
      updateTags({
        shareholderId,
        tagIds,
      });
    }
  }, [newTag]);

  /**
   * tracks when the Autocomplete popup is triggered to open
   */
  const trackOpen = () => {
    track({
      name: "Add a Shareholder Tag Action",
      action: "Add a tag clicked",
      shareholderId,
    });
  };

  /**
   * remove a tag from the list
   */
  const removeTag = (id: number) => {
    const updatedTags = tags.filter((tag) => tag.id !== id);
    setTags(updatedTags);
    const tagIds = updatedTags.map((tag) => tag.id as number);
    updateTags({
      shareholderId,
      tagIds,
    });
  };

  /**
   * Add a tag to the list, can either be an existing tag or need to create a new tag
   * If a new tag is created, we need to hit the tag creation api to get an ID for the tag
   * This tag with the id is then added via the useEffect above
   */
  const handleChange = ({
    allValues: allTags,
    addedValue: newlyAddedTag,
  }: {
    allValues: AutoCompleteOption[];
    addedValue?: AutoCompleteOption;
  }) => {
    const tagIds = allTags.map((tag) => tag.id as number);
    // updates selected tags with fake id, on api complete useEffect will trigger
    // and update with real tag id
    if (newlyAddedTag?.new) {
      track({
        name: "Add a Shareholder Tag Action",
        action: "Tag created",
        shareholderId,
      });

      createTag({
        color: newlyAddedTag.color as TagColor,
        name: newlyAddedTag.name,
      });
    } else {
      track({
        name: "Add a Shareholder Tag Action",
        action: "Tag selected",
        shareholderId,
      });
      updateTags({
        shareholderId,
        tagIds,
      });
    }
    setTags(allTags);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={20}
      >
        <Text component="p" variant="subtitle2">
          Shareholder tags
        </Text>
        <Box>
          <AutocompleteTagging
            options={tagOptions}
            variant="iconButton"
            forceAutocompleteOpen={forceAutocompleteOpen}
            displaySelected={false}
            onChange={handleChange}
            value={tags}
            ctaTitle=""
            ctaCallback={trackOpen}
          />
        </Box>
      </Stack>
      {tags && tags.length ? (
        <Stack direction="row" flexWrap="wrap" mt={6} mb={-2}>
          {tags.map((tag) => (
            <AutocompleteChip
              key={tag.id}
              option={tag}
              onDelete={() => removeTag(tag.id as number)}
              sx={{ mr: 2, mb: 2 }}
              deleteIcon={
                <CloseIcon
                  data-testid={`${tag.id}-close-icon`}
                  style={{ color: COLORS.P_DISABLED }}
                />
              }
            />
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default ShareholderAddTags;
