import { Tabs } from "@asayinc/component-library";
import { Box } from "@mui/material";
import {
  Navigate,
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useGetProxyEventDetailsQuery } from "../../../../store/proxyEvent";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { CollapsingAlert } from "../../../../components/Messages";
import { useRequestMoreInfo } from "../../../../hooks";
import { UiEventActions } from "../../../../store/consumerUser/types";
import { useGetFeaturesQuery } from "../../../../store/settings";

const tabs = [
  { label: "Overview", value: "overview" },
  { label: "Voting messages", value: "messages" },
];

const ProxyVote = () => {
  const navigate = useNavigate();
  const { proxyEventId } = useParams() as { proxyEventId: string };
  const { isError } = useGetProxyEventDetailsQuery(proxyEventId);
  const match = useMatch(`/vote/:proxyEventId/:activeTab`) as {
    params: { activeTab: string; proxyEventId: string };
  };
  const { data: features, isSuccess: isFeaturesSuccess } =
    useGetFeaturesQuery();
  // track request more info
  const { requestMoreInfo } = useRequestMoreInfo({
    action: UiEventActions.RequestInfoVoteMessages,
  });

  useSuccessErrorSnacks({
    errorMsg: "Proxy ID does not exist",
    isError,
  });

  if (isError) {
    return <Navigate to={`/vote`} replace />;
  }

  // redirect from root messages/messageid page
  if (!match) {
    return <Navigate to={`/vote/${proxyEventId}/overview`} replace />;
  }
  const {
    params: { activeTab },
  } = match;

  const setActiveTab = (tab: string) => {
    navigate(`/vote/${proxyEventId}/${tab.toLowerCase()}`);
  };

  const alertActions = [
    {
      label: "Try for free",
      onClick: () => {
        navigate(`/vote/${proxyEventId}/create`);
      },
    },
    {
      label: "Request more info",
      onClick: requestMoreInfo,
    },
  ];

  return (
    <>
      {isFeaturesSuccess && !features?.voteSolicitation && (
        <CollapsingAlert
          showAlert={true}
          actions={alertActions}
          showNotificationIcon={false}
          defaultCloseIcon={false}
          message="Expand your Say feature set to send messages to shareholders."
          sx={{
            width: "100%",
            px: "32px !important",
            mt: 5,
            mb: 4,
          }}
        />
      )}
      <Box mb={8}>
        <Tabs activeTab={activeTab} tabs={tabs} clickHandler={setActiveTab} />
      </Box>
      <Outlet />
    </>
  );
};

export default ProxyVote;
