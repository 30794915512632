export const AnsweredFilterOptions = [
  {
    label: "Not applied",
    value: "",
  },
  {
    label: "Yes",
    value: "true",
  },
  {
    label: "No",
    value: "false",
  },
];
