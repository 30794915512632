import { Stack, SxProps } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { LoadingSkeleton, Text, Tooltip } from "@asayinc/component-library";

interface IProps {
  isLoading: boolean;
  title: string;
  tooltipText?: string;
  largeStat: string;
  subStat?: string;
  subText?: string;
  sx?: SxProps;
}

const Stat = ({
  isLoading,
  title,
  tooltipText,
  largeStat,
  subStat,
  subText,
  sx = {},
}: IProps) => (
  <Stack sx={sx}>
    <Stack direction="row" alignItems="center" spacing={1}>
      <Text variant="subtitle2" loadingProps={{ sx: { width: "80px" } }}>
        {isLoading ? undefined : title}
      </Text>
      {!isLoading && tooltipText && (
        <Tooltip title={tooltipText} placement="top">
          <InfoOutlined sx={{ height: "16px", width: "16px" }} />
        </Tooltip>
      )}
    </Stack>
    <Stack direction="row" alignItems="baseline" mt={3}>
      {isLoading ? (
        <LoadingSkeleton sx={{ width: "232px", height: "24px" }} />
      ) : (
        <>
          <Text variant="h5" mr={1}>
            {largeStat}
          </Text>
          <Text variant="subtitle2">{subStat}</Text>
        </>
      )}
    </Stack>
    {!!subText && (
      <Text variant="body2" width={isLoading ? "50%" : "auto"} mt={2}>
        {isLoading ? undefined : subText}
      </Text>
    )}
  </Stack>
);

export default Stat;
