import { useEffect } from "react";
import { Stack, Box } from "@mui/material";
import { HookDatePicker, HookTimePicker } from "@asayinc/component-library";
import { useFormContext, useWatch } from "react-hook-form";
// dayjs
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";
// helpers
import { betweenEightAndEight } from "../../../helpers/betweenEightAndEight";
import { isOpenDateValid } from "../../../helpers/dateValidation";

dayjs.extend(dayjsBusinessDays);
interface IProps {
  isDisabled?: boolean;
  isDraft?: boolean;
  timezoneAbbr: string;
  timezoneId: string;
}

/**
 * event open date and time selection
 */
const OpenDateAndTime = ({
  isDisabled,
  isDraft,
  timezoneAbbr,
  timezoneId,
}: IProps) => {
  const { trigger } = useFormContext();
  const closeDate = useWatch({
    name: "closeDate",
  });
  const eventDate = useWatch({
    name: "eventDate",
  });
  const openDate = useWatch({
    name: "openDate",
  });

  // re-validate other date inputs
  useEffect(() => {
    if (closeDate) {
      trigger(["closeDate", "eventDate"]);
    }
  }, [closeDate]);

  const defaultMonth = eventDate
    ? dayjs(eventDate)
    : closeDate
    ? dayjs(closeDate)
    : undefined;

  // if invalid date on a non-draft event it cannot be edited
  const isUneditable =
    openDate &&
    !isDraft &&
    isOpenDateValid({
      eventStartDatetime: eventDate,
      openDatetime: openDate,
      closeDatetime: closeDate,
      isDraft,
    }) !== true;

  const disabled = !!(isDisabled || isUneditable);

  return (
    <Stack direction="row" spacing={12}>
      <Box width="100%">
        <HookDatePicker
          dateFieldProps={{
            slotProps: {
              textField: {
                inputSize: "medium",
                outerLabel: "Start Date",
                placeholder: "MM / DD / YYYY",
                sx: { width: "100%" },
                id: "openDate",
              },
            },
            disabled,
          }}
          calendarProps={{
            defaultCalendarMonth: defaultMonth,
          }}
          rules={{
            validate: {
              isDateValid: (date) =>
                isOpenDateValid({
                  eventStartDatetime: eventDate,
                  openDatetime: date,
                  closeDatetime: closeDate,
                  isDraft,
                  disabled,
                }),
            },
          }}
          name="openDate"
        />
      </Box>
      <Box width="100%">
        <HookTimePicker
          disabled={disabled}
          slotProps={{
            textField: {
              outerLabel: `Start Time (${timezoneAbbr})`,
              id: "openTime",
              placeholder: "--:-- --",
            },
          }}
          name="openTime"
          rules={{
            validate: {
              betweenEightAndEight: (date) =>
                betweenEightAndEight(date, timezoneId),
            },
            required: {
              value: true,
              message: "Start Time is required.",
            },
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </Stack>
  );
};

export default OpenDateAndTime;
