import { useCallback, useEffect } from "react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Text } from "@asayinc/component-library";
// data/redux
import {
  IMessageOverviewAPIResponse,
  MessageExportTypes,
  useGetMessageOverviewQuery,
} from "../../../../store/messageStats";
import { useGetSettingsQuery } from "../../../../store/settings";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { useMessageParams } from "../../../../hooks";
import {
  useGetMessageQuery,
  initialState,
} from "../../../../store/messageCompose";
// components
import {
  MessageSummary,
  ClickedLinks,
  ReplyResults,
  ReportingSection,
} from "./components";
import { track } from "../../../../analytics";
// types
import { ISettings } from "../../../../store/settings/types";

interface IProps {
  asDrawer?: boolean;
}

const MessageReport = ({ asDrawer }: IProps) => {
  const navigate = useNavigate();
  const { messageId } = useMessageParams(asDrawer);
  const { data: messageData = initialState, isError: isMessageError } =
    useGetMessageQuery(messageId);
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;
  const {
    data = {},
    isLoading: isOverviewStatsLoading,
    isError: isOverviewStatsError,
  } = useGetMessageOverviewQuery(messageId);

  // set page title
  useEffect(() => {
    document.title = `${messageData.campaignName} Report | Say Issuer Portal`;
  }, [messageData.campaignName]);

  /**
   * Track data upon landing on this tab
   */
  useEffect(() => {
    if (companyName && messageData.campaignName) {
      track({
        name: "Message Campaign Selected",
        client: companyName,
        campaign: messageData.campaignName,
        campaignStatus: "sent",
        menuTab: "Report",
      });
    }
  }, [companyName, messageData.campaignName]);

  // navigate to messages if apis fail
  const getRequestErrorAction = useCallback(() => {
    navigate("/messages");
  }, []);

  // snackbar for if any apis fail
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please try again.",
    isError: isOverviewStatsError || isMessageError,
    errorAction: getRequestErrorAction,
  });

  const { containsLinks, containsReplies } =
    data as IMessageOverviewAPIResponse;
  const isLoading = isOverviewStatsLoading || isOverviewStatsError;

  return (
    <Stack gap={8} pb={asDrawer ? 8 : 0} data-testid="message-report-page">
      <ReportingSection
        title="How your shareholders have been interacting with this message"
        exportType={MessageExportTypes.Overview}
        isLoading={isLoading}
        campaignName={messageData.campaignName}
        companyName={companyName}
        asDrawer={asDrawer}
      >
        <MessageSummary asDrawer={asDrawer} />
      </ReportingSection>
      {!containsLinks && !containsReplies && !isLoading && (
        <Text variant="body3" data-testid="report-empty-copy">
          If your message had links or quick replies, the engagement results
          would be shown here.
        </Text>
      )}
      {(isLoading || containsLinks) && (
        <ClickedLinks
          asDrawer={asDrawer}
          campaignName={messageData.campaignName}
          companyName={companyName}
        />
      )}
      {(isLoading || containsReplies) && (
        <ReplyResults
          asDrawer={asDrawer}
          campaignName={messageData.campaignName}
          companyName={companyName}
        />
      )}
    </Stack>
  );
};

export default MessageReport;
