import { useCallback, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
// components
import { Stack } from "@mui/material";
import { Text, ErrorMessage, HookTextField } from "@asayinc/component-library";
import { CardWrapper } from "../../../Common";
import { ExampleDrawer, GuidelinesSnackbar } from "../../../Messages";
import { InAppLearnMoreDrawer, NotificationOption } from "./components";
// redux/router
import { useParams } from "react-router-dom";

import { useGetProxyEventDetailsQuery } from "../../../../store/proxyEvent";
import { useGetSettingsQuery } from "../../../../store/settings";
import {
  MessageType,
  useGetPushNotificationTemplatesQuery,
} from "../../../../store/messageLists";
import { useHasSentMessage } from "../../../../hooks/useHasSentMessage";

interface IProps {
  isLoading?: boolean;
}

const DraftInAppMessage = ({ isLoading }: IProps) => {
  const { proxyEventId } = useParams() as {
    messageId: string;
    proxyEventId: string;
  };
  const { hasSentMessage, isLoading: isSentMessagesLoading } =
    useHasSentMessage(proxyEventId ? MessageType.ProxySM : MessageType.SM);
  const { data: proxyEvent } = useGetProxyEventDetailsQuery(proxyEventId);
  const { data: settingsData } = useGetSettingsQuery();
  const { data: notificationTemplates = [] } =
    useGetPushNotificationTemplatesQuery(proxyEventId);
  const [drawer, setDrawer] = useState<null | "examples" | "learnmore">(null);

  const closeDrawer = useCallback(() => setDrawer(null), []);
  const openExamplesDrawer = useCallback(() => setDrawer("examples"), []);
  const openLearnMoreDrawer = useCallback(() => setDrawer("learnmore"), []);

  const PUSH_FIELD_NAME = "pushNotificationTemplateId";
  const BODY_FIELD_NAME = "content.bodyShort";

  const {
    register,
    formState: { errors },
  } = useFormContext();
  // hook chips dont register the overal field, manually register here for required validation
  register(PUSH_FIELD_NAME, {
    required: {
      value: true,
      message: "Notification option is required.",
    },
  });

  const inAppMessage = useWatch({
    name: PUSH_FIELD_NAME,
  });

  const bodyShort = useWatch({
    name: BODY_FIELD_NAME,
  });

  const activeNotification = notificationTemplates.find(
    (n) => n.id === inAppMessage
  );
  const messageMaxLength = 25000;

  return (
    <Stack gap={8}>
      {!isSentMessagesLoading && (
        <GuidelinesSnackbar showSnackbar={!hasSentMessage} />
      )}

      <CardWrapper cardName="message">
        <Text variant="subtitle1" mb={2}>
          In-app message
        </Text>
        <Text variant="body2" emphasis="medium" mb={6}>
          Craft simple, yet effective notification and message delivered
          directly to shareholder&apos;s phone.{" "}
          <Text
            variant="subtitle3"
            shade="say"
            data-testid="opendrawer-link-learnmore"
            sx={{ cursor: "pointer" }}
            onClick={openLearnMoreDrawer}
          >
            Learn more
          </Text>
        </Text>
        <Text variant="body2" mt={6} mb={1}>
          Push notification
        </Text>
        <Stack direction="row" gap={16} mb={1}>
          {notificationTemplates.map((n, idx) => (
            <NotificationOption
              key={n.id}
              idx={idx}
              fieldName={PUSH_FIELD_NAME}
              selected={inAppMessage === n.id}
              {...n}
            />
          ))}
        </Stack>
        <ErrorMessage errors={errors} name={PUSH_FIELD_NAME} />
        <Text variant="body2" mt={9} mb={3}>
          Message
        </Text>
        <HookTextField
          placeholder="Write something to your shareholders"
          name={"content.bodyShort"}
          id={"inapp-bodyShort"}
          disabled={isLoading}
          rules={{
            required: {
              value: true,
              message: "Message body must not be blank.",
            },
            maxLength: {
              value: messageMaxLength,
              message: `Message exceeds ${messageMaxLength} characters.`,
            },
          }}
          multiline
          sx={{ width: "100%" }}
          rows={4}
          showCharChount
          maxLength={messageMaxLength}
          helperNode={
            <Stack direction="row" alignItems="center">
              <Text emphasis="medium" variant="body3" mx={1}>
                {"\u00B7"}
              </Text>
              <Text
                variant="subtitle3"
                shade="say"
                data-testid="opendrawer-link-examples"
                sx={{ cursor: "pointer" }}
                onClick={openExamplesDrawer}
              >
                View examples
              </Text>
            </Stack>
          }
        />
      </CardWrapper>
      <ExampleDrawer
        open={drawer === "examples"}
        title="Example in-app body"
        onClose={closeDrawer}
        text={`Vote in ${settingsData?.name}'s ${proxyEvent?.title}!<br/><br/>You're invited to vote and participate in the meeting because you owned ${settingsData?.name} stock on March 30, 2023. Vote on key company decisions and view the meeting materials through the link below.`}
      />
      <InAppLearnMoreDrawer
        open={drawer === "learnmore"}
        onClose={closeDrawer}
        notification={activeNotification}
        messageText={bodyShort}
      />
    </Stack>
  );
};

export default DraftInAppMessage;
