import { useCallback, useState } from "react";
// components
import { Stack } from "@mui/material";
import {
  Text,
  HookTextField,
  HookSelect,
  HookWYSIWYG,
  ElementType,
} from "@asayinc/component-library";
import { CardWrapper } from "../../../Common";
import {
  SignatureSelect,
  GuidelinesSnackbar,
  ExampleDrawer,
  ImportantLinks,
} from "../../../Messages";
import QuickReplySection from "../QuickReplySection";
// constants
import { greetingsOptions } from "../../../../constants";
// redux/router
import { useParams } from "react-router-dom";
import { useGetProxyEventDetailsQuery } from "../../../../store/proxyEvent";
import { useGetSettingsQuery } from "../../../../store/settings";
import { useGetTemplateDataQuery } from "../../../../store/messageCompose";
import {
  IShareholderComposeMessage,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
// types
import { MessageType } from "../../../../store/messageLists";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useHasSentMessage } from "../../../../hooks/useHasSentMessage";

interface IProps {
  isLoading?: boolean;
  templateId?: string | null;
}

const DraftMessage = ({ isLoading, templateId }: IProps) => {
  const { messageId: id = "", proxyEventId } = useParams() as {
    messageId: string;
    proxyEventId?: string;
  };
  const { hasSentMessage, isLoading: isSentMessagesLoading } =
    useHasSentMessage(proxyEventId ? MessageType.ProxySM : MessageType.SM);
  const { data } = useGetMessageQuery(id);
  const { data: proxyEvent } = useGetProxyEventDetailsQuery(
    proxyEventId || skipToken
  );
  const { data: settingsData } = useGetSettingsQuery();
  const [exampleDrawerOpen, setExampleDrawerOpen] = useState(false);

  const closeDrawer = useCallback(() => setExampleDrawerOpen(false), []);
  const openDrawer = useCallback(() => setExampleDrawerOpen(true), []);

  const body = (data as IShareholderComposeMessage)?.content?.body;

  const messageMaxLength = 25000;
  const subjectMaxLength = 160;

  const { data: templateData } = useGetTemplateDataQuery(
    templateId ? templateId : skipToken
  );

  const showViewExamples = proxyEventId || templateId;
  let exampleDrawerText = "";
  if (proxyEventId) {
    exampleDrawerText = `As a shareholder of ${settingsData?.name}, your vote counts!<br/><br/>${settingsData?.name} doesn't yet have enough shareholders participating in our ${proxyEvent?.title} vote. By voting, you help the company decide on meaningful proposals and changes. Vote today!`;
  } else if (templateId && templateData) {
    exampleDrawerText = templateData.suggestions.body[0];
  }

  return (
    <Stack gap={8}>
      {!isSentMessagesLoading && (
        <GuidelinesSnackbar showSnackbar={!hasSentMessage} />
      )}

      <CardWrapper cardName="message">
        <Text variant="subtitle1" mb={2}>
          Email
        </Text>
        <Text variant="body2" emphasis="medium" mb={6}>
          Craft a compelling message to your shareholders on Say to be delivered
          via email.
        </Text>
        <Text variant="body2" mb={3}>
          Subject
        </Text>
        <HookTextField
          name="content.subject"
          placeholder="Write a subject line for this message"
          id="subject"
          variant="outlined"
          disabled={isLoading}
          fullWidth
          maxLength={subjectMaxLength}
          showCharChount
          rules={{
            required: "A subject line is required.",
          }}
        />
        <Text variant="body2" mb={3} mt={6}>
          Greeting
        </Text>
        <HookSelect
          name="content.greetings"
          id="greetings"
          data-testid="select-greetings"
          displayEmpty
          disabled={isLoading}
          options={greetingsOptions}
          fullWidth
          size="medium"
        />
        <Text variant="body2" mt={6} mb={3}>
          Body
        </Text>
        <HookWYSIWYG
          placeholder="Write something to your shareholders"
          fieldName={"content.body"}
          savedValue={body}
          disabled={isLoading}
          controls={[ElementType.Bold, ElementType.Italic, ElementType.Link]}
          rules={{
            required: {
              value: true,
              message: "Message body must not be blank.",
            },
            maxLength: {
              value: messageMaxLength,
              message: `Message exceeds ${messageMaxLength} characters.`,
            },
          }}
          showCharCount
          maxLength={messageMaxLength}
          helperNode={
            showViewExamples ? (
              <Stack direction="row" alignItems="center">
                <Text emphasis="medium" variant="body3" mx={1}>
                  {"\u00B7"}
                </Text>
                <Text
                  variant="subtitle3"
                  shade="say"
                  data-testid="opendrawer-link"
                  sx={{ cursor: "pointer" }}
                  onClick={openDrawer}
                >
                  View examples
                </Text>
              </Stack>
            ) : undefined
          }
        />

        <Text variant="body2" mt={6} mb={3}>
          Signature
        </Text>
        <SignatureSelect isLoading={isLoading} />
      </CardWrapper>
      {!proxyEventId && (
        <CardWrapper cardName="important-links">
          <ImportantLinks />
        </CardWrapper>
      )}
      <ExampleDrawer
        open={exampleDrawerOpen}
        onClose={closeDrawer}
        text={exampleDrawerText}
      />
      {data?.type === MessageType.SM && (
        <CardWrapper cardName="quickReply">
          <QuickReplySection isLoading={isLoading} />
        </CardWrapper>
      )}
    </Stack>
  );
};

export default DraftMessage;
