import { IconButton } from "@asayinc/component-library";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

interface IProps {
  onEdit?: ((e: React.MouseEvent<HTMLElement>) => void) | (() => void);
  cardName: string;
  className: string;
  inset?: boolean;
}
const EditIcon = ({ cardName, onEdit, className, inset }: IProps) => {
  // move icon in if needs to inset
  const insetPx = inset ? 15 : 0;
  if (onEdit) {
    return (
      <IconButton
        onClick={onEdit}
        className={className}
        data-testid={`button-edit-${cardName}`}
        sx={{
          position: "absolute",
          top: insetPx,
          right: insetPx,
        }}
      >
        <EditOutlinedIcon />
      </IconButton>
    );
  }

  return null;
};

export default EditIcon;
