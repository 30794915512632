import {
  IShareholderMeta,
  IShareholderParticipationData,
  IShareholderQuestionsData,
  IShareholderStats,
  IShareholderMessageStats,
  IShareholderMessagesData,
} from "../../types/Shareholder";
import { rootApi } from "../rootApi";

// Define a service using a base URL and expected endpoints
export const shareholderApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // META DATA
    getShareholderMeta: builder.query<
      IShareholderMeta,
      { shareholderId: string }
    >({
      query: ({ shareholderId }) => `shareholders/${shareholderId}/details/`,
      providesTags: ["ShareholderTags"],
    }),
    // STATS
    getShareholderStats: builder.query<IShareholderStats, string>({
      query: (globalUserId) => `shareholders/${globalUserId}/stats/`,
    }),
    // PARTICIPATION PER EVENT
    getShareholderParticipation: builder.query<
      IShareholderParticipationData,
      {
        globalUserId: string;
        offset: number;
      }
    >({
      query: ({ globalUserId, offset }) =>
        `shareholders/${globalUserId}/qa-stats/?limit=3&offset=${offset}`,
    }),
    // QUESTIONS ASKED
    getShareholderQuestionsAsked: builder.query<
      IShareholderQuestionsData,
      {
        globalUserId: string;
        answered?: boolean;
        offset: number;
      }
    >({
      query: ({ globalUserId, answered, offset }) =>
        `shareholders/${globalUserId}/questions/?offset=${offset}&limit=1${
          answered ? "&question_status=answered" : ""
        }`,
      providesTags: (response) =>
        response?.results
          ? response.results
              .map((event) =>
                event.questions.map(({ id }) => ({
                  type: "QuestionTagged" as const,
                  id,
                }))
              )
              .flat()
          : ["QuestionTagged"],
    }),
    // QUESTIONS UPVOTED
    getShareholderQuestionsUpvoted: builder.query<
      IShareholderQuestionsData,
      {
        globalUserId: string;
        answered?: boolean;
        includeOwnQuestions?: boolean;
        offset: number;
      }
    >({
      query: ({ globalUserId, answered, includeOwnQuestions, offset }) =>
        `shareholders/${globalUserId}/upvotes/?offset=${offset}&limit=1${
          answered ? "&question_status=answered" : ""
        }${includeOwnQuestions ? "&include_own_questions=true" : ""}`,
      providesTags: (response) =>
        response?.results
          ? response.results
              .map((event) =>
                event.questions.map(({ id }) => ({
                  type: "QuestionTagged" as const,
                  id,
                }))
              )
              .flat()
          : ["QuestionTagged"],
    }),
    // MESSAGE STATS
    getShareholderMessageStats: builder.query<IShareholderMessageStats, string>(
      {
        query: (shareholderId) =>
          `shareholders/${shareholderId}/messages/stats/`,
      }
    ),
    // MESSAGES RECEIVED
    getShareholderMessageActivity: builder.query<
      IShareholderMessagesData,
      {
        shareholderId: string;
        offset: string;
        limit: string;
      }
    >({
      query: ({ shareholderId, offset, limit }) =>
        `shareholders/${shareholderId}/messages/?limit=${limit}&offset=${offset}`,
    }),
  }),
});

export const {
  useGetShareholderParticipationQuery,
  useGetShareholderMetaQuery,
  useGetShareholderQuestionsUpvotedQuery,
  useGetShareholderQuestionsAskedQuery,
  useGetShareholderStatsQuery,
  useGetShareholderMessageStatsQuery,
  useGetShareholderMessageActivityQuery,
} = shareholderApi;
