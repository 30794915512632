import { IFilterAutocompleteValue } from "@asayinc/component-library";
import { IFilterMessageOption } from "../messageCompose";
// types
import {
  IMessageClickStats,
  IMessageReplyStats,
  IMessagesAPIResponse,
  IMessageRecipientsAPIResponse,
  MessageType,
  IPushNotificationTemplate,
} from "./types";
import { NOT_SENT_TYPES, SENT_TYPES, NOT_SENT_SORT_ORDER } from "./constants";
import { rootApi } from "../rootApi";

// Message Compose Api
export const messageListsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * GET messages
     * */
    getMessages: builder.query<
      IMessagesAPIResponse,
      {
        limit: string;
        offset: number;
        search?: string;
        ordering?: string;
        proxyEventId?: string;
        messageType: MessageType;
        messageStatus: string;
      }
    >({
      query: ({
        limit,
        offset,
        search = "",
        ordering = "",
        proxyEventId = "",
        messageType,
        messageStatus,
      }) =>
        `messages/?type=${messageType}&status=${messageStatus}&limit=${limit}&offset=${offset}&search=${search}&ordering=${ordering}&proxy_event_id=${proxyEventId}`,
      providesTags: (_response, _err, { messageType, messageStatus }) => [
        {
          type: "ShareholderMessagesList",
          id: `${messageType}-${messageStatus}`,
        },
      ],
      transformResponse: (
        response: IMessagesAPIResponse,
        _meta,
        { messageStatus }
      ) => {
        if (messageStatus === NOT_SENT_TYPES) {
          response.results = [...response.results].sort((a, b) => {
            const aOrder = NOT_SENT_SORT_ORDER[a.status];
            const bOrder = NOT_SENT_SORT_ORDER[b.status];
            if (aOrder < bOrder) {
              return -1;
            } else if (aOrder > bOrder) {
              return 1;
            } else {
              return 0;
            }
          });
        }
        return response;
      },
    }),
    /**
     * GET message clicks
     * */
    getMessageClicks: builder.query<
      IMessageClickStats[],
      { messageId: string; sortValue?: string }
    >({
      query: ({ messageId, sortValue }) =>
        `messages/${messageId}/clicks/?ordering=${sortValue}`,
      providesTags: (_response, _err, arg) => [
        "ShareholderSentMessage",
        { type: "ShareholderSentMessage", id: arg.messageId },
      ],
    }),
    /**
     * GET message replies
     * */
    getMessageReplies: builder.query<
      IMessageReplyStats[],
      { messageId: string; sortValue?: string }
    >({
      query: ({ messageId, sortValue }) =>
        `messages/${messageId}/responses/?ordering=${sortValue}`,
      providesTags: (_response, _err, arg) => [
        "ShareholderSentMessage",
        { type: "ShareholderSentMessage", id: arg.messageId },
      ],
    }),
    /**
     * GET message replies
     * */
    getMessageRecipients: builder.query<
      IMessageRecipientsAPIResponse,
      {
        messageId: string;
        limit: string;
        replied?: string;
        offset: number;
        ordering?: string;
        search?: string;
        tags?: string;
      }
    >({
      query: ({ messageId, ordering, limit, offset, search, replied, tags }) =>
        `messages/${messageId}/recipients/?limit=${limit}&offset=${offset}&ordering=${ordering}&search=${search}&tag_ids=${tags}&replied=${replied}`,
      providesTags: (_response, _err, arg) => [
        "ShareholderSentMessage",
        { type: "ShareholderSentMessage", id: arg.messageId },
      ],
    }),
    /**
     * GET all message names for filtering
     * */
    getMessageFilterOptions: builder.query<IFilterAutocompleteValue[], void>({
      query: () => "messages/filters/messages/",
      providesTags: [
        {
          type: "ShareholderMessagesList",
          id: `${MessageType.SM}-${SENT_TYPES}`,
        },
      ],
      transformResponse: (response: IFilterMessageOption[]) => {
        return response.map((r) => ({
          id: r.slug,
          name: r.campaignName,
        }));
      },
    }),
    /**
     * GET all push notification options
     * */
    getPushNotificationTemplates: builder.query<
      IPushNotificationTemplate[],
      string
    >({
      query: (proxyEventId) =>
        `messages/push-notification-templates/?proxy_event_id=${proxyEventId}`,
    }),
  }),
});

export const {
  useGetMessageFilterOptionsQuery,
  useGetMessagesQuery,
  useGetMessageClicksQuery,
  useGetMessageRepliesQuery,
  useGetMessageRecipientsQuery,
  useGetPushNotificationTemplatesQuery,
} = messageListsApi;
