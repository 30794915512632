/**
 * Get no results messaging
 */
export const getNoResultsProps = (
  searchParams: URLSearchParams
): { title: string; subtitle: string } => {
  const hasFilterApplied =
    searchParams.get("filter") ||
    searchParams.get("query") ||
    searchParams.get("type");
  return {
    title: hasFilterApplied
      ? "No participants in this filtered view"
      : "No participants yet",
    subtitle: hasFilterApplied
      ? "Sorry, there are no participants that match your applied filters."
      : "There have not been any participants for this event yet.",
  };
};
