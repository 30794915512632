import { pluralize } from "@asayinc/component-library";
import { Tag } from "../../types/Common";
import { rootApi } from "../rootApi";

// Define a service using a base URL and expected endpoints
export const shareholderTagsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // Get All Shareholder Tag Options
    getShareholderTags: builder.query<Tag[], void>({
      query: () => `shareholders/tags/`,
    }),
    // ADD Tags to multiple shareholders
    bulkAddShareholderTags: builder.mutation<
      {
        data: string;
        message: string;
      },
      {
        rowIds: string[];
        tagIds: number[];
      }
    >({
      query: ({ rowIds, tagIds }) => ({
        url: `shareholders/tags/bulk-add/`,
        method: "POST",
        body: {
          globalShareholderIds: rowIds,
          tagIds,
        },
      }),
      transformResponse: (response: string, _, { rowIds }) => {
        return {
          data: response,
          message: `Tags added to ${rowIds.length} ${pluralize(
            "shareholder",
            "shareholders",
            rowIds.length
          )}!`,
        };
      },
      invalidatesTags: ["ShareholderTags"],
    }),
    // UPDATE TAGS ON Shareholder
    updateTags: builder.mutation<
      string,
      { tagIds: number[]; shareholderId: string }
    >({
      query: ({ shareholderId, tagIds }) => ({
        url: `shareholders/${shareholderId}/tags/`,
        method: "POST",
        body: {
          tagIds,
        },
      }),
      invalidatesTags: ["ShareholderTags"],
    }),
    // ADD TAGS
    createShareHolderTag: builder.mutation<
      Tag,
      { name: string; color: string }
    >({
      query: (options) => ({
        url: `shareholders/tags/`,
        method: "POST",
        body: {
          ...options,
        },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: tag } = await queryFulfilled;
          dispatch(
            shareholderTagsApi.util.updateQueryData(
              "getShareholderTags",
              undefined,
              (allTags) => {
                allTags.push(tag);
                return allTags;
              }
            )
          );
        } catch {}
      },
    }),
  }),
});

export const {
  useGetShareholderTagsQuery,
  useCreateShareHolderTagMutation,
  useUpdateTagsMutation,
  useBulkAddShareholderTagsMutation,
} = shareholderTagsApi;
