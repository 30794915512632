import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
// Api data
import { useRemoveQuestionMutation } from "../../../../store/question";
// say
import { IButtonAction, Dialog } from "@asayinc/component-library";
// components
import { RemoveQuestionContent } from "./components";
// utilities
import { trackRemoveQuestion } from "../../utils";
// types
import { Question } from "../../../../types/Questions";

interface Props {
  navigateToParent: () => void;
  open: boolean;
  closeDialog: () => void;
  question?: Question;
}

const RemoveOverlay = ({
  navigateToParent,
  open,
  closeDialog,
  question,
}: Props): JSX.Element | null => {
  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      removalReason: "",
      bodyHtml: "",
    },
  });
  // router
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };

  const [removeQuestionApi, { isSuccess, isLoading }] =
    useRemoveQuestionMutation();

  const {
    handleSubmit,
    formState: { isValid, errors },
    getValues,
  } = methods;

  // are there any errors
  const hasError = Object.keys(errors).length > 0;

  /**
   * close dialog if status is notesSaved or questionRemoved
   */
  useEffect(() => {
    if (isSuccess) {
      navigateToParent();
    }
  }, [isSuccess]);

  /**
   * trigger remove question api request
   */
  const removeQuestion = () => {
    const values = getValues();
    removeQuestionApi({
      questionId: (question as Question).id,
      eventSlug,
      reason: values,
    });
    trackRemoveQuestion({
      eventSlug,
      questionId: (question as Question).id,
      action: "Question removal form submitted",
    });
  };

  const cancel = useCallback(() => {
    closeDialog();
    methods.reset();
  }, []);

  const buttons = useMemo(
    () =>
      isLoading || isSuccess
        ? []
        : ([
            {
              onClick: handleSubmit(removeQuestion),
              label: "Remove Question",
              variant: "warning",
              disabled: hasError && !isValid,
            },
            {
              onClick: cancel,
              label: "Cancel",
              variant: "secondary",
            },
          ] as IButtonAction[]),
    [isLoading, isValid, isSuccess, hasError]
  );

  if (!question) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        data-testid="dialog-remove-question"
        title="Remove Question"
        content={
          <RemoveQuestionContent question={question} isLoading={isLoading} />
        }
        buttonActions={buttons}
        handleClose={closeDialog}
      />
    </FormProvider>
  );
};

export default RemoveOverlay;
