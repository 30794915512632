import { useContext } from "react";
// components
import { ShareholderAddTags } from "../../Molecules";
import { CardWrapper } from "../../../../../../../components/Common";
// data
import { useGetShareholderTagsQuery } from "../../../../../../../store/shareholderTags";
import { Context } from "../../../context";

const ShareholderTags = () => {
  const { data: metaData, isLoading: isMetaLoading } = useContext(Context);
  const shareholderId = metaData?.globalShareholderId;
  const { isLoading: tagsLoading } = useGetShareholderTagsQuery();

  const isLoading = tagsLoading || isMetaLoading;

  return (
    <CardWrapper
      cardName="shareholder-tags"
      data-testid="section-shareholder-tags"
    >
      {!isLoading && (
        <ShareholderAddTags
          shareholderId={shareholderId as string}
          initTags={metaData?.tags}
        />
      )}
    </CardWrapper>
  );
};

export default ShareholderTags;
