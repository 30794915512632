import { Box } from "@mui/material";
import { IProxyProposal } from "../../../../../../../../../store/proxyEvent/types";
import Proposal from "../../Molecules/Proposal/Proposal";
import { COLORS } from "@asayinc/component-library";

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

interface IProps {
  proposals: IProxyProposal[];
  idx: number;
  isLoading?: boolean;
}

const ProposalSection = ({ proposals, idx, isLoading }: IProps) => {
  const multipleProposals = proposals.length > 1;
  if (isLoading) {
    return (
      <Box
        border={`1px solid ${COLORS.P_OUTLINE}`}
        borderRadius={2}
        p={6}
        data-testid={`proposalSection-${idx}-loading`}
      >
        <Proposal isLoading />
      </Box>
    );
  }
  return (
    <Box
      border={`1px solid ${COLORS.P_OUTLINE}`}
      borderRadius={2}
      p={6}
      data-testid={`proposalSection-${idx}`}
    >
      {proposals.map((proposal, i) => (
        <Proposal
          key={proposal.id}
          {...proposal}
          multi={multipleProposals}
          title={i > 0 ? undefined : `${idx + 1}. ${proposal.title}`}
          details={
            multipleProposals
              ? `${ALPHABET.charAt(i)}. ${proposal.details}`
              : proposal.details
          }
          last={multipleProposals && i === proposals.length - 1}
        />
      ))}
    </Box>
  );
};

export default ProposalSection;
