import {
  Dialog,
  HookTextField,
  IButtonAction,
  Text,
} from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
// store
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  MessageDialog,
  selectMessageComposeData,
  setMessageDialog,
  useSendTestEmailMutation,
} from "../../../../../store/messageCompose";

const TestEmailDialog = () => {
  const dispatch = useAppDispatch();
  const { messageDialogId, messageDialog } = useAppSelector(
    selectMessageComposeData
  );
  const [sendTestEmail, { isLoading, isSuccess, isError }] =
    useSendTestEmailMutation();
  const methods = useForm({
    criteriaMode: "firstError",
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      email: "",
    },
  });

  /**
   * close dialog and reset form to empty
   */
  const closeForm = () => {
    methods.reset({ email: "" });
    dispatch(
      setMessageDialog({
        messageDialogId: null,
        messageDialog: null,
      })
    );
  };

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg: "Email successfully sent.",
    errorMsg: "Failed to send email please try again.",
    errorAction: closeForm,
    successAction: closeForm,
    isSuccess,
    isError,
  });

  const {
    formState: { isValid, errors },
  } = methods;

  // are there any errors
  const hasError = Object.keys(errors).length > 0;

  /**
   * submit dialog form and send test email
   */
  const onSubmit = (data: FieldValues) => {
    sendTestEmail({
      messageId: messageDialogId as string,
      data: {
        email: data.email,
      },
    });
  };

  const canSubmit = (!hasError || isValid) && !isLoading;

  /**
   * list of ctas
   */
  const buttonActions = useMemo(
    () =>
      [
        {
          type: "submit",
          form: "test-email-form",
          label: "Send",
          disabled: !canSubmit,
        },
      ] as IButtonAction[],
    [canSubmit]
  );

  return (
    <Dialog
      titleTextVariant="subtitle1"
      title="Test email"
      data-testid="test-email-dialog"
      handleClose={closeForm}
      buttonActions={buttonActions}
      content={
        <Stack maxWidth={295}>
          <Text variant="body1" sx={{ mb: 6 }}>
            Send a test email to preview how you shareholders may see this
            message.
          </Text>

          <FormProvider {...methods}>
            <form
              id="test-email-form"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <HookTextField
                id="email"
                label="Email"
                name="email"
                disabled={isLoading}
                validationArr={["email"]}
                rules={{
                  required: {
                    value: true,
                    message: "Email is required.",
                  },
                }}
                sx={{ width: "100%" }}
              />
            </form>
          </FormProvider>
        </Stack>
      }
      open={messageDialog === MessageDialog.Email}
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    />
  );
};

export default TestEmailDialog;
