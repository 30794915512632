import { memo } from "react";
import { AutocompleteChip, Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { Tag } from "../../../../../../../types/Common";
import { ShareholderInitials } from "../../../../../../../components/Common";

interface IProps {
  title: string;
  value: string | number;
  tags?: Tag[];
  profileColor?: string;
}

const QuestionStat = ({ title, value, tags, profileColor }: IProps) => {
  return (
    <Stack width="50%" gap={3}>
      <Text variant="subtitle2">{title}</Text>
      <Stack direction="row" alignItems="center">
        {profileColor && (
          <Box mr={3}>
            <ShareholderInitials
              authorName={value as string}
              profileColor={profileColor}
              size={24}
              textVariant="caption"
            />
          </Box>
        )}
        <Text variant="h5" sx={{ wordBreak: "break-word" }}>
          {value}
        </Text>
      </Stack>
      {tags && tags.length ? (
        <Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
          <AutocompleteChip key={tags[0].id} option={tags[0]} />
          {tags?.[1] && <AutocompleteChip key={tags[1].id} option={tags[1]} />}
          {tags?.[2] && <Text variant="body3">+ {tags.length - 2}</Text>}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default memo(QuestionStat);
