import {
  HookColorPicker,
  Text,
  TooltipInfoIcon,
} from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";

interface IProps {
  isLoading?: boolean;
  showSecondary?: boolean;
}

const BrandAssetsColors = ({ showSecondary = true }: IProps) => {
  return (
    <Box data-testid="BrandColor" flexGrow={1}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2.5}
        sx={{
          mb: 1,
        }}
      >
        <Text variant="subtitle1">Brand colors</Text>
        <TooltipInfoIcon
          title="Colors selected here will change the appearance of messages"
          iconColor="black"
          tooltipColor="black"
          placement="right"
        />
      </Stack>
      <Stack width={250} spacing={6}>
        <HookColorPicker
          textFieldProps={{
            id: "primaryColor",
            inputSize: "small",
            placeholder: "e.g., #1968FF",
            outerLabel: "Primary color",
            sx: {
              width: 238,
            },
            inputProps: {
              maxLength: 7,
            },
          }}
          validationArr={["hex"]}
          name="primaryColor"
        />
        {showSecondary && (
          <HookColorPicker
            textFieldProps={{
              id: "secondaryColor",
              inputSize: "small",
              placeholder: "e.g., #1968FF",
              outerLabel: "Secondary color (Optional)",
              sx: {
                width: 238,
              },
              inputProps: {
                maxLength: 7,
              },
            }}
            validationArr={["hex"]}
            name="secondaryColor"
          />
        )}
      </Stack>
    </Box>
  );
};

export default BrandAssetsColors;
