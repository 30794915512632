import { IconButton, Text } from "@asayinc/component-library";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer, Stack } from "@mui/material";
import { InAppPreview } from "../../../../Molecules/PreviewCard/components";
import { IPushNotificationTemplate } from "../../../../../../store/messageLists";

interface IProps {
  open: boolean;
  onClose: () => void;
  messageText?: string;
  notification?: IPushNotificationTemplate;
}

const InAppLearnMoreDrawer = ({
  open,
  onClose,
  messageText,
  notification,
}: IProps) => {
  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={open}
      ModalProps={{
        BackdropProps: {
          sx: { backgroundColor: "transparent" },
        },
      }}
      onClose={onClose}
      data-testid="inapp-drawer"
      PaperProps={{ elevation: 5 }}
      sx={{
        ".MuiDrawer-paper.MuiPaper-root": {
          width: "600px",
          backgroundColor: "#F0F0F0",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 3px 18px rgba(0, 0, 0, 0.1)",
          p: 10,
        },
      }}
    >
      <Box>
        <Stack
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          mb={2}
        >
          <Text variant="h5">Push notification and in-app message</Text>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box mb={8}>
          <Text>
            Say sends your message via mobile notifications and in-app messages,
            which appear in the broker apps which integrate with Say. Here are
            some examples of what they may look like.
          </Text>
        </Box>
        <InAppPreview messageText={messageText} notification={notification} />
      </Box>
    </Drawer>
  );
};
export default InAppLearnMoreDrawer;
