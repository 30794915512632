import {
  CheckCircleSvg,
  COLORS,
  Text,
  Timeline,
} from "@asayinc/component-library";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  TIME_ZONE_VALUE_MAPPING,
  USER_TIMEZONE,
} from "../../../../../../../constants/timezones";
import { useGetEventQuery } from "../../../../../../../store/event";
import { Event } from "../../../../../../../types/Events";

// TODO pending discussion with Qian/MK
/*const compareDates = (a: IDate, b: IDate) => {
  if (!a.date) {
    return 1;
  }
  if (!b.date) {
    return -1;
  }
  if (dayjs(a.date).isBefore(dayjs(b.date))) {
    return -1;
  }
  if (dayjs(a.date).isAfter(dayjs(b.date))) {
    return 1;
  }
  return 0;
};*/

const QATimeline = () => {
  const { eventSlug } = useParams() as {
    eventSlug: string;
  };
  const { data: event = {} as Event, isLoading } = useGetEventQuery(eventSlug);

  const {
    archiveDatetime,
    createdAt,
    approvedAt,
    closeDatetime,
    eventStartDatetime,
    openDatetime,
    publishDatetime,
    submittedAt,
    timezone,
  } = event;

  const timezoneObj = TIME_ZONE_VALUE_MAPPING[timezone];
  const timezoneId =
    timezoneObj?.utc?.[0] || USER_TIMEZONE || "America/New_York";

  // dates array to generate the timeline
  const dates = [
    {
      title: "Q&A created",
      date: createdAt || "-",
    },
    {
      title: "Submitted for review",
      date: submittedAt || "-",
    },
    {
      title: "Reviewed by Say",
      date: approvedAt || "-",
    },
    {
      title: "Q&A link is viewable",
      date:
        publishDatetime || isLoading
          ? publishDatetime || "-"
          : "Once this Q&A is approved",
    },
    {
      title: "Questions open",
      date: openDatetime || "-",
    },
    {
      title: "Questions closed",
      date: closeDatetime || "-",
    },
    {
      title: "Event begins",
      date: eventStartDatetime || "-",
      active:
        dayjs(dayjs()).isAfter(eventStartDatetime) &&
        dayjs(dayjs()).isBefore(archiveDatetime),
    },
  ];
  // parse dates to determine how to display
  const datesParsed = useMemo(() => {
    // set disabled and active flags
    const datesWithDisabled = dates.map((itm, idx) => {
      const disabled =
        isLoading || !itm.date || dayjs(itm.date).isAfter(dayjs());
      const active = !!(
        itm.date &&
        dates?.[idx + 1]?.date &&
        dayjs(dayjs()).isAfter(itm.date) &&
        dayjs(dayjs()).isBefore(dates[idx + 1].date)
      );
      return {
        disabled,
        active,
        circleIconColor: disabled ? COLORS.P_DISABLED : COLORS.SAY_COBALT,
        icon: !active && !disabled ? <CheckCircleSvg /> : undefined,
        ...itm,
      };
    });
    // set line color for next item
    return datesWithDisabled.map((itm, idx) => ({
      ...itm,
      date: isLoading ? undefined : itm.date,
      lineColor: datesWithDisabled?.[idx + 1]?.disabled
        ? COLORS.P_DISABLED
        : COLORS.SAY_COBALT,
    }));
  }, [event]);

  return (
    <Box data-testid="event-overview-stats">
      <Text mb={3} variant="subtitle2">
        Timeline
      </Text>
      <Timeline dates={datesParsed} timezoneId={timezoneId} />
    </Box>
  );
};

export default QATimeline;
