import { MessageStep } from "../../../../types/Messages";

export const SESSION_KEY = "proxy-compose-flow-step";

export const CREATION_STEP_MAPPING = [
  MessageStep.NameCampaign,
  MessageStep.ChooseRecipients,
  MessageStep.DraftMessage,
  MessageStep.DraftInAppMessage,
  MessageStep.Review,
];
