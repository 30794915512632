import { MessageStatus } from "../../types/Messages";

export const isMessageSent = (status: MessageStatus) =>
  status === MessageStatus.Attached ||
  status === MessageStatus.Attaching ||
  status === MessageStatus.Fetched ||
  status === MessageStatus.Fetching ||
  status === MessageStatus.ReadyForFetching ||
  status === MessageStatus.RefreshingIndex ||
  status === MessageStatus.Sending ||
  status === MessageStatus.Sent;
