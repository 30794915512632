import { Stack, lighten } from "@mui/material";
// say lib
import { CircularProgress, COLORS } from "@asayinc/component-library";

const Loader = ({ full }: { full?: boolean }): JSX.Element => {
  if (full) {
    return (
      <Stack
        height="100vh"
        width="100%"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        zIndex="1"
        bgcolor={lighten(COLORS.LIGHT_GREY, 0.5)}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack mt={4} alignItems="center" justifyContent="center">
      <CircularProgress />
    </Stack>
  );
};

export default Loader;
