import { Box, SxProps } from "@mui/material";
import { MessageStatus } from "../../../../types/Messages";
import { COLORS, Text } from "@asayinc/component-library";
import { useMemo } from "react";

interface IProps {
  messageStatus?: MessageStatus;
  sx?: SxProps;
}

/**
 * given a messageStatus, return the color and text to go with it
 */
const getBadgeData = (messageStatus?: MessageStatus) => {
  switch (messageStatus) {
    case MessageStatus.Attached:
    case MessageStatus.Attaching:
    case MessageStatus.Fetched:
    case MessageStatus.Fetching:
    case MessageStatus.ReadyForFetching:
    case MessageStatus.RefreshingIndex:
    case MessageStatus.Sent:
      return {
        color: COLORS.DATA_VIZ_DARK_GREEN,
        text: "Sent",
      };
    case MessageStatus.Draft:
      return {
        color: COLORS.DOLPHIN,
        text: "Draft",
      };
    case MessageStatus.PendingApproval:
      return {
        color: COLORS.DATA_VIZ_GOLD,
        text: "Pending approval",
      };
    case MessageStatus.Scheduled:
      return {
        color: COLORS.DATA_VIZ_GIGAS,
        text: "Scheduled",
      };
    default:
      return null;
  }
};

const StatusBadge = ({ messageStatus, sx }: IProps) => {
  const badgeData = useMemo(() => getBadgeData(messageStatus), [messageStatus]);
  // if no badge for status return nothing
  if (!badgeData) {
    return null;
  }

  return (
    <Box
      component="span"
      bgcolor={badgeData?.color}
      borderRadius={4}
      px={3}
      py={1.5}
      data-testid="status-badge"
      alignSelf="flex-start"
      display="inline-block"
      sx={sx}
    >
      <Text variant="subtitle3" sx={{ display: "inline-block" }} shade="light">
        {badgeData?.text}
      </Text>
    </Box>
  );
};

export default StatusBadge;
