import { Stack, Box } from "@mui/material";
import {
  HookDatePicker,
  HookTimePicker,
  Text,
  useIsMounted,
} from "@asayinc/component-library";
import { HookChipSelection } from "../../../../../components/Common";
import { useFormContext, useWatch } from "react-hook-form";
import { isBeforeEventTime } from "../../../helpers/isBeforeEventTime";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import {
  formatDate,
  formatDateTime,
} from "../../../helpers/parseFormData/parseFormData";
import { QaPublishOptions, QA_PUBLISH_OPTIONS } from "../../../../../constants";
import { useEffect } from "react";
dayjs.extend(timezone);

interface IProps {
  isLoading?: boolean;
  timezoneAbbr: string;
  timezoneId: string;
}

const PublishDateForm = ({ isLoading, timezoneAbbr, timezoneId }: IProps) => {
  const { trigger, setValue } = useFormContext();
  const isMounted = useIsMounted();

  // currently selected publish form values
  const [publishType, publishTime, publishDate, openDate, openTime, timezone] =
    useWatch({
      name: [
        "publishType",
        "publishTime",
        "publishDate",
        "openDate",
        "openTime",
        "timezone",
      ],
    });

  // re-validate if timezone changes
  useEffect(() => {
    if (publishDate && publishTime) {
      trigger(["publishDate", "publishTime"]);
    }
  }, [timezone]);

  useEffect(() => {
    if (publishType === QaPublishOptions.StartTime) {
      setValue("publishDate", openDate);
      setValue("publishTime", openTime);
    }
    if (publishType === QaPublishOptions.OnceApproved) {
      setTimeout(() => {
        if (isMounted()) {
          trigger(["publishDate", "publishTime"]);
        }
      }, 0);
    }
  }, [publishType]);

  // get full datetime for business logic with publish date
  const formattedOpenDate = openDate && formatDate(openDate, openTime);
  const openDatetime =
    formattedOpenDate && openTime
      ? formatDateTime(timezoneId, formattedOpenDate, openTime)
      : null;

  // re-trigger validation when publish time or date changes
  const reValidateForm = () => {
    if (publishDate && publishTime) {
      // need to give react-hook-form a render cycle to get values correct
      setTimeout(() => {
        if (isMounted()) {
          trigger(["publishDate", "publishTime"]);
        }
      }, 0);
    }
  };

  // cant update publish date and time if opendate has passed
  const canNotUpdate = dayjs(openDatetime).isBefore(dayjs(), "day");

  // as long as selection is not once published show date time
  const displayPublishDateTime = publishType !== QaPublishOptions.OnceApproved;

  // disable but still display publish date and time if user selects to use the start time
  const disablePublishDateTime = publishType === QaPublishOptions.StartTime;

  return (
    <>
      <Box>
        <Text variant="subtitle1" mb={2}>
          Q&A link becomes publicly accessible
        </Text>
        <Text variant="body2">
          The date and time when the Q&A link becomes publicly accessible.
          Before this date and time shareholders will not be able to access the
          page and view information about the event. Please consider timing this
          before you plan on adding a link to the Q&amp;A in any promotion
          material like a press release.
        </Text>
        <Stack direction="row" flexWrap="wrap">
          {QA_PUBLISH_OPTIONS.map((option) => (
            <Box key={option.value} mt={6} mr={3}>
              <HookChipSelection
                {...option}
                disabled={
                  isLoading ||
                  (option.value === QaPublishOptions.StartTime && !openDatetime)
                }
                selected={option.value === publishType}
                fieldName="publishType"
              />
            </Box>
          ))}
        </Stack>
      </Box>
      <Text variant="body2" sx={{ mb: 6 }}>
        {canNotUpdate &&
          "This is the time at which your Q&A was made public. Since it is already published you are unable to change this date at this time."}
      </Text>
      {displayPublishDateTime && (
        <Stack direction="row" spacing={12}>
          <Box width="100%">
            <HookDatePicker
              dateFieldProps={{
                slotProps: {
                  textField: {
                    inputSize: "medium",
                    outerLabel: "Date",
                    placeholder: "MM / DD / YYYY",
                    sx: { width: "100%" },
                    id: "publishDate",
                  },
                },
                disabled:
                  isLoading ||
                  canNotUpdate ||
                  !openDatetime ||
                  disablePublishDateTime,
              }}
              callback={reValidateForm}
              rules={{
                validate: {
                  isBeforeEventTime: (val) =>
                    isBeforeEventTime({
                      openDatetime: openDatetime as string,
                      publishTime,
                      publishDate: val,
                      timezoneId,
                    }),
                },
              }}
              calendarProps={{
                shouldDisableDate: (date) => {
                  if (
                    isBeforeEventTime({
                      openDatetime: openDatetime as string,
                      publishTime,
                      publishDate: date,
                      timezoneId,
                    }) === true
                  ) {
                    return false;
                  }
                  return true;
                },
              }}
              name="publishDate"
            />
          </Box>
          <Box width="100%">
            <HookTimePicker
              disabled={
                isLoading ||
                canNotUpdate ||
                !openDatetime ||
                disablePublishDateTime
              }
              slotProps={{
                textField: {
                  outerLabel: `Time (${timezoneAbbr})`,
                  id: "publishTime",
                  placeholder: "--:-- --",
                },
              }}
              callback={reValidateForm}
              name="publishTime"
              rules={{
                validate: {
                  isBeforeEventTime: (val) =>
                    isBeforeEventTime({
                      openDatetime: openDatetime as string,
                      publishTime: val,
                      publishDate,
                      timezoneId,
                    }),
                },
                required: {
                  value: true,
                  message: "Publish Time is required.",
                },
              }}
              sx={{ width: "100%" }}
            />
          </Box>
        </Stack>
      )}
    </>
  );
};

export default PublishDateForm;
