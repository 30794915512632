/* istanbul ignore file */
import "./globalStyles/reset.css";
import "./globalStyles/index.css";
import "swiper/css";
import "react-image-crop/dist/ReactCrop.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
// Material
import { CssBaseline } from "@mui/material";
// Say
import { Provider as SayProvider } from "@asayinc/component-library";

import * as serviceWorker from "./serviceWorker";
import { sayCustomBreakpoints } from "./constants";
import { MaintenanceMode } from "./components/Common";
import { router } from "./router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
  root.render(<MaintenanceMode />);
} else {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <SayProvider customBreakpoints={sayCustomBreakpoints}>
          <CssBaseline />
          <RouterProvider router={router} />
        </SayProvider>
      </Provider>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
