// components
import { CheckBoxOutlineBlank, LabelOutlined } from "@mui/icons-material";
import {
  getInitials,
  nFormatter,
  Row,
  DATE_FORMAT,
  CustomTypographyProps,
} from "@asayinc/component-library";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
// factories
import { createColumnData } from "../../../../../../../utils";
// types
import { TableEventData } from "../../../../../../../types/Table";
import { IEventParticipant } from "../../../../../../../types/Participants";
import { Tag } from "../../../../../../../types/Common";
// constants
import { SORT } from "../../../../../../../constants";
import { DELETED_USER_ID } from "../../../../../../../constants/settings";

dayjs.extend(relativeTime);
dayjs.extend(utc);

interface ColumnDataProps {
  goToShareholder: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}

const tdHeadProps = {
  textProps: { variant: "body3", weight: "normal" } as CustomTypographyProps,
};

export const getColumnData = ({
  goToShareholder,
  getSort,
  sortFn,
}: ColumnDataProps) => ({
  checkbox: createColumnData({
    title: "",
    style: "popoverMenu",
    width: "36px",
  }),
  shareholder: createColumnData({
    title: "Participant",
    tooltip: "Shareholder's name",
    style: "user",
    sort: getSort(SORT.shareholder),
    sortFn,
    width: "400px",
    clickHandler: goToShareholder,
    tdHeadProps,
  }),
  shares: createColumnData({
    title: "Shares owned",
    tooltip: "How many of your company's shares they own",
    style: "text",
    width: "400px",
    sort: getSort(SORT.shares),
    sortFn,
    clickHandler: goToShareholder,
    tdHeadProps,
  }),
  asked: createColumnData({
    title: "Questions asked",
    tooltip: "The amount of questions they submitted in this Q&A",
    style: "text",
    width: "400px",
    sort: getSort(SORT.asked),
    sortFn,
    clickHandler: goToShareholder,
    tdHeadProps,
  }),
  upvoted: createColumnData({
    title: "Upvoted",
    tooltip: "The amount of questions they upvoted in this Q&A",
    style: "text",
    width: "400px",
    sort: getSort(SORT.upvoted),
    sortFn,
    clickHandler: goToShareholder,
    tdHeadProps,
  }),
  answered: createColumnData({
    title: "Answered",
    tooltip: "The amount of questions they answered in this Q&A",
    style: "text",
    width: "400px",
    sort: getSort(SORT.answered),
    sortFn,
    clickHandler: goToShareholder,
    tdHeadProps,
  }),
  lastParticipated: createColumnData({
    title: "Last participated",
    tooltip: "Last time they submitted or upvoted a question in this Q&A",
    style: "text",
    width: "400px",
    sort: getSort(SORT.lastParticipated),
    sortFn,
    clickHandler: goToShareholder,
    tdHeadProps,
  }),
});

interface CreateParticipantsRowData {
  id: string;
  data: TableEventData;
  checkRow: (data: unknown) => void;
  openSingleTagDialog: (data: unknown) => void;
  checked: boolean;
  name: string;
  numUpvotes: number;
  numQuestionsAsked: number;
  numQuestionsAnswered: number;
  sharesOwned: string;
  lastParticipated: string;
  profileColor: string;
  tags: Tag[];
  areRowsSelected: boolean;
  highlighted: boolean;
}

export const createRowData = ({
  id,
  data,
  checkRow,
  checked,
  name,
  numUpvotes,
  numQuestionsAsked,
  numQuestionsAnswered,
  sharesOwned,
  profileColor,
  lastParticipated,
  tags,
  openSingleTagDialog,
  areRowsSelected,
  highlighted,
}: CreateParticipantsRowData): Row => ({
  rowData: {
    checkbox: {
      popoverMenuCellData: {
        selected: checked,
        handleCheckboxClick: checkRow,
        menuItems: [
          // if any row is selected, we'll hide the tag menuItem
          ...(!areRowsSelected
            ? [
                {
                  icon: <LabelOutlined />,
                  id: "tag",
                  onClick: openSingleTagDialog,
                },
              ]
            : []),
          // popoverMenuCells will not render menuItems when selected, so this
          // item can be added in the menuItems array without conditions
          {
            id: "check",
            icon: <CheckBoxOutlineBlank />,
            onClick: checkRow,
          },
        ],
      },
    },
    shareholder: {
      userCellData: {
        userName: name,
        initials: getInitials(name),
        profileColor: profileColor,
        isDeletedUser: id === DELETED_USER_ID,
      },
      tagsData: {
        tags,
        tagsToShow: 2,
      },
    },
    shares: { content: nFormatter(parseFloat(sharesOwned)) },
    asked: {
      content: numQuestionsAsked === 0 ? "-" : nFormatter(numQuestionsAsked),
    },
    upvoted: {
      content: numUpvotes === 0 ? "-" : nFormatter(numUpvotes),
    },
    answered: {
      content:
        numQuestionsAnswered === 0 ? "-" : nFormatter(numQuestionsAnswered),
    },
    lastParticipated: {
      content: dayjs(lastParticipated).format(DATE_FORMAT),
    },
  },
  id,
  data,
  highlighted,
});

interface RowDataProps {
  participants: IEventParticipant[];
  selectedIds: string[];
  checkRow: (data: unknown) => void;
  openSingleTagDialog: (data: unknown) => void;
  drawerShareholderId: string | null;
}

export const getRowData = ({
  participants,
  selectedIds,
  checkRow,
  openSingleTagDialog,
  drawerShareholderId,
}: RowDataProps) =>
  participants.map(
    (
      {
        globalUserId,
        globalShareholderId,
        name,
        profileColor,
        numUpvotes,
        numQuestionsAsked,
        numQuestionsAnswered,
        sharesOwned,
        lastParticipated,
        tags,
      },
      idx
    ) =>
      createRowData({
        id: globalUserId,
        data: {
          id: globalShareholderId,
          idx,
          globalUserId,
        },
        checkRow,
        openSingleTagDialog,
        checked: selectedIds.includes(globalShareholderId),
        name,
        numUpvotes,
        numQuestionsAsked,
        numQuestionsAnswered,
        sharesOwned,
        lastParticipated,
        profileColor,
        tags,
        areRowsSelected: selectedIds.length > 0,
        highlighted: drawerShareholderId === globalUserId,
      })
  );
