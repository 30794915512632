import { ChipV2 as Chip } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { EVENT_SORT_LABEL_MAP } from "../../../../../constants";
import { ISortOrderObj } from "../../../../../types/Events";
import { ReviewDetailsSection } from "../../Organisms";

interface IProps {
  ordering: ISortOrderObj[];
  editStep: (section: string) => void;
}

const ReviewSortOrder = ({ ordering, editStep }: IProps) => {
  return (
    <ReviewDetailsSection
      title="Shareholder question default sort order"
      cardName="sortOrder"
      editStep={editStep}
    >
      <Box mt={6}>
        <Chip
          size="small"
          label={EVENT_SORT_LABEL_MAP[ordering[0]?.value || "recommended"]}
        />
      </Box>
    </ReviewDetailsSection>
  );
};
export default ReviewSortOrder;
