// types
import { parseJSON } from "../../utils/parseJson";
import { ISentMessage, MessageType } from "../messageLists";
import { NOT_SENT_TYPES } from "../messageLists/constants";
import { rootApi } from "../rootApi";
import {
  IShareholderComposeMessage,
  IMessageFilterStats,
  IScheduledComposeMessage,
  IMessageTemplate,
  ISEOMetaData,
} from "./types";

interface IUpdateMessageSubmit extends Partial<IScheduledComposeMessage> {
  submit?: boolean;
}

// Message Compose Api
export const messageComposeApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * GET SINGLE message
     * */
    getMessage: builder.query<
      IShareholderComposeMessage | ISentMessage,
      string
    >({
      query: (id) => `messages/${id}/`,
      providesTags: (_response, _err, arg) => [
        "ShareholderMessage",
        { type: "ShareholderMessage", id: arg },
      ],
    }),
    /**
     * Create message
     * */
    createMessage: builder.mutation<
      IShareholderComposeMessage,
      Partial<IShareholderComposeMessage>
    >({
      query: (message) => ({
        url: `messages/compose/`,
        method: "POST",
        body: message,
      }),
      invalidatesTags: (_res, _err, arg) => [
        {
          type: "ShareholderMessagesList",
          id: `${arg.type}-${NOT_SENT_TYPES}`,
        },
      ],
    }),
    /**
     * Update message
     * */
    updateMessage: builder.mutation<
      IShareholderComposeMessage,
      {
        message: IUpdateMessageSubmit | Partial<IShareholderComposeMessage>;
        id: string;
      }
    >({
      query: ({ message, id }) => ({
        url: `messages/${id}/`,
        method: "PATCH",
        body: message,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          messageComposeApi.util.updateQueryData(
            "getMessage",
            id,
            (message: IShareholderComposeMessage | ISentMessage) => {
              Object.assign(message, patch);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (response, _err, arg) => [
        {
          type: "ShareholderMessagesList",
          id: `${response?.type}-${NOT_SENT_TYPES}`,
        },
        { type: "ShareholderMessage", id: arg.id },
      ],
    }),
    /**
     * Delete message
     * */
    deleteMessage: builder.mutation<void, { id: string; type: MessageType }>({
      query: ({ id }) => ({
        url: `messages/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_response, _err, { id, type }) => [
        {
          type: "ShareholderMessagesList",
          id: `${type}-${NOT_SENT_TYPES}`,
        },
        { type: "ShareholderMessage", id },
      ],
    }),
    /**
     * Duplicate message
     * */
    duplicateMessage: builder.mutation<{ id: string }, string>({
      query: (id) => ({
        url: `messages/${id}/duplicate/`,
        method: "POST",
      }),
    }),
    /**
     * Test Email
     **/
    sendTestEmail: builder.mutation<
      void,
      {
        messageId: string;
        data: { email: string; brokerId?: string; channel?: string };
      }
    >({
      query: ({
        messageId,
        data: { email, brokerId = "", channel = "email" },
      }) => ({
        url: `messages/${messageId}/send-test/`,
        method: "POST",
        body: {
          email,
          channel,
          brokerId,
        },
      }),
    }),
    /**
     * POST - get Filter Stats for message
     * */
    getFilterStats: builder.query<
      IMessageFilterStats,
      { query: string; proxyEventId?: string | null }
    >({
      query: ({ query, proxyEventId }) => ({
        url: `messages/recipients/filter/`,
        method: "POST",
        body: {
          query: {
            ...parseJSON(query),
            ...(proxyEventId ? { proxyEventId: { index: proxyEventId } } : {}),
          },
        },
      }),
    }),
    /**
     * GET - all message template options
     * */
    getMessageTemplates: builder.query<IMessageTemplate[], void>({
      query: () => ({
        url: "messages/templates/",
      }),
    }),
    /**
     * GET - single message template data
     * */
    getTemplateData: builder.query<IMessageTemplate, string>({
      query: (templateId) => `messages/templates/${templateId}/`,
    }),
    /**
     * GET - get SEO metadata
     * */
    getSEOMetaData: builder.query<ISEOMetaData, string>({
      query: (url) => `external/url-seo/?url=${encodeURIComponent(url)}`,
    }),
  }),
});

export const {
  useGetMessageQuery,
  useGetFilterStatsQuery,
  useCreateMessageMutation,
  useUpdateMessageMutation,
  useDuplicateMessageMutation,
  useSendTestEmailMutation,
  useDeleteMessageMutation,
  useGetMessageTemplatesQuery,
  useGetTemplateDataQuery,
  useGetSEOMetaDataQuery,
} = messageComposeApi;
