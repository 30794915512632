import { EventStatusOptions } from "../../../../../types/Events";

export enum EventCardButtons {
  Edit = "Edit",
  ViewDetails = "View Details",
  Questions = "Questions",
}

export const EventStatusButtonMapping = {
  [EventStatusOptions.DRAFT]: [EventCardButtons.Edit],
  [EventStatusOptions.PENDING]: [
    EventCardButtons.ViewDetails,
    EventCardButtons.Edit,
  ],
  [EventStatusOptions.APPROVED]: [
    EventCardButtons.ViewDetails,
    EventCardButtons.Edit,
  ],
  [EventStatusOptions.PUBLISHED]: [
    EventCardButtons.ViewDetails,
    EventCardButtons.Edit,
  ],
  [EventStatusOptions.ACTIVE]: [
    EventCardButtons.ViewDetails,
    EventCardButtons.Questions,
  ],
};

export const RightSectionHeaders = {
  [EventStatusOptions.DRAFT]: "Last edit",
  [EventStatusOptions.PENDING]: "Submitted",
  [EventStatusOptions.APPROVED]: "Approved",
  [EventStatusOptions.PUBLISHED]: "Published",
  [EventStatusOptions.ACTIVE]: "Currently asked",
};
