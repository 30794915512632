import { useCallback } from "react";
import { ButtonV2, Text } from "@asayinc/component-library";
import { FieldValues, UseFieldArrayAppend } from "react-hook-form";
// utils
import { generateRandomString } from "../../../../../../../utils/generateRandomString";
// components
import { IQuickReplyOptionObj } from "../../../../../../../store/messageCompose";
import { Stack } from "@mui/material";

interface IProps {
  textRepliesSelected: IQuickReplyOptionObj[];
  append: UseFieldArrayAppend<FieldValues, string>;
  disabled?: boolean;
}

const AddMoreTextReplies = ({
  textRepliesSelected,
  append,
  disabled,
}: IProps) => {
  /**
   * Append value to fieldArray
   */
  const handleAddTextReply = useCallback(() => {
    const newId = generateRandomString();
    append(
      {
        id: newId,
        value: "",
      },
      {
        shouldFocus: true,
      }
    );
  }, [textRepliesSelected]);

  if (textRepliesSelected.length < 3) {
    return (
      <ButtonV2
        variant="secondary"
        disabled={disabled}
        defaultIcon
        onClick={handleAddTextReply}
        data-testid="button-addMoreTextReply"
        sx={{
          ml: 3,
        }}
      >
        Add
      </ButtonV2>
    );
  }

  return (
    <Stack height="36px" mt={1.4} alignItems="center" direction="row">
      <Text variant="body2" emphasis="disabled" ml={3}>
        You can only add 3 options
      </Text>
    </Stack>
  );
};

export default AddMoreTextReplies;
