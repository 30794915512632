import { Box, Stack } from "@mui/material";

const IconWithDot = ({
  icon,
  showDot,
}: {
  icon: JSX.Element;
  showDot: boolean;
}) => (
  <Stack position="relative" justifyContent="center">
    {showDot && (
      <Box
        data-testid="new-feature-dot"
        sx={{
          position: "absolute",
          top: "-3px",
          right: "-3px",
          width: "6px",
          height: "6px",
          background: "#F12323",
          borderRadius: "20px",
        }}
      />
    )}
    {icon}
  </Stack>
);

export default IconWithDot;
