import { Drawer } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  selectMessageComposeData,
  setMessageDrawer,
} from "../../../../store/messageCompose";
import { Message } from "../../Pages";
import { useEffect } from "react";

export const TabOptions = {
  overview: {
    label: "Overview",
    value: "overview",
  },
  recipients: {
    label: "Recipients",
    value: "recipients",
  },
};

const MessageDrawer = () => {
  // state
  const dispatch = useAppDispatch();
  const { messageDrawerId } = useAppSelector(selectMessageComposeData);

  useEffect(() => {
    return () => {
      onClose();
    };
  }, []);

  const onClose = () => {
    dispatch(
      setMessageDrawer({
        messageDrawerId: null,
        messageDrawerProxyEventId: null,
      })
    );
  };

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      open={!!messageDrawerId}
      ModalProps={{
        BackdropProps: {
          sx: { backgroundColor: "transparent" },
        },
      }}
      onClose={onClose}
      data-testid="message-drawer"
      PaperProps={{ elevation: 5 }}
      sx={{
        ".MuiDrawer-paper.MuiPaper-root": {
          width: "600px",
          backgroundColor: "#F0F0F0",
          boxShadow:
            "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 3px 18px rgba(0, 0, 0, 0.1)",
          p: 10,
        },
      }}
    >
      {messageDrawerId && <Message asDrawer />}
    </Drawer>
  );
};
export default MessageDrawer;
