import { Tag } from "./Common";

export interface IShareholderMeta {
  firstName: string;
  lastName: string;
  globalUserId?: string;
  globalShareholderId?: string | null;
  firstEngagement: string | null;
  lastEngagement: string | null;
  sharesOwned: number;
  location: string;
  city: string;
  state: string;
  country: string;
  numQaParticipated: number;
  profileColor: string;
  tags: Tag[];
}

export interface IShareholderStats {
  numQaParticipated: number;
  numQuestionsAsked: number;
  numUpvotes: number;
  numQuestionsAnswered: number;
  numUpvotedQuestionsAnswered: number;
}

export interface IShareholderParticipationData {
  count: number;
  next: null;
  previous: null;
  results: IShareholderParticipation[];
}

export interface IShareholderParticipation {
  qaEventId: number;
  qaEventSlug: string;
  qaEventName: string;
  numQuestionsAsked: number;
  numUpvotes: number;
  numQuestionsAnswered: number;
  numUpvotedQuestionsAnswered: number;
  lastParticipated: string;
}

export interface IShareholderQuestion {
  id: string;
  authorName: string;
  globalUserId: string;
  profileColor: string;
  questionText: string;
  status: string;
  countSharesUpvoted: number;
  countUsersUpvoted: number;
  tags: Tag[];
}

export enum InvestorType {
  UNKNOWN = "",
  RETAIL = "retail",
  ANALYST = "analyst",
  INSTITUTIONAL = "institutional",
}

export interface IShareholderQuestionsEvent {
  qaEventSlug: string;
  qaEventName: string;
  questions: IShareholderQuestion[];
}

export interface IShareholderQuestionsData {
  count: number;
  next: null;
  previous: null;
  results: IShareholderQuestionsEvent[];
}

export interface IShareholderMessageStats {
  totalReceived: number;
  linkClickRate: string;
  openRate: string;
  replyRate: string;
}

export interface IShareholderMessageEngagement {
  clicked: boolean;
  opened: boolean;
  replied: boolean;
}

export interface IShareholderMessageReceived {
  id: string;
  campaignName: string;
  engagement: IShareholderMessageEngagement;
  sentDate: string;
  sentTimezone: string;
  containsLinks: boolean;
  containsReplies: boolean;
}

export interface IShareholderMessagesData {
  count: number;
  next: null;
  previous: null;
  results: IShareholderMessageReceived[];
}
