import { useParams } from "react-router-dom";
// components
import { Stack, Box } from "@mui/material";
import { Text, nFormatter } from "@asayinc/component-library";
import { CardWrapper } from "../../../../../../../components/Common";
// redux / data
import { useGetQuestionsQuery } from "../../../../../../../store/questions";
import {
  QuestionsApiResponse,
  IQuestionsAggregate,
} from "../../../../../../../store/questions";

const headlineLoadingProps = { sx: { width: "150px" } };
const subheadlineLoadingProps = { sx: { width: "75px" } };

const Summary = () => {
  const { eventSlug } = useParams() as { eventSlug: string };
  const { data: questionsData = {}, isLoading: isQuestionsDataLoading } =
    useGetQuestionsQuery({
      eventSlug,
      params: {},
    });
  const { data: aggData = {}, isLoading: isAggDataLoading } =
    useGetQuestionsQuery({
      eventSlug,
      params: { agg: "sum" },
    });

  const { count: questionsAsked } = questionsData as QuestionsApiResponse;
  const { numVotes } = aggData as IQuestionsAggregate;
  const avgUpvotes = ((numVotes || 0) / questionsAsked).toFixed(0);
  const isLoading = isQuestionsDataLoading || isAggDataLoading;

  return (
    <CardWrapper cardName="questions-summary">
      <Text variant="subtitle2" mb={6}>
        Questions summary
      </Text>
      <Stack direction="row" flexWrap="wrap" ml={8}>
        <Box width="33%">
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Questions asked"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : nFormatter(questionsAsked)}
          </Text>
        </Box>
        <Box width="33%">
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Upvotes received"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : nFormatter(numVotes || 0)}
          </Text>
        </Box>
        <Box width="33%">
          <Text variant="subtitle2" mb={3} loadingProps={headlineLoadingProps}>
            {isLoading ? undefined : "Average upvotes/question"}
          </Text>
          <Text variant="h5" mb={2} loadingProps={subheadlineLoadingProps}>
            {isLoading ? undefined : nFormatter(parseFloat(avgUpvotes))}
          </Text>
        </Box>
      </Stack>
    </CardWrapper>
  );
};

export default Summary;
