import {
  ElementType,
  HookSwitch,
  HookWYSIWYG,
  Text,
} from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { useGetDisclaimerQuery } from "../../../../../store/settings";
import {
  CardWrapper,
  FormSectionDivider,
} from "../../../../../components/Common";

interface IProps {
  isLoading?: boolean;
  messagesFlag?: boolean;
  voteSolicitationFlag?: boolean;
}

const QAFeature = ({
  isLoading,
  messagesFlag,
  voteSolicitationFlag,
}: IProps) => {
  const { data: disclaimerData = { disclaimer: "" } } = useGetDisclaimerQuery();
  const disclaimerMaxLength = 1000;

  return (
    <CardWrapper
      cardName="messages-feature"
      className="settingsSection"
      id="messages"
    >
      <Stack>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Messages
        </Text>
        <Text variant="body2">
          Shareholder Messages allows you to directly communicate with your
          shareholders and get their feedback on-demand.
        </Text>

        <Stack direction="row" alignItems="center" my={6}>
          <HookSwitch
            disableTypography
            label=""
            data-testid="messages"
            disabled
            name="features.messages"
            sx={{
              mr: 0,
            }}
          />
          <Text variant="subtitle2">
            {messagesFlag ? "Enabled" : "Disabled"}
          </Text>
        </Stack>
        <Text variant="subtitle1" sx={{ mb: 2 }}>
          Voting Messages
        </Text>
        <Text variant="body2">
          Voting Messages allow you to communicate directly with shareholders
          about proxy voting events.
        </Text>

        <Stack direction="row" alignItems="center" my={6}>
          <HookSwitch
            disableTypography
            label=""
            data-testid="voteSolicitation"
            disabled
            name="features.voteSolicitation"
            sx={{
              mr: 0,
            }}
          />
          <Text variant="subtitle2">
            {messagesFlag ? "Enabled" : "Disabled"}
          </Text>
        </Stack>
        {(messagesFlag || voteSolicitationFlag) && (
          <>
            <FormSectionDivider />
            <Stack mt={6}>
              <Text variant="subtitle1" mb={2}>
                Disclaimer
              </Text>
              <Text variant="body2" mb={6}>
                Provide a disclaimer below that will be added to all messages
                that you have enabled above.
              </Text>
              <HookWYSIWYG
                placeholder="Add your company disclaimer here"
                fieldName={"disclaimer"}
                disabled={isLoading}
                savedValue={disclaimerData.disclaimer}
                controls={[ElementType.Link]}
                showCharCount
                rules={{
                  maxLength: {
                    value: disclaimerMaxLength,
                    message: `Message exceeds ${disclaimerMaxLength} characters.`,
                  },
                }}
                maxLength={disclaimerMaxLength}
              />
            </Stack>
          </>
        )}
      </Stack>
    </CardWrapper>
  );
};

export default QAFeature;
