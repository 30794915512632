import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
// material
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box } from "@mui/material";
// say
import {
  AutocompleteTagging,
  AutoCompleteOption,
} from "@asayinc/component-library";
// constants
import { Tag } from "../../../../types/Common";
import { URL_PARAMS } from "../../../../constants";
import { validateParam } from "../../../../utils";

interface IProps {
  forceAutocompleteOpen?: boolean;
  tagOptions: Tag[];
  isLoading: boolean;
  ctaTitle: string;
  stateSearchParams?: URLSearchParams;
  stateSetSearchParams?: (params: URLSearchParams) => void;
}

const tagParameter = URL_PARAMS.tags;

const FilterTag = ({
  forceAutocompleteOpen = false,
  tagOptions,
  isLoading,
  ctaTitle,
  stateSearchParams,
  stateSetSearchParams,
}: IProps): JSX.Element | null => {
  // allows for updating url or not updating url and using state params instead
  const [urlSearchParams, urlSetSearchParams] = useSearchParams();
  const searchParams = stateSearchParams || urlSearchParams;
  const setSearchParams = stateSetSearchParams || urlSetSearchParams;
  const [tags, setTags] = useState<AutoCompleteOption[]>([]);

  // if tags are present in query string, loads tags into state on page load
  useEffect(() => {
    const tagIdsString: string = searchParams.get(tagParameter) || "";
    const validatedValue = validateParam(URL_PARAMS.tags, tagIdsString);
    // update query params if value changed during validation
    if (validatedValue !== tagIdsString) {
      searchParams.set(tagParameter, validatedValue);
      setSearchParams(searchParams);
    }
    if (validatedValue.length > 0 && tagOptions.length > 0) {
      const tagIdsList = validatedValue.split(",");
      const pageLoadTags = tagOptions.filter((tag) =>
        tagIdsList.includes(tag.id.toString())
      );
      setTags(pageLoadTags);
    }
  }, [isLoading]);

  const handleChange = ({
    allValues: allTags,
  }: {
    allValues: AutoCompleteOption[];
  }) => {
    const tagParam = allTags.map((tag) => tag.id).join(",");
    if (tagParam.length === 0) {
      searchParams.delete(tagParameter);
    } else {
      searchParams.set(tagParameter, tagParam);
    }
    searchParams.set(URL_PARAMS.page, "1");
    setSearchParams(searchParams);
    setTags(allTags);
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box>
      <AutocompleteTagging
        chipProps={{
          icon: <FilterListIcon />,
          variant: "secondary",
        }}
        options={tagOptions}
        onChange={handleChange}
        value={tags}
        title="FILTER BY"
        forceAutocompleteOpen={forceAutocompleteOpen}
        ctaTitle={ctaTitle}
        canCreate={false}
      />
    </Box>
  );
};

export default FilterTag;
