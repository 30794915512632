import { Stack } from "@mui/material";
import { Summary, UserQuestions } from "./components";

const QAActivity = () => {
  return (
    <Stack data-testid="shareholder-qa-activity" spacing={8}>
      <Summary />
      <UserQuestions />
    </Stack>
  );
};

export default QAActivity;
