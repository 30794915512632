import { ChipV2 as Chip } from "@asayinc/component-library";

import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

interface IProps {
  value: string;
  label: string;
  fieldName: string;
  selected: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const HookChipSelection = ({
  value,
  label,
  fieldName,
  selected,
  disabled,
  tooltip,
}: IProps) => {
  const { setValue } = useFormContext();

  const onClick = useCallback(() => {
    setValue(fieldName, value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, []);

  return (
    <Chip
      size="small"
      label={label}
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      tooltip={tooltip}
    />
  );
};

export default HookChipSelection;
