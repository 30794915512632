import {
  IFeatures,
  ISettings,
  IDisclaimerResponse,
  ICompanyOption,
} from "./types";
import { messageComposeApi } from "../messageCompose";
import { rootApi } from "../rootApi";

export const settingsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    // only for use by super users, to change which company the user is viewing
    getSuperUserCompanies: builder.query<ICompanyOption[], void>({
      query: () => "companies/ ",
    }),
    getSettings: builder.query<ISettings, void>({
      query: () => "company-profile/",
      providesTags: ["Settings"],
    }),
    updateSettings: builder.mutation<
      ISettings,
      {
        companyProfile: Partial<ISettings>;
        action: "save" | "submit";
      }
    >({
      query: ({ companyProfile, action }) => ({
        url: "company-profile/",
        method: "POST",
        body: {
          ...companyProfile,
          action,
        },
      }),
      invalidatesTags: ["Settings"],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            messageComposeApi.util.invalidateTags(["ShareholderMessage"])
          );
        } catch {}
      },
    }),
    getFeatures: builder.query<IFeatures, void>({
      query: () => "company-profile/features/",
      providesTags: ["Features"],
    }),
    updateFeatures: builder.mutation<IFeatures, IFeatures>({
      query: (features) => ({
        url: "company-profile/features/",
        method: "POST",
        body: { ...features },
      }),
      invalidatesTags: ["Features"],
    }),
    getDisclaimer: builder.query<IDisclaimerResponse, void>({
      query: () => "company-profile/disclaimer/",
      providesTags: ["Disclaimer"],
    }),
    updateDisclaimer: builder.mutation<IDisclaimerResponse, string>({
      query: (disclaimer) => ({
        url: "company-profile/disclaimer/",
        method: "POST",
        body: { disclaimer },
      }),
      invalidatesTags: ["Disclaimer"],
    }),
  }),
});

export const {
  useGetSuperUserCompaniesQuery,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  useGetFeaturesQuery,
  useUpdateFeaturesMutation,
  useGetDisclaimerQuery,
  useUpdateDisclaimerMutation,
} = settingsApi;
