import { IButtonAction, ScrollingNav, Text } from "@asayinc/component-library";
import { Box, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ListHeader, MiniDialog } from "../../components/Common";
import { SAY_ID, TESLA_ID } from "../../constants/settings";
import { CompanyProfile, SettingsFooter } from "./components";
import { getDefaultValues } from "./helpers/getDefaultValues";
import { parseFormData } from "./helpers/parseFormData";
import { useSettingsData } from "./hooks/useSettingsData";
import {
  useUpdateSettingsMutation,
  useUpdateDisclaimerMutation,
  useUpdateFeaturesMutation,
} from "../../store/settings";
import { ISettings } from "../../store/settings/types";
import { AuthFeature, MessageFeature, QAFeature } from "./components";
import { useSuccessErrorSnacks } from "../../hooks/useSuccessErrorSnacks";
import { getMenuSections } from "./helpers/getMenuSections";

interface Props {
  create?: boolean;
}

const Settings = ({}: Props): JSX.Element => {
  const [confirmOpen, setConfirm] = useState(false);

  // GET data
  const {
    settings,
    allCompanyLeaders,
    disclaimer,
    isSuccess,
    isApiFetching,
    features,
    isFeaturesLoading,
    isSettingsLoading,
    isLeaderSuccess,
  } = useSettingsData();
  // UPDATE Settings
  const [
    updateSettings,
    {
      isLoading: isMutationPending,
      isSuccess: isSettingsSuccess,
      isError: isSettingsError,
    },
  ] = useUpdateSettingsMutation();
  // UPDATE disclaimer
  const [
    updateDisclaimer,
    {
      isLoading: isDisclaimerLoading,
      isSuccess: isDisclaimerSuccess,
      isError: isDisclaimerError,
    },
  ] = useUpdateDisclaimerMutation();
  // UPDATE features
  const [
    updateFeatures,
    {
      isLoading: isUpdateFeaturesLoading,
      isSuccess: isFeaturesSuccess,
      isError: isFeaturesError,
    },
  ] = useUpdateFeaturesMutation();
  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
  });
  // save success
  const isMutationSuccess =
    isSettingsSuccess || isDisclaimerSuccess || isFeaturesSuccess;
  // save error
  const isMutationError =
    isSettingsError || isDisclaimerError || isFeaturesError;
  // check for tesla
  const companyId = settings?.companyId;
  const isTesla = companyId === TESLA_ID || companyId === SAY_ID;
  // Post mutations
  const isUpdateLoading =
    isMutationPending || isDisclaimerLoading || isUpdateFeaturesLoading;
  const isScrollNavLoading = isFeaturesLoading || isSettingsLoading;
  // is anything loading
  const isSomethingLoading = isApiFetching || isUpdateLoading;

  const {
    formState: { dirtyFields },
    watch,
  } = methods;

  // current values in form for features
  const formFeatures = watch("features");

  /**
   * Scroll to top of page after save
   */
  const saveCallback = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg: "Profile changes saved!",
    errorMsg: "Failed to save profile, please try again.",
    successAction: saveCallback,
    isSuccess: isMutationSuccess,
    isError: isMutationError,
  });

  /**
   * add menu section if tesla
   */
  const menuSections = useMemo(() => getMenuSections(isTesla), [isTesla]);

  /**
   * pull settings data to preset the form
   */
  useEffect(() => {
    document.title = `Settings | Say Issuer Portal`;
  }, []);

  /**
   * set default values after pulling settingsData and disclaimer data
   */
  useEffect(() => {
    if (isSuccess && !isApiFetching) {
      methods.reset(
        getDefaultValues({
          settings: settings as ISettings,
          allCompanyLeaders,
          disclaimer,
          features,
        })
      );
    }
  }, [isSuccess, isApiFetching]);

  /**
   * set leader values after each leader pull, these values are both pulled and updated seperately from the rest of the
   * form values, so needs to be handled independently
   */
  useEffect(() => {
    if (isLeaderSuccess) {
      methods.setValue("leaders", allCompanyLeaders);
    }
  }, [isLeaderSuccess]);

  /**
   * Reset form to current DB values
   */
  const resetForm = () => {
    methods.reset(
      getDefaultValues({
        settings: settings as ISettings,
        allCompanyLeaders,
        disclaimer,
        features,
      })
    );
  };

  /**
   * Save draft state of form
   */
  const save = async () => {
    const data = methods.getValues();
    const companyProfile = parseFormData(data, dirtyFields);
    const promises = [];
    // only update if companyProfile field is edited
    if (Object.keys(companyProfile).length) {
      promises.push(updateSettings({ companyProfile, action: "submit" }));
    }
    // only update if disclaimer field is edited
    if (dirtyFields.disclaimer) {
      promises.push(updateDisclaimer(data.disclaimer));
    }
    // only update if features field is edited
    if (dirtyFields.features) {
      promises.push(updateFeatures(data.features));
    }
    await Promise.all(promises);
  };

  /**
   * submit final state of form which will trigger a confirmation dialog
   */
  const onSubmit = () => {
    setConfirm(true);
  };

  /**
   * Submit company profile changed fields
   */
  const confirmSubmit = () => {
    save();
    closeDialog();
  };

  /**
   * Close submit profile form
   */
  const closeDialog = () => {
    setConfirm(false);
  };

  const buttonActions = [
    {
      onClick: confirmSubmit,
      label: "Submit",
    },
  ] as IButtonAction[];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack px={10} maxWidth={1440} m={"0 auto"}>
          <ListHeader title="Settings" sticky />
          <Stack direction="row" pt={6} justifyContent="space-between">
            <Stack ml={4} width={256} minWidth={200}>
              {!isSettingsLoading && (
                <ScrollingNav
                  sectionClassName="settingsSection"
                  menuSections={menuSections}
                  offset={50}
                  topPosition={104}
                  loading={isScrollNavLoading}
                  bottomOverlayHeight={113}
                />
              )}
            </Stack>
            <Stack spacing={8} pb={10} maxWidth={880} flexGrow={1}>
              <CompanyProfile isLoading={isSomethingLoading} />

              <QAFeature
                isLoading={isSomethingLoading}
                enabled={formFeatures?.qAndA}
              />
              <MessageFeature
                isLoading={isSomethingLoading}
                messagesFlag={formFeatures?.messages}
                voteSolicitationFlag={formFeatures?.voteSolicitation}
              />
              {isTesla && (
                <AuthFeature
                  isLoading={isSomethingLoading}
                  enabled={formFeatures?.authWithSay}
                />
              )}
              <MiniDialog
                open={confirmOpen}
                title="Submit Changes?"
                content={
                  <Box>
                    <Text variant="body1">
                      Say will check your changes for quality assurance. It may
                      take up to 1 business day to approve the changes to your
                      Company Profile.
                      <br /> <br />
                      Your Company Profile updates will be applied once
                      approved.
                    </Text>
                  </Box>
                }
                buttonActions={buttonActions}
                close={closeDialog}
              />
            </Stack>
          </Stack>
        </Stack>
        <SettingsFooter isLoading={isSomethingLoading} reset={resetForm} />
      </form>
    </FormProvider>
  );
};

export default Settings;
