import { ColumnType, nFormatter, Row } from "@asayinc/component-library";
import { SORT } from "../../../../../../../constants";
import { IMessageClickStats } from "../../../../../../../store/messageLists";

/**
 * Column data for table
 */
export const getColumnData = ({
  goToLink,
  getSort,
  sortFn,
}: {
  goToLink: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}): {
  link: ColumnType;
  clickPercent: ColumnType;
  count: ColumnType;
  shares: ColumnType;
} => ({
  link: {
    title: "Link",
    style: "multiTextTooltip",
    width: "225px",
    align: "left",
    sort: getSort(SORT.link),
    sortFn,
    clickHandler: goToLink,
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  },
  clickPercent: {
    title: "of total clicks",
    style: "text",
    width: "225px",
    sort: getSort(SORT.clickPercent),
    sortFn,
    align: "left",
  },
  count: {
    title: "Shareholders clicked",
    style: "text",
    width: "225px",
    sort: getSort(SORT.count),
    sortFn,
    align: "left",
  },
  shares: {
    title: "Shares",
    style: "text",
    width: "225px",
    sort: getSort(SORT.shares),
    sortFn,
    align: "left",
  },
});

/**
 * Each rows specific data for table
 */
export const getRowData = ({
  clickData,
}: {
  clickData: IMessageClickStats[];
}): Row[] =>
  clickData.map((clickItem) => ({
    rowData: {
      link: {
        multiTextTooltipData: {
          displayValues: [
            {
              displayText: clickItem.linkDescription || clickItem.link,
              link: clickItem.link,
            },
          ],
        },
      },
      clickPercent: {
        content: clickItem.shareOfTotalClicks
          ? `${Math.round(clickItem.shareOfTotalClicks * 100)}%`
          : "-",
      },
      count: { content: nFormatter(clickItem.count) },
      shares: { content: nFormatter(clickItem.sharesOwned) },
    },
    data: { link: clickItem.link },
    id: clickItem.id.toString(),
  }));
