import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { INPUT_TIME_FORMAT } from "@asayinc/component-library";

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  openDatetime: string;
  publishTime: Dayjs | null;
  publishDate: string | Dayjs | Date;
  timezoneId: string;
}

/**
 * ensure time is at or before the openDatetime
 */
export const isBeforeEventTime = ({
  openDatetime,
  publishTime,
  publishDate,
  timezoneId,
}: IProps) => {
  if (!publishDate || !openDatetime) {
    return true;
  }
  if (openDatetime && publishDate && !publishTime) {
    if (dayjs(publishDate).isAfter(dayjs(openDatetime))) {
      return "Must be at or before the event date";
    }
    return true;
  }
  const formattedDate = dayjs(publishDate).format("MM-DD-YYYY");
  const publishDatetime = dayjs
    .tz(
      `${formattedDate} ${publishTime?.format(INPUT_TIME_FORMAT)}`,
      "MM-DD-YYYY HH:mm",
      timezoneId
    )
    .utc()
    .format();

  if (dayjs(openDatetime).isBefore(dayjs(publishDatetime), "minute")) {
    return "Must be at or before the event date and time";
  }

  return true;
};
