import { COLORS } from "@asayinc/component-library";
import { Stack } from "@mui/material";
import { QuickReplyTypeOptions } from "../../../../../../../store/messageCompose";
import { QuickReplyType } from "../../Molecules";

interface IProps {
  handleSelection: (type: QuickReplyTypeOptions) => void;
  disabled?: boolean;
}

const QuickReplyPreviews = ({ handleSelection, disabled }: IProps) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <QuickReplyType
        onClick={handleSelection}
        type={QuickReplyTypeOptions.Text}
        disabled={disabled}
      />
      <div
        style={{
          width: "1px",
          background: disabled ? COLORS.P_DISABLED : COLORS.P_OUTLINE,
          marginLeft: "48px",
          marginRight: "48px",
        }}
      />
      <QuickReplyType
        onClick={handleSelection}
        type={QuickReplyTypeOptions.Emoji}
        disabled={disabled}
      />
    </Stack>
  );
};

export default QuickReplyPreviews;
