import { Box } from "@mui/material";
import { LineChart as RechartsLineChart, Line } from "recharts";
import { PointData } from "../../../types";

interface IProps {
  data: PointData[];
  lineColor: string;
}

const LineChart = ({ data, lineColor }: IProps) =>
  // prevent chart from rendering with an empty array to ensure
  // line animates from left side
  data.length > 0 ? (
    <RechartsLineChart
      width={232}
      height={64}
      data={data}
      margin={{
        top: 0,
        right: 0,
        left: 3,
        bottom: 2,
      }}
    >
      <Line
        type="monotone"
        dataKey="val"
        stroke={lineColor}
        strokeWidth={3}
        dot={false}
      />
    </RechartsLineChart>
  ) : (
    <Box sx={{ width: "232px", height: "64px" }} />
  );

export default LineChart;
