import dayjs, { Dayjs } from "dayjs";

export const isAfterVoteCutoff = (
  voteCutoff?: string | null,
  dayToCompare?: Dayjs | null
) => {
  const today = dayjs(dayToCompare);
  const dayjsVoteCutoff = dayjs(voteCutoff);
  return today.isAfter(dayjsVoteCutoff);
};
