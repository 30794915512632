export const columns: string[] = [
  "checkbox",
  "shareholder",
  "shares",
  "asked",
  "upvoted",
  "lastParticipated",
];

export const completedColumns = [
  "checkbox",
  "shareholder",
  "shares",
  "asked",
  "upvoted",
  "answered",
  "lastParticipated",
];
