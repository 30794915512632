import { Text } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { track } from "../../../../../analytics";
import { CompanyLeadershipEditor } from "../../../../../components/Common";
import { CREATION_STEP_MAPPING } from "../../../constants";
import { useCreateQAData } from "../../../hooks";

interface IProps {
  isLoading?: boolean;
}

// Section of form where leaders are selected
const CompanyParticipantsForm = ({ isLoading }: IProps) => {
  const { companyName, event } = useCreateQAData();
  // analytics for add or edit leader
  const onLeaderEditOrAdd = (type: "Add Leader" | "Save Leader") => {
    track({
      name: type,
      client: companyName,
      qaEvent: event.slug,
      qaStatus: event.status,
      composeStep: CREATION_STEP_MAPPING[2],
    });
  };
  return (
    <Stack>
      <Box mb={6}>
        <Stack direction="row" sx={{ mb: 2 }}>
          <Text variant="subtitle1">Company Participants</Text>
        </Stack>
        <Text variant="body2">
          Select and order the list of company leaders who will be addressing
          questions during the event. (Optional)
        </Text>
      </Box>
      <Box>
        <CompanyLeadershipEditor
          editCallback={onLeaderEditOrAdd}
          draggable
          editable
          selectable
          isLoading={isLoading}
        />
      </Box>
    </Stack>
  );
};

export default CompanyParticipantsForm;
