import { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
// components
import { Dialog, IButtonAction } from "@asayinc/component-library";
import { LeaderForm } from "../../../Common";
// redux / data
import { useAppSelector } from "../../../../store/hooks";
import {
  usePatchLeaderMutation,
  usePostLeaderMutation,
} from "../../../../store/companyLeadership";
import { useGetSettingsQuery } from "../../../../store/settings";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { selectStatus } from "../../../../store/common";
// types
import { ICompanyLeader } from "../../../../types/Events";
import { ISettings } from "../../../../store/settings/types";
// utils
import { getDirtyFormValues } from "../../../../utils";
import {
  initialState,
  useGetMessageQuery,
} from "../../../../store/messageCompose";
import { useParams } from "react-router-dom";
import { CREATION_STEP_MAPPING } from "../../../../pages/Messages/subPages/CreateAndEdit/constants";
import { track } from "../../../../analytics";

interface IProps {
  open: boolean;
  closeDialog: () => void;
  leader: ICompanyLeader | null;
  leaders: ICompanyLeader[];
  isCreate: boolean;
}

const defaultValues = {
  companyId: "",
  fullName: "",
  id: "",
  longTitle: "",
  photo: "",
  shortTitle: "",
} as ICompanyLeader;

const SignatureDialog = ({
  open,
  closeDialog,
  leader,
  leaders,
  isCreate,
}: IProps): JSX.Element => {
  const commonStatus = useAppSelector(selectStatus);
  const { data: settings = {} } = useGetSettingsQuery();
  const { companyId, name: companyName = "" } = settings as ISettings;
  // current message id
  const { messageId: id = "" } = useParams() as { messageId: string };
  const { data = initialState } = useGetMessageQuery(id);
  const [patchLeader, patchLeaderRes] = usePatchLeaderMutation();
  const [postLeader, postLeaderRes] = usePostLeaderMutation();

  /** is patch or post loading */
  const isLoading =
    patchLeaderRes.isLoading ||
    postLeaderRes.isLoading ||
    commonStatus === "loading";

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg: "Leader successfully saved.",
    errorMsg: "Failed to save leader, please try again.",
    isSuccess: postLeaderRes.isSuccess || patchLeaderRes.isSuccess,
    isError: postLeaderRes.isError || patchLeaderRes.isError,
  });

  /**
   * Set select value in compose flow when submitting form
   */
  const { setValue: setSignatureSelectValue } = useFormContext();

  useEffect(() => {
    const {
      isLoading: isPatchLoading,
      isSuccess: isPatchSuccess,
      data: patchData,
    } = patchLeaderRes;
    if (!isPatchLoading && isPatchSuccess) {
      const leaderId = patchData.id;
      setSignatureSelectValue("content.signatureId", leaderId);
      closeDialog();
    }
  }, [patchLeaderRes]);
  /**
   * For new leaders, set new signature value after leaders data is updated
   */
  useEffect(() => {
    const {
      isLoading: isPostLoading,
      isSuccess: isPostSuccess,
      data: postData,
    } = postLeaderRes;
    const leadersDataUpdated = !!leaders.find(
      (leader) => leader.id === postData?.id
    );
    if (!isPostLoading && isPostSuccess && leadersDataUpdated) {
      closeDialog();
      // using setTimeout ensures react-hook-form updates correctly
      setTimeout(() => {
        setSignatureSelectValue("content.signatureId", postData.id);
      }, 1);
    }
  }, [postLeaderRes, leaders]);

  const {
    control,
    register,
    setValue: setLeaderValue,
    setError,
    getValues,
    reset,
    formState: { isDirty, isValid, dirtyFields },
  } = useForm({
    criteriaMode: "all",
    mode: "all",
  });
  const canSave = isValid && isDirty;

  useEffect(() => {
    reset(leader || defaultValues, { keepErrors: true });
  }, [leader]);

  /**
   * reset form on open if in create mode
   */
  useEffect(() => {
    if (open && isCreate) {
      reset();
    }
  }, [isCreate, open]);

  const saveLeader = () => {
    const formValues = getDirtyFormValues(
      getValues(),
      dirtyFields
    ) as Partial<ICompanyLeader>;
    const leaderData = {
      ...formValues,
      photo: formValues.photoFilename,
      photoFilename: undefined,
    } as ICompanyLeader;

    track({
      name: isCreate ? "Add Leader" : "Save Leader",
      campaign: data.campaignName,
      campaignStatus: data.status,
      composeStep: CREATION_STEP_MAPPING[2],
      client: companyName,
    });

    if (isCreate) {
      postLeader(leaderData);
    } else {
      patchLeader({ leader: leaderData, id: (leader as ICompanyLeader).id });
    }
  };

  const actions = [
    {
      onClick: saveLeader,
      disabled: !canSave || isLoading,
      label: "Save",
    },
  ] as IButtonAction[];

  return (
    <Dialog
      data-testid="signature-dialog"
      open={open}
      title="Company Leadership"
      handleClose={closeDialog}
      buttonActions={actions}
      content={
        <LeaderForm
          control={control}
          register={register}
          setLeaderValue={setLeaderValue}
          isDisabled={isLoading}
          setError={setError}
          leader={leader || defaultValues}
          companyId={companyId}
        />
      }
    />
  );
};

export default SignatureDialog;
