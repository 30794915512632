import { Text } from "@asayinc/component-library";
import { Stack, Box } from "@mui/material";
import { sanitize } from "dompurify";
import { Leader } from "../../../../../../../components/Common/Organisms/CompanyLeadershipEditor";
import { ICompanyLeadershipResponse } from "../../../../../../../types/Questions";

interface IProps {
  companyLeadershipResponse: ICompanyLeadershipResponse[];
}

const QuestionDetailAnsweredQuestions = ({
  companyLeadershipResponse,
}: IProps) => {
  return (
    <Stack>
      <Text variant="subtitle2" mb={2}>
        Your answer
      </Text>
      <Text variant="body2" mb={6}>
        This is added by Say after your Q&A event.
      </Text>
      <Stack gap={6}>
        {companyLeadershipResponse.map((response, i) => (
          <Box key={i}>
            <Leader idx={0} leader={response.companyLeader} />
            <Text
              ml={16}
              dangerouslySetInnerHTML={{
                __html: sanitize(response.response),
              }}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default QuestionDetailAnsweredQuestions;
