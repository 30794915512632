import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
// components
import { ButtonV2 } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { InteractiveLeadershipList, LeaderDialog } from "./components";
// redux / data
import {
  usePostLeaderMutation,
  usePatchLeaderMutation,
  useDeleteLeaderMutation,
} from "../../../../store/companyLeadership";
import { useGetSettingsQuery } from "../../../../store/settings";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
// types
import { ISettings } from "../../../../store/settings/types";
import { ICompanyLeader } from "../../../../types/Events";

interface IProps {
  selectable?: boolean;
  draggable?: boolean;
  editable?: boolean;
  deletable?: boolean;
  isLoading?: boolean;
  editCallback?: (type: "Add Leader" | "Save Leader") => void;
}

const CompanyLeadershipEditor = ({
  selectable,
  draggable,
  editable,
  deletable,
  isLoading,
  editCallback,
}: IProps) => {
  const { data: settings = {} } = useGetSettingsQuery();
  const { companyId } = settings as ISettings;
  const { setValue } = useFormContext();

  /**
   * we're displaying both inputs and using the input data to render leaders
   * So we need to watch the entire object so we can display changes
   */
  const leaders =
    useWatch({
      name: "leaders",
    }) || [];

  const [isInitialized, setIsInitialized] = useState(false);
  const [createLeader, createLeaderRes] = usePostLeaderMutation();
  const [patchLeader, patchLeaderRes] = usePatchLeaderMutation();
  const [removeLeader] = useDeleteLeaderMutation();

  useSuccessErrorSnacks({
    successMsg: "Leader successfully saved.",
    errorMsg: "Failed to save leader, please try again.",
    isSuccess: createLeaderRes.isSuccess || patchLeaderRes.isSuccess,
    isError: createLeaderRes.isError || patchLeaderRes.isError,
  });

  /** for analytics, alert when add or edit occurs */
  useEffect(() => {
    if (patchLeaderRes.isSuccess && editCallback) {
      editCallback("Save Leader");
    }
  }, [patchLeaderRes.isSuccess]);
  useEffect(() => {
    if (createLeaderRes.isSuccess && editCallback) {
      editCallback("Add Leader");
    }
  }, [createLeaderRes.isSuccess]);
  /** end analytics callback */

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (leaders.length && !isInitialized) {
      setIsInitialized(true);
    }
  }, [leaders.length]);

  /**
   * dispatch deletion of a leader based on id
   */
  const deleteLeader = (id: string) => {
    removeLeader(id);
  };

  /**
   * save changes to or create a new leader
   */
  const saveOrAddLeader = ({
    leader,
    id,
    add,
  }: {
    leader: Partial<ICompanyLeader>;
    add?: boolean;
    id: string;
  }) => {
    if (add) {
      // dont want to reset form, so just update form with id from api request
      createLeader(leader as ICompanyLeader)
        .unwrap()
        .then((newLeader) => {
          setValue(`leaders.${id}.id`, newLeader.id, { shouldTouch: true });
        });
    } else {
      patchLeader({ id, leader });
    }
  };

  return (
    <>
      <InteractiveLeadershipList
        selectable={selectable}
        leaders={leaders}
        draggable={draggable}
        editable={editable}
        deletable={deletable}
        isLoading={isLoading}
        saveOrAddLeader={saveOrAddLeader}
        deleteLeader={deleteLeader}
      />
      {!isLoading ? (
        <Box mt={6}>
          <ButtonV2
            variant="secondary"
            onClick={openDialog}
            data-testid="button-addMoreLeader"
          >
            Add another leader
          </ButtonV2>
        </Box>
      ) : null}

      <LeaderDialog
        open={isDialogOpen}
        closeDialog={closeDialog}
        addNew={true}
        isDisabled={isLoading}
        idx={leaders.length}
        saveOrAddLeader={saveOrAddLeader}
        leader={{
          fullName: "",
          companyId,
          id: leaders.length.toString(),
          longTitle: "",
          shortTitle: "",
          enabled: false,
          photo: "",
        }}
      />
    </>
  );
};

export default CompanyLeadershipEditor;
