import { RootState } from "../store";
import { ISnackBar } from "./types";

export const selectSnackbar = (state: RootState): ISnackBar =>
  state.common.snackbar;

export const selectStatus = (state: RootState): string => state.common.status;

export const selectPrevPage = (state: RootState): string =>
  state.common.prevPage;
