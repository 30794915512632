import { Column } from "@asayinc/component-library";

export const COLUMN_DATA: Column = {
  location: {
    title: "Location",
    style: "text",
    align: "left",
    width: "300px",
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  },
  percent: {
    title: "Percentage of shareholders",
    style: "text",
    align: "left",
    width: "210px",
  },
};

export const COLUMNS = ["location", "percent"];

export const OTHERS_TITLE = "All others";
