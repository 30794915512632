import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Stack } from "@mui/material";
import { COLORS } from "@asayinc/component-library";

interface IProps {
  direction: "l" | "r";
  onClick: () => void;
  isDisabled: boolean;
}

const CarouselArrow = ({ onClick, direction, isDisabled }: IProps) => {
  return (
    <Stack
      width={40}
      position="absolute"
      className={`arrow-${direction}`}
      justifyContent="center"
      alignItems="center"
      height={40}
      right={direction === "r" ? -20 : "auto"}
      left={direction === "l" ? -20 : "auto"}
      border={`1px solid ${COLORS.P_OUTLINE}`}
      zIndex="1"
      borderRadius="50%"
      bgcolor={COLORS.WHITE}
      top="calc(50% - 16px)"
      style={{
        opacity: isDisabled ? "0" : "1",
        transition: "opacity 0.5s linear",
        transform: `translateY(-50%)${
          direction === "l" ? " rotate(180deg)" : ""
        }`,
        cursor: isDisabled ? "auto" : "pointer",
      }}
      onClick={onClick}
    >
      <KeyboardArrowRightIcon />
    </Stack>
  );
};

export default CarouselArrow;
