import { ButtonBase, lighten, Stack } from "@mui/material";
import { COLORS, Text } from "@asayinc/component-library";
import React, { SyntheticEvent } from "react";
import { ShareholderInitials } from "../../Atoms/";
import { DELETED_USER_ID } from "../../../../constants/settings";

interface IProps {
  authorName: string;
  profileColor: string;
  useBadgeStyles: boolean;
  globalUserId?: string;
  numShareholderTags?: number;
  goToShareholder?: ((e: SyntheticEvent) => void) | (() => void);
}
const badgeStyles = {
  background: COLORS.WHITE,
  py: 1,
  pl: 2,
  pr: 3,
  borderRadius: 2,
  alignSelf: "flex-start",
  border: `1px solid ${COLORS.P_OUTLINE}`,
  cursor: "pointer",
  "&:hover": {
    background: lighten(COLORS.SAY_COBALT, 0.88),
  },
};

const deletedUserStyles = {
  cursor: "not-allowed",
  "&:hover": {
    background: "none",
  },
};

const ShareholderChip = ({
  authorName,
  profileColor,
  useBadgeStyles,
  numShareholderTags,
  goToShareholder,
  globalUserId,
}: IProps) => {
  const isDeletedUser = globalUserId === DELETED_USER_ID;
  return (
    <ButtonBase
      component="div"
      data-testid={`shareholder-chip-${authorName}`}
      onClick={isDeletedUser ? undefined : goToShareholder}
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        ...(useBadgeStyles ? badgeStyles : undefined),
        ...(isDeletedUser ? deletedUserStyles : undefined),
      }}
    >
      <ShareholderInitials
        isDeletedUser={isDeletedUser}
        profileColor={profileColor}
        authorName={authorName}
      />
      <Text variant="body3" ml={3}>
        {authorName}
      </Text>
      {numShareholderTags ? (
        <Stack
          alignItems="center"
          width="24px"
          height="16px"
          ml={2}
          bgcolor={lighten(COLORS.SAY_COBALT, 0.88)}
          borderRadius={3}
        >
          <Text variant="caption" emphasis="medium">
            {numShareholderTags}
          </Text>
        </Stack>
      ) : null}
    </ButtonBase>
  );
};

export default React.memo(ShareholderChip);
