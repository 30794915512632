import { rootApi } from "../rootApi";
// types
import { IMessageOverviewAPIResponse, MessageExportTypes } from "./types";

// Message Compose Api
export const messageStatsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * GET Message Overview Stats
     * */
    getMessageOverview: builder.query<IMessageOverviewAPIResponse, string>({
      query: (id) => `messages/${id}/overview/`,
      providesTags: (_response, _err, arg) => [
        "ShareholderSentMessage",
        { type: "ShareholderSentMessage", id: arg },
      ],
    }),
    /**
     * Export a Message stats
     */
    exportMessageData: builder.mutation<
      { message: string },
      { exportType: MessageExportTypes; messageId: string }
    >({
      queryFn: async (
        { exportType, messageId },
        _api,
        _extraOptions,
        baseQuery
      ) => {
        try {
          const result = await baseQuery({
            url: `messages/${messageId}/${exportType}/export/?format=csv`,
            headers: {
              Accept: "text/csv",
            },
            responseHandler: (response) => response.text(),
          });
          if (result.error) {
            throw new Error();
          }

          const blob = new Blob([result.data as BlobPart], {
            type: "application/octet-stream",
          });
          const href = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute(
            "download",
            `message-${messageId}-${exportType}.csv`
          );
          link.setAttribute("data-testid", "csv-download-link");
          document.body.appendChild(link);
          // cant run in jest
          /* istanbul ignore next */
          if (href) {
            link.click();
          }
          return {
            data: {
              message: `Message ${messageId} ${exportType} exported!`,
            },
          };
        } catch {
          return {
            error: {
              status: 500,
              data: { message: "Failed to parse CSV data" },
            },
          };
        }
      },
    }),
  }),
});

export const { useGetMessageOverviewQuery, useExportMessageDataMutation } =
  messageStatsApi;
