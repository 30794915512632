import { useContext } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
// components
import { Stack } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Text, pluralize, IconButton } from "@asayinc/component-library";
import QuestionListItem from "../../../../../Organisms/QuestionListItem";
import { CardWrapper } from "../../../../../../../../../../components/Common";
// redux / data
import { Context } from "../../../../../../context";
import {
  useGetShareholderStatsQuery,
  useGetShareholderQuestionsUpvotedQuery,
} from "../../../../../../../../../../store/shareholder";
// types
import {
  IShareholderStats,
  IShareholderQuestionsEvent,
} from "../../../../../../../../../../types/Shareholder";
// constants
import { TabOptions } from "../../../../../../constants";

const QASummary = () => {
  const { data, setActiveTab } = useContext(Context);
  const goToQa = () => {
    setActiveTab(TabOptions.qa.value);
  };

  const globalUserId = data?.globalUserId;
  const { data: statsData = {} } = useGetShareholderStatsQuery(
    globalUserId ? globalUserId : skipToken
  );
  const { numQaParticipated } = statsData as IShareholderStats;

  const { data: questionsData, isLoading: isQuestionsDataLoading } =
    useGetShareholderQuestionsUpvotedQuery(
      globalUserId
        ? { globalUserId, offset: 0, includeOwnQuestions: true }
        : skipToken
    );

  const hasQaActivity = !isQuestionsDataLoading && questionsData?.count;

  if (!hasQaActivity) {
    return null;
  }

  let qaItem = null;
  if (hasQaActivity) {
    const mostRecentEvent = questionsData?.results[0];
    const { qaEventName, qaEventSlug, questions } =
      mostRecentEvent as IShareholderQuestionsEvent;
    const mostRecentQuestion = questions[0];
    const activityType =
      questions[0].globalUserId === globalUserId ? "asked" : "upvoted";
    qaItem = (
      <Stack spacing={3}>
        <Text variant="body2">
          Most recently {activityType} in {qaEventName}
        </Text>
        <QuestionListItem
          question={mostRecentQuestion}
          qaEventSlug={qaEventSlug}
          isLastItem={true}
        />
      </Stack>
    );
  }

  return (
    <CardWrapper cardName="qa-summary">
      <Stack spacing={6}>
        <Stack spacing={3}>
          <Stack direction="row" alignItems="center" height="20px">
            <Text variant="subtitle2" loadingProps={{ sx: { width: "100px" } }}>
              Total participated
            </Text>
            <IconButton
              onClick={goToQa}
              data-testid="qa-summary-button"
              sx={{ ml: "auto" }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>
          <Text variant="h5" loadingProps={{ sx: { width: "200px" } }}>
            {numQaParticipated} {pluralize("Q&A", "Q&As", numQaParticipated)}
          </Text>
        </Stack>
        {qaItem}
      </Stack>
    </CardWrapper>
  );
};

export default QASummary;
