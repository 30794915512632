import { CardWrapper } from "../../../../../components/Common";
import { EventTypeForm } from "../../Organisms";

interface IProps {
  isLoading?: boolean;
  isDraft?: boolean;
}

const EventTypeTemplate = ({ isLoading, isDraft }: IProps): JSX.Element => {
  return (
    <CardWrapper full cardName="eventType">
      <EventTypeForm isLoading={isLoading} isDraft={isDraft} />
    </CardWrapper>
  );
};

export default EventTypeTemplate;
