import { Text } from "@asayinc/component-library";
import { Formatter } from "recharts/types/component/DefaultLegendContent";

export const renderLegend: Formatter = (value, entry) => {
  const { color } = entry;
  return (
    <Text
      variant="subtitle3"
      component="span"
      data-testid="legend-text"
      textTransform="capitalize"
      style={{ color, marginRight: "6px" }}
    >
      {value}
    </Text>
  );
};

export const renderLoadingLegend: Formatter = (_value, entry) => {
  const { color } = entry;
  return (
    <Text
      variant="subtitle3"
      component="span"
      data-testid="legend-text"
      textTransform="capitalize"
      style={{ color }}
      loadingProps={{ sx: { width: 100 } }}
    />
  );
};
