// types
import { IURL_PARAMS } from "../../types/Common";
import {
  IShareholderListResponse,
  IShareholderListRequestQuery,
} from "./types";
import { rootApi } from "../rootApi";

// Shareholder Lists Api
export const shareholderListApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getShareholders: builder.query<
      IShareholderListResponse,
      { params: IURL_PARAMS; query?: IShareholderListRequestQuery }
    >({
      query: ({ params, query = {} }) => {
        const queryString = new URLSearchParams(params).toString();
        return {
          url: `shareholder-network/?${queryString}`,
          method: "POST",
          body: { query },
        };
      },
    }),
  }),
});

export const { useGetShareholdersQuery } = shareholderListApi;
