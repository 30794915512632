import { ButtonV2 as Button } from "@asayinc/component-library";
import { Stack } from "@mui/material";

interface IProps {
  handleView: () => void;
  createFromTemplate: () => void;
  isFeatureEnabled: boolean;
}

// CTA's display for when messageTemplateTile is not displayed as a card
const OverlayCtas = ({
  handleView,
  createFromTemplate,
  isFeatureEnabled,
}: IProps) => {
  return (
    <Stack
      className="messageTemplate__overlay"
      alignItems="center"
      justifyContent="center"
      gap={3}
      sx={{
        opacity: 0,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        bgcolor: "rgba(0,0,0,0.6)",
        pointerEvents: "none",
        height: "100%",
        transition: "opacity 0.3s ease-in-out",
        borderRadius: 2,
      }}
    >
      <Button
        size="mini"
        data-testid="button-usetemplate"
        onClick={createFromTemplate}
      >
        {isFeatureEnabled ? "Use template" : "Try for free"}
      </Button>
      <Button
        variant="secondary"
        data-testid="button-view"
        size="mini"
        onClick={handleView}
      >
        View
      </Button>
    </Stack>
  );
};

export default OverlayCtas;
