import { IFilterAutocompleteValue } from "@asayinc/component-library";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Message Compose Api
export const staticJsonApi = createApi({
  reducerPath: "staticJsonApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/data" }),
  endpoints: (builder) => ({
    /**
     * GET all state names for filtering
     * */
    getCountryFilterOptions: builder.query<IFilterAutocompleteValue[], void>({
      query: () => `/countries.json`,
      transformResponse: (response: string[]) =>
        response.map((r) => ({
          id: r,
          name: r,
        })),
    }),
    /**
     * GET all state names for filtering
     * */
    getStateFilterOptions: builder.query<IFilterAutocompleteValue[], void>({
      query: () => `/states.json`,
      transformResponse: (response: string[]) =>
        response.map((r) => ({
          id: r,
          name: r,
        })),
    }),
    /**
     * GET city mapping json data
     * */
    getStateCityMapping: builder.query<{ [state: string]: string[] }, void>({
      query: () => `/cityMap.json`,
    }),
  }),
});

export const {
  useGetCountryFilterOptionsQuery,
  useGetStateFilterOptionsQuery,
  useGetStateCityMappingQuery,
} = staticJsonApi;
