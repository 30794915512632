import { COLORS } from "@asayinc/component-library";
import {
  EventStateOptions,
  EventTypeOptions,
  EventStatusOptions,
} from "../types/Events";
import { EVENT_STATUS_VALUES } from "./filters";

export const EVENT_STATE_MAPPING = {
  [EventStateOptions.ARCHIVED]: {
    color: COLORS.LIGHT_GREY,
    content: "Past",
  },
  [EventStateOptions.SUBMISSION_CLOSED]: {
    color: COLORS.LIGHT_GREY,
    content: "Past",
  },
  [EventStateOptions.SUBMISSION_OPEN]: {
    color: COLORS.SAY_COBALT,
    content: "Active",
  },
  [EventStateOptions.UNPUBLISHED]: {
    color: COLORS.PERIWINKLE,
    content: "Upcoming",
  },
};

export const EVENT_TYPE_LABEL_MAP = {
  [EventTypeOptions.EARNINGS_CALL]: "Earnings Call",
  [EventTypeOptions.INVESTOR_DAY]: "Investor Day",
  [EventTypeOptions.PRODUCT_LAUNCH]: "Product Launch",
  [EventTypeOptions.SHAREHOLDER_MEETING]: "Shareholder Meeting",
  [EventTypeOptions.WEBINAR]: "Webinar",
};

export const EVENT_TYPE_SELECT_OPTIONS = [
  {
    label: EVENT_TYPE_LABEL_MAP[EventTypeOptions.EARNINGS_CALL],
    value: EventTypeOptions.EARNINGS_CALL,
  },
  {
    label: EVENT_TYPE_LABEL_MAP[EventTypeOptions.INVESTOR_DAY],
    value: EventTypeOptions.INVESTOR_DAY,
  },
  {
    label: EVENT_TYPE_LABEL_MAP[EventTypeOptions.PRODUCT_LAUNCH],
    value: EventTypeOptions.PRODUCT_LAUNCH,
  },
  {
    label: EVENT_TYPE_LABEL_MAP[EventTypeOptions.SHAREHOLDER_MEETING],
    value: EventTypeOptions.SHAREHOLDER_MEETING,
  },
  {
    label: EVENT_TYPE_LABEL_MAP[EventTypeOptions.WEBINAR],
    value: EventTypeOptions.WEBINAR,
  },
];

export const EVENT_QUARTER_SELECT_OPTIONS = [
  {
    label: "Q1",
    value: "Q1",
  },
  {
    label: "Q2",
    value: "Q2",
  },
  {
    label: "Q3",
    value: "Q3",
  },
  {
    label: "Q4",
    value: "Q4",
  },
];

/**
 * Mapping for badges and event status'
 */
export const EVENT_STATUS_BADGE_MAPPING = {
  [EventStatusOptions.ACTIVE]: {
    label: EVENT_STATUS_VALUES[EventStatusOptions.ACTIVE],
    color: COLORS.LIGHT_PURPLE,
  },
  [EventStatusOptions.APPROVED]: {
    label: EVENT_STATUS_VALUES[EventStatusOptions.APPROVED],
    color: COLORS.LIGHT_GREEN,
  },
  [EventStatusOptions.COMPLETED]: {
    label: EVENT_STATUS_VALUES[EventStatusOptions.COMPLETED],
    color: COLORS.LIGHT_GREY,
  },
  [EventStatusOptions.DRAFT]: {
    label: EVENT_STATUS_VALUES[EventStatusOptions.DRAFT],
    color: COLORS.LIGHT_RED,
  },
  [EventStatusOptions.PENDING]: {
    label: EVENT_STATUS_VALUES[EventStatusOptions.PENDING],
    color: COLORS.LIGHT_YELLOW,
  },
  [EventStatusOptions.PUBLISHED]: {
    label: EVENT_STATUS_VALUES[EventStatusOptions.PUBLISHED],
    color: COLORS.LIGHT_BLUE,
  },
};

export enum QaPublishOptions {
  Specific = "specific",
  OnceApproved = "at_approval",
  StartTime = "at_event_start",
}

export const QA_PUBLISH_OPTIONS = [
  {
    label: "Specific date and time",
    value: QaPublishOptions.Specific,
  },
  {
    label: "Once this Q&A is approved",
    value: QaPublishOptions.OnceApproved,
  },
  {
    label: "Same as when shareholders start submitting questions",
    value: QaPublishOptions.StartTime,
  },
];
