import { Card } from "@asayinc/component-library";
import { CardProps, SxProps } from "@mui/material";
import { EditIcon } from "../../Atoms";

interface IProps extends CardProps {
  sx?: SxProps;
  children: React.ReactNode;
  full?: boolean;
  onEdit?: ((e: React.MouseEvent<HTMLElement>) => void) | (() => void);
  cardName: string;
  variant?: "outlined" | undefined;
}

const CardWrapper = ({
  sx = {},
  children,
  onEdit,
  cardName,
  full,
  ...rest
}: IProps) => {
  return (
    <Card
      hasHoverBg={false}
      elevation={5}
      data-testid={`card-${cardName}`}
      sx={{
        p: 8,
        borderRadius: 2,
        overflow: "visible",
        height: full ? "100%" : "auto",
        position: "relative",
        "&:hover .editIcon": {
          opacity: 1,
        },
        ".editIcon": {
          opacity: 0,
          transition: "all 0.2s linear",
        },
        ...sx,
      }}
      {...rest}
    >
      <EditIcon
        className="editIcon"
        cardName={cardName}
        onEdit={onEdit}
        inset
      />
      {children}
    </Card>
  );
};

export default CardWrapper;
