// components
import { Dialog, Text, IButtonAction } from "@asayinc/component-library";
// store / data
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  MessageDialog,
  selectMessageComposeData,
  setMessageDialog,
} from "../../../../../store/messageCompose";
import { useNavigate, useParams } from "react-router-dom";

const ConfirmEditDialog = (): JSX.Element => {
  const navigate = useNavigate();
  const { proxyEventId } = useParams();
  const dispatch = useAppDispatch();
  const { messageDialog, messageDialogId } = useAppSelector(
    selectMessageComposeData
  );

  const onContinueClick = () => {
    if (proxyEventId) {
      navigate(`/vote/${proxyEventId}/messages/${messageDialogId}/edit`);
    } else {
      navigate(`/messages/${messageDialogId}/edit`);
    }
    closeDialog();
  };

  const closeDialog = () => {
    dispatch(
      setMessageDialog({
        messageDialogId: null,
        messageDialog: null,
      })
    );
  };

  const buttonActions = [
    {
      label: "Yes, continue",
      onClick: onContinueClick,
      ["data-testid"]: "button-dialog-continue",
    },
    {
      label: "Cancel",
      onClick: closeDialog,
      variant: "secondary",
    },
  ] as IButtonAction[];

  return (
    <Dialog
      titleTextVariant="subtitle1"
      title="Edit a scheduled message"
      data-testid="confirm-edit-dialog"
      handleClose={closeDialog}
      buttonActions={buttonActions}
      content={
        <Text variant="body1" width="336px">
          The message is approved and scheduled to send to your shareholders.
          Updated message will need to be approved again. Are you sure you want
          to edit the message?
        </Text>
      }
      open={messageDialog === MessageDialog.ConfirmEdit}
    />
  );
};

export default ConfirmEditDialog;
