import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
// material
import { SelectChangeEvent } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
// say
import { Select } from "@asayinc/component-library";
// constants
import { URL_PARAMS } from "../../../../constants";
// utils
import { validateParam } from "../../../../utils";

interface IProps {
  queryParameter: URL_PARAMS;
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  customDisplayValue?: string;
  stateSearchParams?: URLSearchParams;
  stateSetSearchParams?: (params: URLSearchParams) => void;
}

const GenericFilter = ({
  queryParameter,
  label,
  options,
  customDisplayValue,
  stateSearchParams,
  stateSetSearchParams,
}: IProps): JSX.Element => {
  // allows for updating url or not updating url and using state params instead
  const [urlSearchParams, urlSetSearchParams] = useSearchParams();
  const searchParams = stateSearchParams || urlSearchParams;
  const setSearchParams = stateSetSearchParams || urlSetSearchParams;
  const [firstRender, setFirstRender] = useState(true);
  const filterValue = validateParam(
    queryParameter,
    searchParams.get(queryParameter) || ""
  );

  // update query params if value changed during validation
  useEffect(() => {
    // prevent running on first render to avoid duplicate history states
    if (!firstRender) {
      if (
        searchParams.get(queryParameter) &&
        searchParams.get(queryParameter) !== filterValue
      ) {
        searchParams.set(queryParameter, filterValue);
        setSearchParams(searchParams);
      }
    } else {
      setFirstRender(false);
    }
  }, []);

  const mapping = useMemo(
    () =>
      options.reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {}),
    []
  );

  const handleChange = (e: SelectChangeEvent<unknown>): void => {
    const type = e.target.value as string;
    searchParams.set(queryParameter, type);
    searchParams.set(URL_PARAMS.page, "1");
    setSearchParams(searchParams);
  };

  const onClear = () => {
    searchParams.delete(queryParameter);
    searchParams.set(URL_PARAMS.page, "1");
    setSearchParams(searchParams);
  };

  return (
    <Select
      chipProps={{
        variant: "secondary",
        label: label,
        icon: <FilterListIcon fontSize="small" />,
        onDelete: filterValue ? onClear : undefined,
      }}
      onChange={handleChange}
      valueMapping={mapping}
      clearable
      value={filterValue}
      asChip
      name="filter"
      options={options}
      customDisplayValue={customDisplayValue}
    />
  );
};

export default GenericFilter;
