import { HookTextField, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";
import { useWatch } from "react-hook-form";

interface IProps {
  isDisabled?: boolean;
  charLimit: number;
  companyName: string;
}

const InvestorDayFields = ({ isDisabled, charLimit, companyName }: IProps) => {
  // this inputs current value
  const investorDayName = useWatch({
    name: "meta.investorDayName",
  });

  return (
    <>
      <Text variant="subtitle1">Investor Day name</Text>
      <Box width="100%">
        <HookTextField
          variant="outlined"
          outerLabel={`${companyName} ${investorDayName || ""} Q&A`}
          id="investorDayName"
          inputSize="small"
          disabled={isDisabled}
          showCharChount
          maxLength={charLimit}
          name="meta.investorDayName"
          placeholder="e.g., Q1 2023 Investor Day"
          rules={{
            required: {
              value: true,
              message: "Investor Day name is required.",
            },
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </>
  );
};

export default InvestorDayFields;
