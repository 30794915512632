import { COLORS } from "@asayinc/component-library";
import { Stack, StackProps, SxProps } from "@mui/material";
import React, { useMemo } from "react";

const baseEmojiChipStyles: SxProps = {
  border: `1px solid ${COLORS.P_OUTLINE}`,
  borderRadius: "50px",
  background: COLORS.WHITE,
  height: 48,
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

const shadow =
  "0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)";

interface IProps extends StackProps {
  children: React.ReactNode;
  isDragging?: boolean;
  focused?: boolean;
  error?: boolean;
}

const BaseOptionChip = ({
  sx,
  isDragging,
  focused,
  children,
  error,
  ...rest
}: IProps) => {
  const styles = useMemo(
    () => ({
      ...baseEmojiChipStyles,
      ...sx,
      boxShadow: isDragging ? shadow : "none",
      ...(focused && { border: `1px solid ${COLORS.SAY_COBALT}` }),
      ...(error && { border: `1px solid ${COLORS.SATURATED_RED}` }),
    }),
    [isDragging, focused, error]
  );
  return (
    <Stack sx={styles} direction="row" {...rest}>
      {children}
    </Stack>
  );
};

export default BaseOptionChip;
