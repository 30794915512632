import { Box } from "@mui/material";
import { COLORS, Dialog, TableV2 } from "@asayinc/component-library";
import { useMemo } from "react";
import { IMultiStat } from "../../../../../../../components/Common/Molecules/StatCard/StatCard";
import { COLUMN_DATA, COLUMNS, OTHERS_TITLE } from "./constants";

interface IProps {
  open: boolean;
  close: () => void;
  locationStats: IMultiStat[];
}

const LocationDialog = ({ open, close, locationStats }: IProps) => {
  // set row data for table
  // add tooltip icon if its the aggregated row
  const rowData = useMemo(
    () =>
      locationStats.map((loc, i) => ({
        data: {},
        id: `${i}-${loc.title || ""}`,
        rowData: {
          location: {
            content: loc.title, // show tooltip for aggregated values
            toolTipIcon:
              loc.title === OTHERS_TITLE
                ? "All other locations contain US states and non‒US countries where less than 1％ of shareholders on Say reside."
                : undefined,
          },
          percent: {
            content: loc.value,
          },
        },
      })),
    [locationStats]
  );

  return (
    <Dialog
      open={open}
      handleClose={close}
      title="Locations"
      sx={{
        ".MuiPaper-root": {
          border: "none",
          boxShadow: "none",
        },
      }}
      content={
        <Box
          sx={{
            overflowY: "scroll",
            maxHeight: "540px",
            borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
          }}
        >
          <TableV2
            rows={rowData}
            count={1}
            tableLayout="auto"
            columnData={COLUMN_DATA}
            columns={COLUMNS}
            tableMinWidth={600}
            testid="locations-table"
            memoCells
          />
        </Box>
      }
    />
  );
};

export default LocationDialog;
