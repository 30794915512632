import {
  ColumnType,
  DATE_FORMAT,
  nFormatter,
  Row,
} from "@asayinc/component-library";
import { IMessageListItem } from "../../../../../../../store/messageLists";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// dayjs
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { MessageStatus } from "../../../../../../../types/Messages";
import { SORT } from "../../../../../../../constants";

dayjs.extend(relativeTime);
dayjs.extend(utc);

/**
 * Column data for table
 */
export const getColumnData = ({
  goToMessage,
  getSort,
  sortFn,
}: {
  goToMessage: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}): {
  campaignName: ColumnType;
  deliveredCount: ColumnType;
  scheduledSendAt: ColumnType;
  openedRate: ColumnType;
  clickedRate: ColumnType;
  repliedRate: ColumnType;
} => ({
  campaignName: {
    title: "Campaign name",
    style: "text",
    width: "200px",
    sort: getSort(SORT.campaignName),
    sortFn,
    align: "left",
    clickHandler: goToMessage,
    tdBodyProps: {
      textProps: {
        variant: "subtitle2",
      },
    },
  },
  deliveredCount: {
    title: "Reached",
    style: "icon",
    width: "100px",
    sort: getSort(SORT.deliveredCount),
    sortFn,
    clickHandler: goToMessage,
    align: "left",
  },
  scheduledSendAt: {
    title: "Send date",
    style: "text",
    width: "100px",
    sort: getSort(SORT.scheduledSendAt),
    sortFn,
    clickHandler: goToMessage,
    align: "left",
  },
  openedRate: {
    title: "Opened",
    style: "text",
    width: "100px",
    sort: getSort(SORT.openedRate),
    sortFn,
    clickHandler: goToMessage,
    align: "left",
  },
  clickedRate: {
    title: "Links clicked",
    style: "text",
    width: "100px",
    sort: getSort(SORT.clickedRate),
    sortFn,
    clickHandler: goToMessage,
    align: "left",
  },
  repliedRate: {
    title: "Replied",
    style: "text",
    width: "100px",
    sort: getSort(SORT.repliedRate),
    sortFn,
    clickHandler: goToMessage,
    align: "left",
  },
});

export interface CreateSentMessageRowData extends IMessageListItem {
  data: {
    id: string;
    idx: number;
  };
}

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */
export const createRowData = ({
  campaignName,
  scheduledSendAt,
  status,
  stats: { deliveredCount, openedRate, clickedRate, repliedRate },
  id,
  data,
}: CreateSentMessageRowData): Row => ({
  rowData: {
    campaignName: { content: campaignName },
    deliveredCount: {
      content: deliveredCount ? nFormatter(deliveredCount) : "-",
      Icon:
        status !== MessageStatus.Attached && status !== MessageStatus.Sent
          ? AccessTimeIcon
          : undefined,
      tooltip:
        "Your message is still being sent out to all selected shareholders. This may take up to a few hours.",
    },
    scheduledSendAt: {
      content: dayjs.utc(scheduledSendAt).local().format(DATE_FORMAT),
    },
    openedRate: {
      content: openedRate ? `${Math.round(openedRate * 100)}%` : "-",
    },
    clickedRate: {
      content: clickedRate ? `${Math.round(clickedRate * 100)}%` : "-",
    },
    repliedRate: {
      content: repliedRate ? `${Math.round(repliedRate * 100)}%` : "-",
    },
  },
  data,
  id,
});

/**
 * Each rows specific data for table
 */
export const getRowData = ({
  messages,
}: {
  messages: IMessageListItem[];
}): Row[] =>
  messages.map((message, idx) =>
    createRowData({
      ...message,
      data: {
        id: message.id,
        idx,
      },
    })
  );
