import React, { useEffect, useRef, useState } from "react";
// components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { COLORS, Text, IconButton } from "@asayinc/component-library";
import QuestionListItem from "../../../../QuestionListItem";
import { CardWrapper } from "../../../../../../../../../../components/Common";
import {
  InfiniteScrollWrapper,
  LoadingSpinner,
  EmptyQuestionView,
} from "../../Atoms";
// types
import { IShareholderQuestionsEvent } from "../../../../../../../../../../types/Shareholder";

interface IProps {
  count?: number;
  questionData: IShareholderQuestionsEvent[];
  emptyHeader: string;
  isLoading: boolean;
  emptySubHeader: string;
  getMoreItems: () => void;
  hasMore: boolean;
}

const QuestionList = ({
  count,
  questionData,
  isLoading,
  emptyHeader,
  emptySubHeader,
  getMoreItems,
  hasMore,
}: IProps): JSX.Element | null => {
  const [toggledSlug, setToggledSlug] = useState("");
  const didMount = useRef<boolean>(false);
  const [allQuestionData, setQuestionData] = useState<
    IShareholderQuestionsEvent[]
  >(questionData || []);

  useEffect(() => {
    // if mounting hasnt been set, than this first pass of data is already added in initial state
    if (questionData.length && didMount.current) {
      setQuestionData([...allQuestionData, ...questionData]);
    }
  }, [questionData]);

  useEffect(() => {
    didMount.current = true;
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (allQuestionData.length === 0) {
    return (
      <EmptyQuestionView
        emptyHeader={emptyHeader}
        emptySubHeader={emptySubHeader}
      />
    );
  }

  return (
    <CardWrapper cardName="question-list">
      <Text variant="subtitle2" mb={5}>
        {count} questions
      </Text>
      <InfiniteScrollWrapper
        isLoading={isLoading}
        dataLength={allQuestionData.length}
        next={getMoreItems}
        hasMore={hasMore}
        reCalculateTrigger={toggledSlug}
      >
        {allQuestionData.map(({ qaEventName, qaEventSlug, questions }, i) => (
          <Box key={qaEventSlug}>
            <Accordion
              disableGutters
              defaultExpanded
              onChange={() => setToggledSlug(qaEventSlug)} // used to let inf scroll know to re-calculate heights
              square
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  borderBottom: `1px solid ${COLORS.P_OUTLINE}`,
                },
                ".MuiAccordionSummary-root.Mui-expanded": {
                  borderBottom: "none",
                },
                ".MuiCollapse-entered .MuiAccordionDetails-root": {
                  mb: i !== allQuestionData.length - 1 ? 10 : 0,
                },
              }}
              //
            >
              <AccordionSummary
                expandIcon={
                  <IconButton
                    data-testid="qa-summary-button"
                    sx={{ ml: "auto" }}
                  >
                    <ExpandMore />
                  </IconButton>
                }
                sx={{
                  pl: 0,
                  mt: 6,
                  minHeight: 0,
                  alignItems: "flex-start",
                  ".MuiAccordionSummary-content": {
                    m: 0,
                    mb: 5,
                  },
                }}
              >
                <Text variant="subtitle1">{qaEventName}</Text>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {questions.map((question, i) => (
                  <QuestionListItem
                    key={`${question.id}-key`}
                    question={question}
                    qaEventSlug={qaEventSlug}
                    isLastItem={i === questions.length - 1}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </InfiniteScrollWrapper>
    </CardWrapper>
  );
};

export default React.memo(QuestionList);
