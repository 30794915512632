import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
// components
import { Stack } from "@mui/material";
import {
  Dialog,
  IButtonAction,
  Text,
  ElementType,
  HookWYSIWYG,
} from "@asayinc/component-library";
// data
import {
  useGetDisclaimerQuery,
  useUpdateDisclaimerMutation,
} from "../../../../../store/settings";
import { useUpdateMessageMutation } from "../../../../../store/messageCompose";
import { useSuccessErrorSnacks } from "../../../../../hooks/useSuccessErrorSnacks";
// types
import { IDisclaimerResponse } from "../../../../../store/settings/types";

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

const DisclaimerDialog = ({ open, closeDialog }: IProps) => {
  const { messageId } = useParams() as { messageId: string };
  const { data = {}, isSuccess, isFetching } = useGetDisclaimerQuery();
  const { disclaimer = "" } = data as IDisclaimerResponse;
  const [
    updateDisclaimer,
    { isSuccess: isUpdateSuccess, isError: isUpdateError },
  ] = useUpdateDisclaimerMutation();
  const [updateMessage, { isError: isUpdateMessageError }] =
    useUpdateMessageMutation();

  const methods = useForm({
    criteriaMode: "all",
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: { disclaimer },
  });
  const {
    getValues,
    reset,
    handleSubmit,
    formState: { isDirty, isValid, errors },
  } = methods;

  // are there any errors
  const hasError = Object.keys(errors).length > 0;

  const disclaimerMaxLength = 1000;

  /**
   * set default values after pulling settingsData
   */
  useEffect(() => {
    if (isSuccess && !isFetching) {
      reset({ disclaimer });
    }
  }, [isSuccess, isFetching, open]);

  // show success/error snackbar on api callback
  useSuccessErrorSnacks({
    successMsg: "Your company’s message disclaimer is updated!",
    errorMsg: "Failed to submit message disclaimer, please try again.",
    errorAction: closeDialog,
    successAction: closeDialog,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError || isUpdateMessageError,
  });

  const onSubmitClick = async () => {
    const { disclaimer: formDisclaimerValue } = getValues();
    await updateDisclaimer(formDisclaimerValue);
    // make patch request to refresh disclaimer in message preview
    updateMessage({
      id: messageId,
      message: {},
    });
  };

  const canSave = (!hasError || isValid) && isDirty;
  const buttonActions = [
    {
      label: "Submit",
      disabled: !canSave,
      onClick: handleSubmit(onSubmitClick),
    },
    {
      label: "Cancel",
      onClick: closeDialog,
    },
  ] as IButtonAction[];

  return (
    <Dialog
      titleTextVariant="subtitle1"
      title="Disclaimer"
      data-testid="disclaimer-dialog"
      handleClose={closeDialog}
      buttonActions={buttonActions}
      content={
        <Stack spacing={6}>
          <Text variant="body1" width="598px">
            Optionally, add a disclaimer to go along with your messages. Once
            saved, this disclaimer will be applied across all messages you send
            in the future.
          </Text>
          <FormProvider {...methods}>
            <HookWYSIWYG
              placeholder="Add your company disclaimer here"
              fieldName={"disclaimer"}
              savedValue={disclaimer}
              controls={[ElementType.Link]}
              rules={{
                maxLength: {
                  value: disclaimerMaxLength,
                  message: `Message exceeds ${disclaimerMaxLength} characters.`,
                },
              }}
              maxLength={disclaimerMaxLength}
            />
          </FormProvider>
        </Stack>
      }
      open={open}
    />
  );
};

export default DisclaimerDialog;
