import { CustomDialogProps, Dialog, Text } from "@asayinc/component-library";
import { Box } from "@mui/material";

interface Props extends CustomDialogProps {
  content: string;
  title: string;
  open: boolean;
}

const BasicOverlay = ({ content, title, ...rest }: Props): JSX.Element => {
  const renderTitle = () => {
    return (
      <Box px={6} pt={5} pb={2}>
        <Text variant="subtitle1">{title}</Text>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <Box px={6} minWidth={280}>
        <Text variant="body2">{content}</Text>
      </Box>
    );
  };

  return (
    <Dialog
      customTitle={renderTitle()}
      content={renderContent()}
      customActionStyles={{
        margin: 0,
        pt: 6,
      }}
      {...rest}
      fullBleed
    />
  );
};

export default BasicOverlay;
