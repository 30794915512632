import { COLORS, Text } from "@asayinc/component-library";
import { Stack, SxProps } from "@mui/material";

const chipStyles: SxProps = {
  border: `1px solid ${COLORS.P_OUTLINE}`,
  borderRadius: 5,
  width: 140,
  height: 30,
  alignItems: "center",
  justifyContent: "center",
};

const TextResponsePreview = () => {
  return (
    <Stack gap={4}>
      <Stack sx={chipStyles}>
        <Text variant="body2" emphasis="medium">
          Option A
        </Text>
      </Stack>
      <Stack sx={chipStyles}>
        <Text variant="body2" emphasis="medium">
          Option B
        </Text>
      </Stack>
      <Stack sx={chipStyles}>
        <Text variant="body2" emphasis="medium">
          Option C
        </Text>
      </Stack>
    </Stack>
  );
};

export default TextResponsePreview;
