import { Outlet, useNavigate, useMatch, Navigate } from "react-router-dom";
// components
import { Stack, Box } from "@mui/material";
import { Tabs } from "@asayinc/component-library";
import { useState } from "react";
import { useMessageParams } from "../../../../hooks/useMessageParams";
import {
  MessageHeader,
  MessageOverview,
  MessageRecipients,
  MessageReport,
} from "../../../../components/Messages";
import { TabOptions } from "../../../../constants/message";

interface IProps {
  asDrawer?: boolean;
}

const Message = ({ asDrawer }: IProps): JSX.Element => {
  const navigate = useNavigate();

  // get messageID and proxyId either from url or redux, based on if its in a drawer or not
  const { messageId, proxyEventId, tabs, isMessageLoading } =
    useMessageParams(asDrawer);
  const [drawerActiveTab, setDrawerActiveTab] = useState(
    TabOptions.overview.value
  );
  const match = useMatch(
    proxyEventId
      ? `/vote/:proxyEventId/messages/:messageId/:paramTab`
      : `/messages/:messageId/:paramTab`
  ) as {
    params: { paramTab: string; messageId: string };
  };
  const paramTab = match?.params?.paramTab;
  // use tab based on state or params based on if in drawer
  const activeTab = asDrawer ? drawerActiveTab : paramTab;

  // redirect from root messages/messageid page
  if (!match && !asDrawer) {
    return (
      <Navigate
        to={
          proxyEventId
            ? `/vote/${proxyEventId}/messages/${messageId}/overview`
            : `/messages/${messageId}/overview`
        }
        replace
      />
    );
  }

  const setActiveTab = (tab: string) => {
    if (asDrawer) {
      setDrawerActiveTab(tab);
    } else {
      if (proxyEventId) {
        navigate(
          `/vote/${proxyEventId}/messages/${messageId}/${tab.toLowerCase()}`
        );
      } else {
        navigate(`/messages/${messageId}/${tab.toLowerCase()}`);
      }
    }
  };

  const DrawerTabContent = (
    <>
      {activeTab === TabOptions.overview.value && <MessageOverview asDrawer />}
      {activeTab === TabOptions.report.value && <MessageReport asDrawer />}
      {activeTab === TabOptions.recipients.value && (
        <MessageRecipients asDrawer />
      )}
    </>
  );

  return (
    <Stack
      data-testid="message-page"
      p={asDrawer ? 0 : 10}
      minHeight="100%"
      width="100%"
      maxWidth={1440}
      mx={["0", "auto"]}
    >
      <MessageHeader
        messageId={messageId}
        proxyEventId={proxyEventId}
        asDrawer={asDrawer}
      />
      <Box mb={8}>
        {!isMessageLoading ? (
          <Tabs activeTab={activeTab} tabs={tabs} clickHandler={setActiveTab} />
        ) : null}
      </Box>
      {/* Content */}
      {asDrawer ? DrawerTabContent : <Outlet />}
    </Stack>
  );
};

export default Message;
